import type { FC, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { Input } from 'antd';
import type { TextAreaProps } from 'antd/es/input';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { useValidationDebounce } from '../../hooks';
import FloatingLabel from '../FloatingLabel';
import FormItem, { FormItemOwnProps } from '../FormItem';
import ClearFieldButton from '../ClearFieldButton';
import type { TextFieldProps } from '../types';
import { noop } from 'lodash';

import * as felaRules from './TextAreaField.styles';
import TextAreaFieldFooter from './TextAreaFieldFooter';

export interface TextAreaFieldOwnProps extends TextFieldProps {
    textareaProps?: TextAreaProps;
    fluid?: FormItemOwnProps['fluid'];
    footer?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
    clear?: () => void;
}

const TextAreaField: FC<TextAreaFieldOwnProps> = ({
    label,
    required = false,
    validate,
    help,
    textareaProps,
    footer,
    fluid,
    onBlur = noop,
    clear,
    extend,
    ...props
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend, footer: Boolean(footer) });
    const validateDebounced = useValidationDebounce(validate);
    return (
        <Field<string> validate={validateDebounced} validateFields={[]} {...props}>
            {({ input, meta }) => (
                <FormItem
                    {...{
                        input,
                        meta,
                        required,
                        help,
                        fluid,
                        extend: {
                            // TODO: Find a better solution
                            // @ts-ignore
                            formItem: (args, renderer) => rules.formItem({ ...args, active: meta.active }, renderer),
                        },
                    }}
                >
                    <ClearFieldButton name={props.name} clear={clear} extend={{ button: rules.clearButton }} />
                    <FloatingLabel label={label} input={input}>
                        <Input.TextArea
                            id={input.name}
                            className={styles.textarea}
                            disabled={props.disabled}
                            {...textareaProps}
                            {...input}
                            onBlur={onBlur}
                        />
                    </FloatingLabel>
                    {footer && <TextAreaFieldFooter fieldName={props.name}>{footer}</TextAreaFieldFooter>}
                </FormItem>
            )}
        </Field>
    );
};

export default TextAreaField;
