import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils';

import type { GenerationProgress } from 'modules/entities/modules/jobs';
import {
    apiActions,
    JobStates,
    selectJobCompetitionId,
    selectJobDescription,
    selectJobPercentage,
    selectJobProgressState,
    selectJobsApi,
    selectJobState,
    selectJobVideoURL,
} from 'modules/entities/modules/jobs';

import { Job } from '../../entities';
import { JOB_PROGRESS_POLL_INTERVAL } from '../config';

const useJobPolling = (jobId: Job['id']) => {
    const api: ApiReducerState = useSelector(selectJobsApi);
    const dispatch = useDispatch();
    const jobState = useSelector(state => selectJobState(state, { id: jobId }));
    const percentage: number = useSelector(state => selectJobPercentage(state, { id: jobId }));
    const competitionId: string = useSelector(state => selectJobCompetitionId(state, { id: jobId }));
    const videoURL: string = useSelector(state => selectJobVideoURL(state, { id: jobId }));
    const description: string = useSelector(state => selectJobDescription(state, { id: jobId }));
    const state: GenerationProgress = useSelector(state => selectJobProgressState(state, { id: jobId }));

    useEffect(() => {
        dispatch(apiActions.fetchJob.request(jobId));

        const interval = setInterval(() => {
            dispatch(apiActions.fetchJob.request(jobId));
        }, JOB_PROGRESS_POLL_INTERVAL);

        if (jobState === JobStates.FINISHED || jobState === JobStates.ERROR_VIDEO_GENERATE || videoURL) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [jobState, dispatch, jobId, videoURL]);
    return { ...api, percentage, jobState, description, state, competitionId, videoURL };
};

export default useJobPolling;
