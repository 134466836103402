import React, { FC } from 'react';
import AntdIcon from '@ant-design/icons';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Icon.styles';

import Analytics from './Analytics';
import Calendar from './Calendar';
import Tips from './Tips';
import Plus from './Plus';
import Close from './Close';
import Back from './Back';
import Facebook from './Facebook';
import FacebookRound from './FacebookRound';
import Google from './Google';
import Instagram from './Instagram';
import User from './User';
import HamburgerMenu from './HamburgerMenu';
import Eye from './Eye';
import EyeHidden from './EyeHidden';
import Edit from './Edit';
import Bin from './Bin';
import Drag from './Drag';
import Info from './Info';
import Upload from './Upload';
import UploadComputer from './UploadComputer';
import Minus from './Minus';
import Pixabay from './Pixabay';
import Search from './Search';
import CarretDown from './CarretDown';
import Comment from './Comment';
import Selected from './Selected';
import Error from './Error';
import Forward from './Forward';
import Play from './Play';
import FastForward from './FastForward';
import FastPrevious from './FastPrevious/FastPrevious';
import Previous from './Previous';
import ComingSoon from './ComingSoon';

import instagramRound from 'assets/images/icons/social_instagram@2x.png';

export enum Types {
    ANALYTICS = 'analytics',
    CALENDAR = 'calendar',
    TIPS = 'tips',
    PLUS = 'plus',
    CLOSE = 'close',
    BACK = 'back',
    FACEBOOK = 'facebook',
    FACEBOOK_ROUND = 'facebook-round',
    INSTAGRAM_ROUND = 'instagram-round',
    GOOGLE = 'google',
    INSTAGRAM = 'instagram',
    USER = 'user',
    HAMBURGER_MENU = 'hamburger-menu',
    EYE = 'eye',
    EYE_HIDDEN = 'eye-hidden',
    EDIT = 'edit',
    BIN = 'bin',
    DRAG = 'drag',
    INFO = 'info',
    UPLOAD = 'upload',
    UPLOAD_COMPUTER = 'upload-computer',
    MINUS = 'minus',
    PIXABAY = 'pixabay',
    SEARCH = 'search',
    CARRET_DOWN = 'carret-down',
    SELECTED = 'selected',
    COMMENT = 'comment',
    ERROR = 'error',
    PLAY = 'play',
    FORWARD = 'forward',
    FAST_FORWARD = 'fast_forward',
    PREVIOUS = 'previous',
    FAST_PREVIOUS = 'fast_previous',
    COMING_SOON = 'coming_soon',
}

// eslint-disable-next-line no-console
const assertIconExists = (type: never): void => console.warn(`Unknown icon type '${type}'`);

const getIcon = (type: Types) => {
    switch (type) {
        case Types.ANALYTICS:
            return Analytics;
        case Types.CALENDAR:
            return Calendar;
        case Types.TIPS:
            return Tips;
        case Types.PLUS:
            return Plus;
        case Types.CLOSE:
            return Close;
        case Types.BACK:
            return Back;
        case Types.FACEBOOK:
            return Facebook;
        case Types.FACEBOOK_ROUND:
            return FacebookRound;
        case Types.INSTAGRAM_ROUND:
            return () => <img src={instagramRound} width={24} height={24} alt="" />;
        case Types.GOOGLE:
            return Google;
        case Types.INSTAGRAM:
            return Instagram;
        case Types.USER:
            return User;
        case Types.HAMBURGER_MENU:
            return HamburgerMenu;
        case Types.EYE:
            return Eye;
        case Types.EYE_HIDDEN:
            return EyeHidden;
        case Types.EDIT:
            return Edit;
        case Types.BIN:
            return Bin;
        case Types.DRAG:
            return Drag;
        case Types.INFO:
            return Info;
        case Types.UPLOAD:
            return Upload;
        case Types.UPLOAD_COMPUTER:
            return UploadComputer;
        case Types.MINUS:
            return Minus;
        case Types.PIXABAY:
            return Pixabay;
        case Types.SEARCH:
            return Search;
        case Types.CARRET_DOWN:
            return CarretDown;
        case Types.COMMENT:
            return Comment;
        case Types.SELECTED:
            return Selected;
        case Types.ERROR:
            return Error;
        case Types.PLAY:
            return Play;
        case Types.FORWARD:
            return Forward;
        case Types.FAST_FORWARD:
            return FastForward;
        case Types.FAST_PREVIOUS:
            return FastPrevious;
        case Types.PREVIOUS:
            return Previous;
        case Types.COMING_SOON:
            return ComingSoon;
        default:
            assertIconExists(type);
    }
};

export interface IconProps {
    type: Types;
    size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
    circle?: boolean;
    circleColor?: 'primary' | 'default';
    extend?: RulesExtend<typeof felaRules>;
}

const Icon: FC<IconProps> & { Types: typeof Types } = ({
    type,
    size = 'medium',
    circle = false,
    circleColor = 'primary',
    extend,
    ...rest
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, size, circle, circleColor });
    const Icon = getIcon(type);

    return <AntdIcon component={Icon} className={styles.icon} {...rest} />;
};

Icon.Types = Types;

export default Icon;
