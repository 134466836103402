import { takeLatest, call, put } from '@redux-saga/core/effects';
import { replace } from 'connected-react-router';

import config from 'config';

import * as actions from '../actions';
import savePost from './savePost';

function* savePostDraftHandler(action: ReturnType<typeof actions.saveDraft>) {
    const result = yield call(savePost, action.payload, false);

    if (!result.error) {
        yield put(replace(config.routes.newPost, { postId: result.meta.id }));
    }
}

export default function* savePostDraft() {
    yield takeLatest(actions.saveDraft.toString(), savePostDraftHandler);
}
