import type { TRuleWithTheme } from 'styles/theme';
import type { IconProps } from './Icon';

export const icon: TRuleWithTheme<Pick<IconProps, 'size' | 'circle' | 'circleColor'>> = ({
    theme,
    size,
    circle,
    circleColor,
}) => {
    const isPrimary = circleColor === 'primary';
    const isXLarge = size === 'xlarge';
    const isLarge = size === 'large';

    const sizeByTheme = theme.sizes.icon[size];

    return {
        display: 'inline-block',
        width: sizeByTheme,
        height: sizeByTheme,
        color: 'currentColor',

        '& svg': {
            width: sizeByTheme,
            height: sizeByTheme,
        },

        extend: {
            condition: circle,
            style: {
                '&.anticon': {
                    display: 'inline-flex',
                    flexShrink: 0,
                    alignItems: 'center',
                    justifyContent: 'center',

                    width: isLarge || isXLarge ? sizeByTheme : 28,
                    height: isLarge || isXLarge ? sizeByTheme : 28,
                    padding: 0,

                    color: isPrimary ? theme.colors.white : theme.colors.primary,
                    backgroundColor: isPrimary ? theme.colors.primary : theme.colors.buttonDefaultBackground,
                    borderRadius: '50%',
                },

                '& svg': {
                    width: isLarge || isXLarge ? 28 : 14,
                    height: isXLarge ? 36 : theme.sizes.icon.small,
                },
            },
        },
    };
};
