import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_REQUEST,
        SUCCESS: types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_SUCCESS,
        FAILURE: types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_FAILURE,
        CANCEL: types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_CANCEL,
    },
});
