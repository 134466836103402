import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const list: TRuleWithTheme = ({ theme }) => ({
    '& .ant-list-item:last-child': {
        marginBottom: theme.spacing.small,
    },
});

export const listItemMeta: TRuleWithTheme = ({ theme }) => ({
    desktop: {
        '& .ant-list-item-meta-avatar': {
            marginRight: theme.spacing.xsmall,
        },
    },
});

export const paragraph: TRule = () => ({
    fontSize: '0.8125rem',
});
