import type { FC } from 'react';

import { Article } from 'modules/entities/types';
import { Page } from 'modules/ui';
import { useFetchArticles, useArticles } from 'modules/entities/modules/articles';

import ArticlesCard from '../ArticlesCard';
import ArticlesCardSkeleton from '../ArticlesCardSkeleton';

const numberOfPlaceholders = 4;
const placeholderImages = Array.from(Array(numberOfPlaceholders).keys());

const ArticlesList: FC = () => {
    const { inProgress, success } = useFetchArticles();
    const articles: Array<Article> = useArticles();

    const displayPlaceholders = inProgress || !success;

    return (
        <Page containerSize="medium">
            {(displayPlaceholders ? placeholderImages : articles).map(article => (
                <ArticlesCardSkeleton key={article.id ?? article} active={displayPlaceholders}>
                    <ArticlesCard
                        title={article.title}
                        id={article.id}
                        excerpt={article.excerpt}
                        coverImage={article.featuredImage}
                    />
                </ArticlesCardSkeleton>
            ))}
        </Page>
    );
};

export default ArticlesList;
