import React from 'react';
import type { FC, ReactNode } from 'react';

import { Card } from 'modules/ui';

import * as felaRules from './RandomDrawCard.styles';

export interface RandomDrawCardProps {
    children: ReactNode;
    inProgress?: boolean;
}

const RandomDrawCard: FC<RandomDrawCardProps> = ({ children, inProgress = false }) => {
    return (
        <Card extend={{ card: felaRules.card }} title={null} loading={inProgress}>
            {children}
        </Card>
    );
};

export default RandomDrawCard;
