import React from 'react';
import type { FC, SVGAttributes } from 'react';

export interface UserProps extends SVGAttributes<SVGElement> {}

const User: FC<UserProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15 18.889c0 .613-.479 1.111-1.071 1.111-.593 0-1.072-.498-1.072-1.111 0-3.063-2.403-5.556-5.357-5.556s-5.357 2.493-5.357 5.556c0 .613-.48 1.111-1.072 1.111C.48 20 0 19.502 0 18.889 0 14.6 3.365 11.11 7.5 11.11S15 14.6 15 18.89M7.5 2.222c1.182 0 2.143.997 2.143 2.222 0 1.226-.961 2.223-2.143 2.223S5.357 5.67 5.357 4.444c0-1.225.961-2.222 2.143-2.222m0 6.667c2.364 0 4.286-1.993 4.286-4.445C11.786 1.994 9.864 0 7.5 0S3.214 1.993 3.214 4.444c0 2.452 1.922 4.445 4.286 4.445"
        ></path>
    </svg>
);

export default User;
