import { mapValues, invert } from 'lodash';
import { RandomDrawTab } from './types';

export const MODULE_PREFIX = 'random-draw';

export enum RandomDrawColors {
    PURPLE = 'purple',
    ORANGE = 'orange',
    RED = 'red',
    PURPLE2 = 'purple2',
    AQUA = 'aqua',
    BLACK = 'black',
    YELLOW = 'yellow',
    GREEN = 'green',
    SAND = 'sand',
    YELLOW2 = 'yellow2',
}

export const pickerColors: Record<RandomDrawColors, string> = {
    [RandomDrawColors.PURPLE]: 'linear-gradient(-180deg, #682acc 0%, #3d1877 100%)',
    [RandomDrawColors.ORANGE]: 'linear-gradient(-180deg, #fa9f55 0%, #fb6b5f 100%)',
    [RandomDrawColors.RED]: 'linear-gradient(-180deg, #ff7171 0%, #fe0944 100%)',
    [RandomDrawColors.PURPLE2]: 'linear-gradient(-180deg, #d002ff 0%, #8000ff 100%)',
    [RandomDrawColors.AQUA]: 'linear-gradient(-180deg, #02aaff 0%, #027aff 100%)',
    [RandomDrawColors.BLACK]: 'linear-gradient(-180deg, #66594f 0%, #29241f 100%)',
    [RandomDrawColors.YELLOW]: 'linear-gradient(-180deg, #f1ae0b 0%, #d47e00 100%)',
    [RandomDrawColors.GREEN]: 'linear-gradient(-180deg, #23ae71 0%, #17734c 100%)',
    [RandomDrawColors.SAND]: 'linear-gradient(-180deg, #d1baa4 0%, #9f9182 100%)',
    [RandomDrawColors.YELLOW2]: 'linear-gradient(-180deg, #ffdd00 0%, #fbaf1e 100%)',
};

type APIVideoColor = {
    top: [number, number, number];
    bottom: [number, number, number];
};

export const apiPickerColors: Record<RandomDrawColors, APIVideoColor> = {
    [RandomDrawColors.PURPLE]: { top: [0.407, 0.164, 0.8], bottom: [0.239, 0.094, 0.466] },
    [RandomDrawColors.ORANGE]: { top: [0.98, 0.623, 0.333], bottom: [0.984, 0.419, 0.372] },
    [RandomDrawColors.RED]: { top: [1, 0.443, 0.443], bottom: [0.996, 0.035, 0.266] },
    [RandomDrawColors.PURPLE2]: { top: [0.815, 0.007, 1], bottom: [0.501, 0, 1] },
    [RandomDrawColors.AQUA]: { top: [0.007, 0.666, 1], bottom: [0.007, 0.478, 1] },
    [RandomDrawColors.BLACK]: { top: [0.4, 0.349, 0.309], bottom: [0.16, 0.141, 0.121] },
    [RandomDrawColors.YELLOW]: { top: [0.945, 0.682, 0.043], bottom: [0.831, 0.494, 0] },
    [RandomDrawColors.GREEN]: { top: [0.137, 0.682, 0.443], bottom: [0.09, 0.45, 0.298] },
    [RandomDrawColors.SAND]: { top: [0.819, 0.729, 0.643], bottom: [0.623, 0.568, 0.509] },
    [RandomDrawColors.YELLOW2]: { top: [1, 0.866, 0], bottom: [0.984, 0.686, 0.117] },
};

export const apiPickerColorsInverted = invert(mapValues(apiPickerColors, value => JSON.stringify(value)));

export const MAX_ALLOWED_HASHTAGS = 30;

export const randomDrawTabs = Object.keys(RandomDrawTab).map(key => RandomDrawTab[key]);
