import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';

import * as felaRules from './ComingSoonBadge.styles';
import Icon from '../Icon';

export interface ComingSoonBadgeProps {
    borderRadius?: felaRules.BorderRadiusSize;
    button?: boolean;
    borderRadiusBottom?: boolean;
}

const ComingSoonBadge: FC<ComingSoonBadgeProps> = ({
    children,
    borderRadius = '0',
    button = 'true',
    borderRadiusBottom = false,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { borderRadius, borderRadiusBottom });

    return (
        <div className={styles.container}>
            <div className={styles.badge}>
                {button ? (
                    <div className={styles.button}>
                        <Icon type={Icon.Types.COMING_SOON} />
                        <FormattedMessage id={'general.comingSoon'} />
                    </div>
                ) : null}
            </div>
            {children}
        </div>
    );
};

export default ComingSoonBadge;
