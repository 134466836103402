import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Heading, Icon, Paragraph, useConfirmModal } from 'modules/ui';

export interface DeleteButtonProps {
    name: string;
    onDelete: () => void;
}

const DeleteButton: FC<DeleteButtonProps> = ({ name, onDelete }) => {
    const confirm = useConfirmModal();

    const handleClick = () =>
        confirm({
            title: (
                <Heading level={2}>
                    <FormattedMessage id="profile.groups.delete.title" />
                </Heading>
            ),
            content: (
                <Paragraph faded>
                    <FormattedMessage id="profile.groups.delete.text" values={{ name: <strong>{name}</strong> }} />
                </Paragraph>
            ),
            okType: 'danger',
            okText: <FormattedMessage id="general.delete" />,
            cancelText: <FormattedMessage id="general.cancel" />,
            onOk: () => {
                onDelete();
            },
        });

    return <Button type="text" icon={<Icon type={Icon.Types.BIN} />} onClick={handleClick} />;
};

export default DeleteButton;
