import { call, put } from 'redux-saga/effects';
import { noop } from 'lodash';

import * as log from 'config/loglevel';
import type { ApiPost, ApiResponse } from 'config/antonio';
import { authApi } from 'config/antonio';
import config from 'config/config';

import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeJob } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import { createJob as apiActions } from '../actions';

type Operation = operations['jobCreate'];
type Response = ApiResponse<Operation['responses']['201']['content']['application/json']>;
type RequestBody = Operation['requestBody']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* createJobHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiPost<Response, RequestBody>>(
            authApi.post,
            config.api.endpoints.jobs,
            action.payload,
        );

        const { result: id, entities } = normalizeJob(data);

        yield put(entitiesActions.setEntitiesGroup(EntityKey.JOBS, { ids: [id], byId: entities[EntityKey.JOBS] }));
        yield put(apiActions.success(id, { lastSuccessAt: new Date().toISOString() }));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(uiError));

        log.error(error);
    }
}

export default function* createJob() {
    yield takeLatestRequest(
        () => 'createJob',
        { pattern: apiActions.request.toString(), handler: createJobHandler },
        { pattern: apiActions.cancel.toString(), handler: noop },
    );
}
