import type { TRule } from 'fela';

export const postPreview: TRule = () => ({
    padding: '0.625rem',

    '&.ant-card.ant-card-bordered': {
        marginTop: 0,
        height: '100%',

        '& > .ant-card-cover': {
            height: '90%',

            '& > img': {
                height: '100%',
                objectFit: 'cover',
            },
        },
    },

    '&.ant-card-cover img': {
        height: '100%',
    },
});
