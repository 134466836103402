import { merge } from 'lodash';
import { isEnvDevelopment } from 'constants/index';
import type { EnvConfig } from './config.development';

const envConfig = require(`./config.${process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV}.ts`).default;

const defaultConfig = {
    appName: process.env.REACT_APP_NAME,
    devTools: isEnvDevelopment,
    sentry: {
        dsn: 'https://eb1cf4cd369d4b97b24d2dc2d6ebce28@o774468.ingest.sentry.io/5796386',
    },
    api: {
        endpoints: {
            signUp: '/auth/sign-up',
            signIn: '/auth/sign-in',
            refreshToken: '/auth/token/refresh',
            user: '/users/:id',
            passwordRecovery: '/auth/password-recovery',
            passwordReset: '/auth/password/reset',
            accounts: '/accounts',
            accountDetail: '/accounts/:id',
            accountGroups: '/account-groups',
            accountGroupDetail: '/account-groups/:id',
            competitions: '/competitions',
            competitionDetail: '/competitions/:id',
            media: '/media',
            instagram: {
                accounts: '/proxy/instagram/accounts',
                posts: '/proxy/instagram/accounts/:id/posts',
                postDetail: '/proxy/instagram/accounts/:id/posts/:postId',
            },
            facebook: {
                pages: '/proxy/facebook/pages',
                posts: '/proxy/facebook/pages/:id/posts',
                postDetail: '/proxy/facebook/pages/:id/posts/:postId',
            },
            posts: '/posts',
            postDetail: '/posts/:id',
            jobs: '/jobs',
            jobDetail: '/jobs/:id',
            videoSettings: '/video-settings',
            videoSettingsDetail: '/video-settings/:id',
        },
    },
    routes: {
        posts: '/',
        randomDrawResults: '/random-draw/results/:competitionId',
        newPost: '/new-post',
        randomDraw: '/random-draw/:tab?',
        randomDrawProgress: '/random-draw/progress/:jobId',
        login: '/sign-in',
        signUp: '/sign-up',
        passwordReset: '/password-reset',
        profile: '/profile/:tab?',
        analytics: '/analytics',
        tips: '/inspiration-tips',
        tipsPosts: '/inspiration-tips/:postId',

        termsAndConditions: '#',
        endUserAgreement: '#',
        privacyPolicy: '#',
    },
    auth: {
        instagram: {
            publishingScopes: [
                'public_profile',
                'instagram_basic',
                'instagram_content_publish',
                'instagram_manage_comments',
            ],
        },
        facebook: {
            basicScopes: ['public_profile', 'email'],
            publishingScopes: [
                'public_profile',
                'pages_show_list',
                'pages_read_engagement',
                'pages_manage_posts',
                'pages_manage_engagement',
                'pages_read_user_content',
            ],
        },
        google: {
            cookiePolicy: 'single_host_origin',
        },
    },
    pixabay: {
        apiUrl: 'https://pixabay.com/api/',
    },
    wordpress: {
        apiUrl: 'https://rafflu.com/wp-json/wp/v2',
        endpoints: {
            article: '/posts/:postId',
            articles: '/posts',
        },
    },
    pagination: {
        limit: 10,
    },
};

type DefaultConfig = typeof defaultConfig;

export type Config = DefaultConfig & EnvConfig;

export default merge(defaultConfig, envConfig) as Config;
