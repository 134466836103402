// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@ackee/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"shadows.less"}]);
// Exports
exports.locals = {
	"buttonHover": "0 4px 8px 0 rgba(134, 167, 173, 0.4)",
	"buttonClick": "0 2px 6px 0 #86a7ad",
	"cardHover": "0 10px 8px 0 rgba(171, 205, 211, 0.16)",
	"cardActive": "0 6px 8px 0 rgba(171, 205, 211, 0.2)",
	"header": "0 6px 8px 0 rgba(171, 205, 211, 0.2)",
	"panel": "0 2px 4px 0 rgba(171, 205, 211, 0.2)",
	"mobileMenuItem": "0 6px 8px 0 rgba(171, 205, 211, 0.2)",
	"primaryBackgroundShadow": "0 10px 16px 0 rgba(171, 205, 211, 0.4)"
};
module.exports = exports;
