import type { TRuleWithTheme } from 'styles/theme';

export const summary: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing.large,

    tablet: {
        width: 'auto',
        flex: 'auto',
        marginTop: 0,
        marginLeft: theme.spacing.large,
    },
});
