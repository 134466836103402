import React, { ReactElement, ReactNode, useState } from 'react';
import { noop } from 'lodash';
import { Select as AntdSelect } from 'antd';
import type { SelectProps, SelectValue } from 'antd/es/select';

import { useFelaEnhanced } from 'hooks';
import { Icon } from 'modules/ui';

import * as rules from './Select.styles';

export interface SelectOwnProps<ValueType> extends SelectProps<ValueType> {
    footer?: ReactNode;
}

const Select = <ValueType extends SelectValue = SelectValue>({
    onDropdownVisibleChange = noop,
    footer,
    ...props
}: SelectOwnProps<ValueType>): ReactElement => {
    const [isOpen, setOpen] = useState(false);
    const { styles } = useFelaEnhanced(rules, { isOpen });

    return (
        <AntdSelect
            dropdownRender={
                footer &&
                (menu => (
                    <>
                        {menu}
                        <footer className={styles.footer}>{footer}</footer>
                    </>
                ))
            }
            suffixIcon={<Icon type={Icon.Types.CARRET_DOWN} size="small" />}
            {...props}
            className={styles.select}
            dropdownClassName={styles.dropdown}
            onDropdownVisibleChange={open => {
                setOpen(open);
                onDropdownVisibleChange(open);
            }}
        />
    );
};

export default Select;
