import React from 'react';
import type { FC, SVGAttributes } from 'react';

export interface HamburgerMenuProps extends SVGAttributes<SVGElement> {}

const HamburgerMenu: FC<HamburgerMenuProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" {...props}>
        <g fill="currentColor" fillRule="evenodd" transform="translate(-2 -6)">
            <rect width="20" height="2" x="2" y="6" rx="1"></rect>
            <rect width="20" height="2" x="2" y="11" rx="1"></rect>
            <rect width="20" height="2" x="2" y="16" rx="1"></rect>
        </g>
    </svg>
);

export default HamburgerMenu;
