import { ChangeEmailFormField, ChangePasswordFormField, ChangeAccountGroupNameField } from './config';

export enum ProfileTab {
    ACCOUNT = 'account',
    SOCIAL_PROFILES = 'social-profiles',
}

export interface ChangeEmailFormValues {
    [ChangeEmailFormField.Email]: string;
}

export interface ChangePasswordFormValues {
    [ChangePasswordFormField.Password]: string;
}

export interface ChangeAccountGroupValues {
    [ChangeAccountGroupNameField.Name]: string;
}

export enum PictureManagementStep {
    COMPUTER = 'computer',
    PIXABAY = 'pixabay',
    PIXABAY_DETAIL = 'pixabay-detail',
}
