import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.COMPETITIONS,
});

export const createCompetition = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_COMPETITION_' }),
});

export const fetchCompetitions = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_COMPETITIONS_' }),
});

export const fetchCompetition = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_COMPETITION_' }),
});

export const updateCompetition = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'UPDATE_COMPETITION_' }),
});
