import type { FC } from 'react';
import Typography from 'antd/es/typography';
import type { TitleProps } from 'antd/es/typography/Title';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Heading.styles';

export interface HeadingProps extends Omit<TitleProps, 'level'> {
    level?: TitleProps['level'] | 6;
    extend?: RulesExtend<typeof felaRules>;
}

const Heading: FC<HeadingProps> = ({ level = 1, children, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, level });

    return level === 6 ? (
        <h6 className={styles.heading}>{children}</h6>
    ) : (
        <Typography.Title level={level} {...props} className={styles.heading}>
            {children}
        </Typography.Title>
    );
};

export default Heading;
