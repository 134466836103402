import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const steps: TRule = () => ({
    maxWidth: 270,
    margin: '0 auto',
    paddingTop: '0.375rem',
    paddingBottom: '2rem',
});

export const card: TRuleWithTheme = ({ theme }) => ({
    maxWidth: theme.sizes.container.medium,
    margin: '0 auto',
});

export const content: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingTop: theme.spacing.large,
    paddingBottom: '1.75rem',
    textAlign: 'center',

    ':before': {
        position: 'absolute',
        content: '" "',
        display: 'block',
        top: 0,
        left: '-1.25rem',
        right: '-1.25rem',
        height: '1px',
        backgroundColor: theme.colors.cardBorderDark,
    },

    '> h1': {
        marginBottom: '0.5rem',
    },

    '& > h4.ant-typography, .ant-typography h4': {
        marginBottom: '0.5rem',
    },
});

export const progress: TRuleWithTheme = ({ theme }) => ({
    margin: `${theme.spacing.large} 0 ${theme.spacing.medium}`,
    transitionDuration: theme.transition.duration,
});

export const marginBottom: TRule = () => ({
    marginBottom: '0.5rem',
});
