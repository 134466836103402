import { TRuleWithTheme } from 'styles/theme';

export const item: TRuleWithTheme = ({ theme }) => ({
    padding: '1.5rem',
    position: 'relative',

    tablet: {
        padding: `${theme.spacing.small} ${theme.spacing.large}`,
    },

    '& .ant-list-item-action > li:last-child': {
        paddingRight: 0,
    },
});
