import React, { FC } from 'react';
import { List as AntdList } from 'antd';

import { useFelaEnhanced } from 'hooks';

import ListItem from './ListItem';

import * as felaRules from './List.styles';

export interface ListOwnProps {
    bordered?: boolean;
}

const List: FC<ListOwnProps> & { Item: typeof ListItem } = ({ bordered, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { bordered });

    return <AntdList {...props} className={styles.list} />;
};

List.Item = ListItem;

export default List;
