import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Container.styles';

export interface ContainerProps {
    size?: felaRules.ContainerSize;
    extend?: RulesExtend<typeof felaRules>;
}

const Container: FC<ContainerProps> = ({ size = 'medium', extend, children }) => {
    const { styles } = useFelaEnhanced(felaRules, { size, extend });

    return <div className={styles.container}>{children}</div>;
};

export default Container;
