import React, { FC } from 'react';

export const EditModeContext = React.createContext(null);

export const useEditMode = () => {
    const [editMode, setEditMode] = React.useContext(EditModeContext);
    const closeEditMode = () => setEditMode(null);

    return {
        editMode,
        setEditMode,
        closeEditMode,
    };
};

const EditModeProvider: FC = ({ children }) => {
    const state = React.useState<string>(null);

    return <EditModeContext.Provider value={state}>{children}</EditModeContext.Provider>;
};

export default EditModeProvider;
