import { put, takeEvery, call } from 'redux-saga/effects';

import config from 'config';
import { api } from 'config/antonio';
import { Action } from 'services/actions';
import { createUIErrorMessage } from 'modules/errors';
import { operations } from 'api/apiSchema';
import type { ApiPost, ApiResponse } from 'config/antonio';

import { resetPasswordForm as resetPasswordFormActions } from '../actions';
import { ResetPasswordFormValues } from '../../types';

type Operation = operations['resetPassword'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type Response = ApiResponse<Operation['responses']['204']>;

export function* handleResetPasswordForm(action: Action<ResetPasswordFormValues>) {
    try {
        yield call<ApiPost<Response, RequestBody>>(api.post, config.api.endpoints.passwordReset, action.payload);

        yield put(resetPasswordFormActions.submitSuccess());
    } catch (e) {
        const uiError = createUIErrorMessage(e);
        yield put(resetPasswordFormActions.submitFailure(uiError));
    }
}

export default function* ResetPasswordForm() {
    yield takeEvery(resetPasswordFormActions.submit.toString(), handleResetPasswordForm);
}
