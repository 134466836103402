import { basicApiReducer } from '@ackee/redux-utils';

import * as types from '../../actions/types';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.createPost.CREATE_POST_REQUEST,
        SUCCESS: types.createPost.CREATE_POST_SUCCESS,
        FAILURE: types.createPost.CREATE_POST_FAILURE,
        CANCEL: types.createPost.CREATE_POST_CANCEL,
        RESET: types.createPost.CREATE_POST_RESET,
    },
});
