import type { FC } from 'react';
import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import csCZ from 'antd/es/locale/cs_CZ';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Icon } from 'modules/ui';
import { antdData } from 'modules/core/modules/localization/config';

import * as felaRules from './DatePickerField.styles';
import FloatingLabel from '../FloatingLabel';
import FormItem from '../FormItem';
import { useValidationDebounce } from '../../hooks';
import type { TextFieldProps } from '../types';

export interface DatePickerFieldProps extends TextFieldProps {
    extend?: RulesExtend<typeof felaRules>;
}

csCZ.DatePicker.lang.locale = 'cs';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const DatePickerField: FC<DatePickerFieldProps> = ({
    datePickerProps,
    validate,
    label,
    required = false,
    help,
    extend,
    ...props
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });
    const validateDebounced = useValidationDebounce(validate);

    const { locale } = useIntl();
    const dateTimeFormat = antdData[locale].DatePicker.lang.dateTimeFormat.replace(':ss', '');

    return (
        <Field<string> {...props} validateFields={[]} validate={validateDebounced}>
            {({ input, meta }) => (
                <FormItem {...{ input, meta, required, help, extend: { formItem: rules.formItem } }}>
                    <FloatingLabel label={label} input={input} extend={{ label: felaRules.floatingLabel }}>
                        <Icon type={Icon.Types.CALENDAR} extend={{ icon: felaRules.icon }} />
                        <DatePicker
                            format={dateTimeFormat}
                            {...input}
                            {...datePickerProps}
                            value={input.value ? new Date(input.value) : null}
                            suffixIcon={null}
                            placeholder={null}
                            className={styles.datePicker}
                            dropdownClassName={styles.dropdown}
                        />
                    </FloatingLabel>
                </FormItem>
            )}
        </Field>
    );
};

export default DatePickerField;
