import { all } from 'redux-saga/effects';

import fetchAccounts from './fetchAccounts';
import createAccount from './createAccount';
import deleteAccount from './deleteAccount';
import moveAccount from './moveAccount';

export default function* accountGroups() {
    yield all([fetchAccounts(), createAccount(), deleteAccount(), moveAccount()]);
}
