import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useField } from 'react-final-form';

import { Button, ButtonOwnProps, Icon } from 'modules/ui';

export interface ClearFieldButtonProps extends ButtonOwnProps {
    name: string;
    clear: () => void;
}

const ClearFieldButton: FC<ClearFieldButtonProps> = ({ name, clear, ...props }) => {
    const { input } = useField(name, { subscription: { value: true } });

    if (!clear || input.value.length === 0) {
        return null;
    }

    // TODO: Add correct undo icon when asset added to the design
    return (
        <Button type="text" inverse size="small" icon={<Icon type={Icon.Types.BACK} />} onClick={clear} {...props}>
            <FormattedMessage id="general.undoChanges" />
        </Button>
    );
};

export default ClearFieldButton;
