import type { TRuleWithTheme } from 'styles/theme';

const hoverStyle = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
};

export const button: TRuleWithTheme = ({ theme }) => ({
    padding: 0,

    desktop: {
        padding: 0,
    },

    ':hover': hoverStyle,
    ':focus': hoverStyle,
    ':active': hoverStyle,

    ':not([disabled]):active': {
        boxShadow: 'none',
    },

    ':hover .anticon': {
        boxShadow: theme.shadow.buttonHover,
    },

    ':focus .anticon': {
        boxShadow: theme.shadow.buttonClick,
    },

    '& .anticon': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transition.duration,
    },
});

export const icon: TRuleWithTheme = () => ({});
