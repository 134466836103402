import type { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as rules from './TextAreaFieldFooter.styles';
import { useField } from 'react-final-form';

export interface TextAreaFieldFooterProps {
    fieldName: string;
}

const TextAreaFieldFooter: FC<TextAreaFieldFooterProps> = ({ fieldName, children }) => {
    const { meta } = useField(fieldName, { subscription: { active: true } });
    const { styles } = useFelaEnhanced(rules, { active: meta.active });

    return <footer className={styles.footer}>{children}</footer>;
};

export default TextAreaFieldFooter;
