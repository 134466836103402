import { combineRules } from 'fela';
import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

import type { HeaderLogoProps } from './HeaderLogo';

export const logo: TRuleWithTheme<HeaderLogoProps> = ({ bigger, theme }) => ({
    height: bigger ? 32 : 26,
    width: 'auto',

    transitionProperty: 'height',
    transitionDuration: theme.transition.duration,

    desktop: {
        height: 32,
    },
});

export const logoLink: TRule = combineRules(logo, () => ({
    lineHeight: 1,
}));
