import { call, put } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import { authApi, ApiPut, ApiResponse } from 'config/antonio';
import * as log from 'config/loglevel';
import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizePost } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import * as actions from '../actions';

type RequestAction = ReturnType<typeof actions.updatePost.request>;
type CancelAction = ReturnType<typeof actions.updatePost.cancel>;
type Operation = operations['postUpdate'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type RequestParams = Operation['parameters']['path'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;

function* updatePostHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiPut<Response, RequestBody, RequestParams>>(
            authApi.put,
            config.api.endpoints.postDetail,
            {
                ...action.payload,
                uriParams: { id: action.meta.id },
            },
        );

        const { result: postId, entities } = normalizePost(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.POSTS, {
                ids: [postId],
                byId: entities[EntityKey.POSTS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.MEDIA, entities[EntityKey.MEDIA]),
        ]);
        yield put(actions.updatePost.success(action.meta.id, undefined, { lastSuccessAt: new Date().toISOString() }));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.updatePost.failure(action.meta.id, uiError));

        log.error(error);
    }
}

export default function* updatePost() {
    yield takeLatestRequest(
        (action: RequestAction | CancelAction) => action.meta.id,
        {
            pattern: actions.updatePost.request.toString(),
            handler: updatePostHandler,
        },
        {
            pattern: actions.updatePost.cancel.toString(),
            handler: noop,
        },
    );
}
