import { combineRules } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const textField: TRuleWithTheme = () => ({
    maxWidth: '100%',
});

export const headlineTextField = combineRules(textField, ({ theme }) => ({
    marginBottom: theme.spacing.medium,
}));

export const colorPicker = () => ({
    maxTablet: {
        marginTop: '2.25rem',
        marginBottom: 0,
    },
});

export const mobileOnly: TRuleWithTheme = ({ theme }) => ({
    tablet: {
        display: 'none',
    },
});

export const desktopOnly: TRuleWithTheme = ({ theme }) => ({
    maxTablet: {
        display: 'none',
    },
});
