import { call, put } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import { authApi, ApiPut, ApiResponse } from 'config/antonio';
import * as log from 'config/loglevel';
import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeAccountGroup } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import { types, updateAccountGroup as apiActions } from '../actions';

type Operation = operations['accountGroupUpdate'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type Request = Operation['parameters']['path'] & Operation['requestBody']['content']['application/json'];

function* updateAccountGroupHandler(action) {
    try {
        const { data }: Response = yield call<ApiPut<Response, Request>>(
            authApi.put,
            config.api.endpoints.accountGroupDetail,
            {
                ...action.payload,
                uriParams: { id: action.meta.id },
            },
        );

        const { result: id, entities } = normalizeAccountGroup(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                ids: [id],
                byId: entities[EntityKey.ACCOUNT_GROUPS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.ACCOUNTS, entities[EntityKey.ACCOUNTS]),
        ]);
        yield put(apiActions.updateAccountGroupSuccess(id));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.updateAccountGroupFailure(action.meta.id, uiError));

        log.error(error);
    }
}

export default function* updateAccountGroup() {
    yield takeLatestRequest(
        action => action.meta.id,
        {
            pattern: types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_REQUEST,
            handler: updateAccountGroupHandler,
        },
        {
            pattern: types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_CANCEL,
            handler: noop,
        },
    );
}
