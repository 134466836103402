import { FC } from 'react';

const FastPrevious: FC = props => (
    <svg
        width="12px"
        height="10px"
        viewBox="0 0 12 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <path
                d="M6.86203976,13.00025 C6.60003976,13.00025 6.33903976,12.89825 6.14303976,12.69525 L2.28003976,8.69525 C1.90203976,8.30225 1.90703976,7.67925 2.29303976,7.29325 L6.29303976,3.29325 C6.68303976,2.90225 7.31603976,2.90225 7.70703976,3.29325 C8.09703976,3.68425 8.09703976,4.31625 7.70703976,4.70725 L4.40203976,8.01225 L7.58103976,11.30525 C7.96503976,11.70325 7.95403976,12.33625 7.55703976,12.71925 C7.36203976,12.90725 7.11203976,13.00025 6.86203976,13.00025"
                id="path-1"
            />
            <path
                d="M12.8620398,13.00025 C12.6000398,13.00025 12.3390398,12.89825 12.1430398,12.69525 L8.28003976,8.69525 C7.90203976,8.30225 7.90703976,7.67925 8.29303976,7.29325 L12.2930398,3.29325 C12.6830398,2.90225 13.3160398,2.90225 13.7070398,3.29325 C14.0970398,3.68425 14.0970398,4.31625 13.7070398,4.70725 L10.4020398,8.01225 L13.5810398,11.30525 C13.9650398,11.70325 13.9540398,12.33625 13.5570398,12.71925 C13.3620398,12.90725 13.1120398,13.00025 12.8620398,13.00025"
                id="path-3"
            />
        </defs>
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="posts_calendar_month" transform="translate(-699.000000, -497.000000)">
                <g id="Group" transform="translate(697.000000, 487.000000)">
                    <g transform="translate(0.000000, 7.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <use id="Color" fill="currentColor" xlinkHref="#path-1" />
                        <mask id="mask-4" fill="white">
                            <use xlinkHref="#path-3" />
                        </mask>
                        <use id="Color-Copy" fill="currentColor" xlinkHref="#path-3" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default FastPrevious;
