import type { FC } from 'react';
import { useField } from 'react-final-form';

import type { SocialPost } from 'modules/entities';

import PostPreview from '../PostPreview';
import * as felaRules from './SelectPostList.styles';
import PostInfiniteList, { PostInfiniteListProps } from '../PostInfiniteList';

export interface SelectPostListProps extends Omit<PostInfiniteListProps, 'renderItem'> {
    fieldName: string;
}

const SelectPostList: FC<SelectPostListProps> = ({ fieldName, ...props }) => {
    const { input } = useField<SocialPost['id']>(fieldName, { subscription: { value: true } });

    return (
        <PostInfiniteList
            postId={input.value}
            renderItem={(style, loading, post) => {
                return (
                    <PostPreview
                        post={post}
                        active={input.value === post?.id}
                        loading={loading}
                        onClick={post?.id ? () => input.onChange(post.id) : undefined}
                        style={style}
                        extend={{ card: felaRules.postPreview }}
                    />
                );
            }}
            {...props}
        />
    );
};

export default SelectPostList;
