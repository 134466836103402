import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const item: TRuleWithTheme = ({ theme }) => ({
    ':first-child': {
        paddingBottom: theme.spacing.medium,
    },

    ':only-child': {
        paddingBottom: 0,
    },

    tablet: {
        ':first-child': {
            paddingBottom: 0,
        },
    },
});

export const label: TRule = () => ({
    display: 'block',
    marginBottom: '0.75rem',
});
