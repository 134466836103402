import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '@ackee/petrus';

import type { ApiState } from 'types';
import * as log from 'config/loglevel';

import { logoutApiSelector } from '../services/selectors';

interface UseLogoutProps extends ApiState {
    logout: () => void;
}

const useLogout = (): UseLogoutProps => {
    const dispatch = useDispatch();
    const api = useSelector(logoutApiSelector);

    // TODO: UI error message

    useEffect(() => {
        if (api.error) {
            log.error(api.error);
        }
    }, [api.error]);

    return {
        ...api,
        logout: () => dispatch(logoutRequest()),
    };
};

export default useLogout;
