import { call, put, takeLeading, select } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiDelete, ApiGet, ApiResponse } from 'config/antonio';
import { operations, components } from 'api/apiSchema';

import { createUIErrorMessage } from 'modules/errors';
import { EntityKey } from 'modules/entities/constants';
import { authUserSelector } from 'modules/auth';
import * as entitiesActions from 'modules/entities/services/actions';
import { normalizeAccountGroups } from 'modules/entities/services/normalizr';

import { types, deleteAccountGroup as apiActions } from '../actions';

type DeleteOperation = operations['accountGroupDelete'];
type DeleteResponse = ApiResponse<DeleteOperation['responses']['204']>;
type DeleteRequestParams = DeleteOperation['parameters']['path'];

type FetchOperation = operations['accountGroupList'];
type FetchResponse = ApiResponse<components['responses']['AccountGroup']['content']['application/json'][]>;
type FetchRequestParams = FetchOperation['parameters']['query'];

function* handleDeleteAccountGroup(action) {
    const groupId = action.meta.id;
    const user = yield select(authUserSelector);

    try {
        yield call<ApiDelete<DeleteResponse, DeleteRequestParams>>(
            authApi.delete,
            config.api.endpoints.accountGroupDetail,
            {
                uriParams: {
                    id: groupId,
                },
            },
        );

        // Also fetch account groups to retain order of accounts from the deleted group
        const accountGroupsResponse = yield call<ApiGet<FetchResponse, FetchRequestParams>>(
            authApi.get,
            config.api.endpoints.accountGroups,
            {
                params: {
                    ...action.payload,
                    userId: user.id,
                },
            },
        );

        const { result: ids, entities } = normalizeAccountGroups(accountGroupsResponse.data);

        yield put([
            entitiesActions.removeEntity(EntityKey.ACCOUNT_GROUPS, groupId),
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                ids,
                byId: entities[EntityKey.ACCOUNT_GROUPS],
            }),
            entitiesActions.setEntities(EntityKey.ACCOUNTS, entities[EntityKey.ACCOUNTS]),
            apiActions.deleteAccountGroupSuccess(groupId),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.deleteAccountGroupFailure(groupId, uiError));

        log.error(error);
    }
}

export default function* deleteAccountGroup() {
    yield takeLeading(types.deleteAccountGroup.DELETE_ACCOUNT_GROUP_REQUEST, handleDeleteAccountGroup);
}
