import React, { FC } from 'react';

export interface PixabayProps {}

const Pixabay: FC<PixabayProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        {...props}
    >
        <defs>
            <path id="a45" d="M0 7.632h27.992V0H0z"></path>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#EFEEFB" d="M20 2c9.941 0 18 8.059 18 18s-8.059 18-18 18S2 29.941 2 20 10.059 2 20 2"></path>
            <g transform="translate(6 16)">
                <path
                    fill="#6B67DF"
                    d="M2.112 5.095c.605.015 1.173-.457 1.264-1.057.12-.604-.265-1.253-.854-1.434-.568-.202-1.253.064-1.53.601-.216.367-.14.8-.154 1.205v.685h1.274M2.114 1.7c1.03-.024 1.997.814 2.106 1.842.13.903-.4 1.84-1.233 2.207-.504.25-1.074.174-1.616.186H.84v1.698H0c.002-1.31-.003-2.62 0-3.93.028-.976.827-1.85 1.792-1.98a2.12 2.12 0 01.319-.023"
                ></path>
                <mask id="b72" fill="#fff">
                    <use xlinkHref="#a45"></use>
                </mask>
                <path
                    fill="#6B67DF"
                    d="M4.625 5.914h.83V1.678h-.83zm3.324-1.572h.03l1.18 1.573h1.027L8.583 3.737l1.422-2.057H8.977L7.98 3.13h-.03l-1-1.45H5.923l1.422 2.057-1.603 2.178h1.027z"
                    mask="url(#b72)"
                ></path>
                <path
                    fill="#6B67DF"
                    d="M13.34 5.074c-.003-.47.008-.942-.005-1.413-.05-.63-.634-1.16-1.268-1.143-.602-.015-1.17.453-1.264 1.049-.11.572.219 1.195.764 1.408.337.147.708.086 1.063.1h.71m-1.272-3.397c.84-.016 1.646.535 1.957 1.312.163.361.16.761.155 1.149v1.775c-.749 0-1.498.005-2.247-.004-.861-.042-1.649-.67-1.895-1.494-.226-.717-.039-1.55.493-2.084.398-.41.962-.66 1.536-.654m4.642 3.415c.637.017 1.22-.519 1.267-1.152.08-.63-.388-1.264-1.012-1.379-.345-.047-.695-.015-1.043-.024h-.496c.006.478-.011.957.01 1.435a1.277 1.277 0 001.274 1.12m0-3.394c.993-.024 1.922.758 2.08 1.737.18.928-.344 1.93-1.208 2.312-.844.398-1.93.142-2.51-.589-.36-.424-.515-.987-.485-1.537V0h.84v1.698h1.283m5.822 3.376c-.004-.47.007-.942-.006-1.413-.05-.63-.634-1.16-1.267-1.143-.602-.015-1.17.453-1.264 1.049-.11.572.218 1.195.764 1.408.336.147.707.086 1.063.1h.71m-1.273-3.397c.84-.016 1.647.535 1.958 1.312.162.361.16.761.155 1.149v1.775c-.75 0-1.499.005-2.248-.004-.861-.042-1.649-.67-1.895-1.494-.226-.717-.038-1.55.494-2.084a2.14 2.14 0 011.535-.654m6.733.011c-.001 1.307.004 2.614 0 3.92-.03 1.028-.914 1.954-1.946 2.007-.196.01-.392.004-.589.006v-.84c.358-.001.741.035 1.059-.168.396-.222.648-.67.639-1.123-.744.6-1.898.568-2.621-.051a2.155 2.155 0 01-.776-1.784V1.689h.84c.003.747-.009 1.495.005 2.243a1.278 1.278 0 001.18 1.15c.623.063 1.237-.407 1.346-1.023.047-.346.015-.696.025-1.045V1.69h.84"
                    mask="url(#b)"
                ></path>
            </g>
            <path fill="#FFF" d="M20.27 19.553l-.022.114c.008 0 .014 0 .022-.002v-.112z"></path>
            <path fill="#6B67DF" d="M20.477 19.633c-.083 0-.136.022-.207.031v.225s.354-.256.207-.256"></path>
        </g>
    </svg>
);

export default Pixabay;
