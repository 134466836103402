import { FC } from 'react';

export interface ComingSoonProps {}

const ComingSoon: FC<ComingSoonProps> = props => (
    <svg width="18" height="22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
        <g fillRule="evenodd">
            <path d="m9.07 17.861.44-4.245a.998.998 0 0 0-.996-1.103H4.115l4.707-8.248-.536 4.096a1 1 0 0 0 .991 1.13h4.49l-4.698 8.37Zm6.925-9.65a1.422 1.422 0 0 0-1.235-.72h-4.343l.774-5.92a1.407 1.407 0 0 0-.309-1.043A1.413 1.413 0 0 0 9.927.01c-.54-.062-1.048.19-1.37.688L1.904 12.352a1.42 1.42 0 0 0 1.21 2.161h4.292l-.617 5.96a1.423 1.423 0 0 0 1.417 1.53c.485 0 .94-.25 1.227-.707l6.546-11.66a1.42 1.42 0 0 0 .016-1.426ZM15 5.002a.997.997 0 0 0 .707-.293l2-2a.999.999 0 1 0-1.414-1.414l-2 2A.999.999 0 0 0 15 5.002M2.293 17.295l-2 2a.999.999 0 1 0 1.414 1.414l2-2a.999.999 0 1 0-1.414-1.414M15.707 17.295a.999.999 0 1 0-1.414 1.414l2 2a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-2-2ZM2.293 4.709a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-2-2A.999.999 0 1 0 .293 2.709l2 2Z" />
        </g>
    </svg>
);

export default ComingSoon;
