import { combineReducers } from 'redux';

import api from './api';
import entities from './entities';
import { reducer as addAccountsRequestIds } from './addAccountsRequestIds';

export default combineReducers({
    api,
    entities,
    addAccountsRequestIds,
});
