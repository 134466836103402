import type { TRule } from 'fela';

export const container: TRule = () => ({});

export const button: TRule = () => ({
    fontSize: '0.75rem',
    padding: 0,

    '&.ant-btn-sm': {
        height: 'auto',
    },

    desktop: {
        padding: 0,
    },
});
