import { take, put } from 'redux-saga/effects';

import { types, fetchUser } from '../actions';

export default function* getAuthUser(tokens) {
    yield put(fetchUser.fetchUserRequest({ userId: tokens.accessToken.userId }));

    const { FETCH_USER_SUCCESS, FETCH_USER_FAILURE } = types.fetchUser;
    const result = yield take([FETCH_USER_SUCCESS, FETCH_USER_FAILURE]);

    if (result.type === FETCH_USER_FAILURE) {
        throw new Error('Failed to fetch auth user.');
    }

    return {};
}
