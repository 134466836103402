import { all } from 'redux-saga/effects';

import create from './createCompetition';
import fetchList from './fetchCompetitions';
import fetchDetail from './fetchCompetition';
import update from './updateCompetition';

export default function* competitions() {
    yield all([create(), fetchList(), fetchDetail(), update()]);
}
