import type { TRuleWithTheme } from 'styles/theme';

export const textarea: TRuleWithTheme<{ footer: Boolean }> = ({ theme, footer }) => ({
    paddingTop: theme.spacing.large,
    resize: 'none',

    extend: {
        condition: footer,
        style: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 'none',
        },
    },
});

export const formItem: TRuleWithTheme<{ active?: boolean }> = ({ theme, active }) => ({
    position: 'relative',

    ':hover footer': {
        borderColor: active ? theme.colors.inputFocus : theme.colors.inputHover,
    },

    ':hover .ant-input': {
        borderColor: active ? theme.colors.inputFocus : theme.colors.inputHover,
    },
});

export const clearButton: TRuleWithTheme = () => ({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: '-1.75rem',

    fontSize: '0.875rem',

    extend: {
        textDecoration: 'none',
    },
});
