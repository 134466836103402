import { call, put, select } from 'redux-saga/effects';
import { noop, uniq } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiPost, ApiResponse } from 'config/antonio';

import type { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeAccount } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';
import {
    selectAccountGroup,
    selectSelectedGroupId,
    selectOldestAccountGroup,
} from 'modules/entities/modules/account-groups/services/selectors';

import { createAccount as apiActions } from '../actions';

type Operation = operations['accountCreate'];
type Response = ApiResponse<Operation['responses']['201']['content']['application/json']>;
type RequestBody = Operation['requestBody']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* createAccountHandler(action: RequestAction) {
    const selectedGroupId = yield select(selectSelectedGroupId);
    let accountGroup = yield select(state =>
        selectAccountGroup(state, {
            id: action.payload.accountGroupId ?? selectedGroupId,
        }),
    );

    if (!accountGroup) {
        accountGroup = yield select(selectOldestAccountGroup);
    }

    try {
        const { data }: Response = yield call<ApiPost<Response, RequestBody>>(
            authApi.post,
            config.api.endpoints.accounts,
            { ...action.payload, accountGroupId: accountGroup.id },
        );

        const result = normalizeAccount(data);
        const { result: accountId, entities } = result;
        const ids = [accountId];

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNTS, {
                ids,
                byId: entities[EntityKey.ACCOUNTS],
                strategy: 'append',
            }),
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                ids: [accountGroup.id],
                byId: {
                    [accountGroup.id]: {
                        ...accountGroup,
                        accounts: uniq([...accountGroup.accounts, accountId]),
                    },
                },
                strategy: 'append',
            }),
        ]);

        yield put(apiActions.success(action.meta.id));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(action.meta.id, uiError));

        log.error(error);
    }
}

export default function* createAccount() {
    yield takeLatestRequest(
        (action: RequestAction) => action.meta.id,
        {
            pattern: apiActions.request.toString(),
            handler: createAccountHandler,
        },
        {
            pattern: apiActions.cancel.toString(),
            handler: noop,
        },
    );
}
