import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import { configureStore } from '../config';

type ReduxProps = { children: React.ReactNode };

const Redux: FunctionComponent<ReduxProps> = ({ children }) => {
    const store = configureStore();

    return <Provider store={store}>{children}</Provider>;
};

export default Redux;
