import felaPluginValidator from 'fela-plugin-validator';
import felaPluginUnit from 'fela-plugin-unit';
import felaPluginNamedKeys from 'fela-plugin-named-keys';
import felaPluginExtend from 'fela-plugin-extend';
import felaPluginFriendlyPseudoClass from 'fela-plugin-friendly-pseudo-class';

import namedKeys from './namedKeys';

const defaultUnit = 'px';

const plugins = [
    // docs: https://github.com/rofrischmann/fela/tree/master/packages/fela-plugin-unit
    felaPluginUnit(defaultUnit),

    // docs: https://github.com/rofrischmann/fela/tree/master/packages/fela-plugin-friendly-pseudo-class
    felaPluginFriendlyPseudoClass(),

    // docs: https://github.com/rofrischmann/fela/tree/master/packages/fela-plugin-friendly-pseudo-class
    felaPluginNamedKeys(namedKeys),
    felaPluginExtend(),

    // felaPluginValidator should be the last plugin
    process.env.NODE_ENV === 'development' &&
        felaPluginValidator({
            logInvalid: true,
            // Fela deletes grid layout related rules
            deleteInvalid: false,
            useCSSLint: true,
        }),
].filter(Boolean);

export default plugins;
