import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as rules from './ModalFooter.styles';

const ModalFooter: FC = ({ children }) => {
    const { styles } = useFelaEnhanced(rules);

    return <footer className={styles.footer}>{children}</footer>;
};

export default ModalFooter;
