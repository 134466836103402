import { put, select, take } from 'redux-saga/effects';
import { pick } from 'lodash';

import type { operations } from 'api/apiSchema';
import { actions as videoSettingsActions } from 'modules/entities/modules/video-settings';

import { Competition } from 'modules/entities';
import { apiPickerColors } from 'modules/random-draw/constants';
import { selectDenormalizedCompetition } from 'modules/entities/modules/competitions';

import type { RandomDrawFormValues } from '../../types';
import { RandomDrawField } from '../../config';

type Operation = operations['videoSettingsUpdate'] | operations['videoSettingsCreate'];
type Payload = Operation['requestBody']['content']['application/json'];

export default function* saveVideoSettings(competitionId: Competition['id'], values: RandomDrawFormValues) {
    const videoConfig = pick(values, [RandomDrawField.HEADLINE, RandomDrawField.SUBHEADLINE]);
    const winners = values[RandomDrawField.WINNERS];
    const winnerType = winners === 1 ? 'oneWinner' : 'multipleWinners';

    const payload: Payload = {
        competitionId,
        templateName: winnerType,
        config: {
            ...videoConfig,
            backgroundColor: apiPickerColors[values.color],
        },
    };

    const randomDrawDraft = yield select(state => selectDenormalizedCompetition(state, { id: competitionId }));
    let result;

    if (randomDrawDraft?.videoSettings?.length > 0) {
        const videoSettingsId = randomDrawDraft.videoSettings.pop().id;
        yield put(videoSettingsActions.updateVideoSettings.request(videoSettingsId, payload));

        result = yield take([
            videoSettingsActions.updateVideoSettings.success.toString(),
            videoSettingsActions.updateVideoSettings.failure.toString(),
        ]);
    } else {
        yield put(videoSettingsActions.createVideoSettings.request(payload));
        result = yield take([
            videoSettingsActions.createVideoSettings.success.toString(),
            videoSettingsActions.createVideoSettings.failure.toString(),
        ]);
    }

    if (result.error) {
        throw result.error;
    }
    return result;
}
