import tokenPaginationApiReducer from 'modules/entities/services/reducers/tokenPaginationApi';

import { types } from '../../actions';

export default tokenPaginationApiReducer({
    actionTypes: {
        REQUEST: types.fetchPosts.FETCH_POSTS_REQUEST,
        SUCCESS: types.fetchPosts.FETCH_POSTS_SUCCESS,
        FAILURE: types.fetchPosts.FETCH_POSTS_FAILURE,
        CANCEL: types.fetchPosts.FETCH_POSTS_CANCEL,
    },
    initialState: {
        limit: 12,
    },
});
