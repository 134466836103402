import { createSelector } from 'reselect';

import { Account, EntityKey } from 'modules/entities';
import type { SocialPost } from 'modules/entities';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { OAuthProvider } from 'modules/auth';
import { denormalizeFacebookPost, denormalizeInstagramPost } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';

export const selectSocialPost = createSelector(
    [
        entitiesSelectors.selectFacebookPostsEntities,
        entitiesSelectors.selectInstagramPostsEntities,
        selectProp<Account['type']>('type'),
        selectProp<SocialPost['id']>('id'),
    ],
    (facebookPosts, instagramPosts, type, id) => {
        if (!type || !id) {
            return null;
        }

        switch (type) {
            case OAuthProvider.Facebook:
                return denormalizeFacebookPost(id, {
                    [EntityKey.FACEBOOK_POSTS]: facebookPosts.byId,
                });
            case OAuthProvider.Instagram:
                return denormalizeInstagramPost(id, {
                    [EntityKey.INSTAGRAM_POSTS]: instagramPosts.byId,
                });
        }
    },
);
