import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const counter: TRule = () => ({
    display: 'inline-flex',
    alignItems: 'center',
});

export const text: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1.125rem',
    lineHeight: 1.33,
    color: theme.colors.textDark,
    textAlign: 'center',

    width: 26,
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,

    '&.anticon': {
        width: 14,
    },
});
