import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Schema } from 'modules/entities';
import { Button, Modal, Heading, ImageCrop } from 'modules/ui';
import { ImageSearch, PixabayImage } from 'modules/pixabay';

import { useChangeAccountGroupPicture } from '../../hooks';
import { PictureManagementStep } from '../../types';

export interface AccountGroupPictureSearchProps {
    id: Schema['AccountGroup']['id'];
    setStep: (step: PictureManagementStep) => void;
    hide: () => void;
}

const AccountGroupPictureSearch: FC<AccountGroupPictureSearchProps> = ({ id, setStep, hide }) => {
    const [image, setImage] = useState<PixabayImage>(null);
    const { inProgress, setCroppedPicture, upload } = useChangeAccountGroupPicture(id, hide);

    useEffect(() => {
        setStep(image ? PictureManagementStep.PIXABAY_DETAIL : PictureManagementStep.PIXABAY);
    }, [setStep, image]);

    return (
        <>
            <Modal.Header
                showBackArrow
                onBack={image ? () => setImage(null) : () => setStep(PictureManagementStep.COMPUTER)}
            >
                {!image && (
                    <Heading level={2}>
                        <FormattedMessage id="profile.groups.picture" />
                    </Heading>
                )}
            </Modal.Header>

            {image && (
                <ImageCrop
                    image={image.webformatURL}
                    aspect={1}
                    cropShape="round"
                    cropSize={{ width: 170, height: 170 }}
                    onCropComplete={file => setCroppedPicture(file)}
                />
            )}

            <ImageSearch onImageSelect={setImage} hide={Boolean(image)} />

            <Modal.Footer>
                <Button key="cancel" type="text" inverse size="large" disabled={inProgress} onClick={hide}>
                    <FormattedMessage id="general.cancel" />
                </Button>
                <Button
                    key="save"
                    type="primary"
                    size="large"
                    disabled={inProgress}
                    loading={inProgress}
                    onClick={upload}
                >
                    <FormattedMessage id="pixabay.choose" />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default AccountGroupPictureSearch;
