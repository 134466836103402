import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import type { FC } from 'react';

import config from 'config';
import { useFelaEnhanced } from 'hooks';

import { Logo } from 'modules/ui';

import * as rules from './HeaderLogo.styles';

export interface HeaderLogoProps {
    bigger?: boolean;
}

const HeaderLogo: FC<HeaderLogoProps> = ({ bigger = false }) => {
    const { styles } = useFelaEnhanced(rules, { bigger });

    const matchHomepage = useRouteMatch(config.routes.posts);

    return !matchHomepage?.isExact ? (
        <Link to={config.routes.posts} className={styles.logoLink}>
            <Logo className={styles.logo} />
        </Link>
    ) : (
        <Logo className={styles.logo} />
    );
};

export default HeaderLogo;
