import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'modules/ui';

import { useLogout } from '../../hooks';

export interface LogoutButtonProps {}

const LogoutButton: FC<LogoutButtonProps> = () => {
    const { logout, inProgress } = useLogout();

    return (
        <Button type="text" size="large" onClick={logout} disabled={inProgress}>
            <FormattedMessage id="auth.logout" />
        </Button>
    );
};

export default LogoutButton;
