import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RequestId = string;

const initialState: RequestId[] = [];

const addAccountsRequestIdsSlice = createSlice({
    name: 'addAccountsRequestIds',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<RequestId>) => {
            state.push(action.payload);
            return state
        },
        clear: () => initialState,
    },
});

export const { actions, reducer } = addAccountsRequestIdsSlice;
