import React, { FC } from 'react';
import { Empty as AntdEmpty, EmptyProps } from 'antd';

export interface EmptyOwnProps extends EmptyProps {
    show?: boolean;
}

const Empty: FC<EmptyOwnProps> = ({ show = false, children, ...props }) => {
    return show ? <AntdEmpty {...props} /> : <>{children}</>;
};

export default Empty;
