import React, { FC } from 'react';
import { useLocalStorage } from 'react-use-storage';

import { localStorageKeys, TipKey } from 'constants/index';

import AlertPanel from '../AlertPanel';
import type { AlertPanelProps } from '../AlertPanel/AlertPanel';
import Icon from '../Icon';
import Paragraph from '../Paragraph';

export interface AlertTipProps extends Pick<AlertPanelProps, 'extend'> {
    tipKey: TipKey;
}

const AlertTip: FC<AlertTipProps> = ({ tipKey, extend, children }) => {
    const [visible, setVisible] = useLocalStorage(`${localStorageKeys.TIPS}.${tipKey}`, true);

    return (
        Boolean(visible) && (
            <AlertPanel
                message={<Paragraph size="large">{children}</Paragraph>}
                showIcon
                icon={<Icon type={Icon.Types.INFO} />}
                closable
                closeText={<Icon type={Icon.Types.CLOSE} />}
                extend={extend}
                onClose={() => setVisible(false)}
            />
        )
    );
};

export default AlertTip;
