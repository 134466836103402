import React, { ButtonHTMLAttributes, FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as rules from './MobileNavigation.styles';
import { Icon } from 'modules/ui';

export interface MobileNavigationProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    open: boolean;
}

const MobileNavigation: FC<MobileNavigationProps> = ({ open, ...props }) => {
    const { styles } = useFelaEnhanced(rules, { open });

    return (
        <button className={styles.mobileNavigation} {...props}>
            {open ? (
                <Icon type={Icon.Types.CLOSE} extend={{ icon: rules.close }} />
            ) : (
                <Icon type={Icon.Types.HAMBURGER_MENU} />
            )}
        </button>
    );
};

export default MobileNavigation;
