import React from 'react';
import { useFelaEnhanced } from 'hooks';
import type { ReactNode, FC } from 'react';

import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Label.styles';

export interface LabelProps {
    htmlFor?: string;
    label: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
    children?: ReactNode;
}

const Label: FC<LabelProps> = ({ label, htmlFor, children = null, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.wrapper}>
            <label htmlFor={htmlFor} className={styles.label}>
                {label}
            </label>
            {children}
        </div>
    );
};

export default Label;
