import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const tab: TRule = () => ({
    display: 'none',

    desktop: {
        display: 'block',
    },
});

export const mobileLink: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textDark,
    lineHeight: 1,

    ':hover': {
        color: theme.colors.textDark,
    },

    ':focus': {
        color: theme.colors.textDark,
    },

    ':active': {
        color: theme.colors.textDark,
    },

    desktop: {
        display: 'none',
    },
});
