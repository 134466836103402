export enum JobStates {
    NEW = 'new',
    DRAWING_WINNERS = 'drawingWinners',
    SAVING_WINNERS_METADATA = 'savingWinnersMetadata',
    SAVING_COMPETITION_RESULTS = 'savingCompetitionResults',
    CLEANING_ELASTIC_DATA = 'cleaningElasticData',
    CALLING_VIDEO_CREATOR_SERVICE = 'callingVideoCreatorService',
    CREATING_VIDEO_INPUT_FILE = 'creatingVideoInputFile',
    GENERATING_VIDEO = 'generatingVideo',
    UPLOADING_VIDEO = 'uploadingVideo',
    CLEANING_TMP_FILES = 'cleaningTmpFiles',
    FINISHED = 'finished',
    ERROR_VIDEO_GENERATE = 'errorVideoGenerate',
}

export enum GenerationProgress {
    COMMENTS,
    VIDEO,
}
