import { createApiDetailRequestActions, createApiRequestActions } from '@ackee/redux-utils';
import { VideoSettings } from 'modules/entities/types';
import * as types from './types';
import { operations } from 'api/apiSchema';

export const fetchVideoSettings = createApiDetailRequestActions<
    VideoSettings['id'],
    undefined,
    undefined,
    undefined,
    string
>({
    REQUEST: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_REQUEST,
    SUCCESS: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_SUCCESS,
    FAILURE: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_FAILURE,
    CANCEL: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_CANCEL,
    RESET: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_RESET,
});

export const createVideoSettings = createApiRequestActions<
    operations['videoSettingsCreate']['requestBody']['content']['application/json'],
    undefined,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.createVideoSettings.CREATE_VIDEO_SETTINGS_REQUEST,
    SUCCESS: types.createVideoSettings.CREATE_VIDEO_SETTINGS_SUCCESS,
    FAILURE: types.createVideoSettings.CREATE_VIDEO_SETTINGS_FAILURE,
    CANCEL: types.createVideoSettings.CREATE_VIDEO_SETTINGS_CANCEL,
    RESET: types.createVideoSettings.CREATE_VIDEO_SETTINGS_RESET,
});

export const updateVideoSettings = createApiDetailRequestActions<
    VideoSettings['id'],
    operations['videoSettingsUpdate']['requestBody']['content']['application/json'],
    undefined,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_REQUEST,
    SUCCESS: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_SUCCESS,
    FAILURE: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_FAILURE,
    CANCEL: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_CANCEL,
    RESET: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_RESET,
});
