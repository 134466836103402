import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { noop } from 'lodash';

import { ApiGet, ApiResponseWithHeaders, authApi } from 'config/antonio';
import config from 'config/config';
import * as log from 'config/loglevel';
import takeLatestRequest from 'services/sagas/takeLatestRequest';
import { operations } from 'api/apiSchema';
import * as entitiesActions from 'modules/entities/services/actions';
import { normalizeInstagramPosts } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import { TokenPaginationApiReducerState } from 'modules/entities/services/reducers/tokenPaginationApi';
import { createUIErrorMessage } from 'modules/errors';

import { selectInstagramPostsApi } from '../selectors';
import { fetchPosts as apiActions } from '../actions';

type RequestAction = ReturnType<typeof apiActions.request>;
type Operation = operations['getInstagramAccountPosts'];
type Response = ApiResponseWithHeaders<
    Operation['responses']['200']['content']['application/json'],
    Operation['responses']['200']['headers']
>;
type RequestParams = Operation['parameters']['path'];

function* fetchInstagramPostsHandler(action: RequestAction) {
    const api: TokenPaginationApiReducerState = yield select(selectInstagramPostsApi);
    const limit = action.payload.params?.limit ?? api.limit;

    try {
        const { data, headers }: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.instagram.posts,
            {
                uriParams: {
                    id: action.meta.id,
                },
                params: {
                    ...action.payload.params,
                    limit,
                },
            },
        );

        const { result: ids, entities } = normalizeInstagramPosts(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.INSTAGRAM_POSTS, {
                ids,
                byId: entities[EntityKey.INSTAGRAM_POSTS],
                strategy: action.payload.strategy,
            }),
            apiActions.success(action.meta.id, undefined, {
                limit,
                nextPageToken: headers['x-next-page-token'],
            }),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(action.meta.id, uiError));

        log.error(error);
    }
}

function* resetInstagramPostsHandler() {
    yield put(entitiesActions.resetEntities(EntityKey.INSTAGRAM_POSTS));
}

export default function* fetchInstagramPosts() {
    yield all([
        takeLatestRequest(
            (action: RequestAction) => action.meta.id,
            {
                pattern: apiActions.request.toString(),
                handler: fetchInstagramPostsHandler,
            },
            {
                pattern: apiActions.cancel.toString(),
                handler: noop,
            },
        ),
        takeLatest(apiActions.reset.toString(), resetInstagramPostsHandler),
    ]);
}
