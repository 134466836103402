import React, { FC } from 'react';

const UploadComputer: FC = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" {...props} viewBox="0 0 40 40">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#6B67DF"
                d="M31.018 14.57l-9.114-9.916a2.024 2.024 0 00-2.98 0l-9.1 9.899c-1.18 1.285-.27 3.361 1.475 3.361h3.253v10.898c0 1.104.895 1.999 1.998 1.999h7.71a2.022 2.022 0 002.016-2.016v-10.88h3.274c1.736 0 2.642-2.067 1.468-3.346"
            ></path>
            <path
                fill="#EFEEFB"
                d="M35.655 37.845H5.172A1.172 1.172 0 014 36.672V35.5c0-.649.525-1.173 1.172-1.173h30.483c.647 0 1.173.524 1.173 1.173v1.172c0 .649-.526 1.173-1.173 1.173"
            ></path>
        </g>
    </svg>
);

export default UploadComputer;
