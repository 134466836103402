import React, { FC } from 'react';

const Minus: FC = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 2" width="14" height="2" {...props}>
        <rect
            width="14"
            height="2"
            x="5"
            y="11"
            fill="currentColor"
            fillRule="evenodd"
            rx="1"
            transform="translate(-5 -11)"
        ></rect>
    </svg>
);

export default Minus;
