import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import { EntityKey, DEFAULT_GROUP, ApiReducerKey } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { denormalizeAccounts } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';

import type { Schema } from 'modules/entities/types';

export const selectAccountsApi = state => apiSelector(state, EntityKey.ACCOUNTS, ApiReducerKey.LIST);
export const selectCreateAccountApi = state => apiSelector(state, EntityKey.ACCOUNTS, ApiReducerKey.CREATE);
export const selectDeleteAccountApi = state => apiSelector(state, EntityKey.ACCOUNTS, ApiReducerKey.DELETE);

export const selectAccounts = createSelector(entitiesSelectors.selectAccountsEntities, accounts => {
    const denormalizedAccountGroups: Array<Schema['Account']> = denormalizeAccounts(accounts[DEFAULT_GROUP], {
        [EntityKey.ACCOUNTS]: accounts.byId,
    });

    return denormalizedAccountGroups;
});

export const selectAccountsById = createSelector(entitiesSelectors.selectAccountsEntities, accounts => accounts.byId);

export const selectAccountsCount = createSelector(selectAccounts, accounts => accounts.length);

export const selectAccount = createSelector(
    [entitiesSelectors.selectAccountsEntities, selectProp<Schema['Account']['id']>('id')],
    (accounts, id) => accounts.byId[id],
);

export const selectAccountType = createSelector(selectAccount, account => account?.type);
