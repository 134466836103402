import { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Card } from 'modules/ui';
import * as felaRules from './ArticlesCardSkeleton.styles';
import { Skeleton } from 'antd';
export interface ArticlesCardSkeletonProps {
    active: boolean;
    children: JSX.Element;
}

const ArticlesCardSkeleton: FC<ArticlesCardSkeletonProps> = ({ children, active }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return active ? (
        <Card extend={{ card: felaRules.card }}>
            <Skeleton active avatar className={styles.skeleton} />
        </Card>
    ) : (
        children
    );
};

export default ArticlesCardSkeleton;
