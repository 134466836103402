import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const datePicker: TRule = () => ({
    width: '100%',
    padding: 0,

    '& .ant-picker-input > input': {
        paddingTop: 24,
        paddingBottom: 7,
        paddingLeft: '3.5rem',
        paddingRight: 24,

        // Fix of partialy visible cursor
        marginLeft: -3,
    },

    '& .ant-picker-clear': {
        right: 24,
    },
});

export const dropdown: TRuleWithTheme = ({ theme }) => ({
    '& .ant-picker-header-view': {
        color: theme.colors.textGray,
    },

    '& .ant-picker-content th': {
        color: theme.colors.textGray,
    },

    '& .ant-picker-panel-container': {
        padding: `0.5rem ${theme.spacing.small}`,
        borderRadius: theme.borderRadius.xlarge,

        '& .ant-picker-panel': {
            borderWidth: 0,
        },
    },

    '& .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner': {
        color: theme.colors.white,
    },

    '& .ant-picker-content td': {
        minWidth: 44,
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
    },

    '& .ant-btn': {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

export const floatingLabel: TRule = () => ({
    left: '3.25rem',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing.xsmall,
    left: theme.spacing.xsmall,

    color: theme.colors.icon,
});

export const formItem: TRule = () => ({});
