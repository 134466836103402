import React, { FC } from 'react';

export interface EyeHiddenProps {}

const EyeHidden: FC<EyeHiddenProps> = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M19.868 9.498a15.623 15.623 0 01-2.913 3.629l-1.411-1.411a13.398 13.398 0 002.283-2.711c-.932-1.42-3.755-5.111-8.046-5.002-.6.015-1.163.109-1.7.25l-1.58-1.58a9.356 9.356 0 013.229-.67c5.975-.186 9.5 5.388 10.138 6.5.176.308.176.687 0 .995zm-7.95 4.249l1.58 1.58a9.35 9.35 0 01-3.473.673C4.162 16 .762 10.595.133 9.498a.999.999 0 010-.995 15.605 15.605 0 012.912-3.63l1.41 1.412a13.432 13.432 0 00-2.282 2.71c.932 1.42 3.742 5.103 8.047 5.003a7.29 7.29 0 001.698-.251zM10 10.5c-.827 0-1.5-.672-1.5-1.5 0-.025.01-.048.012-.074l1.563 1.563c-.026 0-.05.01-.075.01zM2.707.293a1 1 0 00-1.414 1.414l5.63 5.63A3.454 3.454 0 006.5 9c0 1.93 1.57 3.5 3.5 3.5.59 0 1.155-.147 1.663-.423l5.63 5.63A.995.995 0 0018 18a.999.999 0 00.707-1.707l-16-16z"
        ></path>
    </svg>
);

export default EyeHidden;
