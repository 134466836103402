import type { TRuleWithTheme } from 'styles/theme';

export const footer: TRuleWithTheme<{ active: true }> = ({ theme, active }) => ({
    width: '100%',

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: active ? theme.colors.inputFocus : theme.colors.inputBorder,
    borderBottomLeftRadius: theme.borderRadius.navItem,
    borderBottomRightRadius: theme.borderRadius.navItem,
    backgroundColor: theme.colors.white,

    transitionProperty: 'border-color',
    transitionDuration: theme.transition.duration,

    tablet: {
        height: 40,
    },
});
