import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';

import { Button, Icon, Page } from 'modules/ui';
import { ConnectSocialProfilesProvider } from 'modules/social-accounts';
import { useFelaEnhanced } from 'hooks';

import PlanPostCardsGrid from './PlanPostCardsGrid';
import PlanPostCardsModal from './PlanPostCardsModal';
import AccountsCard from '../AccountsCard';
import ConnectAccountOnboarding from '../ConnectAccountOnboarding';
import PostCalendar from '../PostCalendar';
import * as felaRules from './PostsPage.styles';

const PostsPage: FC = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const [openPlanModal, setOpenPlanModal] = useState(false);

    const toggleOpenModal = () => setOpenPlanModal(prevState => !prevState);

    return (
        <Page containerSize="large">
            <ConnectSocialProfilesProvider>
                <Row gutter={24} justify="end" className={styles.container}>
                    <Col xs={24} lg={9}>
                        <AccountsCard />
                    </Col>
                    <PlanPostCardsGrid />
                </Row>
                
                <Button
                    size="large"
                    type="primary"
                    icon={<Icon type={Icon.Types.PLUS} />}
                    extend={{ button: rules.planButton }}
                    onClick={toggleOpenModal}
                >
                    <FormattedMessage id="posts.planNew" />
                </Button>

                <PlanPostCardsModal opened={openPlanModal} toggleOpen={toggleOpenModal} />
                <PostCalendar />
                <ConnectAccountOnboarding />
            </ConnectSocialProfilesProvider>
        </Page>
    );
};

export default PostsPage;
