import { call, put, takeLeading } from 'redux-saga/effects';
import config from 'config';
import { authApi } from 'config/antonio';
import { operations } from 'api/apiSchema';
import type { ApiGet, ApiResponse } from 'config/antonio';
import * as log from 'config/loglevel';

import { types, fetchUser } from '../actions';

type Operation = operations['userDetail'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestParams = Operation['parameters']['path'];

function* handleFetchUser(action) {
    try {
        const response: Response = yield call<ApiGet<Response, RequestParams>>(authApi.get, config.api.endpoints.user, {
            uriParams: {
                id: action.payload.userId,
            },
        });

        yield put(fetchUser.fetchUserSuccess(response.data));
    } catch (e) {
        yield put(fetchUser.fetchUserFailure(e));
        log.error(e);
    }
}

export default function* fetchUserSaga() {
    yield takeLeading(types.fetchUser.FETCH_USER_REQUEST, handleFetchUser);
}
