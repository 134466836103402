import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.INSTAGRAM_POSTS,
});

export const fetchPosts = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_POSTS_' }),
});

export const fetchPost = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_POST_' }),
});
