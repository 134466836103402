import React, { FunctionComponent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { BlankPage, Heading, Paragraph, Icon } from 'modules/ui';

import illustration from 'assets/images/sign_in_illustration.png';
import illustration2x from 'assets/images/sign_in_illustration@2x.png';

import * as felaRules from './AuthPage.styles';
import FacebookOAuthButton from '../FacebookOAuthButton';
import GoogleLoginButton from '../GoogleLoginButton';

export interface AuthPageProps {
    header: string | React.ReactNode;
    separatorText: string | React.ReactNode;
    footer?: string | React.ReactNode;
}

const AuthPage: FunctionComponent<AuthPageProps> = ({ header, separatorText, footer, children }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    return (
        <BlankPage>
            <section className={styles.columnFeatures}>
                <Heading level={2} extend={{ heading: felaRules.heading }}>
                    <FormattedMessage id="auth.claim.title" />
                </Heading>
                <Paragraph extend={{ paragraph: felaRules.perex }}>
                    <FormattedMessage id="auth.claim.text" />
                </Paragraph>
                <img
                    srcSet={`${illustration}, ${illustration2x} 2x`}
                    src={illustration}
                    alt={formatMessage({ id: 'auth.login.illustrationAlt' })}
                    className={styles.illustration}
                />
            </section>

            <section className={styles.columnForm}>
                <div className={styles.formContent}>
                    <header className={styles.header}>{header}</header>
                    <FacebookOAuthButton icon={<Icon type={Icon.Types.FACEBOOK} />} />
                    <GoogleLoginButton icon={<Icon type={Icon.Types.GOOGLE} />} />
                    <Paragraph size="small" faded extend={{ paragraph: felaRules.separatorText }}>
                        {separatorText}
                    </Paragraph>
                    {children}
                    <footer className={styles.footer}>{footer}</footer>
                </div>
            </section>
        </BlankPage>
    );
};

export default AuthPage;
