import React, { FC, ImgHTMLAttributes } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    fallbackSrc?: string;
}

const Image: FC<ImageProps> = ({ src, alt, fallbackSrc, ...rest }) => {
    const handleImageError = e => {
        e.target.onerror = null;
        e.target.src = fallbackSrc;
    };

    return <img src={src || fallbackSrc} alt={alt} onError={handleImageError} draggable={false} {...rest} />;
};

export default Image;
