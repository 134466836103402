import { all, call, put, takeLatest } from 'redux-saga/effects';

import config from 'config';
import { api } from 'config/antonio';
import * as log from 'config/loglevel';

import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeArticles } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import * as actions from '../actions';

function* fetchArticlesHandler() {
    try {
        const params = new URLSearchParams('?_fields=id,date,slug,excerpt,title,featured_media,featured_image_url');
        const response = yield call(api.get, config.wordpress.apiUrl + config.wordpress.endpoints.articles, {
            params,
        });
   
        const { result: articleIds, entities } = normalizeArticles(response.data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ARTICLES, {
                ids: articleIds,
                byId: entities[EntityKey.ARTICLES],
             
            }),
            actions.fetchArticles.success(),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchArticles.failure(uiError));

        log.error(error);
    }
}

function* resetArticles() {
    yield put(entitiesActions.resetEntities(EntityKey.ARTICLES));
}

export default function* fetchArticles() {
    yield all([
        takeLatestRequest(
            () => 'fetchArticles',
            {
                pattern: actions.fetchArticles.request.toString(),
                handler: fetchArticlesHandler,
            },
            {
                pattern: actions.fetchArticles.cancel.toString(),
                handler: resetArticles,
            },
        ),
        takeLatest(actions.fetchArticles.reset.toString(), resetArticles),
    ]);
}
