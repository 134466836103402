import type { TRuleWithTheme } from 'styles/theme';
import type { HeadingProps } from './Heading';

const boldLevels = [3, 5];

export const heading: TRuleWithTheme<{ level?: HeadingProps['level'] }> = ({ level }) => ({
    fontWeight: boldLevels.includes(level) ? 700 : 800,
    marginBottom: 0,

    extend: [
        {
            condition: level === 1,
            style: {
                '&.ant-typography': {
                    lineHeight: 1.42,
                },
            },
        },
        {
            condition: level === 6,
            style: {
                fontSize: '1.125rem',
            },
        },
    ],
});
