import React, { FC } from 'react';

export interface BinProps {}

const Bin: FC<BinProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
                fill="currentColor"
                d="M8.4,10.8 C9.06,10.8 9.6,11.34 9.6,12 L9.6,12 L9.6,16.8 C9.6,17.46 9.06,18 8.4,18 C7.74,18 7.2,17.46 7.2,16.8 L7.2,16.8 L7.2,12 C7.2,11.34 7.74,10.8 8.4,10.8 Z M15.6,10.8 C16.26,10.8 16.8,11.34 16.8,12 L16.8,12 L16.8,16.8 C16.8,17.46 16.26,18 15.6,18 C14.94,18 14.4,17.46 14.4,16.8 L14.4,16.8 L14.4,12 C14.4,11.34 14.94,10.8 15.6,10.8 Z M19.2,20.4 C19.2,21.0612 18.6624,21.6 18,21.6 L6,21.6 C5.3376,21.6 4.8,21.0612 4.8,20.4 L4.8,7.2 L19.2,7.2 L19.2,20.4 Z M9.6,2.7936 C9.6,2.6076 9.8568,2.4 10.2,2.4 L13.8,2.4 C14.1432,2.4 14.4,2.6076 14.4,2.7936 L14.4,4.8 L9.6,4.8 L9.6,2.7936 Z M21.6,4.8 L16.8,4.8 L16.8,2.7936 C16.8,1.2528 15.4548,0 13.8,0 L10.2,0 C8.5452,0 7.2,1.2528 7.2,2.7936 L7.2,4.8 L2.4,4.8 L1.2,4.8 C0.54,4.8 0,5.34 0,6 C0,6.66 0.54,7.2 1.2,7.2 L2.4,7.2 L2.4,20.4 C2.4,22.3848 4.0152,24 6,24 L18,24 C19.9848,24 21.6,22.3848 21.6,20.4 L21.6,7.2 L22.8,7.2 C23.46,7.2 24,6.66 24,6 C24,5.34 23.46,4.8 22.8,4.8 L21.6,4.8 Z"
                id="path-1"
            ></path>
        </g>
    </svg>
);

export default Bin;
