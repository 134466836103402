import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';
import sliderHandleImg from 'assets/images/icons/slider_handle.svg';

export const container: TRule = () => ({
    position: 'relative',
    width: '100%',
    maxWidth: 500,
    height: 200,

    tablet: {
        height: 320,
    },
});

export const cropper: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: theme.borderRadius.xlarge,
});

export const cropArea: TRuleWithTheme = ({ theme }) => ({
    color: '#00000033',
    borderColor: theme.colors.cardBorderDark,
});

export const sliderContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: theme.spacing.xsmall,

    color: theme.colors.primary,
});

export const slider: TRule = () => ({
    maxWidth: 280,
    flex: 1,
    margin: '0 0.75rem',

    '& .ant-slider-handle': {
        backgroundImage: `url(${sliderHandleImg})`,
    },
});
