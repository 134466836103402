import type { TRuleWithTheme } from 'styles/theme';

import type { ButtonOwnProps } from './Button';

const circleIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',

    '&.ant-btn-icon-only > *': {
        fontSize: '0.875rem',
    },
};

export const button: TRuleWithTheme<ButtonOwnProps> = ({ size, type, inverse, shape, theme }) => {
    const isLarge = size === 'large';
    const isPrimary = type === 'primary';
    const isText = type === 'text';
    const isCircle = shape === 'circle';

    let color = theme.colors.buttonDefault;
    let hoverColor = theme.colors.primary;
    const hoverBgColor = isPrimary ? theme.colors.buttonHover : theme.colors.buttonDefaultBackground;
    const activeBgColor = isPrimary ? theme.colors.buttonActive : theme.colors.buttonDefaultBackground;

    if (isPrimary) {
        color = theme.colors.white;
        hoverColor = theme.colors.white;
    } else if (type === 'text') {
        color = theme.colors.textGray;
        hoverColor = theme.colors.primary;
    }

    if (inverse) {
        [color, hoverColor] = [hoverColor, color];
    }

    const hoverStyle = {
        color: hoverColor,
        backgroundColor: !isText ? hoverBgColor : 'transparent',
        borderColor: !isText ? hoverBgColor : 'transparent',
        boxShadow: !isText ? theme.shadow.buttonHover : 'none',
    };

    return {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',

        color,
        borderRadius: isLarge ? theme.borderRadius.large : theme.borderRadius.small,

        padding: '4px 16px 6px',

        fontSize: isLarge ? '1.125rem' : '1rem',
        lineHeight: 1.375,
        letterSpacing: 0.2,
        textDecoration: 'none',

        ':hover': {
            ...hoverStyle,
            textDecoration: isText ? 'none' : 'underline',
        },

        ':focus': {
            ...hoverStyle,
            textDecoration: isText ? 'underline' : 'none',
        },

        ':active': {
            color,
            backgroundColor: !isText ? activeBgColor : 'transparent',
            borderColor: !isText ? activeBgColor : 'transparent',
            textDecoration: isText ? 'underline' : 'none',
        },

        '&.ant-btn-lg': {
            height: theme.sizes.button.large,
        },

        '&.ant-btn-lg.ant-btn-block': {
            height: theme.sizes.button.block,
        },

        ':not([disabled]):active': {
            boxShadow: !isText ? theme.shadow.buttonClick : 'none',
        },

        ':disabled:hover': {
            textDecoration: isText ? 'underline' : 'none',
        },

        ':disabled': {
            backgroundColor: isText ? 'transparent' : theme.colors.buttonDisabled,
            opacity: 0.5,
        },

        '& > span': {
            display: 'inline',
        },

        '& .anticon': {
            display: 'inline-flex',
            width: isCircle ? 14 : 24,
            height: isCircle ? 14 : 24,
            marginRight: isCircle ? 0 : '0.875rem',
        },

        '&.ant-btn-icon-only .anticon': {
            marginRight: 0,
        },

        desktop: {
            paddingTop: isLarge ? 9 : 4,
            paddingBottom: isLarge ? 11 : 6,
            paddingLeft: isLarge ? 24 : 16,
            paddingRight: isLarge ? 24 : 16,

            extend: {
                condition: isText || type === 'link',
                style: {
                    desktop: {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },

        extend: [
            {
                condition: isText,
                style: {
                    textDecoration: 'underline',
                },
            },
            {
                condition: isText || type === 'link',
                style: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
            {
                condition: isCircle,
                style: circleIconStyle,
            },
        ],
    };
};
