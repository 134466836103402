import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = () => ({
    marginRight: '2.25rem',
    marginLeft: '1.625rem',

    smallTablet: {
        marginBottom: 0,
    },
});
