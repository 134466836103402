import { noop } from 'lodash';
import { call, put } from 'redux-saga/effects';

import * as log from 'config/loglevel';
import config from 'config/config';
import { ApiPut, ApiResponse, authApi } from 'config/antonio';

import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import * as entitiesActions from 'modules/entities/services/actions';
import { normalizeVideoSettings } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import { createUIErrorMessage } from 'modules/errors';

import { updateVideoSettings as apiActions } from '../actions';

type Operation = operations['videoSettingsUpdate'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestBody = Operation['requestBody']['content']['application/json'];
// TODO: Change to apiSchema when parameters are added BE
type RequestParams = { id: string };
type RequestAction = ReturnType<typeof apiActions.request>;
type CancelAction = ReturnType<typeof apiActions.cancel>;

function* updateVideoSettingsHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiPut<Response, RequestBody, RequestParams>>(
            authApi.put,
            config.api.endpoints.videoSettingsDetail,
            { ...action.payload, uriParams: { id: action.meta.id } },
        );

        const { result: id, entities } = normalizeVideoSettings(data);

        yield put(
            entitiesActions.setEntitiesGroup(EntityKey.VIDEO_SETTINGS, {
                ids: [id],
                byId: entities[EntityKey.VIDEO_SETTINGS],
            }),
        );

        yield put(apiActions.success(action.meta.id, undefined, { lastSuccessAt: new Date().toISOString() }));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(action.meta.id, uiError));

        log.error(error);
    }
}

export default function* updateVideoSettings() {
    yield takeLatestRequest(
        (action: RequestAction | CancelAction) => action.meta.id,
        {
            pattern: apiActions.request.toString(),
            handler: updateVideoSettingsHandler,
        },
        { pattern: apiActions.cancel.toString(), handler: noop },
    );
}
