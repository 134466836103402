import type { FC } from 'react';

const Comment: FC = () => (
    <svg width="11px" height="10px" viewBox="0 0 11 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ic/24/comments_flat" transform="translate(-6.000000, -8.000000)">
                <mask id="mask-2" fill="white">
                    <path
                        d="M15.35,8 L7.65,8 C6.7403,8 6,8.70842105 6,9.57894737 L6,17.4736842 C6,17.6631579 6.1067,17.8384211 6.27885,17.9315789 C6.363,17.9773684 6.4565,18 6.55,18 C6.6479,18 6.7458,17.9752632 6.83325,17.9247368 L9.32145,16.4963158 C9.40725,16.4468421 9.50515,16.4210526 9.6047,16.4210526 L15.35,16.4210526 C16.2597,16.4210526 17,15.7126316 17,14.8421053 L17,9.57894737 C17,8.70842105 16.2597,8 15.35,8 L15.35,8 Z"
                        id="path-1"
                        fill="currentColor"
                    ></path>
                </mask>
                <path
                    d="M15.35,8 L7.65,8 C6.7403,8 6,8.70842105 6,9.57894737 L6,17.4736842 C6,17.6631579 6.1067,17.8384211 6.27885,17.9315789 C6.363,17.9773684 6.4565,18 6.55,18 C6.6479,18 6.7458,17.9752632 6.83325,17.9247368 L9.32145,16.4963158 C9.40725,16.4468421 9.50515,16.4210526 9.6047,16.4210526 L15.35,16.4210526 C16.2597,16.4210526 17,15.7126316 17,14.8421053 L17,9.57894737 C17,8.70842105 16.2597,8 15.35,8 L15.35,8 Z"
                    id="path-1"
                    fill="currentColor"
                ></path>
            </g>
        </g>
    </svg>
);

export default Comment;
