import csAntd from 'antd/es/locale-provider/cs_CZ';
import enAntd from 'antd/es/locale-provider/en_US';
import 'date-fns/locale/cs';
import 'date-fns/locale/en-US';

import * as translations from '../../../../../translations';
import { Languages } from '../constants';

export const languages = Object.values(Languages);

export const intlData = {
    [Languages.CS]: translations.cs,
    [Languages.EN]: translations.en,
};

export const antdData = {
    [Languages.CS]: csAntd,
    [Languages.EN]: enAntd,
};
