import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const wrapper: TRuleWithTheme<{ isDragging: boolean; isDragOverlay }> = ({
    isDragging,
    isDragOverlay,
    theme,
}) => ({
    opacity: isDragging ? 0.5 : 1,
    extend: {
        condition: isDragOverlay,
        style: {
            border: `1px solid ${theme.colors.inputBorder}`,
            borderRadius: theme.borderRadius.large,
            background: theme.colors.white,
        },
    },
});

export const button: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.primary,
    marginLeft: '3.75rem',

    tablet: {
        marginLeft: 0,
    },
});

export const dragButton: TRule = () => ({
    display: 'none',

    tablet: {
        display: 'inline-flex',
    },
});

export const dragButtonMobile: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    right: '1.5rem',
    top: '50%',
    transform: 'translateY(-50%)',

    tablet: {
        display: 'none',
    },
});
