import type { FC } from 'react';
import { FormattedDate } from 'react-intl';

import type { RulesExtend } from 'styles/theme';
import { useFelaEnhanced } from 'hooks';
import { Card, Image, Paragraph, Icon } from 'modules/ui';
import type { CardOwnProps } from 'modules/ui/components/Card/Card';
import type { Schema } from 'modules/entities';
import postFallbackImage from 'assets/images/post_fallback.png';

import * as felaRules from './PostPreview.styles';

type SocialPost = Schema['InstagramAccountPost'] | Schema['FbPagePost'];

const isFacebookPost = (post: SocialPost): post is Schema['FbPagePost'] => 'created_time' in post;

interface VerticalPostProps extends Omit<CardOwnProps, 'onClick'> {
    post?: SocialPost;
    active?: boolean;
    onClick?: (id: SocialPost['id']) => void;
    loading?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const PostPreview: FC<VerticalPostProps> = ({ post, active = false, onClick, extend, loading, ...props }) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend, active });

    let postPicture = postFallbackImage;

    if (!post && !loading) return null;

    if (post) {
        postPicture = isFacebookPost(post) ? post.picture : post.media_url;
    }

    return (
        <Card
            cover={
                <>
                    <Image src={postPicture} fallbackSrc={postFallbackImage} alt="" />
                    {active && <div className={styles.activeOverflow} />}
                </>
            }
            onClick={post ? () => onClick(post.id) : undefined}
            extend={{ card: rules.card }}
            {...props}
        >
            {post && isFacebookPost(post) && (
                <>
                    <Paragraph size="x-small" faded={true}>
                        <FormattedDate value={post.created_time} />
                    </Paragraph>
                    <Paragraph size="x-small" extend={{ paragraph: rules.commentCount }}>
                        <Icon type={Icon.Types.COMMENT} extend={{ icon: rules.commentIcon }} />
                        {post.comments.summary.total_count}
                    </Paragraph>
                </>
            )}
            {post && !isFacebookPost(post) && (
                <>
                    <Paragraph size="x-small" faded={true}>
                        <FormattedDate value={post.timestamp} />
                    </Paragraph>
                    <Paragraph size="x-small" extend={{ paragraph: rules.commentCount }}>
                        <Icon type={Icon.Types.COMMENT} extend={{ icon: rules.commentIcon }} />
                        {post.comments_count}
                    </Paragraph>
                </>
            )}
        </Card>
    );
};

export default PostPreview;
