import type { FC, ReactNode } from 'react';
import { Col } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { Paragraph } from 'modules/ui';

import * as felaRules from './FormSummaryItem.styles';

export interface FormSummaryItemProps {
    label: ReactNode;
    value?: ReactNode;
}

const FormSummaryItem: FC<FormSummaryItemProps> = ({ label, value, children }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Col xs={24} sm={12} className={styles.item}>
            <Paragraph size="small" faded extend={{ paragraph: felaRules.label }}>
                {label}
            </Paragraph>

            {value !== undefined && <Paragraph>{value}</Paragraph>}
            {children}
        </Col>
    );
};

export default FormSummaryItem;
