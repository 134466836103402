import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from '../../../../constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.ACCOUNT_GROUPS,
});

export const fetchAccountGroups = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ACCOUNT_GROUPS_' }),
});

export const updateAccountGroup = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'UPDATE_ACCOUNT_GROUP_' }),
});

export const deleteAccountGroup = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'DELETE_ACCOUNT_GROUP_' }),
});

export const createAccountGroup = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_ACCOUNT_GROUP_' }),
});
