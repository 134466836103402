import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const header: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.spacing.small,
});

export const heading: TRule = () => ({
    textTransform: 'capitalize',
    marginLeft: '0.75rem',
});
