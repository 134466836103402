import type { FC } from 'react';
import { useField } from 'react-final-form';

import { useFelaEnhanced } from 'hooks';
import { Tag } from 'modules/ui';

import TextField, { TextFieldOwnProps } from '../TextField';
import { convertToTags } from '../../services/utils';

import * as felaRules from './TagsField.styles';

export interface TagsFieldProps extends TextFieldOwnProps {}

const TagsField: FC<TagsFieldProps> = props => {
    const { styles } = useFelaEnhanced(felaRules);
    const { input } = useField<string>(props.name, {
        subscription: {
            value: true,
        },
    });

    const chunks = convertToTags(input.value);

    const handleTagRemoval = (index: number) => {
        const updatedChunks = chunks.filter((_, i) => index !== i);
        input.onChange(updatedChunks.join(' '));
    };

    return (
        <div className={styles.tagsField}>
            <TextField {...props} />

            <ul className={styles.tagList}>
                {chunks.map((chunk, index) => (
                    <li data-testid="tagItem" key={chunk + String(index)} className={styles.item}>
                        <Tag closable onClose={() => handleTagRemoval(index)}>
                            {chunk}
                        </Tag>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TagsField;
