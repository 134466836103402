import { FC, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { Radio } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { RadioButton } from 'modules/ui';

import FormItem, { FormItemOwnProps } from '../FormItem';

import * as felaRules from './RadioButtonGroupField.styles';
import type { FormFieldProps } from '../types';

interface Option {
    value: string | number | boolean;
    label: ReactNode;
}

export interface RadioButtonGroupFieldProps extends FormFieldProps<string | number> {
    value: FormFieldProps<string | number>['value'];
    options: Option[];
    fluid?: FormItemOwnProps['fluid'];
    extend?: RulesExtend<typeof felaRules>;
}

const RadioButtonGroupField: FC<RadioButtonGroupFieldProps> = ({
    label,
    required = false,
    value,
    help,
    options,
    fluid,
    extend,
    disabled,
    ...props
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <Field type="radio" {...props} disabled={disabled}>
            {({ input, meta }) => (
                <FormItem {...{ input, meta, required, help, fluid, extend: { formItem: rules.formItem } }}>
                    <Radio.Group {...input} className={styles.group}>
                        {options.map(option => (
                            <RadioButton
                                key={String(option.value)}
                                value={option.value}
                                checked={value === option.value}
                                disabled={disabled}
                                extend={{ radioButton: felaRules.radioButton }}
                            >
                                {option.label}
                            </RadioButton>
                        ))}
                    </Radio.Group>
                </FormItem>
            )}
        </Field>
    );
};

export default RadioButtonGroupField;
