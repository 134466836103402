import { createRenderer } from 'fela';

import * as Consts from 'constants/index';
import { resets, vendors, theme, fonts } from 'styles';
import { applyStaticCSS, applyFonts } from '../utilities';

import plugins from './plugins';
import enhancers from './enhancers';

export const rendererConfig = {
    devMode: Consts.isEnvDevelopment,
    enhancers,
    plugins,
    theme,
};

export const staticCSS = [resets, ...vendors];

export const configureRenderer = (css = staticCSS) => {
    const renderer = createRenderer(rendererConfig);

    applyStaticCSS(renderer, css);
    applyFonts(renderer, fonts);

    return renderer;
};
