import type { TRuleWithTheme } from 'styles/theme';

export const progress: TRuleWithTheme<{ showPercentage: boolean }> = ({ theme, showPercentage }) => ({
    '& .ant-progress-circle-trail': {
        stroke: theme.colors.backgroundPrimary,
        strokeWidth: 2,
    },

    '& .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path': {
        stroke: theme.colors.primary,
        strokeWidth: 2,
    },

    '& .ant-progress-text': {
        visibility: showPercentage ? 'visible' : 'hidden',
    },
});
