import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Button } from 'modules/ui';
import type { RulesExtend } from 'styles/theme';

import PasswordRecoveryModal from '../PasswordRecoveryModal';
import * as felaRules from './PasswordRecovery.styles';

export interface PasswordRecoveryProps {
    extend?: RulesExtend<typeof felaRules>;
}

const PasswordRecovery: FC<PasswordRecoveryProps> = ({ extend }) => {
    const [visible, setModalVisible] = React.useState(false);
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            <Button
                type="text"
                inverse
                size="small"
                onClick={() => setModalVisible(true)}
                extend={{ button: felaRules.button }}
            >
                <FormattedMessage id="auth.forgottenPassword" />
            </Button>
            <PasswordRecoveryModal visible={visible} onClose={() => setModalVisible(false)} />
        </div>
    );
};

export default PasswordRecovery;
