import { combineReducers } from 'redux';

import { ApiReducerKey } from 'modules/entities/constants';

import create from './create';
import detail from './detail';
import update from './update';

export default combineReducers({
    [ApiReducerKey.CREATE]: create,
    [ApiReducerKey.DETAIL]: detail,
    [ApiReducerKey.UPDATE]: update,
});
