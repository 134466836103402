import { combineReducers } from 'redux';
import routingHistory from '@ackee/chris/routing-history';

import { reducer as translateReducer } from '../../localization';
import { connectRouterWithHistory } from '../../router';
import reducers from '../../../../../services/reducers';

export default combineReducers({
    ...reducers,
    translate: translateReducer,
    router: connectRouterWithHistory,
    history: routingHistory.reducer,
});
