import type { TRuleWithTheme } from 'styles/theme';

export const alert: TRuleWithTheme = ({ theme }) => ({
    borderRadius: theme.borderRadius.xlarge,
    padding: '0.875rem 1.375rem',

    '& .ant-alert-message': {
        fontSize: '0.875rem',
        lineHeight: 1.42,
    },

    '& .ant-alert-icon': {
        marginRight: '0.625rem',
    },
});
