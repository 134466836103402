import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.createMedia.CREATE_MEDIA_REQUEST,
        SUCCESS: types.createMedia.CREATE_MEDIA_SUCCESS,
        FAILURE: types.createMedia.CREATE_MEDIA_FAILURE,
        CANCEL: types.createMedia.CREATE_MEDIA_CANCEL,
    },
});
