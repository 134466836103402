import React, { FC, createContext, useState } from 'react';
import { noop } from 'lodash';

type ConnectAccountsContextType = [boolean | null, (boolean) => void];

export const ConnectSocialProfilesContext = createContext<ConnectAccountsContextType>([null, noop]);

const ConnectSocialProfilesProvider: FC = ({ children }) => {
    const visibilityState = useState<boolean>(null);

    return (
        <ConnectSocialProfilesContext.Provider value={visibilityState}>
            {children}
        </ConnectSocialProfilesContext.Provider>
    );
};

export default ConnectSocialProfilesProvider;
