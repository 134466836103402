export const forms = {
    changeEmail: 'changeEmail',
    changePassword: 'changePassword',
    changeAccountGroupName: 'changeAccountGroupName',
};

export enum ChangeEmailFormField {
    Email = 'email',
}

export enum ChangePasswordFormField {
    Password = 'password',
}

export enum ChangeAccountGroupNameField {
    Name = 'name',
}
