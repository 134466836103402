import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import config from 'config';
import { FacebookOAuthButton, OAuthProvider } from 'modules/auth';
import { Icon } from 'modules/ui';

import ConnectSocialProfilesList from '../ConnectSocialProfilesList';
import * as felaRules from './ConnectSocialProfiles.styles';

import { selectAccountsByType } from '../../services/selectors';

export interface ConnectSocialProfilesProps {
    type: Extract<OAuthProvider, OAuthProvider.Facebook | OAuthProvider.Instagram>;
}

const ConnectSocialProfiles: FC<ConnectSocialProfilesProps> = ({ type }) => {
    const accounts = useSelector(state => selectAccountsByType(state, { type }));

    return (
        <>
            <FacebookOAuthButton
                provider={type}
                authType="addAccount"
                scopes={config.auth[type].publishingScopes}
                icon={<Icon type={type === OAuthProvider.Facebook ? Icon.Types.FACEBOOK : Icon.Types.INSTAGRAM} />}
                extend={{ button: felaRules.button }}
            >
                <FormattedMessage id={`socialAccounts.connect.${type}`} />
            </FacebookOAuthButton>

            <ConnectSocialProfilesList accounts={accounts} />
        </>
    );
};

export default ConnectSocialProfiles;
