import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const container: TRule = () => ({
    width: '100%',
});

export const header: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',

    padding: `0 ${theme.spacing.large} ${theme.spacing.small}`,
    marginLeft: `-${theme.spacing.large}`,
    marginRight: `-${theme.spacing.large}`,

    borderBottom: `1px solid ${theme.colors.separator}`,
});

export const content: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingTop: '1.5rem',
    paddingBottom: theme.spacing.xsmall,
});

export const info: TRule = () => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& .ant-typography': {
        marginBottom: 0,
    },
});

export const avatar: TRule = () => ({
    marginRight: '1.25rem',
});

export const actions: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',

    '& .ant-btn + .ant-btn': {
        marginLeft: '1rem',
    },
});
