import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme }) => ({
    '& .ant-card-head': {
        display: 'none',
    },

    '& .ant-card-body': {
        paddingTop: theme.spacing.large,
        paddingBottom: theme.spacing.large,
        paddingLeft: theme.spacing.medium,
        paddingRight: theme.spacing.medium,
    },
});

export const post: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',

    tablet: {
        flexDirection: 'row-reverse',
    },
});

export const caption: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.spacing.xsmall,
});

export const separator: TRuleWithTheme = ({ theme }) => ({
    border: 'none',
    height: 1,
    maxWidth: 300,

    marginTop: theme.spacing.large,
    marginBottom: theme.spacing.large,
    marginLeft: 0,
    marginRight: 0,

    backgroundColor: theme.colors.textDark,
});

export const content: TRuleWithTheme = () => ({
    flex: 1,
});
