import React, { FC } from 'react';

export interface FacebookRoundProps {}

const FacebookRound: FC<FacebookRoundProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 25" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(0 .966)">
            <circle cx="11.707" cy="11.707" r="11.707" fill="#4267B2"></circle>
            <path
                fill="#FFF"
                d="M12.439 9.878V8.415c0-.404.328-.732.732-.732h.731v-1.83H12.44a2.195 2.195 0 00-2.195 2.196v1.829H8.78v1.83h1.464v5.853h2.195v-5.854h1.463l.732-1.829H12.44z"
            ></path>
        </g>
    </svg>
);

export default FacebookRound;
