import type { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormItemProps } from 'antd';
import type { FieldInputProps, FieldMetaState } from 'react-final-form';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './FormItem.styles';

export interface FormItemOwnProps extends FormItemProps {
    input: FieldInputProps<string, HTMLElement>;
    meta: FieldMetaState<string>;
    fluid?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const FormItem: FC<FormItemOwnProps> = ({ input, meta, required = false, help, fluid = false, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, type: input.type, fluid });
    const intl = useIntl();

    const fieldError = meta.submitError ? intl.formatMessage({ id: meta.submitError }) : meta.error;

    return (
        <Form.Item
            required={required}
            help={(meta.touched && fieldError) || help}
            validateStatus={(meta.touched && fieldError && 'error') || 'validating'}
            {...props}
            className={styles.formItem}
        />
    );
};

export default FormItem;
