import React, { FC } from 'react';

const Search: FC = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4 10c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6m17.707 10.293l-5.395-5.396A7.952 7.952 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8a7.952 7.952 0 004.897-1.688l5.396 5.395a.997.997 0 001.414 0 .999.999 0 000-1.414"
        ></path>
    </svg>
);

export default Search;
