import type { FC } from 'react';
import { Field } from 'react-final-form';
import { Form } from 'antd';

import type { FormFieldProps } from '../types';
import Switch from '../Switch';

const SwitchField: FC<FormFieldProps<boolean>> = ({ label, required = false, ...props }) => (
    <Field<boolean>
        type="checkbox"
        validateFields={[]}
        subscription={{ value: true, touched: true, error: true }}
        {...props}
    >
        {({ input, meta }) => (
            <Form.Item
                name={input.name}
                label={label}
                required={required}
                help={meta.touched && meta.error}
                validateStatus={meta.touched && meta.error && 'error'}
            >
                <Switch checked={Boolean(input.value)} disabled={props.disabled} {...input} />
            </Form.Item>
        )}
    </Field>
);

export default SwitchField;
