import { takeLeading, call, put } from 'redux-saga/effects';
import type { PayloadAction } from '@reduxjs/toolkit';

import config from 'config';
import { api } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiGet } from 'config/antonio';
import { createUIErrorMessage } from 'modules/errors';

import { searchImages, setImages } from './actions';
import type { ImageSearchFormValues, PixabayImage } from '../types';
import { ImageSearchField } from '../config';
import { PIXABAY_PAGE_LIMIT } from '../constants';

interface Response {
    data: {
        hits: PixabayImage[];
    };
}

function* searchPixabay(action: PayloadAction<ImageSearchFormValues, string, { locale: string }>) {
    const params = new URLSearchParams();
    params.append('key', config.pixabay.apiKey);
    params.append('lang', action.meta.locale);
    params.append('per_page', PIXABAY_PAGE_LIMIT);

    if (action.payload[ImageSearchField.Query]) {
        params.append(ImageSearchField.Query, action.payload[ImageSearchField.Query]);
    }

    try {
        const { data }: Response = yield call<ApiGet<Response>>(api.get, config.pixabay.apiUrl, {
            params,
            baseUrl: null,
        });

        yield put(setImages(data.hits));
        yield put(searchImages.submitSuccess());
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(searchImages.submitFailure(uiError));

        log.error(error);
    }
}

export default function* pixabaySaga() {
    yield takeLeading(searchImages.submit.toString(), searchPixabay);
}
