import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import Avatar from '../Avatar';
import type { AvatarOwnProps } from '../Avatar/Avatar';
import Button from '../Button';
import Icon from '../Icon';

import * as felaRules from './UploadableAvatar.styles';

export interface UploadableAvatarProps extends AvatarOwnProps {
    onClick?: () => void;
}

const UploadableAvatar: FC<UploadableAvatarProps> = ({ onClick, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { size: props.size });

    return (
        <div className={styles.container}>
            <Avatar {...props} />
            <Button
                shape="circle"
                icon={<Icon type={Icon.Types.UPLOAD} />}
                onClick={onClick}
                extend={{ button: felaRules.button }}
            />
        </div>
    );
};

export default UploadableAvatar;
