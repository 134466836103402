import { noop } from 'lodash';
import { call, put } from 'redux-saga/effects';

import * as log from 'config/loglevel';
import config from 'config/config';
import { ApiPost, ApiResponse, authApi } from 'config/antonio';

import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeVideoSettings } from 'modules/entities/services/normalizr';
import * as entitiesActions from 'modules/entities/services/actions';
import { EntityKey } from 'modules/entities/constants';

import { createVideoSettings as apiActions } from '../actions';

type Operations = operations['videoSettingsCreate'];
type Response = ApiResponse<Operations['responses']['201']['content']['application/json']>;
type RequestBody = Operations['requestBody']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* createVideoSettingsHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiPost<Response, RequestBody>>(
            authApi.post,
            config.api.endpoints.videoSettings,
            action.payload,
        );

        const { result: id, entities } = normalizeVideoSettings(data);

        yield put(
            entitiesActions.setEntitiesGroup(EntityKey.VIDEO_SETTINGS, {
                ids: [id],
                byId: entities[EntityKey.VIDEO_SETTINGS],
            }),
        );

        yield put(apiActions.success(undefined, { lastSuccessAt: new Date().toISOString() }));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(uiError));

        log.error(error);
    }
}

export default function* createVideoSettings() {
    yield takeLatestRequest(
        () => 'createVideoSettings',
        { pattern: apiActions.request.toString(), handler: createVideoSettingsHandler },
        { pattern: apiActions.cancel.toString(), handler: noop },
    );
}
