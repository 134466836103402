import type { TRule } from 'fela';

export const radioButtonGroup: TRule = () => ({
    marginBottom: 0,
});

export const cardHeading: TRule = () => ({
    marginTop: '0.375rem',
    marginBottom: '2rem',

    desktop: {
        marginTop: '0.875rem',
    },
});
