import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import type { FC } from 'react';

import config from 'config';

import { ProfilePage } from 'modules/profile';
import { PostsPage } from 'modules/posts';
import { RandomDrawPage, RandomDrawResultsPage, RandomDrawProgressPage } from 'modules/random-draw';
import { PlanPostPage } from 'modules/new-post';
import { InsipirationTipsPage } from 'modules/inspiration-tips';
import { ArticleDetail } from 'modules/articles';

import NoMatchPage from '../NoMatchPage';

const redirectRoutes: RouteProps[] = [config.routes.login, config.routes.signUp].map(route => ({
    path: route,
    render: () => <Redirect to={config.routes.posts} />,
}));

const authRoutes: RouteProps[] = [
    {
        path: config.routes.posts,
        exact: true,
        render: () => <PostsPage />,
    },
    {
        path: config.routes.randomDrawResults,
        exact: true,
        render: () => <RandomDrawResultsPage />,
    },
    {
        path: config.routes.newPost,
        exact: true,
        render: () => <PlanPostPage />,
    },
    {
        path: config.routes.randomDrawProgress,
        exact: true,
        render: () => <RandomDrawProgressPage />,
    },
    {
        path: config.routes.randomDraw,
        exact: true,
        render: () => <RandomDrawPage />,
    },
    {
        path: config.routes.profile,
        exact: true,
        render: () => <ProfilePage />,
    },
    {
        path: config.routes.tips,
        exact: true,
        render: () => <InsipirationTipsPage />,
    },
    {
        path: config.routes.tipsPosts,
        exact: true,
        render: () => <ArticleDetail />,
    },
    {
        render: () => <NoMatchPage />,
    },
];

const routes: RouteProps[] = redirectRoutes.concat(authRoutes);

export interface AuthRoutesProps {}

const AuthRoutes: FC<AuthRoutesProps> = () => (
    <Switch>
        {routes.map((route, index) => (
            <Route key={String(index)} {...route} />
        ))}
    </Switch>
);

export default AuthRoutes;
