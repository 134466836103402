import React, { FC } from 'react';

export interface PlusProps {}

const Plus: FC<PlusProps> = props => (
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7 0a1 1 0 011 1v5h5a1 1 0 010 2H8v5a1 1 0 01-2 0V8H1a1 1 0 010-2h5V1a1 1 0 011-1z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default Plus;
