import { Authenticated } from '@ackee/petrus';

import { useFelaEnhanced } from 'hooks';
import { ModalProvider, ToastProvider } from 'modules/ui';

import * as felaRules from './App.styles';
import Header from '../Header';
import Routes from '../Routes';
import AuthRoutes from '../AuthRoutes';

const App = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <ToastProvider>
            <ModalProvider>
                <Authenticated FallbackComponent={Routes}>
                    <Header />
                    <main className={styles.main}>
                        <AuthRoutes />
                    </main>
                </Authenticated>
            </ModalProvider>
        </ToastProvider>
    );
};

export default App;
