import { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const card: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '1rem',
    boxShadow: theme.shadow.panel,

    '& .ant-card-head': {
        display: 'none',
    },
    '& .ant-card-body': {
        padding: '1rem',
    },
});
export const box: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    desktop: {
        flexDirection: 'row',
    },
});

export const image: TRuleWithTheme = ({ theme }) => ({
    borderRadius: '24px',
    minHeight: '10rem',
    maxHeight: '12rem',
    maxWidth: '100%',
    objectFit: 'cover',
    backgroundColor: theme.colors.textLight,
    desktop: {
        minWidth: '35%',
    },
});
export const section: TRuleWithTheme = ({ theme }) => ({
    borderRadius: theme.borderRadius.xlarge,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    desktop: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
    },
});
export const text: TRuleWithTheme = ({ theme }) => ({
    padding: '1rem',
    color: theme.colors.textGray,
});
