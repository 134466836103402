import { createApiRequestActions, createApiDetailRequestActions } from '@ackee/redux-utils';

import type { operations } from 'api/apiSchema';
import type { Competition } from 'modules/entities/types';

import * as types from './types';

export { types };

export const createCompetition = createApiRequestActions<
    operations['competitionCreate']['requestBody']['content']['application/json'],
    undefined,
    { id: Competition['id']; lastSuccessAt: string },
    string
>({
    REQUEST: types.createCompetition.CREATE_COMPETITION_REQUEST,
    SUCCESS: types.createCompetition.CREATE_COMPETITION_SUCCESS,
    FAILURE: types.createCompetition.CREATE_COMPETITION_FAILURE,
    CANCEL: types.createCompetition.CREATE_COMPETITION_CANCEL,
});

export const fetchCompetitions = createApiRequestActions<
    operations['competitionList']['parameters']['query'],
    undefined,
    {
        totalCount: number;
        currentCount: number;
        hasMore?: boolean;
    },
    string
>({
    REQUEST: types.fetchCompetitions.FETCH_COMPETITIONS_REQUEST,
    SUCCESS: types.fetchCompetitions.FETCH_COMPETITIONS_SUCCESS,
    FAILURE: types.fetchCompetitions.FETCH_COMPETITIONS_FAILURE,
    CANCEL: types.fetchCompetitions.FETCH_COMPETITIONS_CANCEL,
    RESET: types.fetchCompetitions.FETCH_COMPETITIONS_RESET,
});

export const fetchCompetition = createApiDetailRequestActions<
    Competition['id'],
    undefined,
    undefined,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.fetchCompetition.FETCH_COMPETITION_REQUEST,
    SUCCESS: types.fetchCompetition.FETCH_COMPETITION_SUCCESS,
    FAILURE: types.fetchCompetition.FETCH_COMPETITION_FAILURE,
    CANCEL: types.fetchCompetition.FETCH_COMPETITION_CANCEL,
    RESET: types.fetchCompetition.FETCH_COMPETITION_RESET,
});

export const updateCompetition = createApiDetailRequestActions<
    Competition['id'],
    operations['competitionUpdate']['requestBody']['content']['application/json'],
    undefined,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.updateCompetition.UPDATE_COMPETITION_REQUEST,
    SUCCESS: types.updateCompetition.UPDATE_COMPETITION_SUCCESS,
    FAILURE: types.updateCompetition.UPDATE_COMPETITION_FAILURE,
    CANCEL: types.updateCompetition.UPDATE_COMPETITION_CANCEL,
    RESET: types.updateCompetition.UPDATE_COMPETITION_RESET,
});
