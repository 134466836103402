import type { FC } from 'react';
import { Switch as AntdSwitch, SwitchProps } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as rules from './Switch.styles';

const Switch: FC<SwitchProps> = props => {
    const { styles } = useFelaEnhanced(rules);

    return <AntdSwitch {...props} className={styles.container} />;
};

export default Switch;
