import { createSelector } from 'reselect';
import { pick } from 'lodash';

import { selectDenormalizedCompetition } from 'modules/entities/modules/competitions';
import type { Competition, VideoSettings } from 'modules/entities';

import { RandomDrawField, randomDrawFormInitialValues } from '../../config';
import type { RandomDrawFormValues } from '../../types';
import { apiPickerColorsInverted, RandomDrawColors } from '../../constants';

const randomDrawFields = Object.values(RandomDrawField);

export const selectRandomDrawDraftInitialValues = createSelector(
    selectDenormalizedCompetition,
    // TODO: remove type cast when BE docs updated - https://redmine.ack.ee/issues/61118
    (competition: Competition & { videoSettings?: VideoSettings[] }) => {
        if (!competition) {
            return null;
        }

        const { hashtagsIncluded, profilesBlocked } = randomDrawFormInitialValues;
        const post = competition.posts?.shift();
        const videoSettings = competition.videoSettings?.pop()?.config;

        const color = videoSettings?.backgroundColor
            ? (apiPickerColorsInverted[JSON.stringify(videoSettings.backgroundColor)] as RandomDrawColors)
            : randomDrawFormInitialValues.color;

        const initialValues: RandomDrawFormValues = {
            ...randomDrawFormInitialValues,
            ...pick(competition, randomDrawFields),
            hashtagsIncluded: competition.hashtagsIncluded?.join(' ').replace(/#/g, '') ?? hashtagsIncluded,
            profilesBlocked: competition.profilesBlocked?.join(' ').replace(/@/g, '') ?? profilesBlocked,
            accountId: post?.accountId,
            postId: post?.providerId,
            headline: videoSettings?.headline,
            subheadline: videoSettings?.subheadline,
            color,
        };

        return initialValues;
    },
);
