import { createSelector } from 'reselect';

import { selectAccounts } from 'modules/entities/modules/accounts';
import { OAuthProvider } from 'modules/auth';
import { selectProp } from 'services/selectors';

export const selectAccountsByType = createSelector(
    [selectAccounts, selectProp<OAuthProvider>('type')],
    (accounts, type) => {
        return accounts.filter(account => account.type === type);
    },
);
