import React from 'react';

enum ContentState {
    Form = 'form',
    Success = 'success',
}

const useFormSuccessState = () => {
    const [state, setContentState] = React.useState(ContentState.Form);
    
    const setSuccessState = React.useCallback(() => setContentState(ContentState.Success), [setContentState]);
    const resetState = React.useCallback(() => setContentState(ContentState.Form), [setContentState]);

    return {
        setSuccessState,
        resetState,
        isForm: state === ContentState.Form,
        isSuccess: state === ContentState.Success,
    };
};

export default useFormSuccessState;
