import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import { Modal, Button, Heading, Paragraph, Icon, ImageCrop } from 'modules/ui';
import type { Schema } from 'modules/entities';

import { useChangeAccountGroupPicture } from '../../hooks';
import { PictureManagementStep } from '../../types';
import * as felaRules from './AccountGroupPictureManagement.styles';
import UploadCard from '../AccountGroupPictureManagement/UploadCard';

export interface AccountGroupPictureManagementProps {
    id: Schema['AccountGroup']['id'];
    setStep: (step: PictureManagementStep) => void;
    hide: () => void;
}

const AccountGroupPictureManagement: FC<AccountGroupPictureManagementProps> = ({ id, setStep, hide }) => {
    const { picture, inProgress, setPicture, setCroppedPicture, upload } = useChangeAccountGroupPicture(id, hide);
    const { styles } = useFelaEnhanced(felaRules, { picture: Boolean(picture) });

    return (
        <>
            <Heading level={2} extend={{ heading: felaRules.heading }}>
                <FormattedMessage id="profile.picture" />
            </Heading>

            <Paragraph faded size="x-small">
                <FormattedMessage id="profile.groups.upload" />
            </Paragraph>

            <ul className={styles.cards}>
                <UploadCard
                    icon={Icon.Types.UPLOAD_COMPUTER}
                    title={<FormattedMessage id="profile.groups.upload.computer" />}
                    uploadProps={{
                        accept: 'image/png, image/jpeg',
                        fileList: [],
                        onChange: ({ file }) => setPicture(file.originFileObj),
                        customRequest: noop,
                    }}
                />
                <UploadCard
                    icon={Icon.Types.PIXABAY}
                    title={<FormattedMessage id="profile.groups.upload.pixabay" />}
                    onClick={() => setStep(PictureManagementStep.PIXABAY)}
                />
            </ul>

            {picture && (
                <ImageCrop
                    image={picture}
                    aspect={1}
                    cropShape="round"
                    cropSize={{ width: 170, height: 170 }}
                    onCropComplete={file => setCroppedPicture(file)}
                />
            )}

            <Modal.Footer>
                <Button key="cancel" type="text" inverse size="large" disabled={inProgress} onClick={hide}>
                    <FormattedMessage id="general.cancel" />
                </Button>
                <Button
                    key="save"
                    type="primary"
                    size="large"
                    loading={inProgress}
                    disabled={inProgress}
                    onClick={upload}
                >
                    <FormattedMessage id="general.save" />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default AccountGroupPictureManagement;
