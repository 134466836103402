import * as Petrus from '@ackee/petrus';
import { put, take, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { createUIErrorMessage } from 'modules/errors';
import { Action } from 'services/actions';
import config from 'config';

import { loginForm as loginFormActions } from '../actions';
import { LoginFormValues } from '../../types';

function* handleLoginForm(action: Action<LoginFormValues>) {
    yield put(Petrus.loginRequest(action.payload));
    const result = yield take([Petrus.LOGIN_SUCCESS, Petrus.LOGIN_FAILURE]);

    if (result.type === Petrus.LOGIN_SUCCESS) {
        yield put(loginFormActions.submitSuccess());
        yield put(push(config.routes.posts));
    } else {
        // TODO: parsing field error messages
        const uiError = createUIErrorMessage(result.error);
        yield put(loginFormActions.submitFailure(uiError));
    }
}

export default function* loginForm() {
    yield takeEvery(loginFormActions.submit.toString(), handleLoginForm);
}
