import type { TRuleWithTheme } from 'styles/theme';

import type { NavigationProps } from './Navigation';

type StyleProps = Required<NavigationProps>;

export const nav: TRuleWithTheme<StyleProps> = () => ({
    maxWidth: '100%',
    marginLeft: `0`,

    desktop: {
        display: 'initial',
        marginLeft: `4.5rem`,
    },
});

export const tab: TRuleWithTheme = () => ({
    padding: '0.375rem',
    
    maxDesktop: {
        display: 'flex',
        justifyContent: 'center',

        padding: `2.5rem 0`,

        background: 'transparent',
        boxShadow: 'none',
    },
});

export const navItem: TRuleWithTheme = ({ theme }) => ({
    padding: '0.75rem 1.5rem',

    maxDesktop: {
        minWidth: 0,
        maxWidth: 150,

        flexDirection: 'column',
        flexShrink: 1,
        flexBasis: `${100 / 3}%`,

        alignItems: 'center',
        justifyContent: 'center',

        marginRight: '0.375rem',

        borderRadius: 19,
        boxShadow: theme.shadow.mobileMenuItem,
        backgroundColor: theme.colors.white,

        fontSize: '0.75rem',

        '&:last-child': {
            marginRight: 0,
        },

        '& .anticon': {
            marginRight: 0,
        },

        '& .nav-title': {
            marginTop: '0.625rem',
            textAlign: 'center',
        },
    },

    desktop: {
        '& .nav-title': {
            display: 'none',
        },
    },
});
