import { createAction } from '@reduxjs/toolkit';
import { createApiRequestActions } from '@ackee/redux-utils';

import * as types from './types';
import { OAuthProvider } from '../../types';

export const oauthApi = createApiRequestActions({
    REQUEST: types.oauthApi.OAUTH_REQUEST,
    SUCCESS: types.oauthApi.OAUTH_SUCCESS,
    FAILURE: types.oauthApi.OAUTH_FAILURE,
    CANCEL: types.oauthApi.OAUTH_CANCEL,
    RESET: types.oauthApi.OAUTH_RESET,
});

export interface OAuthLoginPayload {
    accessToken: string;
    provider: OAuthProvider;
}

export const oauthLoginRequest = createAction(types.OAUTH_LOGIN_REQUEST, (payload: OAuthLoginPayload) => ({
    payload,
}));

export interface OAuthAddAccountPayload extends OAuthLoginPayload {
    providerId: string;
}

export const oauthAddAccountRequest = createAction(
    types.OAUTH_ADD_ACCOUNT_REQUEST,
    (payload: OAuthAddAccountPayload) => ({
        payload,
    }),
);
