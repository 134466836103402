import { combineReducers } from 'redux';

import { ApiReducerKey } from '../../../../../constants';

import list from './list';
import add from './add';
import update from './update';

export default combineReducers({
    [ApiReducerKey.CREATE]: add,
    [ApiReducerKey.LIST]: list,
    [ApiReducerKey.UPDATE]: update,
});
