import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const leftBarContent: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',

    '& .ant-typography': {
        marginBottom: 0,
    },

    desktop: {
        marginBottom: 0,
    },
});

export const backLink: TRuleWithTheme = ({ theme }) => ({
    marginRight: theme.spacing.small,
});
