import type { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFormState } from 'react-final-form';
import { Row } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { FormSummary } from 'modules/form';
import { selectAccount, useFetchAccounts } from 'modules/entities/modules/accounts';

import VideoPreview from '../VideoPreview';
import RandomDrawCard from '../RandomDrawCard';
import RandomDrawSummaryPost from '../RandomDrawSummaryPost';
import { RandomDrawField } from '../../config';
import * as felaRules from './RandomDrawSummary.styles';
import type { RandomDrawFormValues } from '../../types';

const RandomDrawSummary: FC = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const { values } = useFormState<RandomDrawFormValues>({ subscription: { values: true } });

    const account = useSelector(state => selectAccount(state, { id: values[RandomDrawField.ACCOUNT] }));
    useFetchAccounts();

    return (
        <>
            <RandomDrawCard>
                <Row justify="center">
                    <VideoPreview headline={values.headline} subHeadline={values.subheadline} color={values.color} />

                    <FormSummary className={styles.summary}>
                        <FormSummary.Row>
                            <FormSummary.Item
                                label={<FormattedMessage id="randomDraw.rules.winners.title" />}
                                value={<FormattedNumber value={values.winners} />}
                            />
                            <FormSummary.Item
                                label={<FormattedMessage id="randomDraw.rules.duplicatedUsersExcluded.title" />}
                                value={
                                    <FormattedMessage id={`general.${values.duplicatedUsersExcluded ? 'on' : 'off'}`} />
                                }
                            />
                        </FormSummary.Row>
                        <FormSummary.Row>
                            <FormSummary.Item
                                label={<FormattedMessage id="randomDraw.rules.substitutes.title" />}
                                value={<FormattedNumber value={values.substitutes} />}
                            />
                            <FormSummary.Item
                                label={<FormattedMessage id="randomDraw.rules.postCommented.title" />}
                                value={<FormattedMessage id={`general.${values.postCommented ? 'on' : 'off'}`} />}
                            />
                        </FormSummary.Row>
                        <FormSummary.Row>
                            <FormSummary.Item
                                label={<FormattedMessage id="randomDraw.rules.profilesMentioned.summaryTitle" />}
                                value={<FormattedNumber value={values.profilesMentioned} />}
                            />
                        </FormSummary.Row>
                    </FormSummary>
                </Row>
            </RandomDrawCard>

            {account && (
                <RandomDrawSummaryPost
                    accountType={account.type}
                    accountId={account.providerId}
                    postId={values[RandomDrawField.POST]}
                />
            )}
        </>
    );
};

export default RandomDrawSummary;
