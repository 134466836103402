import { TRuleWithTheme } from 'styles/theme';

export const calendar: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius.xlarge,
    padding: '2rem 0 0',

    tablet: {
        padding: '1.5625rem 2.0625rem',
    },

    '& .rbc-header': {
        fontSize: '1.125rem',
        fontWeight: 'normal',
        color: theme.colors.textGray,
        border: 'none',
        textAlign: 'center',
        overflowY: 'visible',
        textTransform: 'capitalize',

        tablet: {
            textAlign: 'left',
        },
    },

    '& .rbc-month-header': {
        marginBottom: '1rem',
    },

    '& .rbc-time-view .rbc-allday-cell': {
        marginTop: '1rem',
    },

    '& .rbc-month-view': {
        border: 'none',
    },

    '& .rbc-time-view': {
        border: 'none',
    },

    '& .rbc-off-range-bg': {
        backgroundColor: theme.colors.white,
    },

    '& .rbc-day-bg': {
        backgroundColor: theme.colors.white,
    },

    '& .rbc-off-range a': {
        color: theme.colors.textGray,
    },

    '& .rbc-date-cell': {
        textAlign: 'center',
        paddingTop: '1rem',
        paddingLeft: '.75rem',
        paddingRight: '.75rem',

        '& a': {
            fontSize: '1.125rem',
        },

        tablet: {
            textAlign: 'right',
        },
    },

    '& .rbc-day-bg + .rbc-day-bg': {
        borderColor: theme.colors.separator,
    },

    '& .rbc-month-row': {
        borderBottom: `1px solid ${theme.colors.separator}`,
        minHeight: 160,
        tablet: {
            minHeight: 160,
        },
    },
    '& .rbc-month-row + .rbc-month-row': {
        borderTop: 'none',
    },

    '& .rbc-toolbar': { marginBottom: '2.5rem' },

    '& .rbc-toolbar .rbc-btn-group:first-of-type': {
        order: '1',
    },

    '& .rbc-day-slot .rbc-time-slot': {
        borderTopStyle: 'dashed',
        borderTopColor: theme.colors.separator,
    },

    '& .rbc-time-content > * + * > *': {
        borderLeftColor: theme.colors.separator,
    },

    '& .rbc-timeslot-group': {
        borderBottomColor: theme.colors.separator,
    },

    '& .rbc-day-slot .rbc-timeslot-group .rbc-time-slot:first-of-type': {
        borderTop: 'none',
    },

    '& .rbc-time-content': {
        borderTopColor: theme.colors.textLight,
    },

    '& .rbc-today': {
        background: 'transparent',
    },

    '& .rbc-time-slot': {
        minHeight: 58,
    },

    '& .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot:last-of-type': {
        borderTop: `1px dashed ${theme.colors.separator}`,
    },

    '& .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot:first-of-type': {
        paddingTop: '.5rem',
        color: theme.colors.textGray,
        fontSize: '.875rem',
        paddingLeft: '2.1875rem',
    },

    '& .rbc-time-header-content': {
        borderLeft: 'none',
    },

    '& .rbc-time-content .rbc-day-slot.rbc-time-column:first-of-type .rbc-events-container': {
        borderLeft: 'none',
    },
});
