import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import config from 'config';
import { useFelaEnhanced } from 'hooks';
import { Heading, Paragraph } from 'modules/ui';

import AuthPage from '../AuthPage';
import LoginForm from '../LoginForm';
import * as felaRules from './LoginPage.styles';

export interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <AuthPage
            header={
                <Heading level={1}>
                    <FormattedMessage id="auth.login.title" />
                </Heading>
            }
            separatorText={<FormattedMessage id="auth.login.separatorText" />}
            footer={
                <Paragraph>
                    <FormattedMessage
                        id="auth.login.footer"
                        values={{
                            link: txt => (
                                <Link className={styles.signUpLink} to={config.routes.signUp}>
                                    {txt}
                                </Link>
                            ),
                        }}
                    />
                </Paragraph>
            }
        >
            <LoginForm />
        </AuthPage>
    );
};

export default LoginPage;
