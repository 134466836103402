// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@ackee/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"borderRadius.less"}]);
// Exports
exports.locals = {
	"xlarge": "24px",
	"large": "18px",
	"medium": "14px",
	"small": "12px",
	"card": "24px",
	"modal": "24px",
	"panel": "24px",
	"header": "21px",
	"navItem": "16px"
};
module.exports = exports;
