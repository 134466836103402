import { TRuleWithTheme } from 'styles/theme';

export const footer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // Possition the footer correctly in a modal with padding
    paddingTop: '1.5rem',
    paddingLeft: theme.spacing.small,
    paddingRight: theme.spacing.small,
    marginTop: theme.spacing.large,
    marginLeft: `-${theme.spacing.small}`,
    marginRight: `-${theme.spacing.small}`,
    marginBottom: '-1.5rem',

    borderTop: `1px solid ${theme.colors.separator}`,

    '& > * + *': {
        marginLeft: theme.spacing.large,
    },

    desktop: {
        paddingLeft: theme.spacing.large,
        paddingRight: theme.spacing.large,
        marginLeft: `-${theme.spacing.large}`,
        marginRight: `-${theme.spacing.large}`,
    },
});
