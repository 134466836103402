import type { FC, ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Counter.styles';

export interface CounterProps {
    title: ReactNode;
    count: number;
    max: number;
    extend?: RulesExtend<typeof felaRules>;
}

const Counter: FC<CounterProps> = ({ title, count, max, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            {title}
            <span className={styles.counter}>
                {count} /{max}
            </span>
        </div>
    );
};

export default Counter;
