import React from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Icon } from 'modules/ui';
import type { Types as IconType } from 'modules/ui/components/Icon/Icon';

import * as felaRules from './NavItem.styles';

export type NavItemProps = {
    to: string;
    exact?: boolean;
    disabled?: boolean;
    icon?: IconType;
    compact?: boolean;
    hideTitle?: boolean;
    extend?: RulesExtend<typeof felaRules>;
    children: ReactNode;
};

const NavItem: FC<NavItemProps> = ({
    disabled,
    to,
    exact = true,
    icon,
    compact = false,
    hideTitle = false,
    children,
    extend,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { compact, extend, hideTitle, disabled });

    return !disabled ? (
        <NavLink to={to} className={styles.navItem} activeClassName="active" exact={exact}>
            {Boolean(icon) && <Icon type={icon} />}
            <span className="nav-title">{children}</span>
        </NavLink>
    ) : (
        <div className={styles.navItem}>
            {Boolean(icon) && <Icon type={icon} />}
            <span className="nav-title">{children}</span>
        </div>
    );
};

export default NavItem;
