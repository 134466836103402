import { useState, useEffect, useMemo } from 'react';
import { throttle } from 'lodash';

export type ScrollDirection = 'top' | 'bottom';

const useScrollOffset = (throttleInterval: number = 100) => {
    const [offset, setOffset] = useState(0);
    const [direction, setDirection] = useState<ScrollDirection>('top');

    const handler = useMemo(
        () =>
            throttle(() => {
                setDirection(window.scrollY > offset ? 'bottom' : 'top');
                setOffset(window.scrollY);
            }, throttleInterval),
        [setOffset, setDirection, offset, throttleInterval],
    );

    useEffect(() => {
        window.addEventListener('scroll', handler);

        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, [handler]);

    return [offset, direction];
};

export default useScrollOffset;
