import type { TRuleWithTheme } from 'styles/theme';

export const mainImage: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    border: `2px solid ${theme.colors.inputBorder}`,
    borderRadius: theme.borderRadius.large,
});
