import { takeLatest, take, put } from '@redux-saga/core/effects';
import type { PayloadAction } from '@reduxjs/toolkit';

import { actions } from 'modules/entities/modules/account-groups';
import type { Schema } from 'modules/entities';
import { mapSubmitErrorToFields } from 'modules/errors';

import { changeAccountGroup as changeAccountGroupActions } from '../actions';
import type { ChangeAccountGroupValues } from '../../types';

function* handleChangeAccountGroup(
    action: PayloadAction<ChangeAccountGroupValues, string, { id: Schema['AccountGroup']['id'] }>,
) {
    yield put(actions.updateAccountGroup.updateAccountGroupRequest(action.meta.id, action.payload));

    const updateAction = yield take([
        actions.types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_SUCCESS,
        actions.types.updateAccountGroup.UPDATE_ACCOUNT_GROUP_FAILURE,
    ]);

    if (!updateAction.error) {
        yield put(changeAccountGroupActions.submitSuccess());
    } else {
        const formErrors = mapSubmitErrorToFields(updateAction.error, action.payload);
        yield put(changeAccountGroupActions.submitFailure(formErrors));
    }
}

export default function* changeAccountGroup() {
    yield takeLatest(changeAccountGroupActions.submit.toString(), handleChangeAccountGroup);
}
