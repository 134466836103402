import { applyMiddleware, compose } from 'redux';
import createReduxPromiseListener from 'redux-promise-listener';
import { reduxBatch } from '@manaflair/redux-batch';
import * as reduxLocalStorage from 'redux-localstorage-simple';

import config from 'config';
import * as log from 'config/loglevel';
import { isServerEnv } from 'constants/index';
import { sentryReduxEnhancer } from 'config/sentry';
import * as constants from 'constants/index';

import { routerMiddlewareWithHistory } from '../../router';
import createSagaMiddleware from 'redux-saga';

export const reduxPromiseListener = createReduxPromiseListener();

export default function configureEnhancer(storagePaths = []) {
    const sagaMiddleware = createSagaMiddleware({
        onError: log.error,
    });

    const middlewares = [
        routerMiddlewareWithHistory,
        sagaMiddleware,
        reduxPromiseListener.middleware,
        reduxLocalStorage.save({
            states: storagePaths,
            namespace: constants.LOCAL_STORAGE_NAMESPACE,
        }),
    ];

    // To understand the duplication, see https://github.com/manaflair/redux-batch#usage-w-extra-middlewares
    const enhancerArgs = [reduxBatch, applyMiddleware(...middlewares), reduxBatch, sentryReduxEnhancer];

    if (config.devTools && !isServerEnv) {
        // @ts-ignore
        const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__;
        if (reduxDevTools) {
            enhancerArgs.push(reduxDevTools());
        }
    }

    return {
        enhancer: compose(...enhancerArgs),
        sagaMiddleware,
    };
}
