import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { mapValues, sortBy } from 'lodash';

import { selectAccountGroupsById, selectAccountGroupsApi } from 'modules/entities/modules/account-groups';
import { selectAccountsById, actions } from 'modules/entities/modules/accounts';
import { NormalizedAccountGroup } from 'modules/entities/types';

type GroupsMap = Record<string, NormalizedAccountGroup>;

const DEFAULT_GROUP_ID = 'default';

const useAccountsDnd = () => {
    const dispatch = useDispatch();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [activeId, setActiveId] = React.useState<string>(null);

    const intl = useIntl();
    const defaultGroups = React.useMemo(
        () => ({
            [DEFAULT_GROUP_ID]: {
                name: intl.formatMessage({ id: 'profile.groups.default' }),
                accounts: [],
            } as NormalizedAccountGroup,
        }),
        [intl],
    );

    const { error } = useSelector(selectAccountGroupsApi);
    const accountGroupsMap = useSelector(selectAccountGroupsById);
    const accountsMap = useSelector(selectAccountsById);

    const groupsMap = error ? defaultGroups : accountGroupsMap;

    const [items, setItems] = React.useState<GroupsMap>({});

    React.useEffect(() => {
        setItems(() => {
            return mapValues(groupsMap, group => ({
                ...group,
                accounts: sortBy(group.accounts, accountId => accountsMap[accountId].priority),
            }));
        });
    }, [groupsMap, accountsMap]);

    const findGroup = (id: string) => {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find(groupId => items[groupId].accounts.includes(id));
    };

    const findItemIndex = (groupId: string, itemIndex: string) => {
        return items[groupId].accounts.indexOf(itemIndex);
    };

    const onDragStart = ({ active }) => {
        setActiveId(active.id);
    };

    const onDragOver = ({ active, over }) => {
        const overId = over?.id;

        if (!overId) {
            return;
        }

        const overGroup = findGroup(overId);
        const activeGroup = findGroup(active.id);

        if (!overGroup || !activeGroup) {
            return;
        }

        if (activeGroup !== overGroup) {
            setItems(items => {
                const overItems = items[overGroup];
                const overIndex = findItemIndex(overGroup, overId);
                const activeIndex = findItemIndex(activeGroup, active.id);

                let newIndex: number;

                if (overId in items) {
                    newIndex = overItems.accounts.length + 1;
                } else {
                    const isBelowLastItem =
                        over &&
                        overIndex === overItems.accounts.length - 1 &&
                        active.rect.current.translated &&
                        active.rect.current.translated.offsetTop > over.rect.offsetTop + over.rect.height;

                    const modifier = isBelowLastItem ? 1 : 0;

                    newIndex = overIndex >= 0 ? overIndex + modifier : overItems.accounts.length + 1;
                }

                const activeGroupAccounts = items[activeGroup].accounts;
                const overGroupAccounts = items[overGroup].accounts;

                return {
                    ...items,
                    [activeGroup]: {
                        ...items[activeGroup],
                        accounts: [...activeGroupAccounts.filter(item => item !== active.id)],
                    },
                    [overGroup]: {
                        ...items[overGroup],
                        accounts: [
                            ...overGroupAccounts.slice(0, newIndex),
                            activeGroupAccounts[activeIndex],
                            ...overGroupAccounts.slice(newIndex, overGroupAccounts.length),
                        ],
                    },
                };
            });
        }
    };

    const onDragEnd = event => {
        const { active, over } = event;

        const activeId: string = active.id;
        const activeGroup = findGroup(activeId);

        if (!activeGroup) {
            setActiveId(null);
            return;
        }

        const formerGroup = accountsMap[activeId].accountGroupId;

        const overId: string = over?.id;
        const overGroup = findGroup(overId);

        const activeIndex = findItemIndex(activeGroup, activeId);
        const overIndex = findItemIndex(overGroup, overId);

        if (overGroup) {
            const accounts = arrayMove(items[overGroup].accounts, activeIndex, overIndex);

            setItems(items => ({
                ...items,
                [overGroup]: {
                    ...items[overGroup],
                    accounts,
                },
            }));

            if (formerGroup !== overGroup || activeIndex !== overIndex) {
                dispatch(
                    actions.moveAccount.request(activeId, {
                        oldGroupId: formerGroup,
                        oldGroupAccounts: items[formerGroup].accounts,
                        newGroupId: overGroup,
                        newGroupAccounts: accounts,
                    }),
                );
            }
        }

        setActiveId(null);
    };

    return {
        sensors,
        eventHandlers: { onDragStart, onDragOver, onDragEnd },
        accountGroupsMap: items,
        draggingAccount: activeId ? accountsMap[activeId] : null,
    };
};

export default useAccountsDnd;
