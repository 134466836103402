import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { replace } from 'connected-react-router';
import type { ApiReducerState } from '@ackee/redux-utils';

import { actions as postsActions, selectPostApi } from 'modules/entities/modules/posts';
import { useErrorToast } from 'modules/errors';

import type { PlanPostLocationState } from '../types';

const useLoadPostDraft = () => {
    const dispatch = useDispatch();
    const { state } = useLocation<PlanPostLocationState>();
    const postId = state?.postId;

    useEffect(() => {
        if (postId) {
            dispatch(postsActions.fetchPost.request(postId));

            return () => {
                dispatch(postsActions.fetchPost.cancel(postId));
            };
        }
    }, [postId, dispatch]);

    // Clear location state after unmount
    useEffect(() => {
        return () => {
            dispatch(replace(window.location.pathname, null));
        };
    }, [dispatch]);

    const api: ApiReducerState = useSelector(selectPostApi);
    useErrorToast(api.error, 'post.loadDraft.error');

    return api;
};

export default useLoadPostDraft;
