import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './PostCalendar.styles';
import { Calendar, Heading, ComingSoonBadge } from 'modules/ui';
import { useIntl, FormattedMessage } from 'react-intl';

export interface PostCalendarProps {}

const PostCalendar: FC<PostCalendarProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();
    const palceholderCalerndar = {
        events: [
            {
                id: 0,
                title: formatMessage({ id: 'calendar.mock.first' }),
                allDay: true,
                start: new Date(2021, 5, 17),
                end: new Date(2021, 5, 23),
            },

            {
                id: 2,
                title: formatMessage({ id: 'calendar.mock.second' }),
                start: new Date(2021, 5, 26),
                end: new Date(2021, 5, 26),
                announcement: true,
            },
        ],
    };
    return (
        <>
            <Heading level={2} extend={{ heading: rules.heading }}>
                <FormattedMessage id={'post.yourPosts.title'} />
            </Heading>
            <div className={styles.calendar}>
                <ComingSoonBadge borderRadius={'xlarge'}>
                    <Calendar extend={{ calendar: rules.calendar }} {...palceholderCalerndar} />
                </ComingSoonBadge>
            </div>
        </>
    );
};

export default PostCalendar;
