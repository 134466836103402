import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.fetchAccounts.FETCH_ACCOUNTS_REQUEST,
        SUCCESS: types.fetchAccounts.FETCH_ACCOUNTS_SUCCESS,
        FAILURE: types.fetchAccounts.FETCH_ACCOUNTS_FAILURE,
        CANCEL: types.fetchAccounts.FETCH_ACCOUNTS_CANCEL,
        RESET: types.fetchAccounts.FETCH_ACCOUNTS_RESET,
    },
});
