import { FC, ReactNode, useState } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './FloatingLabel.styles';

export interface FloatingLabelProps {
    label: string | ReactNode;
    input: {
        name: string;
        value: string | number | boolean;
    };
    extend?: RulesExtend<typeof felaRules>;
}

const FloatingLabel: FC<FloatingLabelProps> = ({ label, input, extend, children }) => {
    const [isFocused, setFocus] = useState(false);
    const isFloating = isFocused || Boolean(input.value);

    const { styles } = useFelaEnhanced(felaRules, { isFloating, extend });

    return (
        <div className={styles.container} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            {children}
            <label htmlFor={input.name} className={styles.label}>
                {label}
            </label>
        </div>
    );
};

export default FloatingLabel;
