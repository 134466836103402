import { TRuleWithTheme } from 'styles/theme';

export type BorderRadiusSize = 'large' | 'xlarge' | 'medium' | 'small' | '0';

export const container: TRuleWithTheme<{ borderRadius: BorderRadiusSize; borderRadiusBottom: boolean }> = ({
    borderRadius,
    theme,
    borderRadiusBottom,
}) => {
    if (borderRadiusBottom && borderRadius !== '0') {
        return {
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            borderBottomRightRadius: theme.borderRadius[borderRadius],
            borderBottomLeftRadius: theme.borderRadius[borderRadius],
        };
    }
    return {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        borderRadius: borderRadius !== '0' ? theme.borderRadius[borderRadius] : 0,
    };
};

export const badge: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    zIndex: 10,
    backgroundColor: theme.colors.comingSoon,
});

export const button: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.buttonComingSoon,
    color: theme.colors.textDark,
    fontSize: '1.1rem',
    fontWeight: '800',
    padding: '0.5rem 0.7rem',
    borderRadius: theme.borderRadius.large,
    border: 'none',
    display: 'flex',
    alignItems: 'center',

    '& >  .anticon': {
        margin: '0.1rem',
    },
});
