import React, { FC } from 'react';

const Upload: FC = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        {...props}
    >
        <defs>
            <path
                id="upload"
                d="M11.305 11.28a1.003 1.003 0 011.402.013l3 3a.999.999 0 11-1.414 1.414L13 14.414V20a1 1 0 11-2 0v-5.644l-1.305 1.26a1 1 0 01-1.39-1.439zM12 3a6.018 6.018 0 015.675 4.045A5.008 5.008 0 0122 12a4.995 4.995 0 01-1.25 3.309 1 1 0 11-1.5-1.325A2.993 2.993 0 0020 12c0-1.654-1.346-3-3-3h-.1a1 1 0 01-.98-.803A4.006 4.006 0 0012 5a4.005 4.005 0 00-3.92 3.197A1.001 1.001 0 017.1 9H7c-1.654 0-3 1.346-3 3 0 .732.266 1.438.75 1.984a1 1 0 11-1.499 1.325A4.991 4.991 0 012 11.999a5.008 5.008 0 014.325-4.954A6.02 6.02 0 0112 3z"
            ></path>
        </defs>
        <use fill="currentColor" fillRule="evenodd" transform="translate(-2 -3)" xlinkHref="#upload"></use>
    </svg>
);

export default Upload;
