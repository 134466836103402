import * as Petrus from '@ackee/petrus';
import { put, takeLeading, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { createUIErrorMessage } from 'modules/errors';
import { Action } from 'services/actions';
import config from 'config';

import { types, OAuthLoginPayload, oauthApi } from '../actions';

function* handleOAuthLogin(action: Action<OAuthLoginPayload>) {
    const tokenKey = `${action.payload.provider}AccessToken`;

    yield put(
        Petrus.loginRequest({
            [tokenKey]: action.payload.accessToken,
        }),
    );

    const result = yield take([Petrus.LOGIN_SUCCESS, Petrus.LOGIN_FAILURE]);

    if (result.type === Petrus.LOGIN_SUCCESS) {
        yield put(oauthApi.success());
        yield put(push(config.routes.posts));
    } else {
        const uiError = createUIErrorMessage(result.error);
        yield put(oauthApi.failure(uiError));
    }
}

export default function* oauthLogin() {
    yield takeLeading(types.OAUTH_LOGIN_REQUEST, handleOAuthLogin);
}
