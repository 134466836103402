import type { FC, ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';

import Page, { PageProps } from '../Page';
import Tabs, { TabsOwnProps } from '../Tabs/Tabs';

import * as felaRules from './TabsPage.styles';
import BackLink from '../BackLink';

export interface PageTabsProps extends TabsOwnProps, Omit<PageProps, 'backLink'> {
    footer?: ReactNode;
    backLink?: ReactNode | boolean;
}

const PageTabs: FC<PageTabsProps> = ({ heading, backLink = undefined, footer, children, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Page footer={footer} containerSize="large">
            <Tabs
                {...props}
                tabBarExtraContent={{
                    left: (
                        <div className={styles.leftBarContent}>
                            {backLink === true ? <BackLink /> : backLink}
                            {heading}
                        </div>
                    ),
                }}
            >
                {children}
            </Tabs>
        </Page>
    );
};

export default PageTabs;
