import { apiRequestActions } from '@ackee/redux-utils';

import * as types from './types';
import { actions as selectGroupActions } from '../reducers/selectedGroup';

export { types };

export const fetchAccountGroups = apiRequestActions(types.fetchAccountGroups);
export const updateAccountGroup = apiRequestActions(types.updateAccountGroup, { isDetailRequest: true });
export const deleteAccountGroup = apiRequestActions(types.deleteAccountGroup, { isDetailRequest: true });

export const createAccountGroup = apiRequestActions(types.createAccountGroup);

export const { setSelectedGroupId } = selectGroupActions;
