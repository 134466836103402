import { FC } from 'react';

const FastForward: FC = props => (
    <svg
        width="12px"
        height="10px"
        viewBox="0 0 12 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <path
                d="M9.00025,12.9992724 C8.74425,12.9992724 8.48825,12.9012724 8.29325,12.7062724 C7.90225,12.3152724 7.90225,11.6832724 8.29325,11.2922724 L11.59825,7.98727241 L8.41825,4.69427241 C8.03525,4.29627241 8.04625,3.66327241 8.44325,3.28027241 C8.84125,2.89727241 9.47425,2.90827241 9.85725,3.30427241 L13.71925,7.30427241 C14.09825,7.69727241 14.09325,8.32027241 13.70725,8.70627241 L9.70725,12.7062724 C9.51225,12.9012724 9.25625,12.9992724 9.00025,12.9992724"
                id="path-6"
            />
            <path
                d="M3.00025,12.9992724 C2.74425,12.9992724 2.48825,12.9012724 2.29325,12.7062724 C1.90225,12.3152724 1.90225,11.6832724 2.29325,11.2922724 L5.59825,7.98727241 L2.41825,4.69427241 C2.03525,4.29627241 2.04625,3.66327241 2.44325,3.28027241 C2.84125,2.89727241 3.47425,2.90827241 3.85725,3.30427241 L7.71925,7.30427241 C8.09825,7.69727241 8.09325,8.32027241 7.70725,8.70627241 L3.70725,12.7062724 C3.51225,12.9012724 3.25625,12.9992724 3.00025,12.9992724"
                id="path-5"
            />
        </defs>
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="posts_calendar_month" transform="translate(-970.000000, -497.000000)">
                <g id="Group" transform="translate(697.000000, 487.000000)">
                    <g transform="translate(271.000000, 7.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-6" />
                        </mask>
                        <use id="Color" fill="currentColor" xlinkHref="#path-6" />
                        <mask id="mask-4" fill="white">
                            <use xlinkHref="#path-5" />
                        </mask>
                        <use id="Color-Copy" fill="currentColor" xlinkHref="#path-5" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default FastForward;
