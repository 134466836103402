import type { FC } from 'react';
import { useForm } from 'react-final-form';
import { Row, Col } from 'antd';

import { SocialProvider } from 'constants/index';
import { useFelaEnhanced } from 'hooks';
import { Card, Icon, Heading } from 'modules/ui';

import PostMediaUpload from '../PostMediaUpload';
import PostTextArea from '../PostTextArea';
import CreatePostFirstComment from '../CreatePostFirstComment';

import * as felaRules from './CreatePostCard.styles';
import { PlanPostField } from '../../config';
import { useCreatePostMedia } from '../../hooks';

export interface CreatePostCardProps {
    type: SocialProvider;
}

const socialProviderIconMap = {
    [SocialProvider.FACEBOOK]: Icon.Types.FACEBOOK_ROUND,
    [SocialProvider.INSTAGRAM]: Icon.Types.INSTAGRAM_ROUND,
};

const CreatePostCard: FC<CreatePostCardProps> = ({ type }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const { mutators } = useForm();

    const { media, uploadMedia, inProgress } = useCreatePostMedia(type);

    return (
        <Card>
            <Row align="middle" className={styles.header}>
                <Icon type={socialProviderIconMap[type]} />
                <Heading level={6} extend={{ heading: felaRules.heading }}>
                    {type}
                </Heading>
            </Row>

            <Row gutter={[20, 40]}>
                <Col flex="1 1 280px">
                    <PostMediaUpload media={media} onChange={uploadMedia} uploading={inProgress} />
                </Col>

                <Col flex="auto">
                    <PostTextArea
                        name={`${type}.${PlanPostField.CAPTION}`}
                        clear={() => mutators.clear(`${type}.${PlanPostField.CAPTION}`)}
                        rows={8}
                    />

                    <CreatePostFirstComment
                        name={`${type}.${PlanPostField.FIRST_COMMENT}`}
                        clearField={() => mutators.clear(`${type}.${PlanPostField.FIRST_COMMENT}`)}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default CreatePostCard;
