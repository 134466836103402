import type { FieldValidator } from './types';

export const required: FieldValidator<string | number | boolean | Array<any>> = value => {
    if (
        value === undefined ||
        value === null ||
        String(value).trim() === '' ||
        (Array.isArray(value) && value.length === 0)
    ) {
        return false;
    }

    if (typeof value === 'boolean') {
        return value;
    }

    return true;
};

export const minLength = (min): FieldValidator => (value = '') => value.length >= min;
export const maxLength = (max): FieldValidator => (value = '') => value.length < max;

// Source of the regex: https://stackoverflow.com/a/9204568/12338694
export const email: FieldValidator = (value = '') => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value).toLowerCase());

export const maxTagCount = (max: number): FieldValidator => (value = '') =>
    value
        .split(' ')
        .map(chunk => chunk.trim())
        .filter(Boolean).length <= max;
