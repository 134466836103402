import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.fetchAccountGroups.FETCH_ACCOUNT_GROUPS_REQUEST,
        SUCCESS: types.fetchAccountGroups.FETCH_ACCOUNT_GROUPS_SUCCESS,
        FAILURE: types.fetchAccountGroups.FETCH_ACCOUNT_GROUPS_FAILURE,
        CANCEL: types.fetchAccountGroups.FETCH_ACCOUNT_GROUPS_CANCEL,
    },
});
