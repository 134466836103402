import type { TRuleWithTheme } from 'styles/theme';
import type { TextFieldProps } from '../types';

import type { TextFieldOwnProps } from './TextField';

export const input: TRuleWithTheme<Pick<TextFieldOwnProps, 'prefix' | 'prefixType'>> = ({ prefix, prefixType }) => ({
    paddingTop: 24,
    paddingBottom: 7,

    extend: {
        condition: Boolean(prefix),
        style: {
            paddingLeft: prefixType === 'icon' ? '3.5rem' : '5rem',
        },
    },
});

export const prefix: TRuleWithTheme<Pick<TextFieldOwnProps, 'prefixType'>> = ({ theme, prefixType }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,

    height: '100%',
    width: 44,
    paddingLeft: theme.spacing.small,

    color: theme.colors.textGray,

    extend: {
        condition: prefixType === 'delimited',
        style: {
            width: 64,
            paddingRight: theme.spacing.small,

            borderRight: `1px solid ${theme.colors.inputBorder}`,
        },
    },
});

export const floatingLabel: TRuleWithTheme<Pick<TextFieldOwnProps, 'prefixType'>> = ({ prefixType }) => ({
    left: prefixType === 'icon' ? '3.25rem' : '5rem',
});

export const formItem: TRuleWithTheme<Pick<TextFieldProps, 'type'>> = ({ type }) => ({
    position: 'static',

    extend: {
        condition: type === 'hidden',
        style: {
            height: 0,
            marginBottom: 0,
        },
    },
});

export const charsCounter = () => ({
    float: 'right',
});
