import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.createCompetition.CREATE_COMPETITION_REQUEST,
        SUCCESS: types.createCompetition.CREATE_COMPETITION_SUCCESS,
        FAILURE: types.createCompetition.CREATE_COMPETITION_FAILURE,
        CANCEL: types.createCompetition.CREATE_COMPETITION_CANCEL,
    },
});
