import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.fetchUser.OAUTH_REQUEST,
        SUCCESS: types.fetchUser.OAUTH_SUCCESS,
        FAILURE: types.fetchUser.OAUTH_FAILURE,
    },
});
