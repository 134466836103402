import { take, fork, cancel, call, cancelled, actionChannel } from 'redux-saga/effects';
import { buffers } from 'redux-saga';

import type { Action } from 'redux';
import type { ActionPattern } from 'redux-saga/effects';

import { actionPatternTest } from './helpers';

interface RequestAction extends Action {
    meta?: Record<string, any>;
    payload?: Record<string, any>;
}

interface RequestOptions {
    pattern: ActionPattern;
    handler: (...args: any[]) => any;
}

type RequestIdSelector = (action: RequestAction) => string | number;

export const safelySelectRequestId = (
    effectName: string,
    requestIdSelector: RequestIdSelector,
    action: RequestAction,
): ReturnType<RequestIdSelector> => {
    const requestId = requestIdSelector(action);

    if (!requestId) {
        throw new Error(`${effectName}: requestIdSelector has to return a value.`);
    }

    return requestId;
};

const EFFECT_NAME = 'takeLatestRequest';

// TODO: Resolve @ts-ignores

/**
 * A simulation of Reac.useEffect.
 *   React.useEffect(() => {
 *       dispatch({
 *           type: requestOptions.pattern,
 *       })
 *       return () => {
 *           dispatch({
 *               type: requestOptions.pattern,
 *           })
 *       }
 *   })
 * React's effect only dispatches instruction for the takeLatestRequest saga effect
 * You can imagine requestOptions.handler as useEffect function and
 * cleanupRequest.handler as useEffect cleanup function.
 * cleanupRequest.handler is given a requestTask of requestOptions.handler
 * to be able to cancel it.
 */
const takeLatestRequest = (
    requestIdSelector: RequestIdSelector,
    requestOptions: RequestOptions,
    cleanupRequestOptions: RequestOptions,
    ...args
) => {
    return fork(function* () {
        const requestTaskMap = new Map();

        // The channel iscomplusory, otherwise we loose actions
        const channel = yield actionChannel(
            // @ts-ignore
            [requestOptions.pattern, cleanupRequestOptions.pattern],
            buffers.expanding(1),
        );

        try {
            while (true) {
                const action = yield take(channel);

                const requestId = safelySelectRequestId(EFFECT_NAME, requestIdSelector, action);

                if (actionPatternTest(action, requestOptions.pattern)) {
                    const previousRequestTask = requestTaskMap.get(requestId);
                    if (previousRequestTask) {
                        yield cancel(previousRequestTask);

                        requestTaskMap.delete(requestId);
                    }

                    const requestTask = yield fork(requestOptions.handler, action, ...args);

                    requestTaskMap.set(requestId, requestTask);

                    // @ts-ignore
                    requestTask.toPromise().then(() => {
                        // @ts-ignore
                        if (requestTask.isCancelled()) {
                            return;
                        }
                        if (requestTaskMap.has(requestId)) {
                            requestTaskMap.delete(requestId);
                        }
                    });
                } else {
                    const requestTask = requestTaskMap.get(requestId);

                    if (requestTask) {
                        yield cancel(requestTask);
                        requestTaskMap.delete(requestId);
                    }

                    yield call(cleanupRequestOptions.handler, action, ...args);
                }
            }
        } finally {
            if (yield cancelled()) {
                for (const [, task] of requestTaskMap.entries()) {
                    yield cancel(task);
                }
            }
        }
    });
};

export default takeLatestRequest;
