import { createApiDetailRequestActions } from '@ackee/redux-utils';

import type { InstagramPost, SetStrategy } from 'modules/entities/types';
import { operations } from 'api/apiSchema';

import * as types from './types';

export { types };

export const fetchPosts = createApiDetailRequestActions<
    InstagramPost['id'],
    {
        params?: operations['getInstagramAccountPosts']['parameters']['query'];
        strategy?: SetStrategy;
    },
    undefined,
    operations['getInstagramAccountPosts']['parameters']['query'],
    string
>({
    REQUEST: types.fetchPosts.FETCH_POSTS_REQUEST,
    SUCCESS: types.fetchPosts.FETCH_POSTS_SUCCESS,
    FAILURE: types.fetchPosts.FETCH_POSTS_FAILURE,
    CANCEL: types.fetchPosts.FETCH_POSTS_CANCEL,
    RESET: types.fetchPosts.FETCH_POSTS_RESET,
});

export const fetchPost = createApiDetailRequestActions<
    operations['getInstagramPostDetail']['parameters']['path'],
    undefined,
    undefined,
    undefined,
    string
>({
    REQUEST: types.fetchPost.FETCH_POST_REQUEST,
    SUCCESS: types.fetchPost.FETCH_POST_SUCCESS,
    FAILURE: types.fetchPost.FETCH_POST_FAILURE,
    CANCEL: types.fetchPost.FETCH_POST_CANCEL,
    RESET: types.fetchPost.FETCH_POST_RESET,
});
