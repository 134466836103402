import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Link, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { TabsPage, Heading, Tabs } from 'modules/ui';
import { SeoHead } from 'modules/seo';
import { ConnectSocialProfilesProvider } from 'modules/social-accounts';

import { generateProfilePath } from '../../services/utils';
import { ProfileTab } from '../../types';

import EditModeProvider from '../EditModeProvider';
import Account from '../Account';
import SocialProfiles from '../SocialProfiles';

const profileTabValues = Object.values(ProfileTab);

const ProfilePage: FC = () => {
    const { tab } = useParams<{ tab?: ProfileTab }>();
    const intl = useIntl();

    if (!tab || !profileTabValues.includes(tab)) {
        return <Redirect to={generateProfilePath(ProfileTab.ACCOUNT)} />;
    }

    return (
        <>
            <SeoHead title={intl.formatMessage({ id: `profile.tabs.${tab}` })} />

            <TabsPage
                heading={
                    <Heading level={1}>
                        <FormattedMessage id="profile.title" />
                    </Heading>
                }
                activeKey={tab}
            >
                <Tabs.TabPane
                    tab={
                        <Link to={generateProfilePath(ProfileTab.ACCOUNT)}>
                            <FormattedMessage id="profile.tabs.account" />
                        </Link>
                    }
                    key={ProfileTab.ACCOUNT}
                >
                    <EditModeProvider>
                        <Account />
                    </EditModeProvider>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <Link to={generateProfilePath(ProfileTab.SOCIAL_PROFILES)}>
                            <FormattedMessage id="profile.tabs.social-profiles" />
                        </Link>
                    }
                    key={ProfileTab.SOCIAL_PROFILES}
                >
                    <EditModeProvider>
                        <ConnectSocialProfilesProvider>
                            <SocialProfiles />
                        </ConnectSocialProfilesProvider>
                    </EditModeProvider>
                </Tabs.TabPane>
            </TabsPage>
        </>
    );
};

export default ProfilePage;
