import type { TRuleWithTheme } from 'styles/theme';

export const accounts: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.spacing.small,
});

export const list: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    marginTop: theme.spacing.xsmall,
    padding: 0,

    listStyle: 'none',
});

export const item: TRuleWithTheme = () => ({
    marginRight: '0.75rem',
    paddingLeft: 7,
});
