import { combineRules } from 'fela';
import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRule = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
});

export const segment: TRuleWithTheme = ({ theme }) => ({
    height: '100%',
    flex: 1,
    textAlign: 'right',

    padding: `0.625rem ${theme.spacing.xsmall}`,

    ':last-child': {
        borderLeft: `1px solid ${theme.colors.inputBorder}`,
    },

    smallTablet: {
        flex: 'initial',
        padding: `0.625rem ${theme.spacing.medium}`,

        ':not(:first-child)': {
            borderLeft: `1px solid ${theme.colors.inputBorder}`,
        },
    },
});

export const fluidSegment: TRule = combineRules(segment, () => ({
    display: 'none',

    smallTablet: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        textAlign: 'left',
    },
}));

export const counter: TRule = () => ({
    display: 'block',
    padding: 0,
    minWidth: '6rem',
});
