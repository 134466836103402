import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';

import { FloatingCard, Button, SaveDraftButton, useToast } from 'modules/ui';
import { Col } from 'antd';

import { useRandomDrawSave } from '../../hooks';
import { RandomDrawTab, RandomDrawFormValues } from '../../types';

const RandomDrawFooter: FC = () => {
    const { tab } = useParams<{ tab?: RandomDrawTab }>();
    const toast = useToast();
    const { formatMessage } = useIntl();
    const { save, inProgress: saveInProgress, lastSuccessAt } = useRandomDrawSave();

    const { values, submitting } = useFormState<RandomDrawFormValues>({
        subscription: { submitting: true, values: true },
    });

    useEffect(() => {
        if (lastSuccessAt) {
            toast({ type: 'success', message: formatMessage({ id: 'randomDraw.draftSaved' }) });
        }
    }, [lastSuccessAt, toast, formatMessage]);

    return (
        <FloatingCard>
            {tab !== RandomDrawTab.POST && (
                <Col>
                    <SaveDraftButton
                        disabled={submitting || saveInProgress}
                        loading={saveInProgress}
                        onClick={() => save(values)}
                    />
                </Col>
            )}
            <Col>
                <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={submitting || saveInProgress}
                    loading={submitting}
                >
                    {tab === RandomDrawTab.SUMMARY ? (
                        <FormattedMessage id="randomDraw.action.drawNow" />
                    ) : (
                        <FormattedMessage id="general.continue" />
                    )}
                </Button>
            </Col>
        </FloatingCard>
    );
};

export default RandomDrawFooter;
