import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectAccountsCount } from 'modules/entities/modules/accounts';
import { ConnectSocialProfilesModal, useConnectSocialProfiles } from 'modules/social-accounts';

import useOnboardingVisible from './useOnboardingVisible';
import { useOAuthReset } from 'modules/auth/hooks';

export interface ConnectAccountOnboardingProps {}

const ConnectAccountOnboarding: FC<ConnectAccountOnboardingProps> = () => {
    const [isOnboardingVisible, hideOnboarding] = useOnboardingVisible();
    const [isVisible, setVisible] = useConnectSocialProfiles();
    const accountCount = useSelector(selectAccountsCount);
    const handleReset = useOAuthReset();

    return (
        <ConnectSocialProfilesModal
            title={accountCount === 0 && <FormattedMessage id="socialAccounts.connect.onboardingTitle" />}
            visible={isVisible === null ? isOnboardingVisible : isVisible}
            onCancel={() => {
                handleReset();
                setVisible(false);
                hideOnboarding();
            }}
        />
    );
};

export default ConnectAccountOnboarding;
