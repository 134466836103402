import React from 'react';
import { DEBOUNCE_INTERVAL } from '../constants';

const useValidationDebounce = validate => {
    const clearTimeoutRef = React.useRef(null);

    const validateDebounced = React.useCallback(
        (value, allValues, meta) =>
            new Promise<void>(resolve => {
                if (clearTimeoutRef.current) {
                    clearTimeoutRef.current();
                }

                const timer = setTimeout(() => {
                    resolve(validate(value, allValues, meta));
                }, DEBOUNCE_INTERVAL);

                clearTimeoutRef.current = () => {
                    clearTimeout(timer);
                    resolve();
                };
            }),
        [validate],
    );

    return validate ? validateDebounced : undefined;
};

export default useValidationDebounce;
