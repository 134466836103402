import React, { FC } from 'react';
import type { SVGAttributes } from 'react';

export interface SelectedProps extends SVGAttributes<SVGElement> {}

const Selected: FC<SelectedProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12" {...props} width="16" height="12">
        <defs>
            <path
                d="M9.863 18a1 1 0 01-.729-.315l-4.863-5.179a1 1 0 111.457-1.369l4.125 4.391 8.408-9.202a1 1 0 011.477 1.348l-9.137 10a.998.998 0 01-.73.326h-.008z"
                id="icon-selected"
            />
        </defs>
        <use fill="currentColor" xlinkHref="#icon-selected" transform="translate(-4 -6)" fillRule="evenodd" />
    </svg>
);

export default Selected;
