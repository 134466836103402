import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

import { Page, Panel } from 'modules/ui';
import { useFetchAccounts } from 'modules/entities/modules/accounts';
import { useErrorToast } from 'modules/errors';

import PlanPostForm from '../PlanPostForm';
import PlanPostSelectAccount from '../PlanPostSelectAccount';
import PlanPostFooter from '../PlanPostFooter';
import PlanPostCreatePostCards from '../PlanPostCreatePostCards';

import { useLoadPostDraft } from '../../hooks';

const PlanPostPage: FC = () => {
    const { inProgress: fetchingAccounts, error } = useFetchAccounts();
    const { inProgress: loadingDraft } = useLoadPostDraft();
    useErrorToast(error, 'error.api.accounts');

    const loading = fetchingAccounts || loadingDraft;

    return (
        <PlanPostForm>
            <Page backLink heading={<FormattedMessage id="posts.planNewPost.title" />} footer={<PlanPostFooter />}>
                <Row gutter={[0, 20]}>
                    <Col span={24}>
                        <Panel loading={{ show: loading }}>
                            <PlanPostSelectAccount />
                        </Panel>
                    </Col>

                    {!loading && <PlanPostCreatePostCards />}
                </Row>
            </Page>
        </PlanPostForm>
    );
};

export default PlanPostPage;
