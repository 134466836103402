import React, { FC } from 'react';

export interface DragProps {}

const Drag: FC<DragProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path d="M4 9h16a1 1 0 100-2H4a1 1 0 100 2"></path>
            <path d="M20 11H4a1 1 0 100 2h16a1 1 0 100-2"></path>
            <path d="M20 15H4a1 1 0 100 2h16a1 1 0 100-2"></path>
            <path d="M10 5a.997.997 0 00.707-.293L12 3.414l1.293 1.293a.997.997 0 001.414 0 .999.999 0 000-1.414l-2-2a.999.999 0 00-1.414 0l-2 2A.999.999 0 0010 5"></path>
            <path d="M13.293 19.293L12 20.586l-1.293-1.293a.999.999 0 10-1.414 1.414l2 2a.997.997 0 001.414 0l2-2a.999.999 0 10-1.414-1.414"></path>
        </g>
    </svg>
);

export default Drag;
