import React, { FunctionComponent } from 'react';
import { RendererProvider, ThemeProvider } from 'react-fela';
import type { IRenderer } from 'fela';
import { theme } from 'styles';

import { configureRenderer } from '../config';

interface FelaProps {
    renderer?: IRenderer;
    children: React.ReactNode;
}

const Fela: FunctionComponent<FelaProps> = ({ renderer = configureRenderer(), children }) => {
    React.useEffect(() => {
        return () => {
            renderer.clear();
        };
    }, [renderer]);

    return (
        <RendererProvider renderer={renderer}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </RendererProvider>
    );
};

export default Fela;
