import { childInitialState } from './reducers/entities';
import { EntityKey } from '../constants';
import type { EntitiesState } from '../types';

export interface RootState {
    entities: EntitiesState;
}

export const selectEntities = (state: RootState, { entityKey }: { entityKey: EntityKey }) => {
    const entity = state.entities[entityKey];
    return (entity ?? childInitialState) as typeof entity;
};

export const selectAccountsEntities = (state: RootState) => {
    const accounts = state.entities[EntityKey.ACCOUNTS];
    return (accounts ?? childInitialState) as typeof accounts;
};

export const selectAccountGroupsEntities = (state: RootState) => {
    const accountGroups = state.entities[EntityKey.ACCOUNT_GROUPS];
    return (accountGroups ?? childInitialState) as typeof accountGroups;
};

export const selectPostsEntities = (state: RootState) => {
    const posts = state.entities[EntityKey.POSTS];
    return (posts ?? childInitialState) as typeof posts;
};
export const selectArticlesEntities = (state: RootState) => {
    const articles = state.entities[EntityKey.ARTICLES];
    return (articles ?? childInitialState) as typeof articles;
};

export const selectCompetitionsEntities = (state: RootState) => {
    const competitions = state.entities[EntityKey.COMPETITIONS];
    return (competitions ?? childInitialState) as typeof competitions;
};

export const selectMediaEntities = (state: RootState) => {
    const media = state.entities[EntityKey.MEDIA];
    return (media ?? childInitialState) as typeof media;
};

export const selectInstagramPostsEntities = (state: RootState) => {
    const instagramPosts = state.entities[EntityKey.INSTAGRAM_POSTS];
    return (instagramPosts ?? childInitialState) as typeof instagramPosts;
};

export const selectFacebookPostsEntities = (state: RootState) => {
    const facebookPosts = state.entities[EntityKey.FACEBOOK_POSTS];
    return (facebookPosts ?? childInitialState) as typeof facebookPosts;
};

export const selectJobEntities = (state: RootState) => {
    const job = state.entities[EntityKey.JOBS];
    return (job ?? childInitialState) as typeof job;
};

export const selectVideoSettingsEntities = (state: RootState) => {
    const videoSetting = state.entities[EntityKey.VIDEO_SETTINGS];
    return (videoSetting ?? childInitialState) as typeof videoSetting;
};
