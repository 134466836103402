import type { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './RadioButtonCircle.styles';
import { RulesExtend } from 'styles/theme';

export interface RadioButtonCircleProps {
    checked: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const RadioButtonCircle: FC<RadioButtonCircleProps> = ({ checked, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { checked, extend });

    return (
        <div className={styles.radio}>
            <div className={styles.radioCircle} />
        </div>
    );
};

export default RadioButtonCircle;
