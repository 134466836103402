import { apiRequestActions } from '@ackee/redux-utils';
import { Action } from 'services/actions';

import * as types from './types';
import type { User } from '../../types';

export const fetchUser = apiRequestActions(types.fetchUser);

export const updateUser = (payload: Partial<User>): Action<Partial<User>> => ({
    type: types.UPDATE_USER,
    payload,
});
