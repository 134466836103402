import type { TRuleWithTheme } from 'styles/theme';

import type { NavTabProps } from './NavTab';

type StyleProps = Required<NavTabProps>;

export const tab: TRuleWithTheme<StyleProps> = ({ clickable, shadow, theme }) => ({
    fontSize: '0.9375rem',
    fontWeight: 700,

    borderRadius: theme.borderRadius.card,
    backgroundColor: theme.colors.white,
    boxShadow: shadow ? theme.shadow.buttonHover : 'none',

    ...(clickable && {
        cursor: 'pointer',

        ':hover': {
            boxShadow: shadow ? theme.shadow.cardHover : 'none',
        },
    }),
});
