export const MODULE_PREFIX = 'entities';
export const DEFAULT_GROUP = 'ids';

export enum EntityKey {
    ACCOUNT_GROUPS = 'account-groups',
    ACCOUNTS = 'accounts',
    COMPETITIONS = 'competitions',
    MEDIA = 'media',
    POSTS = 'posts',
    ARTICLES = 'articles',
    JOBS = 'jobs',
    FACEBOOK_POSTS = 'facebook_posts',
    INSTAGRAM_POSTS = 'instagram_posts',
    VIDEO_SETTINGS = 'video_settings',
}

export enum ApiReducerKey {
    CREATE = 'create',
    LIST = 'list',
    DETAIL = 'detail',
    UPDATE = 'update',
    DELETE = 'delete',
}
