import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';

import { validatorsWithMessage, translate, PanelField } from 'modules/form';
import { SelectAccountField } from 'modules/social-accounts';

import * as felaRules from './PlanPostSelectAccount.styles';
import { PlanPostField } from '../../config';
import type { PlanPostFormValues } from '../../types';

const PlanPostSelectAccount: FC = () => {
    const { formatMessage } = useIntl();
    const { initialValues } = useFormState<PlanPostFormValues>({ subscription: { initialValues: true } });
    return (
        <PanelField
            description={<FormattedMessage id="socialAccounts.selectOne" />}
            extend={{ panelItem: felaRules.panelItem }}
        >
            <SelectAccountField
                name={PlanPostField.ACCOUNT}
                disabled={Boolean(initialValues.accountId)}
                validate={translate(formatMessage, validatorsWithMessage.required)}
            />
        </PanelField>
    );
};

export default PlanPostSelectAccount;
