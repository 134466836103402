import { TRuleWithTheme } from 'styles/theme';
import type { ColorPickerProps } from './ColorPicker';

const COLOR_SIZE = 48;
const COLOR_MARGIN_RIGHT = 24;

type StyleProps = Required<Pick<ColorPickerProps, 'colorsRowCount'>>;

export const container: TRuleWithTheme<StyleProps> = ({ colorsRowCount }) => ({
    display: 'flex',
    flexWrap: 'wrap',

    padding: 0,
    margin: 0,

    listStyleType: 'none',

    maxWidth: colorsRowCount === Infinity ? '100%' : colorsRowCount * COLOR_SIZE + colorsRowCount * COLOR_MARGIN_RIGHT,
});

export const color: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: COLOR_SIZE,
    height: COLOR_SIZE,

    marginBottom: theme.spacing.small,
    marginRight: COLOR_MARGIN_RIGHT,

    border: `2px solid ${theme.colors.white}`,
    borderRadius: theme.borderRadius.medium,

    cursor: 'pointer',

    '&:hover': {
        boxShadow: `0 0 0 2px ${theme.colors.inputHover}`,
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,
});
