import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import config from 'config';
import { Heading, Paragraph } from 'modules/ui';

import * as felaRules from './SignUpPage.styles';
import SignUpForm from '../SignUpForm';
import AuthPage from '../AuthPage';

export interface SignUpPageProps {}

const SignUpPage: FunctionComponent<SignUpPageProps> = () => (
    <AuthPage
        header={
            <>
                <Heading level={1}>
                    <FormattedMessage id="auth.signUp.title" />
                </Heading>
                <Paragraph faded>
                    <FormattedMessage id="auth.signUp.subtitle" />
                </Paragraph>
            </>
        }
        separatorText={<FormattedMessage id="auth.signUp.separatorText" />}
        footer={
            <>
                <Paragraph weight="bold">
                    <Link to={config.routes.login}>
                        <FormattedMessage id="auth.signUp.footer" />
                    </Link>
                </Paragraph>
                <Paragraph size="small" faded extend={{ paragraph: felaRules.terms }}>
                    <FormattedMessage
                        id="auth.signUp.terms"
                        values={{
                            linkTerms: txt => <Link to={config.routes.termsAndConditions}>{txt}</Link>,
                            linkUserAgreement: txt => <Link to={config.routes.endUserAgreement}>{txt}</Link>,
                            linkPrivacyPolicy: txt => <Link to={config.routes.privacyPolicy}>{txt}</Link>,
                        }}
                    />
                </Paragraph>
            </>
        }
    >
        <SignUpForm />
    </AuthPage>
);

export default SignUpPage;
