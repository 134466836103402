import { paginationApiReducer } from '@ackee/redux-utils';

import config from 'config';

import { types } from '../../actions';

export default paginationApiReducer({
    actionTypes: {
        REQUEST: types.fetchCompetitions.FETCH_COMPETITIONS_REQUEST,
        SUCCESS: types.fetchCompetitions.FETCH_COMPETITIONS_SUCCESS,
        FAILURE: types.fetchCompetitions.FETCH_COMPETITIONS_FAILURE,
        CANCEL: types.fetchCompetitions.FETCH_COMPETITIONS_CANCEL,
        RESET: types.fetchCompetitions.FETCH_COMPETITIONS_RESET,
    },
    selectors: {
        currentCount: action => action.meta.currentCount,
    },
    initialState: {
        // Reason: incorrectly typed in redux-utils - https://github.com/AckeeCZ/redux-utils/issues/39
        // @ts-ignore
        amount: config.pagination.limit,
        limit: config.pagination.limit,
    },
});
