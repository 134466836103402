import type { TRule } from 'fela';

export const card: TRule = () => ({
    height: '100%',
});

export const avatar: TRule = () => ({
    marginRight: '0.75rem',
    minWidth: 48,

    desktop: {
        marginRight: '1.5rem',
    },
});

export const option: TRule = () => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
});

export const optionParagraph: TRule = () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
