import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useField } from 'react-final-form';
import { Row, Col } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { Button, Icon, IconTextButton } from 'modules/ui';

import * as felaRules from './CreatePostFirstComment.styles';
import PostTextArea from '../PostTextArea';
export interface CreatePostFirstCommentProps {
    name: string;
    clearField: () => void;
}

const CreatePostFirstComment: FC<CreatePostFirstCommentProps> = ({ name, clearField }) => {
    const { styles } = useFelaEnhanced(felaRules);

    const [editMode, setEditMode] = useState(false);
    const { input } = useField<string>(name, { subscription: { value: true } });
    const showField = editMode || input.value.length > 0;

    const handleRemove = () => {
        clearField();
        setEditMode(false);
    };

    return (
        <div className={styles.container}>
            {showField ? (
                <Row align="top" gutter={22}>
                    <Col flex="auto">
                        <PostTextArea name={name} rows={4} />
                    </Col>
                    <Col flex="20px">
                        <Button type="text" icon={<Icon type={Icon.Types.BIN} />} onClick={handleRemove} />
                    </Col>
                </Row>
            ) : (
                <IconTextButton icon={Icon.Types.PLUS} onClick={() => setEditMode(true)}>
                    <FormattedMessage id="post.firstComment" />
                </IconTextButton>
            )}
        </div>
    );
};

export default CreatePostFirstComment;
