import * as Petrus from '@ackee/petrus';
import { put, take, takeEvery, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import config from 'config';
import { api } from 'config/antonio';
import { operations } from 'api/apiSchema';
import { createUIErrorMessage } from 'modules/errors';

import type { Action } from 'services/actions';
import type { ApiPost, ApiResponse } from 'config/antonio';

import { signUpForm as signUpFormActions } from '../actions';
import { SignUpFormValues } from '../../types';

type Operation = operations['signUp'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;

export function* handleSignUpForm(action: Action<SignUpFormValues>) {
    try {
        yield call<ApiPost<Response, RequestBody>>(api.post, config.api.endpoints.signUp, action.payload);

        yield put(Petrus.loginRequest(action.payload));
        const result = yield take([Petrus.LOGIN_SUCCESS, Petrus.LOGIN_FAILURE]);

        if (result.type === Petrus.LOGIN_SUCCESS) {
            yield put(signUpFormActions.submitSuccess());
            yield put(push(config.routes.posts));
        } else {
            throw new Error(result.error);
        }
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(signUpFormActions.submitFailure(uiError));
    }
}

export default function* signUpForm() {
    yield takeEvery(signUpFormActions.submit.toString(), handleSignUpForm);
}
