import type { TRuleWithTheme } from 'styles/theme';

export const wrapper: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    fontSize: '1.125rem',
});

export const label: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '0.5rem',

    color: theme.colors.textGray,
    fontSize: '0.8125rem',
});
