import { RandomDrawColors } from './constants';

export const VIDEO_HEADLINE_MAX_LENGTH = 50;
export const JOB_PROGRESS_POLL_INTERVAL = 15_000;

export const forms = {
    randomDraw: 'randomDraw',
};

export enum RandomDrawField {
    ACCOUNT = 'accountId',
    POST = 'postId',
    WINNERS = 'winners',
    SUBSTITUTES = 'substitutes',
    POST_COMMENTED = 'postCommented',
    DUPLICATED_USERS_EXCLUDED = 'duplicatedUsersExcluded',
    PROFILES_MENTIONED = 'profilesMentioned',
    END_AT = 'endAt',
    HASHTAGS = 'hashtagsIncluded',
    BLOCK_LIST = 'profilesBlocked',
    BRANDING_HIDDEN = 'brandingHidden',
    HEADLINE = 'headline',
    SUBHEADLINE = 'subheadline',
    COLOR = 'color',
}

export const randomDrawFormInitialValues = {
    [RandomDrawField.WINNERS]: 1,
    [RandomDrawField.SUBSTITUTES]: 0,
    [RandomDrawField.POST_COMMENTED]: false,
    [RandomDrawField.DUPLICATED_USERS_EXCLUDED]: true,
    [RandomDrawField.PROFILES_MENTIONED]: 0,
    [RandomDrawField.HASHTAGS]: '',
    [RandomDrawField.BLOCK_LIST]: '',
    [RandomDrawField.BRANDING_HIDDEN]: true,
    [RandomDrawField.COLOR]: RandomDrawColors.PURPLE,
};
