import { takeLatest, put, call } from '@redux-saga/core/effects';
import type { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import config from 'config';

import * as actions from '../actions';
import type { PlanPostFormValues } from '../../types';

import savePost from './savePost';

function* planPostFormSubmitHandler(action: PayloadAction<PlanPostFormValues, string>) {
    const result = yield call(savePost, action.payload, true);

    if (!result.error) {
        yield put(actions.planPostForm.submitSuccess());
        yield put(push(config.routes.newPost, null));
    } else {
        yield put(actions.planPostForm.submitFailure(result.error));
    }
}

export default function* submitPostForm() {
    yield takeLatest(actions.planPostForm.submit.toString(), planPostFormSubmitHandler);
}
