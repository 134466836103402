import type { FC } from 'react';
import { Button } from 'modules/ui';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import config from 'config/config';
import { useCompetionAccountId } from 'modules/entities/modules/competitions';
import type { Competition } from 'modules/entities';

export interface RandomDrawCompetitionPostButtonProps {
    competitionId: Competition['id'];
    disabled?: boolean;
}

const RandomDrawCompetitionPostButton: FC<RandomDrawCompetitionPostButtonProps> = ({ competitionId, disabled }) => {
    const accountId = useCompetionAccountId(competitionId);

    const location = {
        pathname: config.routes.newPost,
        state: { accountId: accountId, competitionId: competitionId },
    };
    return (
        <Link to={location}>
            <Button size="large" disabled={disabled}>
                <FormattedMessage id="randomDraw.results.button.post" />
            </Button>
        </Link>
    );
};

export default RandomDrawCompetitionPostButton;
