import { FC, ReactNode, useState } from 'react';
import { isFunction } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Heading, Paragraph, Panel } from 'modules/ui';

import * as felaRules from './PanelField.styles';
import Switch from '../Switch';

export interface PanelFieldProps {
    title?: ReactNode;
    description?: ReactNode;
    control?: ReactNode;
    optional?: boolean;
    defaultOn?: boolean;
    onDisable?: () => void;
    children?: ReactNode | FC<{ disabled: boolean }>;
    extend?: RulesExtend<typeof felaRules>;
}

const isRenderFunction = (children: PanelFieldProps['children']): children is FC => isFunction(children);

const PanelField: FC<PanelFieldProps> = ({
    title,
    description,
    control,
    optional = false,
    defaultOn = true,
    onDisable,
    children,
    extend,
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend, title });
    const [enabled, setEnabled] = useState(defaultOn);

    return (
        <Panel.Item extend={{ item: rules.panelItem }}>
            <header className={styles.header}>
                <div className={styles.description}>
                    {title && <Heading level={6}>{title}</Heading>}
                    {description && (
                        <Paragraph
                            size={title ? 'small' : 'medium'}
                            weight={title ? 'regular' : 'bold'}
                            faded
                            extend={{ paragraph: rules.paragraph }}
                        >
                            {description}
                        </Paragraph>
                    )}
                </div>

                {optional ? (
                    <Switch
                        checked={enabled}
                        onChange={() => {
                            setEnabled(!enabled);

                            if (enabled) {
                                onDisable();
                            }
                        }}
                    />
                ) : (
                    control
                )}
            </header>

            {children && (
                <div className={styles.content}>
                    {isRenderFunction(children) ? children({ disabled: !enabled }) : children}
                </div>
            )}
        </Panel.Item>
    );
};

export default PanelField;
