import { useDispatch, useSelector } from 'react-redux';

import { selectCreateCompetitionApi, selectUpdateCompetitionApi } from 'modules/entities/modules/competitions';

import { saveDraft } from '../services/actions';
import type { RandomDrawFormValues } from '../types';

const useRandomDrawSave = () => {
    const dispatch = useDispatch();
    const save = (values: RandomDrawFormValues) => dispatch(saveDraft(values));

    const createApi = useSelector(selectCreateCompetitionApi);
    const updateApi = useSelector(selectUpdateCompetitionApi);

    const inProgress = createApi.inProgress || updateApi.inProgress;
    const error = createApi.error || updateApi.error;
    const lastSuccessAt = createApi.lastSuccessAt || updateApi.lastSuccessAt;

    return { save, inProgress, error, lastSuccessAt };
};

export default useRandomDrawSave;
