import { FC, createContext, Context } from 'react';
import { Modal, ModalFuncProps } from 'antd';
import type { ModalStaticFunctions } from 'antd/es/modal/confirm.d';

import { useFelaEnhanced } from 'hooks';

import { confirmModal } from './Modal.styles';

const ModalContext = createContext(null);

const ModalProvider: FC & { Context: Context<ModalStaticFunctions['confirm']> } = ({ children }) => {
    const { styles, theme } = useFelaEnhanced({ confirmModal });
    const [modal, contextHolder] = Modal.useModal();

    const confirm = (params: ModalFuncProps) =>
        modal.confirm({
            width: theme.sizes.modal.confirm,
            className: styles.confirmModal,
            icon: null,
            ...params,
        });

    return (
        <ModalContext.Provider value={confirm}>
            {contextHolder}
            {children}
        </ModalContext.Provider>
    );
};

ModalProvider.Context = ModalContext;

export default ModalProvider;
