import { put, takeEvery, call, select } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import { Action } from 'services/actions';
import { operations } from 'api/apiSchema';
import type { ApiPut, ApiResponse } from 'config/antonio';
import * as log from 'config/loglevel';
import { createUIErrorMessage, mapSubmitErrorToFields } from 'modules/errors';
import { authUserSelector, actions as authActions } from 'modules/auth';

import { changeUserAccount as changeUserAccountActions } from '../actions';
import { ChangeEmailFormValues, ChangePasswordFormValues } from '../../types';

type Operation = operations['userUpdate'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type RequestParams = Operation['parameters']['path'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;

export function* handleChangeUserAccount(action: Action<ChangeEmailFormValues | ChangePasswordFormValues>) {
    try {
        const { id } = yield select(authUserSelector);

        const { data: user }: Response = yield call<ApiPut<Response, RequestBody, RequestParams>>(
            authApi.put,
            config.api.endpoints.user,
            {
                ...action.payload,
                uriParams: {
                    id,
                },
            },
        );

        yield put(authActions.updateUser(user));
        yield put(changeUserAccountActions.submitSuccess());
    } catch (e) {
        const uiError = createUIErrorMessage(e);
        const formErrors = mapSubmitErrorToFields(uiError, action.payload);
        yield put(changeUserAccountActions.submitFailure(formErrors));

        log.error(e);
    }
}

export default function* changeUserAccount() {
    yield takeEvery(changeUserAccountActions.submit.toString(), handleChangeUserAccount);
}
