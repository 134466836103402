import { takeLatest, put, take } from '@redux-saga/core/effects';
import * as actions from '../actions';
import { push } from 'connected-react-router';

import { apiActions as jobApiActions } from 'modules/entities/modules/jobs';

import { generatePath } from 'react-router-dom';
import config from 'config/config';

function* handleSubmitRandomDrawAgain(action) {
    const competitionId = action.payload;
    yield put(jobApiActions.createJob.request({ competitionId }));
    const createJobResult = yield take([
        jobApiActions.createJob.success.toString(),
        jobApiActions.createJob.failure.toString(),
    ]);
    const jobId = createJobResult.payload;
    if (!createJobResult.error) {
        yield put(push(generatePath(config.routes.randomDrawProgress, { jobId })));
    } else {
    }
}

export default function* submitRandomDrawForm() {
    yield takeLatest(actions.randomDrawAgain.toString(), handleSubmitRandomDrawAgain);
}
