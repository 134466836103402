import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRule<{ isFloating: boolean }> = ({ isFloating }) => ({
    position: 'relative',

    // Override antd selector
    '& label': {
        fontSize: isFloating ? '0.8125rem' : '1.125rem',
    },
});

export const label: TRuleWithTheme<{ isFloating: boolean }> = ({ theme, isFloating }) => ({
    position: 'absolute',
    zIndex: 2,
    top: 15,
    left: 24,
    transform: isFloating ? 'translateY(-8px)' : 'translateY(0)',

    color: theme.colors.inputPlaceholder,

    pointerEvents: 'none',
    transitionDuration: theme.transition.duration,
    transitionProperty: 'transform, font-size',
});
