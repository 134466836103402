import { Modal as AntdModal } from 'antd';
import type { ModalProps as AntdModalProps } from 'antd';
import type { FC } from 'react';
import type { RulesExtend } from 'styles/theme';

import { useFelaEnhanced } from 'hooks';

import Icon from '../Icon';

import * as felaRules from './Modal.styles';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

export interface ModalProps extends AntdModalProps {
    size?: 'small' | 'large';
    extend?: RulesExtend<typeof felaRules>;
}

const Modal: FC<ModalProps> & {
    Header: typeof ModalHeader;
    Footer: typeof ModalFooter;
} = ({ extend, size = 'small', footer = null, children, ...props }) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend, size });

    return (
        <AntdModal
            centered
            footer={footer}
            {...props}
            width={null}
            className={styles.modal}
            closeIcon={<Icon type={Icon.Types.CLOSE} extend={{ icon: rules.closeIcon }} />}
        >
            {children}
        </AntdModal>
    );
};

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
