import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { Post } from 'modules/post';
import type { Account, SocialPost } from 'modules/entities';
import { useErrorToast } from 'modules/errors';
import { useFetchSocialPost } from 'modules/post/hooks';

import { selectSummaryPost } from '../../services/selectors';

export interface RandomDrawSummaryPostProps {
    accountType: Account['type'];
    accountId: Account['providerId'];
    postId: SocialPost['id'];
}

const RandomDrawSummaryPost: FC<RandomDrawSummaryPostProps> = ({ accountType, accountId, postId }) => {
    const post = useSelector(state => selectSummaryPost(state, { type: accountType, id: postId }));
    const { inProgress, error } = useFetchSocialPost(accountType, accountId, postId);
    useErrorToast(error, 'error.api.post');

    return <Post {...post} loading={inProgress} />;
};

export default RandomDrawSummaryPost;
