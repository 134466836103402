import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.ARTICLES,
});

export const fetchArticles = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ARTICLES_' }),
});
export const fetchArticle = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ARTICLE_' }),
});
