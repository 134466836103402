import React, { FC } from 'react';

const Minus: FC = props => (
    <svg width="24" height="36" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 36" {...props}>
        <path
            d="M23.105 19.868L4.181 35.411c-1.648 1.354-4.18.223-4.18-1.868V2.457C0 .367 2.532-.766 4.18.59l18.924 15.543a2.397 2.397 0 010 3.736"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default Minus;
