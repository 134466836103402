import type { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme }) => ({
    boxShadow: theme.shadow.panel,
    marginBottom: theme.spacing.small,

    '& .ant-card-head': {
        display: 'none',
    },

    ':last-of-type': {
        marginBottom: 0,
    },

    '& .ant-card-body': {
        paddingTop: theme.spacing.large,
        paddingBottom: theme.spacing.large,
        paddingLeft: theme.spacing.small,
        paddingRight: theme.spacing.small,
    },

    desktop: {
        '& > .ant-card-body': {
            paddingBottom: theme.spacing.large,
            paddingLeft: theme.spacing.medium,
            paddingRight: theme.spacing.medium,
        },
    },
});
