import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'inline-flex',
    marginTop: '1rem',

    tablet: {
        marginTop: 0,
    },
});

export const secondButton: TRuleWithTheme = ({ theme }) => ({
    marginLeft: '1.5rem',
});
