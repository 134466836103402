export const MEDIA_MAX_COUNT = 10;
export const PLACEHOLDERS_COUNT = 5;
export const POST_MAX_CHARACTERS = 160;
export const POST_MAX_HASHTAGS = 30;

export const forms = {
    planPost: 'planPost',
};

export enum PlanPostField {
    ACCOUNT = 'accountId',
    CAPTION = 'caption',
    VIDEO = 'video',
    FIRST_COMMENT = 'firstComment',
}
