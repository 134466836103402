import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

export interface SeoHeadProps {
    title?: string;
    description?: string;
}

const SeoHead: FC<SeoHeadProps> = ({ title, description }) => {
    const intl = useIntl();

    const appName = intl.formatMessage({ id: 'app.name' });
    const fullTitle = title ? `${title} | ${appName}` : appName;

    return (
        <Helmet>
            <title>{fullTitle}</title>
            {description && <meta name="description" content={description} />}
        </Helmet>
    );
};

export default SeoHead;
