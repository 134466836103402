import { createAction } from '@reduxjs/toolkit';

import { createFormActions } from 'modules/form';
import type { Schema } from 'modules/entities';

import { MODULE_PREFIX } from '../../constants';

export const changeUserAccount = createFormActions(`${MODULE_PREFIX}/CHANGE_USER_ACCOUNT`);
export const changeAccountGroup = createFormActions(`${MODULE_PREFIX}/CHANGE_ACCOUNT_GROUP`);

export const changeAccountGroupPicture = createAction(
    `${MODULE_PREFIX}/CHANGE_ACCOUNT_GROUP_PICTURE`,
    (accountGroupId: Schema['AccountGroup']['id'], image: File | Blob) => ({
        meta: {
            accountGroupId,
        },
        payload: {
            image,
        },
    }),
);

export const changeAccountGroupPictureCancel = createAction(
    `${MODULE_PREFIX}/CHANGE_ACCOUNT_GROUP_PICTURE_CANCEL`,
    (accountGroupId: Schema['AccountGroup']['id']) => ({
        meta: {
            accountGroupId,
        },
        payload: undefined,
    }),
);
