import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import ErrorBoundary from 'modules/errors/components/ErrorBoundary';

import * as felaRules from './Page.styles';
import Alert from '../Alert';
import Container from '../Container';
import type { ContainerProps } from '../Container/Container';
import Heading from '../Heading';
import BackLink from '../BackLink';

export interface PageProps {
    heading?: ReactNode | string;
    backLink?: boolean;
    footer?: ReactNode;
    containerSize?: ContainerProps['size'];
    extend?: RulesExtend<typeof felaRules>;
}

const Page: FC<PageProps> = ({ containerSize, children, heading, backLink, footer }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.page}>
            <ErrorBoundary
                fallback={() => (
                    <Alert
                        type="error"
                        message={<FormattedMessage id="error.appCrash" />}
                        className={styles.errorAlert}
                    />
                )}
            >
                <div className={styles.pageContent}>
                    {(backLink || heading) && (
                        <Container size="large">
                            <header className={styles.pageHeader}>
                                {backLink && <BackLink />}
                                {heading && <Heading>{heading}</Heading>}
                            </header>
                        </Container>
                    )}

                    <Container size={containerSize}>{children}</Container>

                    {footer && (
                        <Container size="large">
                            <footer className={styles.footer}>{footer}</footer>
                        </Container>
                    )}
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default Page;
