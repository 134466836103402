import { basicApiReducer } from '@ackee/redux-utils';

import * as types from '../../actions/types';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_REQUEST,
        SUCCESS: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_SUCCESS,
        FAILURE: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_FAILURE,
        CANCEL: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_CANCEL,
        RESET: types.fetchVideoSettings.FETCH_VIDEO_SETTINGS_RESET,
    },
});
