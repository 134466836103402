import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './NavTab.styles';

export interface NavTabProps {
    clickable?: boolean;
    shadow?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const NavTab: FC<NavTabProps> = ({ clickable = false, shadow = true, children, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, clickable, shadow });

    return <div className={styles.tab}>{children}</div>;
};

export default NavTab;
