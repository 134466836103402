import React from 'react';
import type { FC, ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import Heading from '../Heading';
import Paragraph from '../Paragraph';

import * as felaRules from './Intro.styles';

export interface IntroProps {
    title: ReactNode;
    text: ReactNode;
    illustration?: string;
    illustationAlt?: string;
    extend?: RulesExtend<typeof felaRules>;
}

const Intro: FC<IntroProps> = ({ title, text, illustration, extend, illustationAlt = '' }) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <>
            {illustration && <img src={illustration} alt={illustationAlt} className={styles.illustration} />}

            <Heading level={2} extend={{ heading: rules.title }}>
                {title}
            </Heading>

            <Paragraph size="medium" faded extend={{ paragraph: rules.paragraph }}>
                {text}
            </Paragraph>
        </>
    );
};

export default Intro;
