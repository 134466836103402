import type { FC } from 'react';
import { noop } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import { Button, Icon } from 'modules/ui';

import * as felaRules from './Counter.styles';

export interface CounterProps {
    count?: number;
    minCount?: number;
    maxCount?: number;
    disabled?: boolean;
    onChange?: (count: number) => void;
}

const Counter: FC<CounterProps> = ({ count = 0, minCount = 0, maxCount = null, disabled = false, onChange = noop }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.counter}>
            <Button
                type="text"
                icon={<Icon type={Icon.Types.MINUS} size="small" circle extend={{ icon: felaRules.icon }} />}
                onClick={() => onChange(count - 1)}
                disabled={disabled || (minCount !== null && count === minCount)}
            />
            <span className={styles.text}>{count}</span>
            <Button
                type="text"
                icon={<Icon type={Icon.Types.PLUS} size="small" circle extend={{ icon: felaRules.icon }} />}
                onClick={() => onChange(count + 1)}
                disabled={disabled || (maxCount !== null && count === maxCount)}
            />
        </div>
    );
};

export default Counter;
