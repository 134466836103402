import { normalize } from 'normalizr';
import type { Schema as NormalizerScheama } from 'normalizr';

import * as schemas from './schemas';
import type { EntityKey } from '../../constants';
import type {
    Schema,
    Entities,
    Article,
    AccountGroup,
    NormalizedAccountGroup,
    Competition,
    Post,
    Job,
} from '../../types';

interface NormalizrResult<Entity extends Entities, Result> {
    result: Result;
    entities: Record<EntityKey, Record<Entity['id'], Entity>>;
}

const normalizeFactory = <Entity extends Entities, Result extends Array<Entity['id']> | Entity['id']>(
    schema: NormalizerScheama,
) => <Data>(data: Data): NormalizrResult<Entity, Result> => normalize(data, schema);

export const normalizeAccount = normalizeFactory<Schema['Account'], Schema['Account']['id']>(schemas.accountSchema);
export const normalizeAccounts = normalizeFactory<Schema['Account'], Array<Schema['Account']['id']>>(
    schemas.accountListSchema,
);

export const normalizeAccountGroup = normalizeFactory<NormalizedAccountGroup, AccountGroup['id']>(
    schemas.accountGroupSchema,
);
export const normalizeAccountGroups = normalizeFactory<NormalizedAccountGroup, Array<AccountGroup['id']>>(
    schemas.accountGroupListSchema,
);

export const normalizeCompetition = normalizeFactory<Competition, Competition['id']>(schemas.competitionSchema);
export const normalizeCompetitions = normalizeFactory<Competition, Array<Competition['id']>>(
    schemas.competitionListSchema,
);

export const normalizeInstagramPost = normalizeFactory<
    Schema['InstagramAccountPost'],
    Schema['InstagramAccountPost']['id']
>(schemas.instagramPostSchema);
export const normalizeInstagramPosts = normalizeFactory<
    Schema['InstagramAccountPost'],
    Array<Schema['InstagramAccountPost']['id']>
>(schemas.instagramPostListSchema);

export const normalizeFacebookPost = normalizeFactory<Schema['FbPagePost'], Schema['FbPagePost']['id']>(
    schemas.facebookPostSchema,
);
export const normalizeFacebookPosts = normalizeFactory<Schema['FbPagePost'], Array<Schema['FbPagePost']['id']>>(
    schemas.facebookPostListSchema,
);

export const normalizeMedia = normalizeFactory<Schema['Media'], Schema['Media']['id']>(schemas.mediaSchema);

export const normalizePost = normalizeFactory<Post, Post['id']>(schemas.postSchema);
export const normalizePosts = normalizeFactory<Competition, Array<Post['id']>>(schemas.postListSchema);

export const normalizeArticle = normalizeFactory<Article, Article['id']>(schemas.articleSchema);
export const normalizeArticles = normalizeFactory<Article, Array<Article['id']>>(schemas.articleListSchema);

export const normalizeJob = normalizeFactory<Job, Job['id']>(schemas.jobSchema);
export const normalizeJobs = normalizeFactory<Job, Array<Job['id']>>(schemas.jobListSchema);

export const normalizeVideoSettings = normalizeFactory<Competition, Post['id']>(schemas.videoSettingsSchema);
export const normalizeVideoSettingsList = normalizeFactory<Competition, Array<Post['id']>>(
    schemas.videoSettingsListSchema,
);
