import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Button } from 'modules/ui';
import { ErrorAlert } from 'modules/errors';
import { Form, TextField, useFormSubmit, validatorsWithMessage, composeValidators, translate } from 'modules/form';

import * as felaRules from './SignUpForm.styles';
import { forms, PASSWORD_LENGTH } from '../../config';
import { SignUpFormField } from '../../types';
import { signUpForm } from '../../services/actions';
import { oauthApiSelector } from '../../services/selectors';

const { required, email, password } = validatorsWithMessage;

const SignUpForm: FC = () => {
    const { rules } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    const onSubmit = useFormSubmit(signUpForm);
    const oauthApi = useSelector(oauthApiSelector);

    return (
        <Form name={forms.login} onSubmit={onSubmit} subscription={{ submitting: true, submitError: true }}>
            {({ submitting, submitError }) => (
                <>
                    <ErrorAlert error={submitError ?? oauthApi.error} extend={{ alert: rules.alert }} />

                    <TextField
                        name={SignUpFormField.Email}
                        type="email"
                        label={<FormattedMessage id="auth.email" />}
                        validate={translate(formatMessage, composeValidators(required, email))}
                    />

                    <TextField
                        name={SignUpFormField.Password}
                        type="password"
                        label={<FormattedMessage id="auth.password" />}
                        help={<FormattedMessage id="error.form.password" values={{ length: PASSWORD_LENGTH }} />}
                        validate={translate(
                            ({ id }) => formatMessage({ id }, { length: PASSWORD_LENGTH }),
                            composeValidators(required, password(PASSWORD_LENGTH)),
                        )}
                    />

                    <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        block
                        disabled={submitting || oauthApi.inProgress}
                        loading={submitting}
                        extend={{ button: rules.button }}
                    >
                        <FormattedMessage id="auth.signUp.button" />
                    </Button>
                </>
            )}
        </Form>
    );
};

export default SignUpForm;
