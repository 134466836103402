import { all } from 'redux-saga/effects';

import fetchAccountGroups from './fetchAccountGroups';
import updateAccountGroup from './updateAccountGroup';
import deleteAccountGroup from './deleteAccountGroup';
import createAccountGroup from './createAccountGroup';

export default function* accountGroups() {
    yield all([fetchAccountGroups(), updateAccountGroup(), deleteAccountGroup(), createAccountGroup()]);
}
