import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const media: TRuleWithTheme = ({ theme }) => ({
    maxWidth: 280,
    marginTop: theme.spacing.large,

    tablet: {
        marginRight: theme.spacing.large,
        marginTop: 0,
    },
});

export const mainContent: TRuleWithTheme = ({ theme }) => ({
    maxWidth: 280,
    height: 280,
    overflow: 'hidden',

    borderRadius: theme.borderRadius.large,
    backgroundColor: theme.colors.backgroundPrimary,
});

export const list: TRule = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,

    marginTop: '0.75rem',
    marginBottom: '0.75rem',
    marginLeft: '-0.25rem',
    marginRight: '-0.25rem',
});

export const listItem: TRuleWithTheme = ({ theme }) => ({
    width: 50,
    height: 50,
    margin: '0.225rem',
    overflow: 'hidden',

    borderRadius: theme.borderRadius.small,
    backgroundColor: theme.colors.backgroundPrimary,
});
