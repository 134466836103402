import { createAction } from '@reduxjs/toolkit';

import { MODULE_PREFIX, DEFAULT_GROUP, EntityKey } from '../constants';
import type { Entities, SetStrategy } from '../types';

type EntityId = Entities['id'];
type ById = Record<string, Entities>;

type PrepareSetEntitiesAction = (
    entityKey: EntityKey,
    byId: ById,
) => {
    meta: {
        entityKey: EntityKey;
        group?: never;
        strategy?: never;
    };
    payload: {
        byId: ById;
        ids?: never;
        id?: never;
    };
};

export const setEntities = createAction<PrepareSetEntitiesAction>(
    `${MODULE_PREFIX}/SET_ENTITIES`,
    (entityKey, byId) => ({
        meta: { entityKey },
        payload: { byId },
    }),
);

type PrepareRemoveEntityAction = (
    entityKey: EntityKey,
    id: EntityId,
) => {
    meta: {
        entityKey: EntityKey;
        group?: string;
        strategy?: never;
    };
    payload: {
        id: EntityId;
        byId?: never;
        ids?: never;
    };
};

export const removeEntity = createAction<PrepareRemoveEntityAction>(
    `${MODULE_PREFIX}/REMOVE_ENTITY`,
    (entityKey, id, group = DEFAULT_GROUP) => ({
        meta: { entityKey, group },
        payload: { id },
    }),
);

type PrepareSetEntitiesGroupAction = (
    entityKey: EntityKey,
    payload?: {
        group?: string;
        strategy?: SetStrategy;
        ids?: Array<EntityId>;
        byId: ById;
    },
) => {
    meta: {
        entityKey: EntityKey;
        group: string;
        strategy: SetStrategy;
    };
    payload: {
        ids?: Array<EntityId>;
        byId: ById;
        id?: EntityId;
    };
};

export const setEntitiesGroup = createAction<PrepareSetEntitiesGroupAction>(
    `${MODULE_PREFIX}/SET_ENTITIES_GROUP`,
    (entityKey, payload = { byId: {} as ById, ids: [] }) => ({
        meta: {
            entityKey,
            group: payload.group ?? DEFAULT_GROUP,
            strategy: payload.strategy ?? 'append',
        },
        payload,
    }),
);

type PrepareResetEntitiesGroupAction = (
    entityKey: EntityKey,
    group?: string,
) => {
    meta: {
        entityKey: EntityKey;
        group?: string;
        strategy?: never;
    };
    payload: undefined;
};

export const resetEntitiesGroup = createAction<PrepareResetEntitiesGroupAction>(
    `${MODULE_PREFIX}/RESET_ENTITIES_GROUP`,
    (entityKey, group = DEFAULT_GROUP) => ({
        meta: {
            entityKey,
            group,
        },
        payload: undefined,
    }),
);

type PrepareResetEntitiesAction = (
    entityKey: EntityKey,
) => {
    meta: {
        entityKey: EntityKey;
        group?: never;
        strategy?: never;
    };
    payload: undefined;
};

export const resetEntities = createAction<PrepareResetEntitiesAction>(`${MODULE_PREFIX}/RESET_ENTITIES`, entityKey => ({
    meta: { entityKey },
    payload: undefined,
}));

type PrepareUnsetEntitiesGroupAction = (
    entityKey: EntityKey,
    group?: string,
    ids?: Array<EntityId>,
) => {
    meta: {
        entityKey: EntityKey;
        group?: string;
        strategy?: never;
    };
    payload: {
        ids?: Array<EntityId>;
        byId?: never;
        id?: never;
    };
};

export const unsetEntitiesGroup = createAction<PrepareUnsetEntitiesGroupAction>(
    `${MODULE_PREFIX}/UNSET_ENTITIES_GROUP`,
    (entityKey, group = DEFAULT_GROUP, ids = []) => ({
        meta: {
            entityKey,
            group,
        },
        payload: {
            ids,
        },
    }),
);

export type EntitiesActions =
    | ReturnType<typeof setEntities>
    | ReturnType<typeof removeEntity>
    | ReturnType<typeof setEntitiesGroup>
    | ReturnType<typeof resetEntitiesGroup>
    | ReturnType<typeof resetEntities>
    | ReturnType<typeof unsetEntitiesGroup>;
