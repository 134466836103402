import type { TRuleWithTheme } from 'styles/theme';

const BUTTON_SIZE = 80;

export const container: TRuleWithTheme = () => ({
    display: 'inline-block',
    position: 'relative',
});

export const video: TRuleWithTheme = ({ theme }) => ({
    borderRadius: theme.borderRadius.large,
});

export const controlButton: TRuleWithTheme = () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',

    '&.ant-button': {
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
    },
});

export const buttonIcon = () => ({
    '& svg': {
        transform: 'translateX(4px)',
    },
});
