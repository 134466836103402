import type { TRule } from 'fela';

export const planButton: TRule = () => ({
    display: 'flex',
    margin: '2rem auto 0',

    '& .anticon': {
        height: 18,
        width: 18,
        marginRight: '1rem',

        '& svg': {
            height: 18,
            width: 18,
        },
    },

    '& > .anticon + span': {
        marginLeft: 0,
    },

    desktop: {
        display: 'none',
    },
});

export const container: TRule = () => ({
    '& .ant-col:not(:first-of-type)': {
        display: 'none',
    },
    desktop: {
        '& .ant-col:not(:first-of-type)': {
            display: 'block',
        },
    },
});
