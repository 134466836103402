import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { GenerationProgress, JobStates } from 'modules/entities/modules/jobs';
import { Card, Heading, Page, Paragraph, Progress, Steps, useToast } from 'modules/ui';
import type { Job } from 'modules/entities';

import { useFelaEnhanced } from 'hooks';
import config from 'config/config';

import { useJobPolling } from '../../hooks';
import * as felaRules from './RandomDrawProgressPage.styles';

type RandomDrawProgressPageProps = {};
type UrlParams = {
    jobId: Job['id'];
};

const RandomDrawProgressPage: FC<RandomDrawProgressPageProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const { jobId } = useParams<UrlParams>();
    const { percentage, jobState, description, competitionId, state, videoURL } = useJobPolling(jobId);
    const { formatMessage } = useIntl();

    const toast = useToast();

    const history = useHistory();

    useEffect(() => {
        if (jobState === JobStates.FINISHED || videoURL) {
            history.push(generatePath(config.routes.randomDrawResults, { competitionId }));
        } else if (jobState === JobStates.ERROR_VIDEO_GENERATE) {
            const location = {
                pathname: generatePath(config.routes.randomDrawResults, { competitionId }),
                state: {
                    error: true,
                },
            };
            history.push(location);
            toast({ type: 'error', message: formatMessage({ id: 'error.generate.video' }) });
        }
    }, [jobState, history, competitionId, toast, formatMessage, videoURL]);

    return (
        <Page heading={<FormattedMessage id="posts.randomDraw.title" />}>
            <Card extend={{ card: rules.card }}>
                <Steps size="small" extend={{ steps: rules.steps }} current={state}>
                    <Steps.Step title={<FormattedMessage id="randomDraw.progress.comments" />} />
                    <Steps.Step title={<FormattedMessage id="randomDraw.progress.video" />} />
                </Steps>
                <div className={styles.content}>
                    <Paragraph size="x-small" faded extend={{ paragraph: rules.marginBottom }}>
                        <FormattedMessage id="randomDraw.progress.processing" />
                    </Paragraph>
                    <Heading>
                        <FormattedMessage id={`randomDraw.progress.${description}`} />
                    </Heading>
                    <Paragraph size="small" faded>
                        <FormattedMessage
                            id={
                                state === GenerationProgress.VIDEO
                                    ? 'randomDraw.progress.itTakesMomentVideo'
                                    : 'randomDraw.progress.itTakesMomentComments'
                            }
                        />
                    </Paragraph>
                    <Progress
                        type="circle"
                        percent={percentage}
                        showPercentage={false}
                        extend={{ progress: rules.progress }}
                    />
                    <Paragraph size="small" faded>
                        <FormattedMessage id="randomDraw.progress.doNotLeave" />
                    </Paragraph>
                </div>
            </Card>
        </Page>
    );
};

export default RandomDrawProgressPage;
