import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ColorPicker.styles';
import Icon from '../Icon';

export interface ColorPickerProps {
    value?: string;
    colors: { [value: string]: string };
    onChange: (color: string) => void;
    colorsRowCount?: number;
}

const ColorPicker: FC<ColorPickerProps> = ({ value, colors, onChange, colorsRowCount = Infinity }) => {
    const { styles } = useFelaEnhanced(felaRules, { colorsRowCount });

    return (
        <ul className={styles.container}>
            {Object.entries(colors).map(([colorValue, color]) => (
                <li
                    key={colorValue}
                    className={styles.color}
                    tabIndex={0}
                    onClick={() => onChange(colorValue)}
                    style={{ background: color }}
                >
                    {value === colorValue && <Icon type={Icon.Types.SELECTED} extend={{ icon: felaRules.icon }} />}
                </li>
            ))}
        </ul>
    );
};

export default ColorPicker;
