import { FormattedMessage } from 'react-intl';
import { FormSpy } from 'react-final-form';
import { Col, Row } from 'antd';

import { ColorPickerField, TextField } from 'modules/form';

import { RandomDrawField, VIDEO_HEADLINE_MAX_LENGTH } from '../../config';
import { pickerColors } from '../../constants';
import VideoPreview from '../VideoPreview';
import RandomDrawCard from '../RandomDrawCard';

import * as felaRules from './RandomDrawVideo.styles';
import { useFelaEnhanced } from 'hooks';

const RandomDrawVideo = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <RandomDrawCard>
            <Row gutter={40}>
                <Col lg={8}>
                    <div className={styles.mobileOnly}>
                        <TextField
                            name={RandomDrawField.SUBHEADLINE}
                            type="text"
                            label={<FormattedMessage id="randomDraw.video.subheadline.label" />}
                            maxLength={VIDEO_HEADLINE_MAX_LENGTH}
                            extend={{ formItem: rules.textField }}
                            showCounter
                        />
                        <TextField
                            name={RandomDrawField.HEADLINE}
                            type="text"
                            label={<FormattedMessage id="randomDraw.video.headline.label" />}
                            maxLength={VIDEO_HEADLINE_MAX_LENGTH}
                            extend={{ formItem: rules.headlineTextField }}
                            showCounter
                        />
                    </div>

                    <FormSpy
                        subscription={{ values: true }}
                        render={({ values }) => (
                            <VideoPreview
                                color={values[RandomDrawField.COLOR]}
                                headline={values[RandomDrawField.HEADLINE]}
                                subHeadline={values[RandomDrawField.SUBHEADLINE]}
                            />
                        )}
                    />
                </Col>

                <Col lg={16}>
                    <div className={styles.desktopOnly}>
                        <TextField
                            name={RandomDrawField.HEADLINE}
                            type="text"
                            label={<FormattedMessage id="randomDraw.video.headline.label" />}
                            maxLength={VIDEO_HEADLINE_MAX_LENGTH}
                            extend={{ formItem: rules.headlineTextField }}
                            showCounter
                        />
                        <TextField
                            name={RandomDrawField.SUBHEADLINE}
                            type="text"
                            label={<FormattedMessage id="randomDraw.video.subheadline.label" />}
                            maxLength={VIDEO_HEADLINE_MAX_LENGTH}
                            extend={{ formItem: rules.textField }}
                            showCounter
                        />
                    </div>
                    <ColorPickerField
                        name={RandomDrawField.COLOR}
                        colors={pickerColors}
                        label={<FormattedMessage id="randomDraw.video.color.label" />}
                        extend={{ formItem: rules.colorPicker }}
                        colorsRowCount={5}
                    />
                </Col>
            </Row>
        </RandomDrawCard>
    );
};

export default RandomDrawVideo;
