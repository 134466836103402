import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { FC } from 'react';

import { Panel, Button } from 'modules/ui';
import { TextField, Label, useFormSubmit, composeValidators, validatorsWithMessage, translate } from 'modules/form';
import { useUser } from 'modules/auth';

import { forms, ChangeEmailFormField } from '../../config';
import { changeUserAccount } from '../../services/actions';

import AccountFieldForm from '../AccountFieldForm';
import { useEditMode } from '../EditModeProvider';

const validators = {
    [ChangeEmailFormField.Email]: composeValidators(validatorsWithMessage.required, validatorsWithMessage.email),
};

export interface EditablePanelItemProps {}

const emailLabel = <FormattedMessage id="general.email" />;

const ChangeEmailForm: FC<EditablePanelItemProps> = () => {
    const { editMode, setEditMode, closeEditMode } = useEditMode();
    const intl = useIntl();

    const user = useUser();
    const email = user?.email;

    const onSubmit = useFormSubmit(changeUserAccount);
    const isEmailEditMode = editMode === forms.changeEmail;

    const editAction = (
        <Button onClick={() => setEditMode(forms.changeEmail)}>
            <FormattedMessage id="general.edit" />
        </Button>
    );

    return (
        <Panel.Item actions={isEmailEditMode ? null : [editAction]} disabled={editMode && !isEmailEditMode}>
            {isEmailEditMode ? (
                <AccountFieldForm
                    name={forms.changeEmail}
                    initialValues={{ [ChangeEmailFormField.Email]: email }}
                    onSubmit={onSubmit}
                    onSubmitSucceeded={closeEditMode}
                >
                    {({ dirty, submitting }) => (
                        <>
                            <TextField
                                name={ChangeEmailFormField.Email}
                                type="email"
                                label={emailLabel}
                                validate={translate(intl.formatMessage, validators[ChangeEmailFormField.Email])}
                                hasFeedback={false}
                            />

                            <AccountFieldForm.Buttons
                                onCancel={closeEditMode}
                                onSave={() => null}
                                dirty={dirty}
                                submitting={submitting}
                            />
                        </>
                    )}
                </AccountFieldForm>
            ) : (
                <Label label={emailLabel}>{email}</Label>
            )}
        </Panel.Item>
    );
};

export default ChangeEmailForm;
