import React, { FC } from 'react';
import { Card as AntdCard, CardProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Card.styles';
import Heading from '../Heading';

export interface CardOwnProps extends CardProps {
    selected?: boolean;
    clickable?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const Card: FC<CardOwnProps> = ({ title, selected = false, clickable = false, extend, children, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { selected, clickable, extend });

    return (
        <AntdCard {...props} title={<Heading level={5}>{title}</Heading>} className={styles.card}>
            {children}
        </AntdCard>
    );
};

export default Card;
