import { FC, ReactNode, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';

import { useFelaEnhanced } from 'hooks';
import { breakpoints } from 'styles/theme';

import type { Account, SocialPost } from 'modules/entities';
import { useErrorToast } from 'modules/errors';

import { POSTS_PAGE_SIZE } from '../../config';
import { useFetchPosts } from '../../hooks';
import { selectPosts } from '../../services/selectors';

import * as felaRules from './PostInfiniteList.styles';

const AutoSizer = lazy(() => import('react-virtualized-auto-sizer'));
const InfiniteLoader = lazy(() => import('react-window-infinite-loader'));
const Grid = lazy(() => import('react-window').then(module => ({ default: module.FixedSizeGrid })));

type PostWithPlaceholder = SocialPost | { placeholder?: boolean };

const createPlaceholders = (count: number): PostWithPlaceholder[] =>
    Array.from(Array(count).keys()).map(() => ({ placeholder: true }));
    
export interface PostInfiniteListProps {
    account: Account;
    postId?: SocialPost['id'];
    renderItem: (style: object, loading: boolean, post?: SocialPost) => ReactNode;
}

const PostInfiniteList: FC<PostInfiniteListProps> = ({ account, renderItem, postId }) => {
    const { styles, theme } = useFelaEnhanced(felaRules);

    const { inProgress, requestPosts, nextPageToken, error } = useFetchPosts(account.providerId, account.type);
    const posts = useSelector(state => selectPosts(state, { type: account.type, id: postId }));

    useErrorToast(error, 'randomDraw.posts.fetchError');

    const isItemLoaded = (index: number) => Boolean(posts[index]) || !nextPageToken;

    return (
        <div className={styles.infiniteList}>
            <Suspense fallback={null}>
                <AutoSizer>
                    {({ width, height }) => {
                        let columnCount = width > breakpoints.tablet ? 4 : 2;
                        if (width <= breakpoints.mobile) {
                            columnCount = 1;
                        }

                        let postsWithPlaceholders: PostWithPlaceholder[] = posts;
                        if (inProgress && posts.length === 0) {
                            postsWithPlaceholders = createPlaceholders(2 * columnCount);
                        } else if (inProgress) {
                            postsWithPlaceholders = postsWithPlaceholders.concat(createPlaceholders(columnCount));
                        }

                        const rowCount = Math.round(postsWithPlaceholders.length / columnCount);
                        const itemCount = nextPageToken
                            ? postsWithPlaceholders.length + columnCount
                            : postsWithPlaceholders.length;

                        return (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={requestPosts}
                                minimumBatchSize={POSTS_PAGE_SIZE}
                                threshold={columnCount * 2}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <Grid
                                        outerRef={ref}
                                        height={height}
                                        width={width}
                                        rowHeight={theme.sizes.infiniteList.rowHeight}
                                        columnWidth={width / columnCount}
                                        columnCount={columnCount}
                                        rowCount={rowCount}
                                        itemData={postsWithPlaceholders}
                                        overscanColumnCount={0}
                                        onItemsRendered={gridProps => {
                                            onItemsRendered({
                                                overscanStartIndex: gridProps.overscanRowStartIndex * columnCount,
                                                overscanStopIndex: gridProps.overscanRowStopIndex * columnCount,
                                                visibleStartIndex: gridProps.visibleRowStartIndex * columnCount,
                                                visibleStopIndex: gridProps.visibleRowStopIndex * columnCount,
                                            });
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        {({ columnIndex, rowIndex, style }) => {
                                            const itemIndex =
                                                rowIndex === 0 ? columnIndex : rowIndex * columnCount + columnIndex;
                                            const post = postsWithPlaceholders[itemIndex];
                                            const loading = post && 'placeholder' in post;

                                            return renderItem(
                                                style,
                                                loading,
                                                (post && 'placeholder' in post ? null : post) as SocialPost,
                                            );
                                        }}
                                    </Grid>
                                )}
                            </InfiniteLoader>
                        );
                    }}
                </AutoSizer>
            </Suspense>
        </div>
    );
};

export default PostInfiniteList;
