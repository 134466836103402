import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import { Form, FormProps } from 'modules/form';

import AccountFieldFormButtons from '../AccountFieldFormButtons';

import * as rules from './AccountFieldForm.styles';

interface AccountFieldFormProps extends FormProps {}

const AccountFieldForm: FC<AccountFieldFormProps> & { Buttons: typeof AccountFieldFormButtons } = props => {
    const { styles } = useFelaEnhanced(rules);

    return (
        <Form
            antdProps={{ layout: 'inline', className: styles.form }}
            extend={{ form: rules.formContainer }}
            {...props}
        />
    );
};

AccountFieldForm.Buttons = AccountFieldFormButtons;

export default AccountFieldForm;
