import { all, call, put, takeLatest } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiGet, ApiResponse } from 'config/antonio';

import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizePost } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import * as actions from '../actions';

type RequestAction = ReturnType<typeof actions.fetchPost.request>;
type CancelAction = ReturnType<typeof actions.fetchPost.cancel>;
type Operation = operations['postDetail'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestParams = Operation['parameters']['path'];

function* fetchPostHandler(action: RequestAction) {
    try {
        const response: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.postDetail,
            { uriParams: { id: action.meta.id } },
        );

        const { result: postId, entities } = normalizePost(response.data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.POSTS, {
                ids: [postId],
                byId: entities[EntityKey.POSTS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.MEDIA, entities[EntityKey.MEDIA]),
            actions.fetchPost.success(action.meta.id),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchPost.failure(action.meta.id, uiError));

        log.error(error);
    }
}

function* resetPost(action: CancelAction) {
    yield put(entitiesActions.removeEntity(EntityKey.POSTS, action.meta.id));
}

export default function* fetchPost() {
    yield all([
        takeLatestRequest(
            (action: RequestAction | CancelAction) => action.meta.id,
            {
                pattern: actions.fetchPost.request.toString(),
                handler: fetchPostHandler,
            },
            {
                pattern: actions.fetchPost.cancel.toString(),
                handler: resetPost,
            },
        ),
        takeLatest(actions.fetchPost.reset.toString(), resetPost),
    ]);
}
