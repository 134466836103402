import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';

import { Heading, TabsPage, Tabs } from 'modules/ui';
import { SeoHead } from 'modules/seo';
import { useErrorToast } from 'modules/errors';

import RandomDrawPosts from '../RandomDrawPosts';
import RandomDrawRules from '../RandomDrawRules';
import RandomDrawVideoForm from '../RandomDrawVideo';
import RandomDrawTabTitle from '../RandomDrawTabTitle';
import RandomDrawSummary from '../RandomDrawSummary';
import RandomDrawFooter from '../RandomDrawFooter';
import RandomDrawTabRedirect from '../RandomDrawTabRedirect';
import RandomDrawBackLink from '../RandomDrawBackLink';

import { generateRandomDrawPath, getTabIndex } from '../../services/utils';
import { useFirstInvalidTab, useRandomDrawSave } from '../../hooks';
import { RandomDrawTab } from '../../types';
import RandomDrawWizard from '../RandomDrawWizard';

const randomDrawTabValues = Object.values(RandomDrawTab);

const RandomDrawPage: FC = () => {
    const { tab } = useParams<{ tab?: RandomDrawTab }>();
    const intl = useIntl();
    const firstInvalidTab = useFirstInvalidTab();

    const { error } = useRandomDrawSave();
    useErrorToast(error, 'randomDraw.submitError');

    if (!tab || !randomDrawTabValues.includes(tab)) {
        return <Redirect to={generateRandomDrawPath(RandomDrawTab.POST)} />;
    }

    const pageTitle = intl.formatMessage({ id: 'posts.randomDraw.title' });
    const tabIndex = getTabIndex(firstInvalidTab);

    return (
        <RandomDrawWizard>
            <RandomDrawTabRedirect />
            <TabsPage
                backLink={<RandomDrawBackLink />}
                heading={
                    <Heading level={1}>
                        <FormattedMessage id="posts.randomDraw.title" />
                    </Heading>
                }
                activeKey={tab}
                footer={<RandomDrawFooter />}
            >
                <Tabs.TabPane
                    tab={<RandomDrawTabTitle tab={RandomDrawTab.POST} currentTabIndex={tabIndex} />}
                    key={RandomDrawTab.POST}
                >
                    <SeoHead title={`${intl.formatMessage({ id: 'randomDraw.tab.post' })} - ${pageTitle}`} />
                    <RandomDrawPosts />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={<RandomDrawTabTitle tab={RandomDrawTab.RULES} currentTabIndex={tabIndex} />}
                    key={RandomDrawTab.RULES}
                >
                    <SeoHead title={`${intl.formatMessage({ id: 'randomDraw.tab.rules' })} - ${pageTitle}`} />
                    <RandomDrawRules />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={<RandomDrawTabTitle tab={RandomDrawTab.VIDEO} currentTabIndex={tabIndex} />}
                    key={RandomDrawTab.VIDEO}
                >
                    <SeoHead title={`${intl.formatMessage({ id: 'randomDraw.tab.video' })} - ${pageTitle}`} />
                    <RandomDrawVideoForm />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={<RandomDrawTabTitle tab={RandomDrawTab.SUMMARY} currentTabIndex={tabIndex} />}
                    key={RandomDrawTab.SUMMARY}
                >
                    <SeoHead title={`${intl.formatMessage({ id: 'randomDraw.tab.summary' })} - ${pageTitle}`} />
                    <RandomDrawSummary />
                </Tabs.TabPane>
            </TabsPage>
        </RandomDrawWizard>
    );
};

export default RandomDrawPage;
