import { useDispatch } from 'react-redux';
import { oauthApi } from '../services/actions';

function useOAuthReset() {
    const dispatch = useDispatch();
    const handleReset = () => {
        dispatch(oauthApi.reset());
    };
    return handleReset;
}
export default useOAuthReset;
