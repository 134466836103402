import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import type { FormRenderProps } from 'react-final-form';
import { Skeleton } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { TextField } from 'modules/form';
import { ErrorAlert } from 'modules/errors';
import { Empty, Icon } from 'modules/ui';

import { ImageSearchField } from '../../config';
import type { ImageSearchFormValues, PixabayImage } from '../../types';
import useImageSearch from '../../hooks/useImageSearch';
import * as felaRules from './ImageSearch.styles';

export interface ImageSearchProps
    extends Pick<FormRenderProps<ImageSearchFormValues>, 'submitting' | 'submitError' | 'values' | 'handleSubmit'> {
    onImageSelect?: (image: PixabayImage) => void;
    hide?: boolean;
}

const placeholderImages = Array.apply(null, Array(15)).map((_, id) => ({ id }));

const ImageSearch: FC<ImageSearchProps> = ({
    onImageSelect,
    submitting,
    submitError,
    values,
    handleSubmit,
    hide = false,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { hide });

    const images = useImageSearch(values, handleSubmit);
    const displayPlaceholders = images.length === 0 || submitting;

    return (
        <div className={styles.container}>
            <TextField
                name={ImageSearchField.Query}
                label={<FormattedMessage id="pixabay.label" />}
                prefix={<Icon type={Icon.Types.SEARCH} />}
            />

            <ErrorAlert error={submitError}>
                <Empty show={images.length === 0 && !submitting} description={<FormattedMessage id="pixabay.empty" />}>
                    <ul className={styles.list}>
                        {(displayPlaceholders ? placeholderImages : images).map(image => (
                            <li key={image.id}>
                                <button
                                    type="button"
                                    className={styles.button}
                                    disabled={displayPlaceholders}
                                    onClick={() => onImageSelect(image)}
                                >
                                    {displayPlaceholders ? (
                                        <Skeleton.Image className={styles.image} />
                                    ) : (
                                        <img src={image.previewURL} alt={image.tags} className={styles.image} />
                                    )}
                                </button>
                            </li>
                        ))}
                    </ul>
                </Empty>
            </ErrorAlert>
        </div>
    );
};

export default ImageSearch;
