import { FC, lazy, Suspense } from 'react';
import { noop } from 'lodash';
import { Dropdown } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './EmojiPicker.styles';

const Picker = lazy(() => import('emoji-mart').then(module => ({ default: module.Picker })));

export interface EmojiPickerProps {
    onSelect?: (emoji: string) => void;
}

const EmojiPicker: FC<EmojiPickerProps> = ({ onSelect = noop }) => {
    const { styles, theme } = useFelaEnhanced(felaRules);

    return (
        <Suspense fallback={<>😀</>}>
            <Dropdown
                trigger={['click']}
                overlay={
                    <Picker
                        native
                        color={theme.colors.primary}
                        onSelect={emoji => {
                            onSelect(emoji.native);
                        }}
                    />
                }
            >
                <button onClick={e => e.stopPropagation()} className={styles.button}>
                    <span role="img" aria-label="Emoji picker">
                        😀
                    </span>
                </button>
            </Dropdown>
        </Suspense>
    );
};

export default EmojiPicker;
