import { put, take, select, call } from 'redux-saga/effects';

import config from 'config';
import { ApiGet, ApiResponse, authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { operations } from 'api/apiSchema';

import { actions as accountsActions } from 'modules/entities/modules/accounts';
import { createUIErrorMessage } from 'modules/errors';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import * as actions from '../actions';
import type { OAuthAddAccountPayload } from '../actions';
import { authUserSelector, selectAddAccountsRequestIds } from '../selectors';
import { OAuthProvider } from '../../types';

type ProviderType = operations['accountCreate']['requestBody']['content']['application/json']['type'];
type RequestAction = ReturnType<typeof actions.oauthAddAccountRequest>;
type AddAccountPayload = OAuthAddAccountPayload & { name: string; profilePicture: string };

type FacebokPagesResponse = ApiResponse<
    operations['getFacebookPages']['responses']['200']['content']['application/json']
>;
type FacebookPagesRequestParams = operations['getFacebookPages']['parameters']['query'];

type InstagramAccountsResponse = ApiResponse<
    operations['getInstagramAccounts']['responses']['200']['content']['application/json']
>;
type InstagramAccountsRequestParams = operations['getInstagramAccounts']['parameters']['query'];

function* fetchFacebookPages(accessToken: string) {
    const { data }: FacebokPagesResponse = yield call<ApiGet<FacebokPagesResponse, FacebookPagesRequestParams>>(
        authApi.get,
        config.api.endpoints.facebook.pages,
        {
            params: {
                accessToken,
            },
        },
    );

    return data;
}

function* fetchInstagramAccounts(accessToken: string) {
    const { data }: InstagramAccountsResponse = yield call<
        ApiGet<InstagramAccountsResponse, InstagramAccountsRequestParams>
    >(authApi.get, config.api.endpoints.instagram.accounts, {
        params: {
            accessToken,
        },
    });

    return data;
}

function* createAccount(payload: AddAccountPayload) {
    const { providerId, provider, accessToken, name, profilePicture } = payload;
    const user = yield select(authUserSelector);

    yield put(
        accountsActions.createAccount.request(payload.providerId, {
            providerId,
            type: provider as ProviderType,
            accessToken,
            userId: user.id,
            name,
            profilePicture,
        }),
    );

    const result = yield take([
        action => action.type === accountsActions.createAccount.success.toString() && action.meta.id === providerId,
        action => action.type === accountsActions.createAccount.failure.toString() && action.meta.id === providerId,
    ]);

    if (result.type === accountsActions.createAccount.failure.toString()) {
        throw result.error;
    }
}

function* handleOAuthAddAccount(action: RequestAction) {
    const { provider, accessToken } = action.payload;
    let data: AddAccountPayload[] = [];

    try {
        // Fetch FB pages or IG accounts and map payload
        if (provider === OAuthProvider.Facebook) {
            const pages: FacebokPagesResponse['data'] = yield call(fetchFacebookPages, accessToken);

            data = pages.map(page => ({
                provider,
                providerId: page.id,
                accessToken: page.access_token,
                name: page.name,
                profilePicture: page.picture.data.url,
            }));
        } else if (provider === OAuthProvider.Instagram) {
            const accounts: InstagramAccountsResponse['data'] = yield call(fetchInstagramAccounts, accessToken);

            data = accounts.map(account => ({
                provider,
                providerId: account.id,
                accessToken: account.accessToken,
                name: account.username,
                profilePicture: account.profile_picture_url,
            }));
        }

        // Create account for each page
        for (const payload of data) {
            yield put(actions.addAccountsRequestIds.add(payload.providerId));
            yield call(createAccount, payload);
        }

        yield put([actions.oauthApi.success(), actions.addAccountsRequestIds.clear()]);
    } catch (error) {
        const uiError = typeof error === 'string' ? error : createUIErrorMessage(error);
        yield put([actions.oauthApi.failure(uiError), actions.addAccountsRequestIds.clear()]);
        log.error(error);
    }
}

function* handleCancel() {
    const requestIds = yield select(selectAddAccountsRequestIds);

    if (requestIds.length > 0) {
        for (const id of requestIds) {
            yield put(accountsActions.createAccount.cancel(id));
        }
        yield put(actions.addAccountsRequestIds.clear());
    }
}

export default function* oauthAddAccount() {
    yield takeLatestRequest(
        () => 'oauthAddAccount',
        {
            pattern: actions.oauthAddAccountRequest.toString(),
            handler: handleOAuthAddAccount,
        },
        {
            pattern: actions.oauthApi.cancel.toString(),
            handler: handleCancel,
        },
    );
}
