import React, { FC } from 'react';
import { Avatar as AvatarAntd } from 'antd';
import type { AvatarProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Avatar.styles';

export interface AvatarOwnProps extends AvatarProps {
    extend?: RulesExtend<typeof felaRules>;
}

const Avatar: FC<AvatarOwnProps> = ({ extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <AvatarAntd {...props} className={styles.avatar} />;
};

export default Avatar;
