import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import * as entitiesSelectors from 'modules/entities/services/selectors';
import { FacebookPost, Schema } from 'modules/entities/types';
import { denormalizeFacebookPosts } from 'modules/entities/services/normalizr';
import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { selectProp } from 'services/selectors';

export const selectFacebookPostsApi = state => apiSelector(state, EntityKey.FACEBOOK_POSTS, ApiReducerKey.LIST);
export const selectFacebookPostApi = state => apiSelector(state, EntityKey.FACEBOOK_POSTS, ApiReducerKey.DETAIL);

export const selectFacebookPosts = createSelector(entitiesSelectors.selectFacebookPostsEntities, posts => {
    const denormalizedPosts: Array<Schema['FbPagePost']> = denormalizeFacebookPosts(posts[DEFAULT_GROUP], {
        [EntityKey.FACEBOOK_POSTS]: posts.byId,
    });

    return denormalizedPosts;
});

export const selectFacebookPost = createSelector(
    [entitiesSelectors.selectFacebookPostsEntities, selectProp<FacebookPost['id']>('id')],
    ({ id, posts }) => {
        return denormalizeFacebookPosts(id, {
            [EntityKey.FACEBOOK_POSTS]: posts.byId,
        });
    },
);
