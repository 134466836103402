import type { TRule } from 'fela';

export const mobileNavigation: TRule = () => ({
    padding: 0,
    margin: '0 0 0 1.5rem',

    lineHeight: 1,
    border: 'none',
    background: 'none',

    desktop: {
        display: 'none',
    },
});

export const close = () => ({
    '& svg': {
        width: 8,
        height: 8,
    },
});
