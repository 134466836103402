import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

type ErrorBoundaryProps = {
    fallback?: FC;
};

const ErrorBoundary: FC<ErrorBoundaryProps> = ({
    children,
    fallback = () => <FormattedMessage id="error.appCrash" />,
}) => <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>;

export default ErrorBoundary;
