import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { denormalizeMediaList } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';

import type { MediaGroup } from '../types';
import { Media } from 'modules/entities/types';

export const selectCreateMediaApi = state => apiSelector(state, EntityKey.MEDIA, ApiReducerKey.CREATE);

export const selectMedia = createSelector(
    [entitiesSelectors.selectMediaEntities, selectProp<MediaGroup>('group')],
    (media, group) => {
        const denormalizedMedia: Media[] = denormalizeMediaList(media[group], {
            [EntityKey.MEDIA]: media.byId,
        });

        return denormalizedMedia;
    },
);
