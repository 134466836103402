import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './DisabledContent.styles';

export interface DisabledContentProps {
    disabled?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const DisabledContent: FC<DisabledContentProps> = ({ disabled = false, extend, children }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, disabled });

    return <div className={styles.content}>{children}</div>;
};

export default DisabledContent;
