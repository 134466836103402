import { createSelector } from 'reselect';

import type { Schema } from 'modules/entities/types';
import type { AccountGroup } from 'modules/entities/types';
import { selectSelectedGroupId, selectAccountGroups } from 'modules/entities/modules/account-groups';

import { selectCompetitions } from './competitions';

export const selectRandomDrawDrafts = createSelector(
    selectCompetitions,

    competitions => {
        // @ts-ignore
        const randomDraws: Schema['RandomDrawResponseList'][] = competitions.filter(
            /* TODO: Remove when https://gitlab.ack.ee/Backend/rafflu/-/merge_requests/101 merged on BE*/
            /* @ts-ignore*/
            competition => competition.type === 'randomDraw' && competition.draft,
        );

        return randomDraws;
    },
);

export const selectRandomDrawLatestDraft = createSelector(
    selectSelectedGroupId,
    selectAccountGroups,
    selectRandomDrawDrafts,
    (groupId: AccountGroup['id'], groups: [AccountGroup], randomDraws) => {
        const selectedGroup = groups.filter(group => group.id === groupId);
        const latestRandomDraw = randomDraws
            .filter(randomDraw => {
                const post = randomDraw?.posts[0];
                return selectedGroup[0]?.accounts.some(account => account.id === post?.accountId);
            })
            .sort((lhs, rhs) => rhs.updatedAt.localeCompare(lhs.updatedAt))[0];

        return latestRandomDraw ? latestRandomDraw : null;
    },
);
