import type { FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use-storage';
import { isEqual } from 'lodash';

import { localStorageKeys } from 'constants/index';
import { Form, useFormSubmit } from 'modules/form';
import { useFetchAccountGroups } from 'modules/entities/modules/account-groups';
import { useResetUpdateCompetition } from 'modules/entities/modules/competitions';
import { forms, RandomDrawField, randomDrawFormInitialValues } from '../../config';
import { RandomDrawTab, RandomDrawFormValues } from '../../types';

import { useRandomDrawInitialValues } from '../../hooks';
import { getNextTab, generateRandomDrawPath } from '../../services/utils';
import { randomDrawForm } from '../../services/actions';

import { validateRandomDrawForm } from '../../validators';

const RandomDrawWizard: FC = ({ children }) => {
    const history = useHistory();
    const { tab } = useParams<{ tab?: RandomDrawTab }>();

    const [, setStoredValues, deleteStoredValues] = useLocalStorage<RandomDrawFormValues>(
        localStorageKeys.RANDOM_DRAW_FORM,
        null,
    );
    useFetchAccountGroups();
    useResetUpdateCompetition();
    const onSubmit = useFormSubmit(randomDrawForm);
    const initialValues = useRandomDrawInitialValues();

    const handleSubmit = (values: RandomDrawFormValues, form) => {
        const errors = validateRandomDrawForm(tab, values);

        if (errors) {
            return errors;
        }

        if (tab === RandomDrawTab.SUMMARY) {
            return onSubmit(values, form).then(errors => {
                if (!errors) {
                    deleteStoredValues();
                }

                return errors;
            });
        } else {
            const nextTab = getNextTab(tab);
            window.scrollTo(0, 0);
            setStoredValues(values);
            history.push(generateRandomDrawPath(nextTab));
        }
    };

    return (
        <Form<RandomDrawFormValues>
            name={forms.randomDraw}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            initialValuesEqual={isEqual}
            subscription={{}}
            mutators={{
                clear: ([field]: [RandomDrawField], state, utils) => {
                    utils.changeValue(state, field, () => randomDrawFormInitialValues[field]);
                    utils.resetFieldState(field);
                },
            }}
        >
            {() => <>{children}</>}
        </Form>
    );
};

export default RandomDrawWizard;
