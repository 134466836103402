import { TRuleWithTheme } from 'styles/theme';

export const select: TRuleWithTheme<{ isOpen: boolean }> = ({ theme, isOpen }) => ({
    width: '100%',

    '&:not(.ant-select-customize-input) .ant-select-selector': {
        borderRadius: theme.borderRadius.navItem,
    },

    '& .ant-select-arrow': {
        top: '52%',
        right: 24,
    },

    '& .ant-select-arrow .anticon': {
        color: theme.colors.textDark,
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
        transitionProperty: 'transform',
        transitionDuration: theme.transition.duration,
    },

    '&.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector': {
        padding: '0 1.25rem',
    },

    '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
        padding: '0 1.25rem',
    },

    '& .ant-select-selection-item': {
        display: 'flex',
    },

    '&.ant-select-single.ant-select-show-arrow .ant-select-selection-item': {
        paddingRight: '3rem',
    },

    '&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        borderColor: theme.colors.inputFocus,
        boxShadow: 'none',
    },
});

export const dropdown: TRuleWithTheme = ({ theme }) => ({
    padding: 0,
    borderRadius: theme.borderRadius.xlarge,
    boxShadow: theme.shadow.primaryBackgroundShadow,

    '& .ant-select-item': {
        padding: '0.75rem',
        margin: '0.25rem 0.5rem',
        borderRadius: theme.borderRadius.medium,
    },

    '& .ant-select-item-option': {
        alignItems: 'center',
    },
});

export const footer: TRuleWithTheme = () => ({
    padding: '1rem 1.25rem',
    marginBottom: '0.5rem',
});
