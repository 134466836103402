import bold from 'assets/fonts/Mulish-Bold.ttf';
import extraBold from 'assets/fonts/Mulish-ExtraBold.ttf';
import semiBold from 'assets/fonts/Mulish-SemiBold.ttf';

export default [
    {
        name: 'Mulish',
        files: [extraBold],
        options: {
            fontStyle: 'regular',
            fontWeight: 800,
            fontDisplay: 'swap',
        },
    },
    {
        name: 'Mulish',
        files: [bold],
        options: {
            fontStyle: 'regular',
            fontWeight: 700,
            fontDisplay: 'swap',
        },
    },
    {
        name: 'Mulish',
        files: [semiBold],
        options: {
            fontStyle: 'regular',
            fontWeight: 400,
            fontDisplay: 'swap',
        },
    },
];
