import { all, call, put, takeLatest } from 'redux-saga/effects';

import config from 'config';
import { api } from 'config/antonio';
import * as log from 'config/loglevel';

import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';
import { normalizeArticle } from 'modules/entities/services/normalizr';
import * as actions from '../actions';

type RequestAction = ReturnType<typeof actions.fetchArticle.request>;
type CancelAction = ReturnType<typeof actions.fetchArticle.cancel>;

function* fetchArticleHandler(action: RequestAction) {
    try {
        const response = yield call(api.get, config.wordpress.apiUrl + config.wordpress.endpoints.article, {
            uriParams: { postId: action.meta.id },
        });
        const { result: articleId, entities } = normalizeArticle(response.data);
        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ARTICLES, {
                ids: [articleId],
                byId: entities[EntityKey.ARTICLES],
                strategy: 'append',
            }),
            actions.fetchArticle.success(action.meta.id),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchArticle.failure(action.meta.id, uiError));

        log.error(error);
    }
}

function* resetArticle() {
    yield put(entitiesActions.resetEntitiesGroup(EntityKey.ARTICLES));
}

export default function* fetchArticle() {
    yield all([
        takeLatestRequest(
            (action: RequestAction | CancelAction) => action.meta.id,
            {
                pattern: actions.fetchArticle.request.toString(),
                handler: fetchArticleHandler,
            },
            {
                pattern: actions.fetchArticle.cancel.toString(),
                handler: resetArticle,
            },
        ),
        takeLatest(actions.fetchArticle.reset.toString(), resetArticle),
    ]);
}
