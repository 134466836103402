import type { TRuleWithTheme } from 'styles/theme';
import type { FormItemOwnProps } from './FormItem';

export const formItem: TRuleWithTheme<{ type: FormItemOwnProps['input']['type']; fluid: boolean }> = ({
    theme,
    type,
    fluid,
}) => ({
    width: '100%',

    '& .ant-form-item-explain': {
        fontSize: '0.8125rem',
        textAlign: 'left',

        paddingTop: 4,
        paddingLeft: 24,
    },

    '&.ant-form-item-has-error .ant-input:focus': {
        boxShadow: 'none',
    },

    '& .ant-input-suffix': {
        marginTop: -16,
    },

    '& .ant-input-affix-wrapper .ant-input': {
        // Fix of partialy visible cursor on empty password input
        paddingLeft: type === 'password' ? 3 : 27,
    },

    '& .ant-input': {
        fontSize: '1.125rem',
        lineHeight: 1.33,
        outlineColor: theme.colors.inputFocus,

        ':focus': {
            borderColor: theme.colors.inputFocus,
            boxShadow: 'none',
        },
    },

    extend: {
        condition: !fluid,
        style: {
            maxWidth: 420,
        },
    },
});
