import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { noop } from 'lodash';
import { Upload } from 'antd';
import type { RcFile } from 'antd/es/upload';

import { useFelaEnhanced } from 'hooks';
import { Button, Icon, Paragraph } from 'modules/ui';
import type { Media } from 'modules/entities';
import { PostMediaSkeleton, PostMediaThumbnail } from 'modules/post';

import * as felaRules from './PostMediaUpload.styles';
import { MEDIA_MAX_COUNT, PLACEHOLDERS_COUNT } from '../../config';

export interface PostMediaUploadProps {
    onChange?: (file: RcFile) => void;
    media?: Media[];
    uploading?: boolean;
}

const uploadProps = {
    multiple: true,
    accept: 'image/*',
    maxCount: MEDIA_MAX_COUNT,
    showUploadList: false,
};

const PostMediaUpload: FC<PostMediaUploadProps> = ({ media = [], onChange = noop, uploading = false }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const isVideo = media[0]?.filename?.endsWith('.mp4');
    const intl = useIntl();

    let thumbnailsCount = media.length === 0 ? PLACEHOLDERS_COUNT : media.length + 1;
    if (media.length === MEDIA_MAX_COUNT) {
        thumbnailsCount = media.length;
    } else if (isVideo) {
        thumbnailsCount = 0;
    }

    const handleChange = async (file: RcFile) => {
        if (media.length < MEDIA_MAX_COUNT) {
            onChange(file);
        }

        return false;
    };

    const renderThumbnail = (index: number) => {
        if (media.length === 0 || isVideo) {
            return null;
        }

        if (index < media.length) {
            return (
                <PostMediaThumbnail
                    src={media[index].url}
                    active={mainImageIndex === index}
                    onClick={() => setMainImageIndex(index)}
                />
            );
        }

        if (media.length < MEDIA_MAX_COUNT) {
            return (
                <Upload
                    {...uploadProps}
                    disabled={media.length === MEDIA_MAX_COUNT || uploading}
                    beforeUpload={handleChange}
                    className={styles.uploadButton}
                >
                    <Button
                        icon={<Icon type={Icon.Types.PLUS} size="small" />}
                        disabled={uploading}
                        loading={uploading}
                        extend={{ button: felaRules.button }}
                    />
                </Upload>
            );
        }
    };

    return (
        <PostMediaSkeleton
            thumbnailCount={thumbnailsCount}
            renderThumbnail={renderThumbnail}
            footer={
                !isVideo ? (
                    <Paragraph size="small" faded extend={{ paragraph: felaRules.footer }}>
                        <FormattedMessage id="post.media.photosCount" />
                        <span className={styles.footerCount}>
                            {media.length} / {MEDIA_MAX_COUNT}
                        </span>
                    </Paragraph>
                ) : null
            }
            extend={{ mainContent: felaRules.mainContent }}
        >
            {media.length === 0 ? (
                <Upload.Dragger
                    {...uploadProps}
                    className={styles.upload}
                    beforeUpload={handleChange}
                    disabled={uploading}
                >
                    <Button
                        type="primary"
                        icon={<Icon type={Icon.Types.UPLOAD} />}
                        disabled={uploading}
                        loading={uploading}
                    >
                        <FormattedMessage id="post.media.button" />
                    </Button>
                    <Paragraph size="small" faded>
                        <FormattedMessage id="post.media.uploadText" values={{ count: MEDIA_MAX_COUNT }} />
                    </Paragraph>
                </Upload.Dragger>
            ) : isVideo ? (
                <video src={media[mainImageIndex].url} controls className={styles.mainImage} />
            ) : (
                <img
                    src={media[mainImageIndex].url}
                    alt={intl.formatMessage({ id: 'post.mediaAlt' })}
                    className={styles.mainImage}
                />
            )}
        </PostMediaSkeleton>
    );
};

export default PostMediaUpload;
