import { TRuleWithTheme } from 'styles/theme';

import type { ListOwnProps } from './List';

export const list: TRuleWithTheme<Pick<ListOwnProps, 'bordered'>> = ({ theme, bordered }) => ({
    width: '100%',

    '& .ant-list-item + .ant-list-item': {
        marginTop: theme.spacing.xsmall,
    },

    '& .ant-list-item:last-child': {
        marginBottom: '1.5rem',
    },

    extend: [
        {
            condition: bordered,
            style: {
                '& .ant-list-item': {
                    border: `1px solid ${theme.colors.inputBorder}`,
                    borderRadius: theme.borderRadius.xlarge,
                },

                '&.ant-list-split .ant-list-item': {
                    borderBottom: `1px solid ${theme.colors.inputBorder}`,
                },
            },
        },
        {
            condition: !bordered,
            style: {
                '& .ant-list-item': {
                    padding: '0.75rem 0',
                },

                '&.ant-list-split .ant-list-item': {
                    borderBottom: 'none',
                },

                '& .ant-list-item + .ant-list-item': {
                    marginTop: 0,
                },
            },
        },
    ],
});
