import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateCompetition } from '../services/actions';

const useResetUpdateCompetition = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            // @ts-ignore
            dispatch(updateCompetition.reset());
        };
    }, [dispatch]);
};

export default useResetUpdateCompetition;
