import React, { FC } from 'react';
import { List, Skeleton } from 'antd';
import type { ListItemProps } from 'antd/es/list';

import { useFelaEnhanced } from 'hooks';

import ListItemMeta from '../ListItemMeta';

import * as felaRules from './ListItem.styles';

export interface ListItemOwnProps extends ListItemProps {
    loading?: boolean;
}

const ListItem: FC<ListItemOwnProps> & { Meta: typeof ListItemMeta } = ({ loading = false, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, props);

    return (
        <Skeleton active loading={loading} paragraph={null} className={styles.skeleton}>
            <List.Item {...props} className={styles.item} />
        </Skeleton>
    );
};

ListItem.Meta = ListItemMeta;

export default ListItem;
