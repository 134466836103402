import { createReducer } from '@reduxjs/toolkit';

import type { PixabayImage } from '../types';
import { setImages, resetImages } from './actions';

const initialState: PixabayImage[] = [];

const picturesReducer = createReducer(initialState, builder =>
    builder.addCase(setImages, (_, action) => action.payload).addCase(resetImages, () => initialState),
);

export default picturesReducer;
