import React, { FunctionComponent } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../config';

type RouterProps = { children: React.ReactNode };

const Router: FunctionComponent<RouterProps> = ({ children }) => (
    <ConnectedRouter history={history}>{children}</ConnectedRouter>
);

export default Router;
