import type { TRule } from 'fela';

export const button: TRule = () => ({
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',

    border: 'none',
    background: 'none',
    padding: 0,
    cursor: 'pointer',
});
