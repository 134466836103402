import type { TRuleWithTheme } from 'styles/theme';

export const row: TRuleWithTheme = ({ theme }) => ({
    ':not(:last-child)': {
        marginBottom: '2rem',
    },

    ':not(:first-child)': {
        paddingTop: theme.spacing.medium,
        borderTop: `1px solid ${theme.colors.separator}`,
    },
});
