import { FormattedMessage } from 'react-intl';
import { Button } from 'modules/ui';
import type { FC } from 'react';

interface RandomDrawDownloadVideoButtonProps {
    videoUrl: string;
    disabled?: boolean;
}

const RandomDrawDownloadVideoButton: FC<RandomDrawDownloadVideoButtonProps> = ({ videoUrl, disabled }) => {
    return (
        <a target="_blank" rel="noreferrer" href={videoUrl} download="video">
            <Button size="large" disabled={disabled}>
                <FormattedMessage id="randomDraw.results.button.download" />
            </Button>
        </a>
    );
};

export default RandomDrawDownloadVideoButton;
