import type { FC } from 'react';
import { Field } from 'react-final-form';

import type { FormFieldProps } from '../types';
import Counter, { CounterProps } from '../Counter/Counter';

export interface CounterFieldProps extends FormFieldProps<number>, Pick<CounterProps, 'minCount' | 'maxCount'> {}

const CounterField: FC<CounterFieldProps> = ({ label, required = false, minCount, maxCount, ...props }) => (
    <Field<number> subscription={{ value: true }} {...props}>
        {({ input }) => (
            <Counter
                count={input.value || minCount}
                onChange={input.onChange}
                disabled={props.disabled}
                minCount={minCount}
                maxCount={maxCount}
            />
        )}
    </Field>
);

export default CounterField;
