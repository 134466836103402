import { call, put, select, takeEvery } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiPost, ApiResponse } from 'config/antonio';
import { operations } from 'api/apiSchema';

import { authUserSelector } from 'modules/auth';
import { createUIErrorMessage } from 'modules/errors';
import { normalizeMedia } from 'modules/entities/services/normalizr';
import { DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import * as actions from '../actions';

type RequestActions = ReturnType<typeof actions.createMedia.request>;
type Operation = operations['mediaUpload'];
type Response = ApiResponse<Operation['responses']['201']['content']['application/json']>;

function* createMediaHandler(action: RequestActions) {
    const user = yield select(authUserSelector);

    const body = {
        ...action.payload.file,
        userId: user.id,
    };

    const formData = Object.keys(body).reduce((formData, key) => {
        formData.append(key, body[key]);
        return formData;
    }, new FormData());

    try {
        const { data }: Response = yield call<ApiPost<Response, FormData>>(
            authApi.post,
            config.api.endpoints.media,
            formData,
        );

        const result = normalizeMedia(data[0]);
        const { result: mediaId, entities } = result;

        yield put(
            entitiesActions.setEntitiesGroup(EntityKey.MEDIA, {
                group: action.payload.group ?? DEFAULT_GROUP,
                ids: [mediaId],
                byId: entities[EntityKey.MEDIA],
                strategy: 'append',
            }),
        );

        // Don't batch this action so it can be used with take effect
        yield put(
            actions.createMedia.success(undefined, {
                id: mediaId,
                lastSuccessAt: new Date().toISOString(),
            }),
        );
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.createMedia.failure(uiError));

        log.error(error);
    }
}

export default function* createMedia() {
    yield takeEvery(actions.createMedia.request.toString(), createMediaHandler);
}
