import type { TRuleWithTheme } from 'styles/theme';
import type { RadioButtonCircleProps } from './RadioButtonCircle';

export const radio: TRuleWithTheme<Pick<RadioButtonCircleProps, 'checked'>> = ({ theme, checked }) => ({
    height: 24,
    width: 24,
    borderRadius: '50%',
    backgroundColor: theme.colors.white,
    display: 'inline-block',
    visibility: 'visible',
    border: `1px solid ${checked ? theme.colors.white : theme.colors.inputBorder}`,
    position: 'relative',
    marginLeft: '2rem',

    '& svg': {
        display: checked ? 'block' : 'none',
        height: '0.5625rem',
        width: '0.6875rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

export const radioCircle: TRuleWithTheme<Pick<RadioButtonCircleProps, 'checked'>> = ({ theme, checked }) => ({
    height: 12,
    width: 12,
    backgroundColor: checked ? theme.colors.primary : 'transparent',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
});
