import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { RcFile } from 'antd/es/upload';
import type { ApiReducerState } from '@ackee/redux-utils';

import { SocialProvider } from 'constants/index';
import { actions as mediaActions, selectCreateMediaApi } from 'modules/entities/modules/media';
import { useErrorToast } from 'modules/errors';

import { selectNewPostMediaFactory } from '../services/selectors';
import { PlanPostLocationState } from '../types';
import { providerGroupMap } from '../constants';

const useCreatePostMedia = (type: SocialProvider) => {
    const dispatch = useDispatch();
    const location = useLocation<PlanPostLocationState>();
    const postId = location.state?.postId;
    const competitionId = location.state?.competitionId;

    const group = providerGroupMap[type];
    const selectMedia = useMemo(() => selectNewPostMediaFactory(group, postId, competitionId), [
        group,
        postId,
        competitionId,
    ]);

    const media = useSelector(selectMedia);

    const api: ApiReducerState = useSelector(selectCreateMediaApi);
    useErrorToast(api.error, 'error.api.media.create');

    const uploadMedia = (file: RcFile) => {
        dispatch(
            mediaActions.createMedia.request({
                group,
                file: {
                    type: 'post',
                    name: file.name,
                    // File type is fine here
                    // @ts-ignore
                    filename: file,
                },
            }),
        );
    };

    return { ...api, media, uploadMedia };
};

export default useCreatePostMedia;
