import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import { EntityKey, DEFAULT_GROUP, ApiReducerKey } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { denormalizeAccountGroup, denormalizeAccountGroups } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';
import type { Schema } from 'modules/entities/types';

export const selectAccountGroupsApi = state => apiSelector(state, EntityKey.ACCOUNT_GROUPS, ApiReducerKey.LIST);
export const selectAddAccountGroupApi = state => apiSelector(state, EntityKey.ACCOUNT_GROUPS, ApiReducerKey.CREATE);
export const selectUpdateAccountGroupApi = state => apiSelector(state, EntityKey.ACCOUNT_GROUPS, ApiReducerKey.UPDATE);

export const selectAccountGroupsById = createSelector(
    entitiesSelectors.selectAccountGroupsEntities,
    accountGroups => accountGroups.byId,
);

export const selectAccountGroups = createSelector(
    [entitiesSelectors.selectAccountGroupsEntities, entitiesSelectors.selectAccountsEntities],
    (accountGroups, accounts) => {
        const denormalizedAccountGroups: Array<Schema['AccountGroup']> = denormalizeAccountGroups(
            accountGroups[DEFAULT_GROUP],
            {
                [EntityKey.ACCOUNTS]: accounts.byId,
                [EntityKey.ACCOUNT_GROUPS]: accountGroups.byId,
            },
        );

        return denormalizedAccountGroups;
    },
);

export const selectAccountGroup = createSelector(
    [entitiesSelectors.selectAccountGroupsEntities, selectProp<Schema['AccountGroup']['id']>('id')],
    (accountGroups, id) => accountGroups.byId[id],
);

export const selectAccountGroupDetail = createSelector(
    [
        entitiesSelectors.selectAccountGroupsEntities,
        entitiesSelectors.selectAccountsEntities,
        selectProp<Schema['AccountGroup']['id']>('id'),
    ],
    (accountGroups, accounts, id) =>
        denormalizeAccountGroup(id, {
            [EntityKey.ACCOUNT_GROUPS]: accountGroups.byId,
            [EntityKey.ACCOUNTS]: accounts.byId,
        }),
);

export const selectGroupAccounts = createSelector(selectAccountGroupDetail, group => group?.accounts ?? []);

export const selectSelectedGroupId = state => state[EntityKey.ACCOUNT_GROUPS].selectedGroup.id;

export const selectAccountGroupByAccount = createSelector(
    entitiesSelectors.selectAccountsEntities,
    selectSelectedGroupId,
    selectProp<Schema['AccountGroup']['id']>('id'),

    (accounts, groupId, accountId) =>
        Object.values(accounts.byId).filter(account =>
            accountId
                ? account.accountGroupId === accounts.byId[accountId]?.accountGroupId
                : account.accountGroupId === groupId,
        ),
);
export const selectOldestAccountGroup = createSelector(
    entitiesSelectors.selectAccountGroupsEntities,
    groups => Object.values(groups.byId).sort((lhs, rhs) => lhs.createdAt.localeCompare(rhs.createdAt))[0],
);
