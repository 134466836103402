import { Field } from 'react-final-form';

import { FormFieldProps } from 'modules/form/components/types';

import FormItem, { FormItemOwnProps } from '../FormItem';

export interface GenericFieldProps<Value> extends FormFieldProps<Value> {
    fluid?: boolean;
    extend?: FormItemOwnProps['extend'];
}

const GenericField = <Value extends any>({ name, fluid, children, extend, ...props }: GenericFieldProps<Value>) => (
    <Field name={name} validateFields={[]} subscription={{ submitError: true, error: true, touched: true }} {...props}>
        {({ input, meta }) => (
            <FormItem input={input} meta={meta} fluid={fluid} extend={extend}>
                {children}
            </FormItem>
        )}
    </Field>
);

export default GenericField;
