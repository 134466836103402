import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import logoWhite from 'assets/images/logo-white.png';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './VideoPreview.styles';
import { RandomDrawColors } from '../../constants';

export interface VideoPreviewProps {
    color: RandomDrawColors;
    headline?: string;
    subHeadline?: string;
}

const VideoPreview: FC<VideoPreviewProps> = ({ headline = '', subHeadline = '', color }) => {
    const { styles } = useFelaEnhanced(felaRules, { color });
    const intl = useIntl();

    return (
        <div className={styles.preview}>
            <div className={styles.subHeadline}>{subHeadline}</div>
            <div className={styles.headline}>{headline}</div>
            <div className={styles.footer}>
                <div className={styles.stats}>
                    <span className={styles.column}>
                        <span className={styles.count}></span>
                        <FormattedMessage id="randomDraw.video.participants" />
                    </span>
                    <span className={styles.column}>
                        <span className={styles.count}></span>
                        <FormattedMessage id="randomDraw.video.correctAnswers" />
                    </span>
                </div>
                <img alt={intl.formatMessage({ id: 'app.name' })} src={logoWhite} height={16} />
            </div>
        </div>
    );
};

export default VideoPreview;
