import type { TRuleWithTheme } from 'styles/theme';
import type { ScrollDirection } from 'hooks/useScrollOffset';

type StyleProps = { isScrolled: boolean; direction: ScrollDirection; menuOpen: boolean };

export const header: TRuleWithTheme<StyleProps> = ({ theme, isScrolled, direction, menuOpen }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,

    transform: `translateY(${direction === 'top' ? 0 : -100}%)`,

    paddingBottom: isScrolled ? theme.spacing.xsmall : '0.625rem',

    borderBottomLeftRadius: theme.borderRadius.header,
    borderBottomRightRadius: theme.borderRadius.header,
    transitionProperty: 'background-color, box-shadow, padding, transform',
    transitionDuration: theme.transition.duration,

    extend: [
        {
            paddingTop: isScrolled ? theme.spacing.xsmall : '1.5rem',

            backgroundColor: isScrolled ? theme.colors.white : 'transparent',
            boxShadow: isScrolled ? theme.shadow.header : 'none',
        },
        {
            condition: menuOpen,
            style: {
                backgroundColor: theme.colors.backgroundPrimary,
                boxShadow: theme.shadow.primaryBackgroundShadow,
            },
        },
    ],

    desktop: {
        width: 'calc(100% - 2.5rem)',

        transform: `translateY(0)`,

        paddingTop: isScrolled ? 0 : '1.625rem',
        paddingBottom: isScrolled ? 0 : '0.625rem',

        margin: '0 1.25rem',
    },
});

export const container: TRuleWithTheme<StyleProps> = ({ menuOpen }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: menuOpen ? 'initial' : 'wrap',

    desktop: {
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
    },
});

export const menu: TRuleWithTheme<StyleProps> = ({ menuOpen, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: menuOpen ? 0 : 1,
    order: 3,
    flexDirection: menuOpen ? 'column' : 'row',
    maxWidth: '100%',
    flexBasis: '100%',
    
    // The value is random, it must be more than max expected height
    maxHeight: menuOpen ? '300px' : 0,
    
    overflow: `hidden`,
    
    transitionProperty: 'max-height',
    transitionDuration: theme.transition.duration,
    
    desktop: {
        maxHeight: 'initial',
        flexBasis: 'initial',
        order: 2,
        overflow: 'visible',
    },
});

export const userWrapper: TRuleWithTheme<StyleProps> = ({ menuOpen }) => ({
    display: menuOpen ? 'none' : 'initial',
});

export const rightColumn: TRuleWithTheme<StyleProps> = ({ isScrolled, menuOpen }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',

    order: 2,

    padding: isScrolled ? '0.375rem' : 0,

    desktop: {
        marginLeft: 56,
        order: 3,
    },
});
