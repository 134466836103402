import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils';

import { Account, SocialPost } from 'modules/entities';
import { actions as instagramActions, selectInstagramPostApi } from 'modules/entities/modules/instagram-posts';
import { actions as facebookActions, selectFacebookPostApi } from 'modules/entities/modules/facebook-posts';
import { OAuthProvider } from 'modules/auth';

const actions = {
    [OAuthProvider.Instagram]: instagramActions,
    [OAuthProvider.Facebook]: facebookActions,
};

const selectors = {
    [OAuthProvider.Instagram]: selectInstagramPostApi,
    [OAuthProvider.Facebook]: selectFacebookPostApi,
};

const useFetchSocialPost = (platform: Account['type'], providerId: Account['providerId'], postId: SocialPost['id']) => {
    const api: ApiReducerState = useSelector(selectors[platform]);
    const dispatch = useDispatch();

    useEffect(() => {
        const ids = { id: providerId, postId };
        dispatch(actions[platform].fetchPost.request(ids));

        return () => {
            batch(() => {
                dispatch(actions[platform].fetchPost.cancel(ids));
                dispatch(actions[platform].fetchPost.reset(ids));
            });
        };
    }, [dispatch, providerId, postId, platform]);

    return api;
};

export default useFetchSocialPost;
