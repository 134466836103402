import type { TRule } from 'fela';
import type { Theme } from 'styles/theme';

export const illustration: TRule = () => ({
    marginBottom: '1rem',
});

export const card: TRule<{ theme: Theme }> = ({ theme }) => ({
    boxShadow: theme.shadow.cardActive,
    height: '100%',

    '& .ant-card-head': {
        minHeight: 'unset',
    },

    '& .ant-card-body': {
        display: 'flex',
        alignItems: 'center',
        padding: '.8125rem 1.75rem 2rem',
    },
});
