import type { FC } from 'react';
import {
    Calendar as BigCalendar,
    dateFnsLocalizer,
    Formats,
    Event as CalendarEvent,
    DateLocalizer,
} from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { cs, enUS } from 'date-fns/locale';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import CalendarToolbar from './CalendarToolbar';
import * as felaRules from './Calendar.styles';
import type { RulesExtend } from 'styles/theme';

const locales = {
    cs,
    en: enUS,
};

const localizer: DateLocalizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const formats: Formats = {
    monthHeaderFormat: 'LLLL yyyy',
};

interface CalendarProps {
    events: CalendarEvent[];
    extend?: RulesExtend<typeof felaRules>;
}

const Calendar: FC<CalendarProps> = ({ events = [], extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    const intl = useIntl();

    return (
        <BigCalendar
            localizer={localizer}
            formats={formats}
            events={events}
            culture={intl.locale ?? intl.defaultLocale}
            startAccessor="start"
            endAccessor="end"
            className={styles.calendar}
            views={{
                month: true,
                week: true,
            }}
            components={{ toolbar: CalendarToolbar }}
        />
    );
};

export default Calendar;
