import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Form, useFormSubmit } from 'modules/form';

import { forms, ImageSearchField } from '../../config';
import type { ImageSearchFormValues } from '../../types';
import { searchImages } from '../../services/actions';
import ImageSearch from '../ImageSearch';
import type { ImageSearchProps } from '../ImageSearch';

type ImageSearchFormProps = Pick<ImageSearchProps, 'onImageSelect'> & { hide?: boolean };

const ImageSearchForm: FC<ImageSearchFormProps> = props => {
    const intl = useIntl();
    const submit = useFormSubmit<ImageSearchFormValues>(searchImages, { locale: intl.locale });

    return (
        <Form<ImageSearchFormValues>
            name={forms.imageSearch}
            onSubmit={submit}
            subscription={{ submitting: true, submitError: true, values: true }}
            initialValues={{
                [ImageSearchField.Query]: '',
            }}
        >
            {formState => <ImageSearch {...props} {...formState} />}
        </Form>
    );
};

export default ImageSearchForm;
