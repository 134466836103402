import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiGet, ApiResponse } from 'config/antonio';

import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeCompetition } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import * as actions from '../actions';
import { selectDenormalizedCompetition } from '../selectors';

type RequestAction = ReturnType<typeof actions.fetchCompetition.request>;
type CancelAction = ReturnType<typeof actions.fetchCompetition.cancel>;
type Operation = operations['competitionDetail'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestParams = Operation['parameters']['path'];

function* fetchCompetitionRequest(action: RequestAction) {
    try {
        const response: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.competitionDetail,
            { uriParams: { id: action.meta.id } },
        );
        const { result: id, entities } = normalizeCompetition(response.data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.COMPETITIONS, {
                ids: [id],
                byId: entities[EntityKey.COMPETITIONS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.POSTS, entities[EntityKey.POSTS]),
            entitiesActions.setEntities(EntityKey.VIDEO_SETTINGS, entities[EntityKey.VIDEO_SETTINGS]),
        ]);

        yield put(actions.fetchCompetition.success(action.meta.id));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchCompetition.failure(action.meta.id, uiError));

        log.error(error);
    }
}

function* resetCompetition(action: CancelAction) {
    const competition = yield select(state => selectDenormalizedCompetition(state, { id: action.meta.id }));

    yield put([
        entitiesActions.removeEntity(EntityKey.COMPETITIONS, action.meta.id),
        entitiesActions.removeEntity(EntityKey.VIDEO_SETTINGS, competition.videoSettings?.shift()?.id),
    ]);
}

export default function* fetchCompetition() {
    yield all([
        takeLatestRequest(
            (action: RequestAction | CancelAction) => action.meta.id,
            {
                pattern: actions.fetchCompetition.request.toString(),
                handler: fetchCompetitionRequest,
            },
            {
                pattern: actions.fetchCompetition.cancel.toString(),
                handler: noop,
            },
        ),
        takeLatest(actions.fetchCompetition.reset.toString(), resetCompetition),
    ]);
}
