import React, { FunctionComponent } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Button as AntdButton, ButtonProps } from 'antd';

import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Button.styles';

export interface ButtonOwnProps extends ButtonProps {
    inverse?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const Button: FunctionComponent<ButtonOwnProps> = ({
    children,
    extend,
    size,
    type = 'default',
    inverse = false,
    block,
    shape,
    ...props
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, size, type, inverse, shape, block });

    return (
        <AntdButton {...props} type={type} size={size} shape={shape} block={block} className={styles.button}>
            {children}
        </AntdButton>
    );
};

export default Button;
