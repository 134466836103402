import { useSelector } from 'react-redux';

import { selectArticle } from '../services/selectors';
import type { Article } from 'modules/entities/types';

export default function useArticle(id: Article['id']) {
    const article = useSelector(state => selectArticle(state, { id }));

    return article;
}
