import { combineReducers } from 'redux';

import { ApiReducerKey } from 'modules/entities/constants';

import list from './list';
import detail from './detail';

export default combineReducers({
    [ApiReducerKey.DETAIL]: detail,
    [ApiReducerKey.LIST]: list,
});
