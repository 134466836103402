import React, { FC } from 'react';
import { combineRules } from 'fela';

import type { RulesExtend } from 'styles/theme';

import Button from '../Button';
import Icon from '../Icon';

import type { ButtonOwnProps } from '../Button';
import type { Types, IconProps } from '../Icon/Icon';

import * as felaRules from './IconTextButton.styles';

export interface IconTextButtonProps extends Omit<ButtonOwnProps, 'type' | 'icon' | 'shape' | 'block' | 'extend'> {
    icon: Types;
    iconSize?: IconProps['size'];
    color?: IconProps['circleColor'];
    extend?: RulesExtend<typeof felaRules>;
}

const IconTextButton: FC<IconTextButtonProps> = ({
    size,
    icon,
    iconSize = 'medium',
    color,
    extend,
    children,
    ...props
}) => (
    <Button
        {...props}
        type="link"
        size={size}
        icon={<Icon type={icon} circle circleColor={color} size={iconSize} extend={{ icon: extend?.icon }} />}
        extend={{ button: combineRules(felaRules.button, extend?.button) }}
    >
        {children}
    </Button>
);

export default IconTextButton;
