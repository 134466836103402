import type { RadioProps } from 'antd';
import { TRuleWithTheme } from 'styles/theme';

export const radioButton: TRuleWithTheme<Pick<RadioProps, 'checked' | 'disabled'>> = ({
    theme,
    checked,
    disabled,
}) => ({
    padding: '.75rem 1.25rem',
    display: 'inline-flex',
    alignItems: 'center',
    border: `1px solid ${checked ? theme.colors.primarySelected : theme.colors.inputBorder}`,
    borderRadius: theme.borderRadius.large,
    backgroundColor: checked ? theme.colors.primarySelected : 'transparent',
    boxShadow: checked ? theme.shadow.cardActive : 'unset',
    cursor: disabled ? 'no-drop' : 'pointer',
    transitionDuration: theme.transition.duration,

    '&:hover': {
        borderColor: theme.colors.primarySelected,
        boxShadow: theme.shadow.cardHover,
    },

    '& > span:last-child': {
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 auto',
    },

    '& .ant-radio': {
        order: 1,
        position: 'relative',
        display: 'none',
    },

    '& .ant-badge': {
        marginRight: '.75rem',
    },

    extend: {
        condition: !disabled,
        style: {
            ':hover': {
                borderColor: theme.colors.primarySelected,
                boxShadow: theme.shadow.cardHover,
            },
        },
    },
});
