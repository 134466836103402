import { call, put } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiPost, ApiResponse } from 'config/antonio';

import type { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizePosts } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import { createPost as apiActions } from '../actions';

type Operation = operations['postCreate'];
type Response = ApiResponse<Operation['responses']['201']['content']['application/json']>;
type RequestBody = Operation['requestBody']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* createPostHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiPost<Response, RequestBody>>(
            authApi.post,
            config.api.endpoints.posts,
            action.payload,
        );

        const result = normalizePosts(data);
        const { result: ids, entities } = result;

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.POSTS, {
                ids,
                byId: entities[EntityKey.POSTS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.MEDIA, entities[EntityKey.MEDIA]),
        ]);

        yield put(
            apiActions.success(undefined, {
                id: ids[0], // For now this is fine, we only support one account for a post
                lastSuccessAt: new Date().toISOString(),
            }),
        );
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(uiError));

        log.error(error);
    }
}

export default function* createPost() {
    yield takeLatestRequest(
        () => 'createPost',
        {
            pattern: apiActions.request.toString(),
            handler: createPostHandler,
        },
        {
            pattern: apiActions.cancel.toString(),
            handler: noop,
        },
    );
}
