import { useEffect, useRef, useState } from 'react';

export enum VideoState {
    STOPPED = 'stopped',
    PLAYING = 'playing',
    PAUSED = 'paused',
}

const useVideo = () => {
    const [videoState, setVideoState] = useState<VideoState>(VideoState.STOPPED);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const video = videoRef.current;

        const handlePlay = () => setVideoState(VideoState.PLAYING);
        const handlePause = () => setVideoState(VideoState.PAUSED);
        const handleEnded = () => setVideoState(VideoState.STOPPED);

        video.addEventListener('play', handlePlay);
        video.addEventListener('pause', handlePause);
        video.addEventListener('ended', handleEnded);

        return () => {
            video.removeEventListener('play', handlePlay);
            video.removeEventListener('pause', handlePause);
            video.removeEventListener('ended', handleEnded);
        };
    }, []);

    const play = () => videoRef.current.play();
    const pause = () => videoRef.current.pause();

    return {
        state: videoState,
        play,
        pause,

        ref: videoRef,
    };
};

export default useVideo;
