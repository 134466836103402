import type { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme }) => ({
    border: 'none',
    position: 'relative',
    marginTop: '1.375rem',
    marginBottom: '1.375rem',

    ':hover': {
        cursor: 'pointer',
    },

    '& .ant-card-head-title': {
        padding: 0,
    },

    '& .ant-card-body': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
        ':after': {
            display: 'none',
        },
        ':before': {
            display: 'none',
        },
    },

    '& .ant-card-cover img': {
        borderRadius: theme.borderRadius.xlarge,
        border: `1px solid ${theme.colors.inputBorder}`,
        position: 'relative',
    },

    '& .ant-card-cover': {
        position: 'relative',
        margin: '0 0 0.625rem 0',
    },
});

export const activeOverflow: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.primarySelectedWithOpactiy,
    border: `1px solid ${theme.colors.inputBorder}`,
    borderRadius: theme.borderRadius.xlarge,

    ':after': {
        position: 'absolute',
        top: -6,
        left: -6,
        right: -6,
        bottom: -6,
        content: '" "',
        backgroundColor: 'transparent',
        borderRadius: theme.borderRadius.xlarge,
        border: `2px solid ${theme.colors.primarySelected}`,
    },
});

export const commentCount: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textLight,
});

export const commentIcon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textLight,
    height: 11,

    '& svg': {
        height: 11,
        width: 11,
    },
});
