import { EntityKey } from 'modules/entities';

import { reducer as auth, petrusReducer as petrus } from 'modules/auth';
import { reducer as pixabay } from 'modules/pixabay';

import api from './api';
import entities from './entities';
import { reducer as accountGroups } from 'modules/entities/modules/account-groups';

// NOTE:
// Do not use combineReducers() here,
// export reducers as plain object, not as a function.
const reducers = {
    auth,
    petrus,
    api,
    entities,
    [EntityKey.ACCOUNT_GROUPS]: accountGroups,
    pixabay,
};

export default reducers;
