import type { TRuleWithTheme } from 'styles/theme';

export const floatingCard: TRuleWithTheme = ({ theme }) => ({
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    boxShadow: theme.shadow.cardActive,

    '& .ant-card-head-title': {
        padding: 0,
    },

    '& .ant-card-body': {
        paddingLeft: '1.125rem',
        paddingRight: '1.125rem',
        paddingBottom: '1.125rem',
        paddingTop: '1.125rem',
    },
});
