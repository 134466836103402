import * as loglevel from 'loglevel';
import * as Sentry from '@sentry/react';
import { omit, isEmpty } from 'lodash';
import { isEnvProduction, enableSentry } from 'constants/index';

isEnvProduction ? loglevel.disableAll() : loglevel.enableAll();

export const { warn, info, trace, debug } = loglevel;

const forbiddenExtras = ['authorization'];

export const error = (err: Error | string | object, extra = {}) => {
    const extras = typeof extra === 'object' ? omit(extra, forbiddenExtras) : { extra };

    if (isEmpty(extras)) {
        loglevel.error(err);
    } else {
        loglevel.error(err, extras);
    }

    if (enableSentry) {
        Sentry.withScope(scope => {
            scope.setExtras(extras);
            Sentry.captureException(err);
        });
    }
};
