import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { denormalizePost } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';

import type { Post } from 'modules/entities/types';

export const selectCreatePostApi = state => apiSelector(state, EntityKey.POSTS, ApiReducerKey.CREATE);
export const selectPostApi = state => apiSelector(state, EntityKey.POSTS, ApiReducerKey.DETAIL);
export const selectUpdatePostApi = state => apiSelector(state, EntityKey.POSTS, ApiReducerKey.UPDATE);

export const selectPostsById = createSelector(entitiesSelectors.selectPostsEntities, posts => posts.byId);

export const selectPostDetail = createSelector(
    [entitiesSelectors.selectPostsEntities, entitiesSelectors.selectMediaEntities, selectProp<Post['id']>('id')],
    (posts, media, id) =>
        denormalizePost(id, {
            [EntityKey.POSTS]: posts.byId,
            [EntityKey.MEDIA]: media.byId,
        }),
);
