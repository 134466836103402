import { put, takeEvery, call } from 'redux-saga/effects';
import { Action } from 'services/actions';
import config from 'config';
import { api } from 'config/antonio';
import type { ApiPost } from 'config/antonio';
import type { operations } from 'api/apiSchema';

import { createUIErrorMessage } from 'modules/errors';

import { passwordRecoveryForm as passwordRecoveryFormActions } from '../actions';
import { PasswordRecoveryFormValues } from '../../types';
import { PasswordRecoveryFormField } from '../../config';

type Operation = operations['recoverPassword'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type Response = Operation['responses']['200'];

export function* handlePasswordRecoveryForm({ payload }: Action<PasswordRecoveryFormValues>) {
    try {
        yield call<ApiPost<Response, RequestBody>>(api.post, config.api.endpoints.passwordRecovery, {
            email: payload[PasswordRecoveryFormField.Email],
        });

        yield put(passwordRecoveryFormActions.submitSuccess());
    } catch (e) {
        const uiError = createUIErrorMessage(e);
        yield put(passwordRecoveryFormActions.submitFailure(uiError));
    }
}

export default function* passwordRecovery() {
    yield takeEvery(passwordRecoveryFormActions.submit.toString(), handlePasswordRecoveryForm);
}
