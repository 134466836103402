import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'antd';

import { generateRandomDrawPath } from 'modules/random-draw/services/utils';
import newPostIllustration from 'assets/images/plan_new_post_illustration.svg';
import newGiveawayIllustration from 'assets/images/plan_new_giveaway_illustration.svg';
import randomDrawIllustration from 'assets/images/random_draw_illustration.svg';

import { ComingSoonBadge } from 'modules/ui';

import PlanPostCard from '../PlanPostCard';
import type { PlanPostCardProps } from '../PlanPostCard/PlanPostCard';
import config from 'config';

const cards: PlanPostCardProps[] = [
    {
        heading: <FormattedMessage id="posts.planNewPost.title" />,
        description: <FormattedMessage id="posts.planNewPost.description" />,
        illustration: newPostIllustration,
        link: config.routes.newPost,
    },
    {
        heading: <FormattedMessage id="posts.planNewGiveaway.title" />,
        description: <FormattedMessage id="posts.planNewGiveaway.description" />,
        illustration: newGiveawayIllustration,
        comingSoon: true,
    },
    {
        heading: <FormattedMessage id="posts.randomDraw.title" />,
        description: <FormattedMessage id="posts.randomDraw.description" />,
        illustration: randomDrawIllustration,
        link: generateRandomDrawPath(),
    },
];

const PlanPostCardsGrid: FC = () => (
    <>
        {cards.map((card, index) => (
            <Col lg={5} md={24} key={String(index)}>
                {card.comingSoon ? (
                    <ComingSoonBadge borderRadius='xlarge'>
                        <PlanPostCard
                            heading={card.heading}
                            description={card.description}
                            illustration={card.illustration}
                            link={card.link}
                        />
                    </ComingSoonBadge>
                ) : (
                    <PlanPostCard
                        heading={card.heading}
                        description={card.description}
                        illustration={card.illustration}
                        link={card.link}
                    />
                )}
            </Col>
        ))}
    </>
);

export default PlanPostCardsGrid;
