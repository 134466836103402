import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const label: TRule = () => ({
    fontSize: '1.375rem',
    marginRight: '1.5rem',
    marginLeft: '1.5rem',
    display: 'inline-block',
    textTransform: 'capitalize',
});

export const button: TRule = () => ({
    marginTop: 0,
    marginBottom: 0,
    marginLeft: '.5rem',
    marginRight: '.5rem',

    '& .anticon': {
        margin: 0,
        height: 14,
        width: 14,
    },

    desktop: {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

export const viewButton: TRuleWithTheme = ({ theme }) => ({
    display: 'none',
    padding: '0.5rem 1.25rem',

    tablet: {
        display: 'inline-block',
        color: theme.colors.textGray,
        cursor: 'pointer',
        borderRadius: theme.borderRadius.medium,
        transition: theme.transition.duration,

        '& .ant-radio': {
            display: 'none',
        },

        '&.ant-radio-wrapper-checked': {
            backgroundColor: theme.colors.primarySelected,
            color: theme.colors.textDark,
            fontWeight: 'bold',
        },
    },
});

export const toolbar: TRule = () => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '2.5rem',
});

export const navigation: TRule = () => ({
    margin: '0 auto',
    tablet: {
        flex: '0 1 auto',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
});
