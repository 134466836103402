import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use-storage';

import { localStorageKeys } from 'constants/index';
import { useFetchAccounts, selectAccountsCount } from 'modules/entities/modules/accounts';

export const useOnboardingVisible = (): [boolean, () => void] => {
    const [initialAccountCount, setInitialAccountsCount] = useState<number>(null);

    const [isOnboardingHidden, setOnboardingHidden] = useLocalStorage(
        localStorageKeys.CONNECT_ACCOUNT_ONBOARDING,
        false,
    );

    const { inProgress, lastSuccessAt } = useFetchAccounts();
    const accountsFetched = Boolean(lastSuccessAt);
    const accountCount = useSelector(selectAccountsCount);

    useEffect(() => {
        if (initialAccountCount === null && accountsFetched) {
            setInitialAccountsCount(accountCount);
        }
    }, [accountCount, initialAccountCount, accountsFetched]);

    const isOnboardingVisible = !inProgress && accountsFetched && !isOnboardingHidden && initialAccountCount === 0;

    return [isOnboardingVisible, () => setOnboardingHidden(true)];
};

export default useOnboardingVisible;
