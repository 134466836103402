import type { Schema } from 'modules/entities';

import type { PasswordRecoveryFormField, ResetPasswordFormField } from './config';

export type User = Schema['User'];

export interface LoginFormValues {
    email: string;
    password: string;
}

export enum SignUpFormField {
    Email = 'email',
    Password = 'password',
    TermsAndConditions = 'termsAndConditions',
}

export interface SignUpFormValues {
    email: string;
    password: string;
    termsAndConditions: boolean;
}

export interface PasswordRecoveryFormValues {
    [PasswordRecoveryFormField.Email]: string;
}

export enum OAuthProvider {
    Instagram = 'instagram',
    Facebook = 'facebook',
    Google = 'google',
}

export interface ResetPasswordFormValues {
    [ResetPasswordFormField.NewPassword]: string;
    [ResetPasswordFormField.ResetToken]: string;
}
