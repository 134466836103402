import { createSelector } from 'reselect';
import * as Petrus from '@ackee/petrus';

import type { ApiState } from 'types';

import type { User } from '../../types';
import { MODULE_PREFIX } from '../../constants';

interface AuthEntitiesState {
    user?: User;
}

interface AuthApi {
    login: ApiState;
    logout: ApiState;
    oAuth: ApiState;
}

interface AuthState {
    entities: AuthEntitiesState;
    api: AuthApi;
    addAccountsRequestIds: string[];
}

export const authEntitiesSelector = (state: { auth: AuthState }) => state.auth.entities;
export const authUserSelector = createSelector(authEntitiesSelector, (auth: AuthEntitiesState) => auth.user);

export const loginApiSelector = createSelector(Petrus.apiSelector, (api: AuthApi) => api.login);
export const logoutApiSelector = createSelector(Petrus.apiSelector, (api: AuthApi) => api.logout);
export const oauthApiSelector = (state: { auth: AuthState }) => state[MODULE_PREFIX].api.oAuth;

export const selectAddAccountsRequestIds = (state: { auth: AuthState }) => state.auth.addAccountsRequestIds;
