import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
    actions as accountGroupActions,
    selectAddAccountGroupApi,
    useAccountGroups,
} from 'modules/entities/modules/account-groups';

const getUniqueNewGroupName = (existingGroups, intl) => {
    const defaultGroupName = intl.formatMessage({ id: 'profile.groups.new.name' });
    const existingNames = existingGroups.map(group => group.name);
    let newGroupName = defaultGroupName;
    let iterator = 1;

    while (true) {
        if (!existingNames.includes(newGroupName)) {
            return newGroupName;
        }

        newGroupName = `${defaultGroupName} ${iterator++}`;
    }
};

const useAddNewGroup = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const api = useSelector(selectAddAccountGroupApi);
    const accountGroups = useAccountGroups();

    const addGroup = () =>
        dispatch(
            accountGroupActions.createAccountGroup.createAccountGroupRequest({
                name: getUniqueNewGroupName(accountGroups, intl),
            }),
        );

    return [addGroup, api] as const;
};

export default useAddNewGroup;
