import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use-storage';

import { localStorageKeys } from 'constants/index';
import {
    selectRandomDrawLatestDraft,
    useFetchCompetition,
    useFetchCompetitions,
} from 'modules/entities/modules/competitions';

import { selectRandomDrawDraftInitialValues } from '../services/selectors';
import { RandomDrawFormValues } from '../types';
import { randomDrawFormInitialValues } from '../config';

const useRandomDrawInitialValues = () => {
    const [storedValues] = useLocalStorage<RandomDrawFormValues>(localStorageKeys.RANDOM_DRAW_FORM, null);

    // Get latest random draw draft with list request
    useFetchCompetitions({ type: 'randomDraw', draft: true });
    const competition = useSelector(selectRandomDrawLatestDraft);
    // Fetch the draft detail to get video settings values
    useFetchCompetition(competition?.id);
    
    const draftInitialValues = useSelector(state => selectRandomDrawDraftInitialValues(state, { id: competition?.id }));
    const initialValues = draftInitialValues ?? storedValues ?? randomDrawFormInitialValues;

    return initialValues;
};

export default useRandomDrawInitialValues;
