import type { TRule } from 'fela';

export const illustration: TRule = () => ({
    marginBottom: '0.5rem',
});

export const title: TRule = () => ({
    marginTop: '0.5rem',
});

export const paragraph: TRule = () => ({
    marginBottom: '2rem',
});
