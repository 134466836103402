import { combineRules } from 'fela';
import type { ThemeProps, TRuleWithTheme } from 'styles/theme';
import type { ButtonProps } from 'antd';

import type { ModalProps } from './Modal';
import { button as buttonStyle } from '../Button/Button.styles';

const ICON_SIZE = 8;

const modalClose = combineRules<ThemeProps & ButtonProps, ThemeProps>(buttonStyle, ({ theme }) => ({
    width: 28,
    height: 28,
    minWidth: 28,
    padding: 0,

    transform: 'translate(33%, -33%)',

    background: theme.colors.buttonDefaultBackground,
    borderColor: theme.colors.buttonDefaultBackground,
    textAlign: 'center',

    '& .anticon': {
        verticalAlign: 'middle',
        height: ICON_SIZE,
    },
}));

export const modal: TRuleWithTheme<Pick<ModalProps, 'size'>> = ({ theme, size }, renderer) => ({
    width: size === 'large' ? '100%' : 480,

    tablet: {
        width: size === 'large' ? 700 : 480,
    },

    '& .ant-modal-close-x': {
        width: 8,
        height: 'auto',
        lineHeight: 'unset',
        fontSize: ICON_SIZE,
    },

    '& .ant-modal-close': modalClose({ shape: 'circle', theme }, renderer),

    '& .ant-modal-content': {
        borderRadius: theme.borderRadius.modal,
        textAlign: 'center',
    },

    '& .ant-modal-body': {
        padding: `2.5rem ${theme.spacing.small}`,
    },

    desktop: {
        '& .ant-modal-body': {
            padding: '3rem 2.5rem',
        },
    },
});

export const button: TRuleWithTheme = () => ({
    width: 28,
    height: 28,
    minWidth: 28,
    paddingLeft: 13,
    paddingRight: 13,

    '& .anticon': {
        height: ICON_SIZE,
    },
});

export const closeIcon: TRuleWithTheme = () => ({
    width: ICON_SIZE,
    height: ICON_SIZE,
});

export const confirmModal: TRuleWithTheme = ({ theme }) => ({
    '&.ant-modal-confirm .ant-modal-body': {
        padding: `3rem ${theme.spacing.large}`,
    },

    '& .ant-modal-confirm-body': {
        textAlign: 'center',
    },

    '& .ant-modal-confirm-btns': {
        float: 'unset',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
    },

    '& .ant-btn': {
        fontSize: '1.125rem',
        flex: 1,
        height: theme.sizes.button.block,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',

        border: 'none',
        borderRadius: theme.borderRadius.large,

        color: theme.colors.white,
        backgroundColor: theme.colors.primary,

        ':hover': {
            border: 'none',
        },
    },

    '& .ant-btn-dangerous': {
        border: 'none',
        color: theme.colors.error,
        backgroundColor: theme.colors.errorBackgroundLight,
    },

    '&.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn': {
        marginLeft: '1rem',
    },
});
