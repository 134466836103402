import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormState } from 'react-final-form';

import { useFelaEnhanced } from 'hooks';
import { Paragraph } from 'modules/ui';
import { validatorsWithMessage, translate } from 'modules/form';
import { useFetchAccounts } from 'modules/entities/modules/accounts';

import { SelectAccountField } from 'modules/social-accounts';

import RandomDrawCard from '../RandomDrawCard';
import * as felaRules from './RandomDrawSelectAccount.styles';
import { RandomDrawField } from '../../config';
import type { RandomDrawFormValues } from '../../types';

const RandomDrawSelectAccount: FC = () => {
    const { rules } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    const { inProgress } = useFetchAccounts();

    const { values } = useFormState<RandomDrawFormValues>({ subscription: { values: true } });

    return (
        <RandomDrawCard inProgress={inProgress}>
            <Paragraph faded weight="bold" extend={{ paragraph: rules.cardHeading }}>
                <FormattedMessage id="socialAccounts.selectOne" />
            </Paragraph>

            <SelectAccountField
                name={RandomDrawField.ACCOUNT}
                value={values.accountId}
                validate={translate(formatMessage, validatorsWithMessage.required)}
                extend={{ formItem: rules.radioButtonGroup }}
            />
        </RandomDrawCard>
    );
};

export default RandomDrawSelectAccount;
