import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as log from 'config/loglevel';

import { oauthApi, oauthLoginRequest, oauthAddAccountRequest } from '../services/actions';
import { OAuthProvider } from '../types';
import { oauthApiSelector } from '../services/selectors';

interface UseOAuthLogin {
    inProgress: boolean;
    started: boolean;
    error?: Error | string;
    startAuth: () => void;
    handleResponse: (object) => void;
    handleFailure: (error: Error) => void;
}

interface FacebookAuthResponse {
    id: string;
    accessToken: string;
    status?: 'unknown' | string;
}

export type AuthType = 'login' | 'addAccount';

const useOAuth = (type: AuthType, provider: OAuthProvider): UseOAuthLogin => {
    const [started, setStarted] = useState(false);

    const { inProgress, error } = useSelector(oauthApiSelector);
    const dispatch = useDispatch();

    const handleResponse = (response: FacebookAuthResponse) => {
        if (response.status === 'unknown') {
            handleFailure(new Error('Unknown OAuth status'));
            return;
        }

        switch (type) {
            case 'login':
                dispatch(
                    oauthLoginRequest({
                        accessToken: response.accessToken,
                        provider,
                    }),
                );
                break;

            case 'addAccount':
                dispatch(
                    oauthAddAccountRequest({
                        accessToken: response.accessToken,
                        provider,
                        providerId: response.id,
                    }),
                );
                break;
        }
    };

    const handleFailure = (error: Error) => {
        setStarted(false);
        dispatch(oauthApi.failure('error.login.oauth'));
        log.error(error);
    };

    const startAuth = () => {
        setStarted(true);
        dispatch(oauthApi.request());
    };

    useEffect(() => {
        return () => {
            dispatch(oauthApi.cancel());
        };
    }, [dispatch]);

    return {
        inProgress,
        started,
        error,
        startAuth,
        handleResponse,
        handleFailure,
    };
};

export default useOAuth;
