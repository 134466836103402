import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { Input } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Icon, Counter } from 'modules/ui';

import FloatingLabel from '../FloatingLabel';
import FormItem from '../FormItem';
import { useValidationDebounce } from '../../hooks';
import type { TextFieldProps } from '../types';

import * as felaRules from './TextField.styles';

export interface TextFieldOwnProps extends TextFieldProps {
    prefix?: ReactNode;
    prefixType?: 'icon' | 'delimited';
    maxLength?: number;
    showCounter?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const TextField: FC<TextFieldOwnProps> = ({
    label,
    required = false,
    validate,
    help,
    prefix,
    prefixType = 'icon',
    extend,
    maxLength,
    showCounter = false,
    ...props
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { prefix, prefixType, extend, ...props });
    const validateDebounced = useValidationDebounce(validate);

    return (
        <Field<string> validate={validateDebounced} validateFields={[]} {...props}>
            {({ input, meta }) => (
                <FormItem {...{ input, meta, required, help, extend: { formItem: rules.formItem } }}>
                    <FloatingLabel label={label} input={input} extend={{ label: prefix ? rules.floatingLabel : null }}>
                        {prefix && <span className={styles.prefix}>{prefix}</span>}
                        {props.type === 'password' ? (
                            <Input.Password
                                id={input.name}
                                className={styles.input}
                                disabled={props.disabled}
                                {...input}
                                iconRender={visible => <Icon type={visible ? Icon.Types.EYE_HIDDEN : Icon.Types.EYE} />}
                            />
                        ) : (
                            <Input
                                id={input.name}
                                className={styles.input}
                                disabled={props.disabled}
                                {...input}
                                maxLength={maxLength}
                            />
                        )}
                        {showCounter && (
                            <Counter
                                title={<FormattedMessage id="general.characters" />}
                                count={input.value.length}
                                max={maxLength}
                                extend={{ container: rules.charsCounter }}
                            />
                        )}
                    </FloatingLabel>
                </FormItem>
            )}
        </Field>
    );
};

export default TextField;
