import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { useFelaEnhanced } from 'hooks';
import { Col, Row } from 'antd';

import { Heading, Page, Video } from 'modules/ui';
import type { Competition } from 'modules/entities';
import { useCompetition, useFetchCompetition } from 'modules/entities/modules/competitions';
import * as felaRules from './RandomDrawResultsPage.styles';
import RandomDrawDownloadVideoButton from '../RandomDrawDownloadVideoButton';
// import RandomDrawCopyVideoLinkButton from '../RandomDrawCopyVideoLinkButton';
import RandomDrawCompetitionPostButton from '../RandomDrawCompetitionPostButton';
import RandomDrawDrawAgainButton from '../RandomDrawDrawAgainButton';

export interface RandomDrawResultsPageProps {}

type UrlParams = {
    competitionId: Competition['id'];
};
type Location = {
    error: boolean;
};

const RandomDrawResultsPage: FC<RandomDrawResultsPageProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const { competitionId } = useParams<UrlParams>();
    const { state } = useLocation<Location>();
    const disabled = Boolean(state?.error);

    useFetchCompetition(competitionId);
    const competition: Competition = useCompetition(competitionId);

    return (
        <Page containerSize="large">
            <header className={styles.header}>
                <Heading extend={{ heading: rules.heading }}>
                    <FormattedMessage id="randomDraw.results.title" />
                </Heading>

                <Row gutter={[16, 16]}>
                    <Col>
                        <RandomDrawDownloadVideoButton disabled={disabled} videoUrl={competition?.videoUrl} />
                    </Col>
                    <Col>
                        <RandomDrawCompetitionPostButton disabled={disabled} competitionId={competitionId} />
                    </Col>
                    {/* <RandomDrawCopyVideoLinkButton /> */}
                    <Col>
                        <RandomDrawDrawAgainButton competitionId={competitionId} />
                    </Col>
                </Row>
            </header>
            <Page>
                <section className={styles.mockup}>
                    <div className={styles.boxMockup}>
                        {competition?.videoUrl ? <Video src={competition.videoUrl} width={288} height={511} /> : null}
                    </div>
                </section>
            </Page>
        </Page>
    );
};

export default RandomDrawResultsPage;
