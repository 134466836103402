import type { TRuleWithTheme } from 'styles/theme';

export const tabs: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textGray,
    overflow: 'initial',

    '& .ant-tabs-tab': {
        padding: '0.5625rem 1.25rem 0.6875rem 1.25rem',
        borderRadius: theme.borderRadius.medium,
        cursor: 'default',

        ':hover': {
            color: theme.colors.textDark,
        },
        ':focus': {
            color: theme.colors.textDark,
        },
    },

    '& .ant-tabs-tab + .ant-tabs-tab': {
        marginLeft: theme.spacing.xsmall,
        marginRight: 0,
    },

    '& .ant-tabs-tab-active': {
        color: theme.colors.textDark,
        backgroundColor: theme.colors.primarySelected,
    },

    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
        fontWeight: 700,
    },

    '& .ant-tabs-ink-bar': {
        display: 'none',
    },

    '& > .ant-tabs-nav': {
        flexWrap: 'wrap',
        marginBottom: '2rem',
    },

    '& > .ant-tabs-nav::before': {
        display: 'none',
    },

    '& .ant-tabs-tab .anticon': {
        marginRight: 0,
        transform: 'translateY(2px)',
    },

    '& > .anticon svg': {
        width: 24,
        height: 24,
    },

    '& .ant-tabs-tabpane': {
        maxWidth: theme.sizes.container.medium,
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    '& .ant-tabs-extra-content': {
        width: '100%',
    },

    '& a': {
        color: 'inherit',
    },

    '& .ant-tabs-nav-wrap': {
        margin: `0 -${theme.spacing.xsmall}`,
        padding: `0 ${theme.spacing.xsmall}`,
    },

    desktop: {
        overflow: 'hidden',

        '& .ant-tabs-extra-content': {
            width: 'auto',
        },

        '& .ant-tabs-extra-content .ant-typography': {
            marginRight: '4rem',
        },

        '& .ant-tabs-nav-wrap': {
            margin: 0,
            padding: 0,
        },
    },
});
