import { call, put, select, takeEvery } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import type { ApiPut, ApiResponse } from 'config/antonio';
import * as log from 'config/loglevel';

import type { operations } from 'api/apiSchema';

import { createUIErrorMessage } from 'modules/errors';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';
import { selectAccountGroup } from 'modules/entities/modules/account-groups/services/selectors';

import { moveAccount as apiActions } from '../actions';
import { selectAccountsById } from '../selectors';

type Operation = operations['accountUpdate'];
type Response = ApiResponse<Operation['responses']['200']>;
type RequestBody = Operation['requestBody']['content']['application/json'];
type RequestParams = Operation['parameters']['path'];
type RequestAction = ReturnType<typeof apiActions.request>;

type GroupOperation = operations['accountGroupUpdate'];
type GroupResponse = ApiResponse<GroupOperation['responses']['200']['content']['application/json']>;
type GroupRequestBody = GroupOperation['requestBody']['content']['application/json'];
type GroupRequestParams = GroupOperation['parameters']['path'];

function* moveAccountHandler(action: RequestAction) {
    const accountId = action.meta.id;
    const { newGroupId, oldGroupId, oldGroupAccounts, newGroupAccounts } = action.payload;

    const oldAccountGroup = yield select(state => selectAccountGroup(state, { id: oldGroupId }));
    const newAccountGroup = yield select(state => selectAccountGroup(state, { id: newGroupId }));
    const accountsMap = yield select(selectAccountsById);

    const accountsPriorities = newGroupAccounts.map((id, index) => ({ id, priority: index + 1 }));

    try {
        yield call<ApiPut<Response, RequestBody, RequestParams>>(authApi.put, config.api.endpoints.accountDetail, {
            accountGroupId: newGroupId,
            uriParams: {
                id: accountId,
            },
        });

        yield call<ApiPut<GroupResponse, GroupRequestBody, GroupRequestParams>>(
            authApi.put,
            config.api.endpoints.accountGroupDetail,
            {
                accounts: accountsPriorities,
                uriParams: { id: newGroupId },
            },
        );

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                byId: {
                    [oldGroupId]: {
                        ...oldAccountGroup,
                        accounts: oldGroupAccounts,
                    },
                    [newGroupId]: {
                        ...newAccountGroup,
                        accounts: newGroupAccounts,
                    },
                },
            }),
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNTS, {
                byId: accountsPriorities.reduce((accountsById, { id, priority }) => {
                    accountsById[id] = {
                        ...accountsMap[id],
                        accountGroupId: newGroupId,
                        priority,
                    };
                    return accountsById;
                }, {}),
            }),
            apiActions.success(accountId),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(accountId, uiError));

        log.error(error);
    }
}

export default function* moveAccount() {
    yield takeEvery(apiActions.request.toString(), moveAccountHandler);
}
