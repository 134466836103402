import type { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Alert as AntdAlert, AlertProps } from 'antd';

import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Alert.styles';

export interface AlertOwnProps extends AlertProps {
    extend?: RulesExtend<typeof felaRules>;
}

const Alert: FC<AlertOwnProps> = ({ extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <AntdAlert {...props} className={styles.alert} />;
};

export default Alert;
