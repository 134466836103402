import type { FC } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Col } from 'antd';

import { SocialProvider } from 'constants/index';
import { selectAccountType } from 'modules/entities/modules/accounts';

import CreatePostCard from '../CreatePostCard';
import { PlanPostFormValues } from '../../types';

export interface PlanPostCreatePostCardsProps {}

const PlanPostCreatePostCards: FC<PlanPostCreatePostCardsProps> = () => {
    const { values } = useFormState<PlanPostFormValues>({ subscription: { values: true } });
    const accountType = useSelector(state => selectAccountType(state, { id: values.accountId }));

    return (
        <>
            {accountType === SocialProvider.FACEBOOK && (
                <Col span={24}>
                    <CreatePostCard type={SocialProvider.FACEBOOK} />
                </Col>
            )}

            {accountType === SocialProvider.INSTAGRAM && (
                <Col span={24}>
                    <CreatePostCard type={SocialProvider.INSTAGRAM} />
                </Col>
            )}
        </>
    );
};

export default PlanPostCreatePostCards;
