import { apiRequestType } from '@ackee/redux-utils';

import { MODULE_PREFIX } from '../../constants';

export const oauthApi = apiRequestType({
    modulePrefix: MODULE_PREFIX,
    typePrefix: 'OAUTH_',
});

export const fetchUser = apiRequestType({
    modulePrefix: MODULE_PREFIX,
    typePrefix: 'FETCH_USER_',
});
export const UPDATE_USER = `${MODULE_PREFIX}/UPDATE_USER`;

export const OAUTH_LOGIN_REQUEST = `${MODULE_PREFIX}/OAUTH_LOGIN_REQUEST`;
export const OAUTH_ADD_ACCOUNT_REQUEST = `${MODULE_PREFIX}/OAUTH_ADD_ACCOUNT_REQUEST`;
