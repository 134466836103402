import { call } from 'redux-saga/effects';
import { createExpirationDate } from '@ackee/petrus';

import config from 'config';
import { api } from 'config/antonio';
import { operations } from 'api/apiSchema';
import type { ApiPost, ApiResponse } from 'config/antonio';

type Operation = operations['signIn'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;

export default function* authenticate(payload) {
    const response: Response = yield call<ApiPost<Response, RequestBody>>(
        api.post,
        config.api.endpoints.signIn,
        payload,
    );
    const { credentials, user } = response.data;

    return {
        tokens: {
            accessToken: {
                token: credentials.accessToken,
                userId: user.id,
                expiration: createExpirationDate(credentials.expiresIn * 1000),
            },
            refreshToken: {
                token: credentials.refreshToken,
                expiration: createExpirationDate(credentials.refreshExpiresIn * 1000),
            },
        },
        // This will force @ackee/petrus to run `getAuthUser` saga and get auth user from our custom EP
        user: null,
    };
}
