import React, { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFelaEnhanced } from 'hooks';
import { Modal, Intro, Button, Icon, Paragraph } from 'modules/ui';
import { OAuthProvider, oauthApiSelector } from 'modules/auth';
import { selectAccountsApi, selectAccountsCount } from 'modules/entities/modules/accounts';
import { ErrorAlert } from 'modules/errors';

import ConnectSocialProfiles from '../ConnectSocialProfiles';
import * as felaRules from './ConnectSocialProfilesModal.styles';

export interface ConnectSocialProfilesModalProps {
    title?: ReactNode;
    visible: boolean;
    onCancel: () => void;
}

const ConnectSocialProfilesModal: FC<ConnectSocialProfilesModalProps> = ({
    title = <FormattedMessage id="socialAccounts.connect.title" />,
    visible,
    onCancel,
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const { error } = useSelector(selectAccountsApi);
    const oauthApi = useSelector(oauthApiSelector);

    const accountCount = useSelector(selectAccountsCount);

    return (
        <Modal visible={visible} closable={false} onCancel={onCancel}>
            <Intro
                title={title}
                text={<FormattedMessage id="socialAccounts.connect.text" />}
                extend={{ paragraph: rules.intro }}
            />

            <ErrorAlert error={error || oauthApi.error} extend={{ alert: felaRules.alert }} />
            <ConnectSocialProfiles type={OAuthProvider.Facebook} />
            <ConnectSocialProfiles type={OAuthProvider.Instagram} />

            {/* TODO: Display only for premium user */}
            <div className={styles.info}>
                <Icon type={Icon.Types.INFO} extend={{ icon: felaRules.icon }} />
                <Paragraph size="small" faded>
                    <FormattedMessage id="socialAccounts.connect.accountCount" values={{ count: accountCount }} />
                </Paragraph>
            </div>

            <Modal.Footer>
                <Button type="text" inverse onClick={onCancel}>
                    <FormattedMessage id="general.skip" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConnectSocialProfilesModal;
