import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/reducers';

import { fetchArticles } from '../services/actions';
import { selectArticlesApi } from '../services/selectors';

const useFetchArticles = () => {
    const api: ApiReducerState = useSelector(selectArticlesApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchArticles.request());

        return () => {
            batch(() => {
                dispatch(fetchArticles.cancel());
                dispatch(fetchArticles.reset());
            });
        };
    }, [dispatch]);
    return api;
};

export default useFetchArticles;
