import { combineReducers } from 'redux';

import { ApiReducerKey } from '../../../../../constants';

import list from './list';
import create from './create';
import deleteReducer from './delete';

export default combineReducers({
    [ApiReducerKey.LIST]: list,
    [ApiReducerKey.CREATE]: create,
    [ApiReducerKey.DELETE]: deleteReducer,
});
