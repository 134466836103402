import React, { FC } from 'react';
import { List, Skeleton } from 'antd';

import { useFelaEnhanced } from 'hooks';

import PanelItem from './PanelItem';

import * as rules from './Panel.styles';

export interface PanelProps {
    loading?: {
        show: boolean;
        items?: number;
    };
}

const Panel: FC<PanelProps> & { Item: typeof PanelItem } = ({ loading = { show: false }, children, ...props }) => {
    const { styles } = useFelaEnhanced(rules, { loading: loading.show });

    return (
        <List {...props} className={styles.list}>
            <Skeleton
                loading={loading.show}
                active
                title={false}
                paragraph={{ rows: loading.items ?? 2, width: '100%' }}
                className={styles.skeleton}
            >
                {children}
            </Skeleton>
        </List>
    );
};

Panel.Item = PanelItem;

export default Panel;
