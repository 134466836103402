import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    padding: `1.5rem ${theme.spacing.xsmall}`,

    desktop: {
        position: 'relative',
        display: 'flex',
        minHeight: '100vh',
        padding: 0,
    },
});

export const logo: TRule = () => ({
    desktop: {
        position: 'absolute',
        top: '2.5rem',
        left: '2rem',
    },
});
