import type { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Heading, Page, Card } from 'modules/ui';
import { Skeleton } from 'antd';

import * as felaRules from './ArticleDetail.styles';
import { useFetchArticle, useArticle } from 'modules/entities/modules/articles';
import { Article } from 'modules/entities/types';
import { useParams } from 'react-router-dom';
import { useParseHtml } from '../../hooks';

export interface ArticleDetailProps {}
type UrlParams = {
    postId: Article['id'];
};
const ArticleDetail: FC<ArticleDetailProps> = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const { postId } = useParams<UrlParams>();
    const { inProgress, success } = useFetchArticle(postId);
    const article: Article = useArticle(postId);
    const displayPlaceholder = inProgress || !success;

    const parsedContent = useParseHtml(article?.content || '');
    const parsedExcerpt = useParseHtml(article?.excerpt || '');
    return (
        <Page containerSize="medium">
            <Card extend={{ card: felaRules.card }}>
                <Skeleton
                    className={styles.skeleton}
                    avatar={{ shape: 'square' }}
                    active
                    loading={displayPlaceholder}
                    paragraph={{ rows: 2, width: '100%' }}
                >
                    {!displayPlaceholder ? (
                        <>
                            <Heading>{article?.title}</Heading>
                            <div className={styles.excerpt}>{parsedExcerpt}</div>
                            <img className={styles.image} alt={article?.title} src={article?.featuredImage} />
                            <div className={styles.content}>{parsedContent}</div>
                        </>
                    ) : null}
                </Skeleton>
            </Card>
        </Page>
    );
};

export default ArticleDetail;
