import type { TRule } from 'fela';

export const alert: TRule = () => ({
    textAlign: 'left',
    marginBottom: '2rem',
});

export const button: TRule = () => ({
    marginTop: '2rem',
});
