import type { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import config from 'config';
import { previousLocationSelector } from 'services/selectors';
import { BackLink } from 'modules/ui';

type RandomDrawBackLinkProps = {};

const rootRandomDrawPath = generatePath(config.routes.randomDraw, { tab: null });

const RandomDrawBackLink: FC<RandomDrawBackLinkProps> = () => {
    const previousLocation = useSelector(previousLocationSelector) as Location;

    return (
        <BackLink
            to={rootRandomDrawPath === previousLocation?.pathname ? config.routes.posts : previousLocation.pathname}
        />
    );
};

export default RandomDrawBackLink;
