import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.createAccount.CREATE_ACCOUNT_REQUEST,
        SUCCESS: types.createAccount.CREATE_ACCOUNT_SUCCESS,
        FAILURE: types.createAccount.CREATE_ACCOUNT_FAILURE,
        CANCEL: types.createAccount.CREATE_ACCOUNT_CANCEL,
    },
});
