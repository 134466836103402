import React, { FC } from 'react';
import Cropper from 'react-easy-crop';
import type { CropperProps } from 'react-easy-crop';
import { Slider } from 'antd';
import { noop } from 'lodash';

import { useFelaEnhanced } from 'hooks';

import * as rules from './ImageCrop.styles';
import useImageCrop from './useImageCrop';
import Icon from '../Icon';

export interface ImageCropProps extends Partial<Omit<CropperProps, 'image' | 'onCropComplete'>> {
    image: string | File | Blob;
    onCropComplete?: (image: File) => void;
}

const ImageCrop: FC<ImageCropProps> = ({ image, onCropComplete = noop, ...props }) => {
    const { styles } = useFelaEnhanced(rules);
    const { zoom, crop, setZoom, setCrop, imageData, handleCropComplete } = useImageCrop(image, onCropComplete);

    return (
        <>
            <div className={styles.container}>
                <Cropper
                    {...props}
                    image={imageData}
                    zoom={zoom}
                    crop={crop}
                    showGrid={false}
                    restrictPosition
                    disableAutomaticStylesInjection
                    onZoomChange={setZoom}
                    onCropChange={setCrop}
                    onCropComplete={handleCropComplete}
                    classes={{
                        containerClassName: styles.cropper,
                        cropAreaClassName: styles.cropArea,
                    }}
                />
            </div>

            <div className={styles.sliderContainer}>
                <Icon type={Icon.Types.MINUS} size="small" />
                <Slider
                    className={styles.slider}
                    min={0.5}
                    max={1.5}
                    step={0.025}
                    value={zoom}
                    tooltipVisible={false}
                    onChange={setZoom}
                />
                <Icon type={Icon.Types.PLUS} size="small" />
            </div>
        </>
    );
};

export default ImageCrop;
