import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Label } from 'modules/form';
import { Panel, Button } from 'modules/ui';
import { LogoutButton } from 'modules/auth';

import ChangeEmailForm from '../ChangeEmailForm';
import ChangePasswordForm from '../ChangePasswordForm';

import * as felaRules from './Account.styles';
import { useEditMode } from '../EditModeProvider';

export interface AccountProps {}

const Account: FC<AccountProps> = () => {
    const { editMode } = useEditMode();
    const isEditMode = Boolean(editMode);
    const { styles } = useFelaEnhanced(felaRules, { isEditMode });

    return (
        <>
            <Panel>
                <Panel.Item
                    actions={[
                        <Button size="large" type="primary">
                            <FormattedMessage id="profile.account.type.upgrade" />
                        </Button>,
                    ]}
                    disabled={isEditMode}
                >
                    <Label label={<FormattedMessage id="profile.account.type" />}>
                        <FormattedMessage id="profile.account.type.free" />
                    </Label>
                </Panel.Item>

                <ChangeEmailForm />

                <ChangePasswordForm />
            </Panel>

            <div className={styles.logout}>
                <LogoutButton />
            </div>
        </>
    );
};

export default Account;
