import { createApiRequestActions, createApiDetailRequestActions } from '@ackee/redux-utils';

import type { Article } from 'modules/entities/types';

import * as types from './types';

export const fetchArticle = createApiDetailRequestActions<Article['id'], undefined, undefined, undefined, string>({
    REQUEST: types.fetchArticle.FETCH_ARTICLE_REQUEST,
    SUCCESS: types.fetchArticle.FETCH_ARTICLE_SUCCESS,
    FAILURE: types.fetchArticle.FETCH_ARTICLE_FAILURE,
    CANCEL: types.fetchArticle.FETCH_ARTICLE_CANCEL,
    RESET: types.fetchArticle.FETCH_ARTICLE_RESET,
});

export const fetchArticles = createApiRequestActions({
    REQUEST: types.fetchArticles.FETCH_ARTICLES_REQUEST,
    SUCCESS: types.fetchArticles.FETCH_ARTICLES_SUCCESS,
    FAILURE: types.fetchArticles.FETCH_ARTICLES_FAILURE,
    CANCEL: types.fetchArticles.FETCH_ARTICLES_CANCEL,
    RESET: types.fetchArticles.FETCH_ARTICLES_RESET,
});
