import React, { FC, ReactNode } from 'react';
import { Upload, UploadProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { Icon, Paragraph } from 'modules/ui';
import type { IconProps } from 'modules/ui';

import * as felaRules from './UploadCard.styles';

export interface UploadCardProps {
    icon: IconProps['type'];
    title: ReactNode;
    onClick?: () => void;
    uploadProps?: UploadProps;
}

const UploadCard: FC<UploadCardProps> = ({ icon, title, uploadProps, onClick }) => {
    const { styles } = useFelaEnhanced(felaRules);

    const content = (
        <>
            <Icon type={icon} size="large" />
            <Paragraph extend={{ paragraph: felaRules.title }}>{title}</Paragraph>
        </>
    );

    return (
        <li className={styles.card}>
            <button className={styles.button} onClick={onClick}>
                {uploadProps ? <Upload {...uploadProps}>{content}</Upload> : content}
            </button>
        </li>
    );
};

export default UploadCard;
