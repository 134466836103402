import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector, useDispatch, batch } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/reducers';

import type { operations } from 'api/apiSchema';

import { fetchCompetitions } from '../services/actions';
import { selectCompetitionsApi } from '../services/selectors';

type Params = operations['competitionList']['parameters']['query'];

const useFetchCompetitions = (params?: Params) => {
    const api: ApiReducerState = useSelector(selectCompetitionsApi);
    const dispatch = useDispatch();

    useDeepCompareEffect(() => {
        dispatch(fetchCompetitions.request(params));

        return () => {
            batch(() => {
                dispatch(fetchCompetitions.cancel());
                dispatch(fetchCompetitions.reset());
            });
        };
    }, [dispatch, params]);

    return api;
};

export default useFetchCompetitions;
