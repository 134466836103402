import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/reducers';

import { fetchAccountGroups as actions } from '../services/actions';
import { selectAccountGroupsApi } from '../services/selectors';

const useFetchAccountGroups = () => {
    const api: ApiReducerState = useSelector(selectAccountGroupsApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchAccountGroupsRequest());

        return () => {
            dispatch(actions.fetchAccountGroupsCancel());
        };
    }, [dispatch]);

    return api;
};

export default useFetchAccountGroups;
