import { all } from 'redux-saga/effects';

import { saga as antonio } from 'config/antonio';
import { saga as auth } from 'modules/auth';
import { saga as entities } from 'modules/entities';
import { saga as profile } from 'modules/profile';
import { saga as pixabay } from 'modules/pixabay';
import { saga as newPost } from 'modules/new-post';
import { saga as posts } from 'modules/posts';
import { saga as randomDraw } from 'modules/random-draw';

export default function* appSaga() {
    yield all([auth(), antonio(), entities(), profile(), pixabay(), posts(), randomDraw(), newPost()]);
}
