import React, { FC } from 'react';

const Instagram: FC = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 6.869a5.13 5.13 0 00-5.132 5.129A5.13 5.13 0 0012 17.126 5.13 5.13 0 1012 6.87zm5.335-1.401a1.198 1.198 0 100 2.396 1.199 1.199 0 100-2.396zm-6.23-1.658c8.543-.014 9.631-.963 9.03 12.22-.212 4.664-3.766 4.152-8.134 4.152-7.964 0-8.193-.228-8.193-8.189 0-8.052.632-8.179 7.296-8.186v.003zM12 2.01c-4.096 0-7.985-.363-9.472 3.446-.612 1.574-.523 3.617-.523 6.543 0 2.568-.083 4.981.523 6.542 1.483 3.811 5.404 3.447 9.47 3.447 3.922 0 7.966.407 9.47-3.447.614-1.59.525-3.602.525-6.542 0-3.902.215-6.42-1.68-8.312-1.917-1.916-4.51-1.677-8.318-1.677H12z"
        ></path>
    </svg>
);

export default Instagram;
