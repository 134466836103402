import { createApiDetailRequestActions, createApiRequestActions } from '@ackee/redux-utils';

import type { operations } from 'api/apiSchema';
import type { Job } from 'modules/entities/types';

import * as types from './types';

export { types };

export const fetchJob = createApiDetailRequestActions<Job['id'], undefined, undefined, undefined, string>({
    REQUEST: types.fetchJob.FETCH_JOB_REQUEST,
    SUCCESS: types.fetchJob.FETCH_JOB_SUCCESS,
    FAILURE: types.fetchJob.FETCH_JOB_FAILURE,
    CANCEL: types.fetchJob.FETCH_JOB_CANCEL,
    RESET: types.fetchJob.FETCH_JOB_RESET,
});

export const createJob = createApiRequestActions<
    operations['jobCreate']['requestBody']['content']['application/json'],
    string,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.createJob.CREATE_JOB_REQUEST,
    SUCCESS: types.createJob.CREATE_JOB_SUCCESS,
    FAILURE: types.createJob.CREATE_JOB_FAILURE,
    CANCEL: types.createJob.CREATE_JOB_CANCEL,
    RESET: types.createJob.CREATE_JOB_RESET,
});
