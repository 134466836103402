import type { FC } from 'react';
import { Tag as AntdTag, TagProps } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Tag.styles';
import Icon from '../Icon';

const Tag: FC<TagProps> = props => {
    const { styles } = useFelaEnhanced(felaRules);

    return <AntdTag {...props} className={styles.tag} closeIcon={<Icon type={Icon.Types.CLOSE} size="small" />} />;
};

export default Tag;
