import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from '../../../../constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.VIDEO_SETTINGS,
});

export const fetchVideoSettings = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_VIDEO_SETTINGS_' }),
});

export const createVideoSettings = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_VIDEO_SETTINGS_' }),
});

export const updateVideoSettings = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'UPDATE_VIDEO_SETTINGS_' }),
});
