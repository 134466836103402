import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { TextField, composeValidators, translate, validatorsWithMessage, useFormSubmit } from 'modules/form';
import type { Schema } from 'modules/entities';

import { forms, ChangeAccountGroupNameField } from '../../config';
import { changeAccountGroup } from '../../services/actions';

import AccountFieldForm from '../AccountFieldForm';
import * as felaRules from './ChangeAccountGroupForm.styles';

export interface ChangeAccountGroupFormProps extends Pick<Schema['AccountGroup'], 'id' | 'name'> {
    closeEditMode: () => void;
}

const validators = {
    [ChangeAccountGroupNameField.Name]: composeValidators(validatorsWithMessage.required),
};

const ChangeAccountGroupForm: FC<ChangeAccountGroupFormProps> = ({ id, name, closeEditMode }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const intl = useIntl();

    const onSubmit = useFormSubmit(changeAccountGroup, { id });

    return (
        <AccountFieldForm
            name={forms.changeAccountGroupName}
            initialValues={{ [ChangeAccountGroupNameField.Name]: name }}
            onSubmit={onSubmit}
            onSubmitSucceeded={closeEditMode}
        >
            {({ dirty, submitting }) => (
                <div className={styles.form}>
                    <TextField
                        name={ChangeAccountGroupNameField.Name}
                        label={intl.formatMessage({ id: 'profile.groups.name' })}
                        validate={translate(intl.formatMessage, validators[ChangeAccountGroupNameField.Name])}
                        hasFeedback={false}
                    />

                    <AccountFieldForm.Buttons
                        onCancel={closeEditMode}
                        onSave={() => null}
                        dirty={dirty}
                        submitting={submitting}
                        extend={{ container: rules.buttons }}
                    />
                </div>
            )}
        </AccountFieldForm>
    );
};

export default ChangeAccountGroupForm;
