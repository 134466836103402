import React, { FC } from 'react';

export interface GoogleProps {}

const Google: FC<GoogleProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#9491E8"
                d="M4.433 12.086l-.696 2.6-2.545.053A9.962 9.962 0 010 9.999a9.95 9.95 0 011.119-4.598l2.265.415.993 2.252A5.936 5.936 0 004.056 10c0 .734.133 1.438.377 2.086"
            ></path>
            <path
                fill="#534EDA"
                d="M19.825 8.132C19.94 8.736 20 9.36 20 10a9.981 9.981 0 01-3.74 7.799v-.001l-2.853-.145-.404-2.522a5.962 5.962 0 002.564-3.043H10.22V8.132h9.605z"
            ></path>
            <path
                fill="#7C78E3"
                d="M16.26 17.798A9.957 9.957 0 0110 20a10 10 0 01-8.808-5.261l3.24-2.653A5.944 5.944 0 0010 15.944a5.903 5.903 0 003.003-.813l3.257 2.667z"
            ></path>
            <path
                fill="#635FDD"
                d="M16.383 2.302l-3.24 2.652a5.947 5.947 0 00-8.767 3.115L1.12 5.402h-.001A9.998 9.998 0 0110 0c2.426 0 4.65.865 6.383 2.302"
            ></path>
        </g>
    </svg>
);

export default Google;
