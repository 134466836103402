import type { TRuleWithTheme } from 'styles/theme';

export const header: TRuleWithTheme = ({ theme }) => ({
    display: 'block',
    justifyContent: 'space-between',
    tablet: {
        display: 'flex',
    },
});

export const heading: TRuleWithTheme = ({ theme }) => ({
    marginRight: theme.spacing.xsmall,
});

export const button: TRuleWithTheme = ({ theme }) => ({
    marginLeft: theme.spacing.xsmall,
    marginBottom: theme.spacing.xsmall,

    ':first-child': {
        marginLeft: 0,
    },
});

export const mockup: TRuleWithTheme = ({ theme }) => ({
    maxWidth: 952,
    height: 630,
    margin: `${theme.spacing.xsmall} auto`,
    boxShadow: theme.shadow.panel,
    padding: '2rem',
    borderRadius: theme.borderRadius.xlarge,

    backgroundColor: theme.colors.white,
});
export const boxMockup: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: theme.borderRadius.xlarge,
    backgroundColor: theme.colors.white,
    background: theme.colors.backgroundPrimary,

    height: '100%',
});
