import { basicApiReducer, basicResetReducer } from '@ackee/redux-utils';
import { AUTH_SESSION_END } from '@ackee/petrus';

import { types } from '../../actions';

const oAuthReducer = basicApiReducer({
    actionTypes: {
        REQUEST: types.oauthApi.OAUTH_REQUEST,
        SUCCESS: types.oauthApi.OAUTH_SUCCESS,
        FAILURE: types.oauthApi.OAUTH_FAILURE,
        RESET: types.oauthApi.OAUTH_RESET,
    },
});

export default basicResetReducer(oAuthReducer, AUTH_SESSION_END);
