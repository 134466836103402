import { createApiRequestActions, createApiDetailRequestActions } from '@ackee/redux-utils';

import type { operations } from 'api/apiSchema';
import type { Schema } from 'modules/entities/types';

import * as types from './types';

export { types };

type AccountGroupId = Schema['AccountGroup']['id'];
type AccountId = Schema['Account']['id'];

export const createAccount = createApiDetailRequestActions<
    string,
    operations['accountCreate']['requestBody']['content']['application/json'],
    undefined,
    undefined,
    string
>({
    REQUEST: types.createAccount.CREATE_ACCOUNT_REQUEST,
    SUCCESS: types.createAccount.CREATE_ACCOUNT_SUCCESS,
    FAILURE: types.createAccount.CREATE_ACCOUNT_FAILURE,
    CANCEL: types.createAccount.CREATE_ACCOUNT_CANCEL,
});

export const fetchAccounts = createApiRequestActions<
    operations['accountList']['parameters']['query'],
    undefined,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.fetchAccounts.FETCH_ACCOUNTS_REQUEST,
    SUCCESS: types.fetchAccounts.FETCH_ACCOUNTS_SUCCESS,
    FAILURE: types.fetchAccounts.FETCH_ACCOUNTS_FAILURE,
    CANCEL: types.fetchAccounts.FETCH_ACCOUNTS_CANCEL,
    RESET: types.fetchAccounts.FETCH_ACCOUNTS_RESET,
});

export const deleteAccount = createApiDetailRequestActions<
    AccountId,
    operations['accountDelete']['parameters']['path'],
    undefined,
    undefined,
    string
>({
    REQUEST: types.deleteAccount.DELETE_ACCOUNT_REQUEST,
    SUCCESS: types.deleteAccount.DELETE_ACCOUNT_SUCCESS,
    FAILURE: types.deleteAccount.DELETE_ACCOUNT_FAILURE,
    CANCEL: types.deleteAccount.DELETE_ACCOUNT_CANCEL,
});

export const moveAccount = createApiDetailRequestActions<
    AccountId,
    {
        oldGroupId: AccountGroupId;
        oldGroupAccounts: AccountId[];
        newGroupId: AccountGroupId;
        newGroupAccounts: AccountId[];
    }
>({
    REQUEST: types.moveAccount.MOVE_ACCOUNT_REQUEST,
    SUCCESS: types.moveAccount.MOVE_ACCOUNT_SUCCESS,
    FAILURE: types.moveAccount.MOVE_ACCOUNT_FAILURE,
    CANCEL: types.moveAccount.MOVE_ACCOUNT_CANCEL,
});
