import { useState, useEffect } from 'react';
import type { Area } from 'react-easy-crop/types.d';

import * as log from 'config/loglevel';

import type { ImageCropProps } from './ImageCrop';

const convertToBase64Image = (image: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(image);
        reader.onload = () => resolve(reader.result.toString());
        reader.onerror = error => reject(error);
    });

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
const cropImage = async (imageSrc: string, pixelCrop: Area): Promise<File> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    return new Promise(resolve => {
        canvas.toBlob(blob => {
            const file = new File([blob], '');
            resolve(file);
        }, 'image/jpeg');
    });
};

const useImageCrop = (image: ImageCropProps['image'], onCropComplete: ImageCropProps['onCropComplete']) => {
    const [zoom, setZoom] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [imageData, setImageData] = useState('');

    useEffect(() => {
        if (image instanceof File || image instanceof Blob) {
            convertToBase64Image(image)
                .then(data => setImageData(data))
                .catch(error => {
                    log.error(error);
                });
        } else {
            setImageData(image);
        }
    }, [image, setImageData]);

    const handleCropComplete = async (_, croppedAreaPixels: Area) => {
        try {
            const croppedImage = await cropImage(imageData, croppedAreaPixels);
            onCropComplete(croppedImage);
        } catch (error) {
            log.error(error);
        }
    };

    return {
        zoom,
        setZoom,
        crop,
        setCrop,
        imageData,
        handleCropComplete,
    };
};

export default useImageCrop;
