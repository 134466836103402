import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.JOBS,
});

export const fetchJob = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_JOB_' }),
});

export const createJob = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_JOB_' }),
});
