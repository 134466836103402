import { createApiRequestActions } from '@ackee/redux-utils';

import type { operations } from 'api/apiSchema';
import type { Media } from 'modules/entities/types';

import * as types from './types';
import type { MediaGroup } from '../../types';

export { types };

export const createMedia = createApiRequestActions<
    {
        file: operations['mediaUpload']['requestBody']['content']['multipart/form-data'];
        group?: MediaGroup;
    },
    undefined,
    { id: Media['id']; lastSuccessAt: string },
    string
>({
    REQUEST: types.createMedia.CREATE_MEDIA_REQUEST,
    SUCCESS: types.createMedia.CREATE_MEDIA_SUCCESS,
    FAILURE: types.createMedia.CREATE_MEDIA_FAILURE,
    RESET: types.createMedia.CREATE_MEDIA_RESET,
});
