import { useDispatch } from 'react-redux';

import { actions as accountGroupActions } from 'modules/entities/modules/account-groups';
import type { Schema } from 'modules/entities';

const useAccountGroupActions = (id: Schema['AccountGroup']['id']) => {
    const dispatch = useDispatch();
    const onDelete = () => dispatch(accountGroupActions.deleteAccountGroup.deleteAccountGroupRequest(id));

    return {
        onDelete,
    };
};

export default useAccountGroupActions;
