import React, { FC, VideoHTMLAttributes } from 'react';
import { useFelaEnhanced } from 'hooks';

import Icon from '../Icon';
import IconTextButton from '../IconTextButton';

import * as felaRules from './Video.styles';
import useVideo, { VideoState } from 'modules/ui/hooks/useVideo';

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {}

const Video: FC<VideoProps> = props => {
    const { state, play, ref, pause } = useVideo();
    const videoIsNotPlaying = state !== VideoState.PLAYING;

    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <video {...props} onClick={videoIsNotPlaying ? play : pause} className={styles.video} ref={ref} />

            {videoIsNotPlaying && (
                <IconTextButton
                    icon={Icon.Types.PLAY}
                    size="large"
                    iconSize="xlarge"
                    color="primary"
                    extend={{ button: rules.controlButton }}
                    onClick={play}
                />
            )}
        </div>
    );
};

export default Video;
