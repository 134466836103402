import { basicApiReducer } from '@ackee/redux-utils';
import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.fetchPost.FETCH_POST_REQUEST,
        SUCCESS: types.fetchPost.FETCH_POST_SUCCESS,
        FAILURE: types.fetchPost.FETCH_POST_FAILURE,
        CANCEL: types.fetchPost.FETCH_POST_CANCEL,
    },
});
