import React, { FC } from 'react';
import { List } from 'antd';
import type { ListItemMetaProps } from 'antd/es/list';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './ListItemMeta.styles';

export interface ListItemMetaOwnProps extends ListItemMetaProps {
    extend?: RulesExtend<typeof felaRules>;
}

const ListItemMeta: FC<ListItemMetaOwnProps> = ({ extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <List.Item.Meta {...props} className={styles.meta} />;
};

export default ListItemMeta;
