import { call, put } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiPost, ApiResponse } from 'config/antonio';

import type { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeCompetition } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import { createCompetition as apiActions } from '../actions';

type Operation = operations['competitionCreate'];
type Response = ApiResponse<Operation['responses']['201']['content']['application/json']>;
type RequestBody = Operation['requestBody']['content']['application/json'];

type RequestAction = ReturnType<typeof apiActions.request>;

function* createCompetitionHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiPost<Response, RequestBody>>(
            authApi.post,
            config.api.endpoints.competitions,
            action.payload,
        );

        const result = normalizeCompetition(data);
        const { result: competitionId, entities } = result;

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.COMPETITIONS, {
                ids: [competitionId],
                byId: entities[EntityKey.COMPETITIONS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.POSTS, entities[EntityKey.POSTS]),
        ]);

        yield put(
            apiActions.success(undefined, {
                id: competitionId,
                lastSuccessAt: new Date().toISOString(),
            }),
        );
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(uiError));

        log.error(error);
    }
}

export default function* createCompetition() {
    yield takeLatestRequest(
        () => 'createCompetition',
        {
            pattern: apiActions.request.toString(),
            handler: createCompetitionHandler,
        },
        {
            pattern: apiActions.cancel.toString(),
            handler: noop,
        },
    );
}
