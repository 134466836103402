import React, { FC, useEffect, useState } from 'react';

import { Modal } from 'modules/ui';
import type { Schema } from 'modules/entities';

import { PictureManagementStep } from '../../types';
import AccountGroupPictureManagement from '../AccountGroupPictureManagement';
import AccountGroupPictureSearch from '../AccountGroupPictureSearch';

export interface AccountGroupPictureManagementModalProps {
    id: Schema['AccountGroup']['id'];
    visible?: boolean;
    hide?: () => void;
}

const AccountGroupPictureManagementModal: FC<AccountGroupPictureManagementModalProps> = ({ id, visible, hide }) => {
    const [step, setStep] = useState<PictureManagementStep>(null);

    useEffect(() => {
        // Allow proper component lifecycle flow of AccountGroupPictureManagement on modal close/open
        setStep(visible ? PictureManagementStep.COMPUTER : null);
    }, [visible]);

    return (
        <Modal
            visible={visible}
            size={step === PictureManagementStep.PIXABAY ? 'large' : 'small'}
            closable={false}
            onCancel={hide}
        >
            {step === PictureManagementStep.COMPUTER && (
                <AccountGroupPictureManagement id={id} setStep={setStep} hide={hide} />
            )}
            {(step === PictureManagementStep.PIXABAY || step === PictureManagementStep.PIXABAY_DETAIL) && (
                <AccountGroupPictureSearch id={id} setStep={setStep} hide={hide} />
            )}
        </Modal>
    );
};

export default AccountGroupPictureManagementModal;
