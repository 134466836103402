import type { TRuleWithTheme } from 'styles/theme';

import type { CardOwnProps } from './Card';

type StyleProps = Required<Pick<CardOwnProps, 'selected' | 'clickable'>>;

export const card: TRuleWithTheme<StyleProps> = ({ selected, clickable, theme }) => {
    const hoverStyle = {
        borderColor: theme.colors.primarySelected,
        boxShadow: theme.shadow.cardHover,
    };

    return {
        backgroundColor: selected ? theme.colors.primarySelected : theme.colors.white,
        boxShadow: selected ? theme.shadow.cardActive : 'none',
        borderColor: selected ? theme.colors.primarySelected : theme.colors.cardBorder,

        cursor: clickable ? 'pointer' : 'unset',

        ...(clickable && {
            ':hover': hoverStyle,
            ':focus': hoverStyle,

            ':active': {
                borderColor: theme.colors.cardBorderActive,
                boxShadow: theme.shadow.cardActive,
            },
        }),

        '& .ant-card-head': {
            borderBottom: 'none',
            minHeight: 'unset',
        },
        '& .ant-card-head-title': {
            paddingBottom: 0,
        },

        '& .ant-card-body': {
            paddingTop: '0.375rem',
        },
    };
};
