import React, { FC } from 'react';

export interface TipsProps {}

const Tips: FC<TipsProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
                fill="currentColor"
                d="M19.168 6.467l-1.438 1.39a1 1 0 11-1.389-1.438l1.438-1.39a1 1 0 011.389 1.438M7.684 7.832a.997.997 0 01-1.414.026l-1.439-1.39a1 1 0 011.39-1.44l1.439 1.39a1 1 0 01.024 1.414M6 12a1 1 0 01-1 1H3a1 1 0 110-2h2a1 1 0 011 1m16 0a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1M11 5V3a1 1 0 112 0v2a1 1 0 11-2 0m2.501 9.593c-.31.179-.501.51-.501.867V20h-2v-4.54c0-.357-.191-.688-.501-.867A2.996 2.996 0 0112 9a2.996 2.996 0 011.501 5.593M12 7c-2.757 0-5 2.243-5 5 0 1.579.753 3.062 2 3.996V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-4.004A5.007 5.007 0 0017 12c0-2.757-2.243-5-5-5"
            ></path>
        </g>
    </svg>
);

export default Tips;
