import { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '1rem',
    boxShadow: theme.shadow.panel,

    '& .ant-card-head': {
        display: 'none',
    },
    '& .ant-card-body': {
        padding: '1rem',
    },
});

export const skeleton: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.textGray,

    desktop: {
        flexDirection: 'row',
    },

    '& .ant-skeleton-header > .ant-skeleton-avatar': {
        borderRadius: theme.borderRadius.large,
        minHeight: '12rem',

        minWidth: '100%',
    },
    '& .ant-skeleton-header': {
        padding: 0,
        width: '55%',
    },
    '& .ant-skeleton-content': {
        padding: '1rem 2rem',
    },
});
