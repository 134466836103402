import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormState } from 'react-final-form';

import { FloatingCard, Button, SaveDraftButton, ComingSoonBadge } from 'modules/ui';
import { Col } from 'antd';

import type { PlanPostFormValues } from '../../types';
import { usePostSave } from '../../hooks';

const PlanPostFooter: FC = () => {
    const { inProgress: saveInProgress } = usePostSave();
    const { submitting } = useFormState<PlanPostFormValues>({
        subscription: { submitting: true, values: true, valid: true },
    });

    const loading = submitting || saveInProgress;

    return (
        <FloatingCard>
            <Col>
                <ComingSoonBadge borderRadius={'medium'} button={false}>
                    <SaveDraftButton
                        disabled
                        // disabled={loading || !valid}
                        // loading={saveInProgress}
                        // onClick={() => save(values)}
                    />
                </ComingSoonBadge>
            </Col>
            <Col>
                <Button htmlType="submit" type="primary" size="large" disabled={loading} loading={submitting}>
                    <FormattedMessage id="post.plan" />
                </Button>
            </Col>
        </FloatingCard>
    );
};

export default PlanPostFooter;
