import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import PostFieldFooter from '../PostFieldFooter';
import { FormattedMessage } from 'react-intl';

import { TextAreaField, validatorsWithMessage, translate } from 'modules/form';
import type { TextFieldProps } from '../../../form/components/types';

interface PostTextAreaProps extends TextFieldProps {
    clear?: () => void;
    name: string;
    rows?: number;
}

const PostTextArea: FC<PostTextAreaProps> = ({ name, clear, rows }) => {
    const [cursorPosition, setCursorPosition] = React.useState(0);
    const { formatMessage } = useIntl();

    return (
        <TextAreaField
            name={name}
            label={<FormattedMessage id="post.caption" />}
            onBlur={e => setCursorPosition(e.target.selectionStart)}
            footer={<PostFieldFooter cursorPosition={cursorPosition} fieldName={name} />}
            textareaProps={{ rows: rows }}
            fluid
            clear={clear}
            validate={translate(formatMessage, validatorsWithMessage.required)}
        />
    );
};

export default PostTextArea;
