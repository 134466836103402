import { createApiRequestActions, createApiDetailRequestActions } from '@ackee/redux-utils';
import { operations } from 'api/apiSchema';

import type { Post } from 'modules/entities/types';

import * as types from './types';

export const createPost = createApiRequestActions<
    operations['postCreate']['requestBody']['content']['application/json'],
    undefined,
    { id: Post['id']; lastSuccessAt: string },
    string
>({
    REQUEST: types.createPost.CREATE_POST_REQUEST,
    SUCCESS: types.createPost.CREATE_POST_SUCCESS,
    FAILURE: types.createPost.CREATE_POST_FAILURE,
    CANCEL: types.createPost.CREATE_POST_CANCEL,
    RESET: types.createPost.CREATE_POST_RESET,
});

export const fetchPost = createApiDetailRequestActions<Post['id'], undefined, undefined, undefined, string>({
    REQUEST: types.fetchPost.FETCH_POST_REQUEST,
    SUCCESS: types.fetchPost.FETCH_POST_SUCCESS,
    FAILURE: types.fetchPost.FETCH_POST_FAILURE,
    CANCEL: types.fetchPost.FETCH_POST_CANCEL,
    RESET: types.fetchPost.FETCH_POST_RESET,
});

export const updatePost = createApiDetailRequestActions<
    Post['id'],
    operations['postUpdate']['requestBody']['content']['application/json'],
    undefined,
    { lastSuccessAt: string },
    string
>({
    REQUEST: types.updatePost.UPDATE_POST_REQUEST,
    SUCCESS: types.updatePost.UPDATE_POST_SUCCESS,
    FAILURE: types.updatePost.UPDATE_POST_FAILURE,
    CANCEL: types.updatePost.UPDATE_POST_CANCEL,
    RESET: types.updatePost.UPDATE_POST_RESET,
});
