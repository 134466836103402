import { put, take } from 'redux-saga/effects';

import { actions as accountGroupsActions } from 'modules/entities/modules/account-groups';
import { actions as mediaActions } from 'modules/entities/modules/media';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import * as actions from '../actions';

const { createMedia: createMediaTypes } = mediaActions.types;

function* changeAccountGroupPictureHandler(action: ReturnType<typeof actions.changeAccountGroupPicture>) {
    yield put(
        mediaActions.createMedia.request({
            file: {
                type: 'accountGroup',
                name: action.payload.image instanceof File ? action.payload.image.name : null,
                // File type is fine here
                // @ts-ignore
                filename: action.payload.image,
            },
        }),
    );

    const createMediaAction = yield take([
        createMediaTypes.CREATE_MEDIA_SUCCESS,
        createMediaTypes.CREATE_MEDIA_FAILURE,
    ]);

    if (createMediaAction.type === createMediaTypes.CREATE_MEDIA_SUCCESS) {
        yield put(
            accountGroupsActions.updateAccountGroup.updateAccountGroupRequest(action.meta.accountGroupId, {
                mediaIds: [createMediaAction.meta.id],
            }),
        );
    }
}

function* changeAccountGroupPictureCancelHandler(action: ReturnType<typeof actions.changeAccountGroupPictureCancel>) {
    yield put(accountGroupsActions.updateAccountGroup.updateAccountGroupCancel(action.meta.accountGroupId));
}

export default function* changeAccountGroupPicture() {
    yield takeLatestRequest(
        action => action.meta.accountGroupId,
        {
            pattern: actions.changeAccountGroupPicture.toString(),
            handler: changeAccountGroupPictureHandler,
        },
        {
            pattern: actions.changeAccountGroupPictureCancel.toString(),
            handler: changeAccountGroupPictureCancelHandler,
        },
    );
}
