import type { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Link, LinkProps } from 'react-router-dom';

import * as felaRules from './PlanPostCard.styles';
import { Card, Heading, Icon, IconTextButton, Paragraph } from 'modules/ui';

export interface PlanPostCardProps {
    heading: ReactNode;
    description: ReactNode;
    illustration: string;
    illustrationAlt?: string;
    link?: LinkProps['to'];
    comingSoon?: boolean;
}

const PlanPostCard: FC<PlanPostCardProps> = ({ heading, description, illustration, illustrationAlt, link }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    return (
        <Link to={link ?? '#'}>
            <Card extend={{ card: rules.card }} clickable>
                <div>
                    {illustration && <img src={illustration} alt={illustrationAlt} className={styles.illustration} />}
                    <Heading level={3}>{heading}</Heading>
                    <Paragraph size="small" faded>
                        {description}
                    </Paragraph>
                </div>
                <IconTextButton iconSize="large" icon={Icon.Types.PLUS} />
            </Card>
        </Link>
    );
};

export default PlanPostCard;
