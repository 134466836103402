import React, { FC } from 'react';

export interface InfoProps {}

const Info: FC<InfoProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props} width="20" height="20">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M10 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-18C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0"></path>
            <path d="M10 8a1 1 0 00-1 1v5a1 1 0 102 0V9a1 1 0 00-1-1m.56-2.83a.757.757 0 00-.18-.09.64.64 0 00-.18-.06.954.954 0 00-.58.06c-.13.05-.23.12-.33.21-.18.19-.29.45-.29.71 0 .27.1.52.29.71.1.09.2.16.33.21.12.06.25.08.38.08a.994.994 0 001-1c0-.26-.11-.52-.29-.71-.05-.04-.1-.09-.15-.12"></path>
        </g>
    </svg>
);

export default Info;
