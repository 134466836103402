import { basicResetReducer } from '@ackee/redux-utils';
import { AUTH_SESSION_END } from '@ackee/petrus';

import { types } from '../../actions';
import type { User } from '../../../types';

const initialState: User | null = null;

function authEntitiesReducer(state = initialState, action) {
    switch (action.type) {
        case types.fetchUser.FETCH_USER_SUCCESS:
            return action.payload;

        case types.UPDATE_USER:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}

export default basicResetReducer(authEntitiesReducer, AUTH_SESSION_END);
