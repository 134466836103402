import { createStore } from 'redux';
import { END } from 'redux-saga';
import * as Consts from '../../../../../constants/index';
import * as reduxLocalStorage from 'redux-localstorage-simple';
import * as constants from 'constants/index';

import rootReducer from '../services/reducer';
import rootSaga from '../services/saga';

import configureEnhancer from './enhancer';

export default function configureStore() {
    const storagePaths = ['account-groups.selectedGroup.id'];
    const initialState = reduxLocalStorage.load({
        states: storagePaths,
        namespace: constants.LOCAL_STORAGE_NAMESPACE,
    });

    const { enhancer, sagaMiddleware } = configureEnhancer(storagePaths);

    const store = createStore(rootReducer, initialState, enhancer);

    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);

    if (!Consts.isServerEnv) {
        store.runSaga(rootSaga);
    }

    return store;
}
