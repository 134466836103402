import type { TRuleWithTheme } from 'styles/theme';

export type ContainerSize = 'medium' | 'large';

export const container: TRuleWithTheme<{ size: ContainerSize }> = ({ size, theme }) => ({
    width: '100%',
    maxWidth: theme.sizes.container[size],
    paddingLeft: theme.spacing.xsmall,
    paddingRight: theme.spacing.xsmall,
    marginLeft: 'auto',
    marginRight: 'auto',
});
