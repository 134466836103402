import type { TRule } from 'fela';

export const tagsField: TRule = () => ({});

export const tagList: TRule = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    marginLeft: '-0.25rem',
    marginRight: '-0.25rem',
});

export const item: TRule = () => ({
    padding: '0.25rem 0',
});
