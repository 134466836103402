import { call, put, takeLeading } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiPost, ApiResponse } from 'config/antonio';
import { operations } from 'api/apiSchema';
import { createUIErrorMessage } from 'modules/errors';
import { normalizeAccountGroup } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import { types, createAccountGroup as apiActions } from '../actions';

type Operation = operations['accountGroupCreate'];
type Response = ApiResponse<Operation['responses']['201']['content']['application/json']>;
type RequestBody = Operation['requestBody']['content']['application/json'];

function* createAccountGroupHandler(action) {
    try {
        const { data }: Response = yield call<ApiPost<Response, RequestBody>>(
            authApi.post,
            config.api.endpoints.accountGroups,
            {
                name: action.payload.name,
            },
        );

        const result = normalizeAccountGroup(data);
        const { result: id, entities } = result;
        const ids = [id];

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                ids,
                byId: entities[EntityKey.ACCOUNT_GROUPS],
                strategy: 'append',
            }),
            entitiesActions.setEntities(EntityKey.ACCOUNTS, entities[EntityKey.ACCOUNTS]),

            apiActions.createAccountGroupSuccess(),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.createAccountGroupFailure(uiError));

        log.error(error);
    }
}

export default function* createAccountGroup() {
    yield takeLeading(types.createAccountGroup.CREATE_ACCOUNT_GROUP_REQUEST, createAccountGroupHandler);
}
