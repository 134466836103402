import { createAction } from '@reduxjs/toolkit';

import { createFormActions } from 'modules/form';

import { MODULE_PREFIX } from '../../constants';
import type { Competition } from 'modules/entities/types';
import type { RandomDrawFormValues } from '../../types';

export const randomDrawForm = createFormActions(`${MODULE_PREFIX}/RANDOM_DRAW_FORM`);
export const randomDrawAgain = createAction<Competition['id']>(`${MODULE_PREFIX}/DRAW_AGAIN`);
export const saveDraft = createAction<RandomDrawFormValues>(`${MODULE_PREFIX}/SAVE_DRAFT`);
