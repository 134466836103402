import type { TRule } from 'fela';

export const checkboxWrapper: TRule = () => ({
    // Fix broken checkbox layout in vertical form
    '& .ant-form-item': {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginBottom: 24,
    },

    '& .ant-form-item-label': {
        padding: '5px 0 0 8px',
    },

    '& .ant-form-item-control': {
        flexGrow: 0,
        marginBottom: 4,
    },

    '& .ant-form-item-explain': {
        position: 'absolute',
        width: 200,
        top: 26,
        zIndex: 1,
    },
});
