import React, { FC } from 'react';

import { Modal } from 'modules/ui';

import PasswordRecoveryForm from '../PasswordRecoveryForm';
import PasswordRecoverySuccess from '../PasswordRecoverySuccess';

import { useFormSuccessState } from 'modules/form';

export interface PasswordRecoveryModalProps {
    visible: boolean;
    onClose: () => void;
}

const PasswordRecoveryModal: FC<PasswordRecoveryModalProps> = ({ visible, onClose }) => {
    const { isForm, isSuccess, setSuccessState, resetState } = useFormSuccessState()

    React.useEffect(() => {
        if (!visible && isSuccess) {
            resetState();
        }
    }, [visible, isSuccess, resetState]);

    return (
        <Modal
            visible={visible}
            closable={isForm}
            onCancel={onClose}
        >
            {isForm && <PasswordRecoveryForm onSubmitSucceeded={setSuccessState} />}
            {isSuccess && <PasswordRecoverySuccess onClose={onClose} />}
        </Modal>
    );
};

export default PasswordRecoveryModal;
