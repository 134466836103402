import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use-storage';

import { useFelaEnhanced } from 'hooks';
import { localStorageKeys } from 'constants/index';
import { Avatar, Card, Paragraph, IconTextButton, Icon } from 'modules/ui';
import { Select } from 'modules/form';
import { generateProfilePath, ProfileTab } from 'modules/profile';
import { useErrorToast } from 'modules/errors';
import {
    useAccountGroups,
    useFetchAccountGroups,
    actions as accountGroupActions,
    selectSelectedGroupId,
} from 'modules/entities/modules/account-groups';

import defaultGroupPicture from 'assets/images/default_user_group.png';

import * as felaRules from './AccountsCard.styles';
import AccountAvatarList from './AccountAvatarList';

export interface AccountsCardProps {}

const AccountsCard: FC<AccountsCardProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const [, , deleteStoredValues] = useLocalStorage(localStorageKeys.RANDOM_DRAW_FORM, null);
    const { inProgress, error } = useFetchAccountGroups();
    const groups = useAccountGroups();
    const selectedGroupId = useSelector(selectSelectedGroupId);
    const dispatch = useDispatch();

    useErrorToast(error, 'error.api.accountGroups');

    useEffect(() => {
        if (!selectedGroupId && groups.length > 0) {
            dispatch(accountGroupActions.setSelectedGroupId(groups[0].id));
        }
    }, [selectedGroupId, groups, dispatch]);

    const onAccountGroupChange = value => {
        deleteStoredValues();
        dispatch(accountGroupActions.setSelectedGroupId(value));
    };

    return (
        <Card title={null} loading={inProgress || (!error && !selectedGroupId)} extend={{ card: rules.card }}>
            <Select
                size="large"
                value={selectedGroupId}
                options={groups.map(group => ({
                    value: group.id,
                    label: (
                        <div className={styles.option}>
                            <Avatar
                                size={48}
                                src={group.profilePicture ?? defaultGroupPicture}
                                extend={{ avatar: felaRules.avatar }}
                            />
                            <Paragraph size="large" weight="bold" extend={{ paragraph: felaRules.optionParagraph }}>
                                {group.name}
                            </Paragraph>
                        </div>
                    ),
                }))}
                footer={
                    <Link to={generateProfilePath(ProfileTab.SOCIAL_PROFILES)}>
                        <IconTextButton icon={Icon.Types.PLUS}>
                            <FormattedMessage id="posts.createNewGroup" />
                        </IconTextButton>
                    </Link>
                }
                onChange={value => onAccountGroupChange(value)}
            />

            <AccountAvatarList groupId={selectedGroupId} />
        </Card>
    );
};

export default AccountsCard;
