import React, { FC } from 'react';
import { Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import config from 'config';

import { LoginPage, SignUpPage, ResetPasswordPage } from 'modules/auth';

export const routes: Array<RouteProps> = [
    {
        path: config.routes.login,
        exact: true,
        render: () => <LoginPage />,
    },
    {
        path: config.routes.signUp,
        exact: true,
        render: () => <SignUpPage />,
    },
    {
        path: config.routes.passwordReset,
        exact: true,
        render: () => <ResetPasswordPage />,
    },
    {
        render: ({ location }) => <Redirect to={{ pathname: config.routes.login, state: { from: location } }} />,
    },
];

export interface RoutesProps {}

const Routes: FC<RoutesProps> = () => (
    <Switch>
        {routes.map((route, index) => (
            <Route key={String(index)} {...route} />
        ))}
    </Switch>
);

export default Routes;
