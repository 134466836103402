import * as validators from './validators';
import { setValidatorMessage } from './helpers';
import type { FieldValidatorWithMessage } from './types';

export const required: FieldValidatorWithMessage<string | number | boolean | Array<any>> = setValidatorMessage(
    validators.required,
    'error.form.required',
);

export const email: FieldValidatorWithMessage = setValidatorMessage(validators.email, 'error.form.email');

export const password = (length): FieldValidatorWithMessage =>
    setValidatorMessage(validators.minLength(length), 'error.form.password');

export const maxTagCount = (max: number): FieldValidatorWithMessage =>
    setValidatorMessage(validators.maxTagCount(max), 'error.form.maxTagCount');
