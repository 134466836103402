import { identity } from 'lodash';
import type { ActionPattern } from 'redux-saga/effects';

const patternTestFactory = ({ transform = identity }: { transform?: (param: any) => any } = {}) => (
    entity: any,
    pattern: ActionPattern,
) => {
    const transformed = transform(entity);

    if (typeof pattern === 'function') {
        return pattern(entity);
    }
    if (typeof pattern === 'string') {
        return transformed === pattern;
    }
    if (Array.isArray(pattern)) {
        return pattern.includes(transformed);
    }
    return false;
};

export const actionPatternTest = patternTestFactory({ transform: action => action.type });
export const listIdPatternTest = patternTestFactory();
