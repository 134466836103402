import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import type { FormRenderProps } from 'react-final-form';

import { resetImages } from '../services/actions';
import { selectImages } from '../services/selectors';
import { DEBOUNCE_INTERVAL } from '../constants';
import type { ImageSearchFormValues } from '../types';

const useImageSearch = (
    values: ImageSearchFormValues,
    handleSubmit: FormRenderProps<ImageSearchFormValues>['handleSubmit'],
) => {
    const dispatch = useDispatch();
    const submitDebounced = useDebouncedCallback(handleSubmit, DEBOUNCE_INTERVAL);

    const images = useSelector(selectImages);

    useEffect(() => {
        if (values.q) {
            submitDebounced();
        }
    }, [values, submitDebounced]);

    useEffect(() => {
        submitDebounced();

        return () => {
            dispatch(resetImages());
        };
    }, [dispatch, submitDebounced]);

    return images;
};

export default useImageSearch;
