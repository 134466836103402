import React, { FC, createContext, Context, useCallback } from 'react';
import { notification } from 'antd';
import type { NotificationInstance } from 'antd/es/notification';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ToastProvider.styles';

const ToastContext = createContext(null);

const ToastProvider: FC & { Context: Context<NotificationInstance['open']> } = ({ children }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const [api, contextHolder] = notification.useNotification();

    const open: NotificationInstance['open'] = useCallback(
        options => {
            api.open({
                placement: 'bottomLeft',
                duration: 5,
                ...options,
            });
        },
        [api],
    );

    return (
        <div className={styles.container}>
            <ToastContext.Provider value={open}>
                {contextHolder}
                {children}
            </ToastContext.Provider>
        </div>
    );
};

ToastProvider.Context = ToastContext;

export default ToastProvider;
