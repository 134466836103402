import purplePreview from 'assets/images/video-bg/color_01.png';
import orangePreview from 'assets/images/video-bg/color_02.png';
import redPreview from 'assets/images/video-bg/color_03.png';
import purple2Preview from 'assets/images/video-bg/color_04.png';
import aquaPreview from 'assets/images/video-bg/color_05.png';
import blackPreview from 'assets/images/video-bg/color_06.png';
import yellowPreview from 'assets/images/video-bg/color_07.png';
import greenPreview from 'assets/images/video-bg/color_08.png';
import sandPreview from 'assets/images/video-bg/color_09.png';
import yellow2Preview from 'assets/images/video-bg/color_10.png';

import type { TRuleWithTheme } from 'styles/theme';

import { RandomDrawColors } from '../../constants';
import type { VideoPreviewProps } from './VideoPreview';
import theme from 'styles/theme';
import { combineRules } from 'fela';

const backgroundsMap: Record<RandomDrawColors, string> = {
    [RandomDrawColors.PURPLE]: purplePreview,
    [RandomDrawColors.ORANGE]: orangePreview,
    [RandomDrawColors.RED]: redPreview,
    [RandomDrawColors.PURPLE2]: purple2Preview,
    [RandomDrawColors.AQUA]: aquaPreview,
    [RandomDrawColors.BLACK]: blackPreview,
    [RandomDrawColors.YELLOW]: yellowPreview,
    [RandomDrawColors.GREEN]: greenPreview,
    [RandomDrawColors.SAND]: sandPreview,
    [RandomDrawColors.YELLOW2]: yellow2Preview,
};

export const preview: TRuleWithTheme<Required<Pick<VideoPreviewProps, 'color'>>> = ({ color, theme }) => ({
    width: 280,
    height: 497,

    display: 'flex',
    flexDirection: 'column',

    border: `2px solid ${theme.colors.inputBorder}`,
    borderRadius: theme.borderRadius.large,

    transition: 'background 1s',

    backgroundImage: `url(${backgroundsMap[color]})`,
    backgroundSize: 'cover',
});

const sectionBase: TRuleWithTheme = () => ({
    color: theme.colors.white,

    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '1rem',

    wordBreak: 'break-all',

    fontWeight: '800',
    textAlign: 'center',
    letterSpacing: '0',
});

const HEADLINE_SIZE_PERC = 46;
const SUBHEADLINE_SIZE_PERC = 30;

export const subHeadline: TRuleWithTheme = combineRules(sectionBase, () => ({
    flexBasis: `${SUBHEADLINE_SIZE_PERC}%`,
    alignItems: 'flex-end',

    fontSize: '0.875rem',
}));

export const headline: TRuleWithTheme = combineRules(sectionBase, () => ({
    flexBasis: `${HEADLINE_SIZE_PERC}%`,

    fontSize: '1.25rem',
}));

export const footer: TRuleWithTheme = combineRules(sectionBase, () => ({
    flexBasis: `${100 - HEADLINE_SIZE_PERC - SUBHEADLINE_SIZE_PERC}%`,

    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const stats: TRuleWithTheme = combineRules(sectionBase, () => ({
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    width: '100%',
    padding: `1rem 0.5rem`,

    fontSize: '0.75rem',
}));

export const column: TRuleWithTheme = () => ({
    display: 'inline-flex',

    flexDirection: 'column',
    alignItems: 'center',
});

export const count: TRuleWithTheme = ({ theme }) => ({
    minWidth: '1rem',
    marginBottom: '0.5rem',

    '&:empty': {
        borderBottom: `1px solid ${theme.colors.white}`,
    },
});
