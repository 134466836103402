import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import { ApiReducerKey, EntityKey, DEFAULT_GROUP } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { denormalizeArticle, denormalizeArticles } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';
import type { Article } from 'modules/entities/types';

export const selectArticleApi = state => apiSelector(state, EntityKey.ARTICLES, ApiReducerKey.DETAIL);
export const selectArticlesApi = state => apiSelector(state, EntityKey.ARTICLES, ApiReducerKey.LIST);

export const selectArticlesById = createSelector(entitiesSelectors.selectArticlesEntities, articles => articles.byId);

export const selectArticles = createSelector(entitiesSelectors.selectArticlesEntities, articles => {
    const denormalizedArticles: Array<Article> = denormalizeArticles(articles[DEFAULT_GROUP], {
        [EntityKey.ARTICLES]: articles.byId,
    });
    return denormalizedArticles;
});

export const selectArticle = createSelector(
    [entitiesSelectors.selectArticlesEntities, selectProp<Article['id']>('id')],
    (articles, id) =>
        denormalizeArticle(id, {
            [EntityKey.ARTICLES]: articles.byId,
        }),
);
