import { TRuleWithTheme } from 'styles/theme';

export const alert: TRuleWithTheme = ({ theme }) => ({
    alignItems: 'flex-start',
    padding: `${theme.spacing.small} 1.5rem`,

    color: theme.colors.textDark,
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadow.panel,
    borderRadius: theme.borderRadius.panel,

    '& .ant-alert-message': {
        maxWidth: 560,
    },

    '& .ant-alert-icon': {
        paddingTop: 4,
        marginRight: theme.spacing.xsmall,
        color: theme.colors.textGray,
    },

    '& .ant-alert-close-text': {
        color: theme.colors.textDark,

        ':hover': {
            color: theme.colors.primary,
        },
    },
});
