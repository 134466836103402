import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { readAndCompressImage } from 'browser-image-resizer';
import { noop } from 'lodash';

import * as log from 'config/loglevel';
import type { Schema } from 'modules/entities';
import { selectUpdateAccountGroupApi } from 'modules/entities/modules/account-groups';
import { selectCreateMediaApi } from 'modules/entities/modules/media';
import { useErrorToast } from 'modules/errors';
import type { ApiState } from 'types';

import * as actions from '../services/actions';

const useChangeAccountGroupPicture = (id: Schema['AccountGroup']['id'], hide: () => void = noop) => {
    const [picture, setPicture] = useState<File | Blob>(null);
    const [croppedPicture, setCroppedPicture] = useState<File>(null);
    const [uploaded, setUploaded] = useState(false);
    const dispatch = useDispatch();

    const accountGroupsApi: ApiState = useSelector(selectUpdateAccountGroupApi);
    const mediaApi: ApiState = useSelector(selectCreateMediaApi);

    const upload = async () => {
        try {
            const resizedPicture = await readAndCompressImage(croppedPicture, {
                mimeType: croppedPicture.type,
                quality: 0.8,
                maxHeight: 100,
            });

            dispatch(actions.changeAccountGroupPicture(id, resizedPicture));
            setUploaded(true);
        } catch (error) {
            log.error(error);
        }
    };

    const reset = () => {
        setPicture(null);
        setUploaded(false);
    };

    useEffect(() => {
        return () => {
            dispatch(actions.changeAccountGroupPictureCancel(id));
            reset();
        };
    }, [dispatch, id]);

    const success = uploaded && accountGroupsApi.success && mediaApi.success;

    useEffect(() => {
        if (success) {
            reset();
            hide();
        }
    }, [success, hide]);

    const error = accountGroupsApi.error ?? mediaApi.error;
    useErrorToast(error, 'error.api.accountGroupPicture');

    return {
        picture,
        inProgress: accountGroupsApi.inProgress || mediaApi.inProgress,
        setPicture,
        setCroppedPicture,
        upload,
    };
};

export default useChangeAccountGroupPicture;
