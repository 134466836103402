import React, { FC } from 'react';

export interface CrossProps {}

const Cross: FC<CrossProps> = (props) => (
    <svg width="8" height="8" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5.414 4l2.293-2.293A.999.999 0 106.293.293L4 2.586 1.707.293A.999.999 0 10.293 1.707L2.586 4 .293 6.293a.999.999 0 101.414 1.414L4 5.414l2.293 2.293a.997.997 0 001.414 0 .999.999 0 000-1.414L5.414 4z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default Cross;
