import React, { FC } from 'react';

const CarretDown: FC = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8 9a.997.997 0 01-.707-.293l-7-7A.999.999 0 111.707.293L8 6.586 14.293.293a.999.999 0 111.414 1.414l-7 7A.997.997 0 018 9"
        ></path>
    </svg>
);

export default CarretDown;
