export const forms = {
    login: 'login',
    passwordRecovery: 'passwordRecovery',
    resetPassword: 'resetPassword',
};

export enum LoginFormField {
    Email = 'email',
    Password = 'password',
}

export const PASSWORD_LENGTH = 8;

export enum PasswordRecoveryFormField {
    Email = 'reset-email',
}

export enum ResetPasswordFormField {
    ResetToken = 'token',
    NewPassword = 'password',
}