import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { BadgeAvatar, Icon, IconTextButton, Paragraph } from 'modules/ui';
import type { Schema } from 'modules/entities';
import { selectGroupAccounts } from 'modules/entities/modules/account-groups';
import { useConnectSocialProfiles } from 'modules/social-accounts';

import * as felaRules from './AccountAvatarList.styles';

export interface AccountsListProps {
    groupId?: Schema['AccountGroup']['id'];
}

const AccountAvatarList: FC<AccountsListProps> = ({ groupId }) => {
    const { styles } = useFelaEnhanced(felaRules);

    const accounts = useSelector(state => selectGroupAccounts(state, { id: groupId }));
    const [, setVisible] = useConnectSocialProfiles();

    return (
        <div className={styles.accounts}>
            <Paragraph faded size="x-small">
                <FormattedMessage id="posts.connectedAccounts" />
            </Paragraph>

            <ul className={styles.list}>
                {accounts.map(account => (
                    <li key={account.id} className={styles.item}>
                        <BadgeAvatar
                            src={account.profilePicture}
                            badgeIconType={
                                account.type === 'facebook' ? Icon.Types.FACEBOOK_ROUND : Icon.Types.INSTAGRAM_ROUND
                            }
                        />
                    </li>
                ))}
                <li>
                    <IconTextButton
                        icon={Icon.Types.PLUS}
                        iconSize="large"
                        color="default"
                        onClick={() => setVisible(true)}
                        data-testid="addAccountButton"
                    />
                </li>
            </ul>
        </div>
    );
};

export default AccountAvatarList;
