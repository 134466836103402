const namedKeys = {
    desktop: '@media (min-width: 1024px)',
    tablet: '@media (min-width: 768px)',
    smallTablet: '@media (min-width: 440px)',

    maxTablet: '@media (max-width: 767px)',
    maxDesktop: '@media (max-width: 1024px)',
};

export default namedKeys;
