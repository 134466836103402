import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.textLight,
    borderRadius: 11,

    '& .ant-switch-handle': {
        width: 29,

        ':before': {
            backgroundColor: theme.colors.textGray,
            borderRadius: 9,
        },
    },

    '&.ant-switch-checked': {
        backgroundColor: theme.colors.buttonDefaultBackground,

        '& .ant-switch-handle': {
            left: 'calc(100% - 29px - 2px)',

            ':before': {
                backgroundColor: theme.colors.buttonDefault,
            },
        },
    },

    '&.ant-switch-disabled': {
        opacity: 1,

        '& .ant-switch-handle:before': {
            opacity: 0.5,
        },
    },
});
