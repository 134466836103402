import type { FC, SVGAttributes } from 'react';

interface LogoProps extends SVGAttributes<SVGElement> {}

const Logo: FC<LogoProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="30"
        {...props}
        viewBox="0 0 140 48"
    >
        <defs>
            <path id="logo_a" d="M0 48h138.47V0H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="M45.1 23.734c1.813 0 3.96-1.332 3.96-4.145 0-1.406-.518-4.33-3.96-4.33h-6.217v8.475H45.1zM33.072 37.24V10.19H45.1c5.81 0 9.77 3.849 9.77 9.4 0 3.923-2.147 7.142-5.514 8.586 2.331 2.923 4.81 6.18 6.994 9.066h-6.994c-2.22-2.775-4.256-5.477-6.477-8.437h-3.996v8.437h-5.81zM67.896 21.291c-3.515 0-5.588 2.443-5.588 5.773 0 3.22 2.184 5.736 5.588 5.736 3.442 0 5.588-2.183 5.588-5.736 0-3.367-2.146-5.773-5.588-5.773zm5.366 13.915c-.85 1.147-3.293 2.48-6.18 2.48-6.44 0-10.214-4.774-10.214-10.622 0-5.847 3.775-10.62 10.214-10.62 2.96 0 5.144 1.183 6.18 2.553v-2.11h5.477v20.354h-5.477v-2.035zM89.583 37.241h-5.551V21.957h-2.295v-4.736h2.295c.185-5.367 3.182-8.105 8.104-8.105.703 0 1.555.11 2.443.333l-.296 4.478a5.5 5.5 0 00-2.073-.185c-2.48.259-2.627 2.11-2.627 3.479h3.664v4.736h-3.664v15.284zM102.906 37.241h-5.551V21.957H95.06v-4.736h2.295c.185-5.367 3.182-8.105 8.104-8.105.703 0 1.554.11 2.443.333l-.296 4.478c-.63-.185-1.37-.26-2.073-.185-2.48.259-2.627 2.11-2.627 3.479h3.663v4.736h-3.663v15.284z"
                fill="#000"
            />
            <mask id="logo_bb" fill="#fff">
                <use xlinkHref="#logo_a" />
            </mask>
            <path
                fill="#000"
                mask="url(#b)"
                d="M109.79 37.241h5.55V9.264h-5.55zM119.485 28.989V16.96h5.588V28.99c0 3.108 1.85 4.07 3.96 4.07 1.554 0 4.034-.925 3.885-5.329V16.961h5.552v20.28h-5.552v-1.739c-1.332 1.37-3.293 2.183-5.44 2.183-5.847 0-7.993-3.441-7.993-8.696"
            />
            <path
                d="M15.565 27.711H1.088c-.6 0-1.088-.487-1.088-1.088v-5.225c0-.601.487-1.089 1.088-1.089h14.477c.601 0 1.089.488 1.089 1.089v5.225c0 .6-.488 1.088-1.089 1.088"
                fill="#3EC0D9"
                mask="url(#b)"
            />
            <path
                d="M15.968 15.79L5.731 5.551a1.088 1.088 0 010-1.539L9.426.32a1.088 1.088 0 011.54 0l10.236 10.237a1.088 1.088 0 010 1.539l-3.695 3.694a1.088 1.088 0 01-1.539 0"
                fill="#5DE593"
                mask="url(#b)"
            />
            <path
                d="M21.202 37.445L10.965 47.68a1.088 1.088 0 01-1.539 0l-3.695-3.694a1.088 1.088 0 010-1.54l10.237-10.236a1.088 1.088 0 011.54 0l3.694 3.694a1.088 1.088 0 010 1.54"
                fill="#8F62D7"
                mask="url(#b)"
            />
        </g>
    </svg>
);

export default Logo;
