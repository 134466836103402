import type { FC } from 'react';

import FormSummaryRow from './FormSummaryRow';
import FormSummaryItem from './FormSummaryItem';

export interface FormSummaryProps {
    className?: string;
}

const FormSummary: FC<FormSummaryProps> & { Row: typeof FormSummaryRow; Item: typeof FormSummaryItem } = ({
    children,
    className,
}) => <section className={className}>{children}</section>;

FormSummary.Row = FormSummaryRow;
FormSummary.Item = FormSummaryItem;

export default FormSummary;
