import { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .ant-form-item': {
        marginBottom: theme.spacing.xsmall,
    },

    tablet: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

export const buttons: TRuleWithTheme = () => ({
    marginTop: 0,
});
