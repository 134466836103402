import type { FC, ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './PostMediaSkeleton.styles';

export interface PostMediaSkeletonProps {
    thumbnailCount?: number;
    renderThumbnail?: (index: number) => ReactNode;
    footer?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const PostMediaSkeleton: FC<PostMediaSkeletonProps> = ({
    thumbnailCount = 5,
    renderThumbnail: renderItem,
    children,
    footer,
    extend,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <section className={styles.media}>
            <div className={styles.mainContent}>{children}</div>

            {thumbnailCount > 0 && (
                <ul className={styles.list}>
                    {Array(thumbnailCount)
                        .fill(null)
                        .map((_, index) => (
                            <li key={String(index)} className={styles.listItem}>
                                {renderItem && renderItem(index)}
                            </li>
                        ))}
                </ul>
            )}

            {footer && <footer>{footer}</footer>}
        </section>
    );
};

export default PostMediaSkeleton;
