import { TRuleWithTheme } from 'styles/theme';

export const page: TRuleWithTheme = ({ theme }) => ({
    paddingTop: theme.spacing.small,
    paddingBottom: theme.spacing.small,

    desktop: {
        paddingTop: theme.spacing.large,
        paddingBottom: theme.spacing.large,
    },
});

export const pageHeader: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.small,

    '& .ant-typography': {
        marginBottom: 0,
    },

    desktop: {
        marginBottom: '2rem',
    },
});

export const errorAlert: TRuleWithTheme = () => ({
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const footer: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3.5rem',
});

export const pageContent: TRuleWithTheme = ({ theme }) => ({
    desktop: {
        margin: `0 ${theme.spacing.small}`,
    },
});
