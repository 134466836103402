import type { FC } from 'react';
import { Row } from 'antd';

import * as felaRules from './FloatingCard.styles';
import Card from '../Card';

const FloatingCard: FC = ({ children }) => (
    <Card extend={{ card: felaRules.floatingCard }} title={null}>
        <Row gutter={[20, 20]} justify="center">
            {children}
        </Row>
    </Card>
);

export default FloatingCard;
