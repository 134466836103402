import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, Icon, List, Paragraph } from 'modules/ui';
import { selectAccountsApi } from 'modules/entities/modules/accounts';
import { useDeleteAccountInList } from 'modules/social-accounts/hooks';
import type { Schema } from 'modules/entities';

import * as felaRules from './ConnectSocialProfilesList.styles';
import RevokeAccountButton from '../RevokeAccountButton';

export interface ConnectSocialProfilesListProps {
    accounts: Schema['Account'][];
}

const placeholderAccounts = [{ id: '1' } as Schema['Account']];

const ConnectSocialProfilesList: FC<ConnectSocialProfilesListProps> = ({ accounts }) => {
    const { inProgress } = useSelector(selectAccountsApi);
    const [isDeleteInProgress, deleteAccount] = useDeleteAccountInList();

    return (
        <List>
            {(inProgress ? placeholderAccounts : accounts).map(account => (
                <List.Item
                    key={account.id}
                    loading={inProgress}
                    actions={[
                        <RevokeAccountButton
                            key="revoke"
                            type="text"
                            icon={<Icon type={Icon.Types.BIN} />}
                            loading={isDeleteInProgress(account.id)}
                            disabled={isDeleteInProgress()}
                            onClick={() => deleteAccount(account.id)}
                        />,
                    ]}
                >
                    <List.Item.Meta
                        avatar={<Avatar size={32} src={account.profilePicture} alt={account.type} />}
                        title={<Paragraph>{account.name}</Paragraph>}
                        extend={{ meta: felaRules.listItemMeta }}
                    />
                </List.Item>
            ))}
        </List>
    );
};

export default ConnectSocialProfilesList;
