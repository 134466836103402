import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { FC } from 'react';

import { useFelaEnhanced, useScrollOffset } from 'hooks';
import { Container } from 'modules/ui';

import * as felaRules from './Header.styles';
import Navigation from '../Navigation';
import MobileNavigation from '../MobileNavigation';
import NavUser from '../NavUser';
import HeaderLogo from './HeaderLogo';

const useMobileMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    React.useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    return { menuOpen, setMenuOpen };
};

const Header: FC = () => {
    const [offset, direction] = useScrollOffset();
    const isScrolled = offset > 0;

    const { menuOpen, setMenuOpen } = useMobileMenu();
    const { styles, rules } = useFelaEnhanced(felaRules, { isScrolled, direction, menuOpen });

    return (
        <header className={styles.header}>
            <Container size="large" extend={{ container: rules.container }}>
                <HeaderLogo bigger={menuOpen} />

                <div className={styles.menu}>
                    <Navigation headerScrolled={isScrolled} />
                </div>

                <div className={styles.rightColumn}>
                    <span className={styles.userWrapper}>
                        <NavUser isHeaderScrolled={isScrolled} />
                    </span>
                    <MobileNavigation onClick={() => setMenuOpen(!menuOpen)} open={menuOpen} />
                </div>
            </Container>
        </header>
    );
};

export default Header;
