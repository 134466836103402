import React, { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button, ButtonOwnProps } from 'modules/ui';
import config from 'config';
import { fullLocaleMapping } from 'translations';

import * as facebookLoginButtonStyles from './FacebookOAuthButton.styles';
import { useOAuth } from '../../hooks';
import { OAuthProvider } from '../../types';
import type { AuthType } from '../../hooks/useOAuth';

export interface FacebookOAuthButtonProps extends ButtonOwnProps {
    provider?: OAuthProvider;
    authType?: AuthType;
    scopes?: string[];
    children?: ReactNode;
}

const FacebookOAuthButton: FC<FacebookOAuthButtonProps> = ({
    provider = OAuthProvider.Facebook,
    authType = 'login',
    scopes = config.auth.facebook.basicScopes,
    children = <FormattedMessage id="auth.facebook.button" />,
    ...props
}) => {
    const { rules } = useFelaEnhanced(facebookLoginButtonStyles);
    const { locale } = useIntl();

    const { started, inProgress, handleResponse, handleFailure, startAuth } = useOAuth(authType, provider);

    return (
        <>
            <FacebookLogin
                appId={config.auth.facebook.appId}
                scope={scopes.join(',')}
                language={fullLocaleMapping[locale]}
                onClick={startAuth}
                onFailure={handleFailure}
                callback={handleResponse}
                disableMobileRedirect
                render={({ onClick, isSdkLoaded, isDisabled }) => (
                    <Button
                        size="large"
                        block
                        disabled={inProgress || isDisabled || !isSdkLoaded}
                        loading={started && inProgress}
                        onClick={onClick}
                        extend={{ button: rules.button }}
                        {...props}
                    >
                        {children}
                    </Button>
                )}
            />
        </>
    );
};

export default FacebookOAuthButton;
