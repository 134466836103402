import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { FC } from 'react';

import { IconTextButton, Icon } from 'modules/ui';
import useAddNewGroup from './useAddNewGroup';

export interface AddGroupButtonProps {}

const AddGroupButton: FC<AddGroupButtonProps> = () => {
    const [handleAddGroup, api] = useAddNewGroup();

    return (
        <IconTextButton
            size="large"
            icon={Icon.Types.PLUS}
            iconSize="large"
            onClick={handleAddGroup}
            loading={api.inProgress}
        >
            <FormattedMessage id="profile.groups.new" />
        </IconTextButton>
    );
};

export default AddGroupButton;
