import type { TRule } from 'fela';

import { locals as colors } from './colors.less';
import { locals as borderRadius } from './borderRadius.less';
import { locals as shadow } from './shadows.less';
import { locals as zIndex } from './zIndex.less';

export const breakpoints = {
    mobile: 425,
    tablet: 768,
};

const theme = {
    colors,
    borderRadius,
    shadow,
    sizes: {
        icon: {
            xsmall: 12,
            xlarge: 80,
            large: 40,
            medium: 24,
            small: 14,
        },
        button: {
            large: 44,
            block: 56,
        },
        container: {
            large: 1600,
            medium: 950,
        },
        modal: {
            confirm: 480,
        },
        infiniteList: {
            rowHeight: 254,
        },
    },
    spacing: {
        xsmall: '1rem',
        small: '1.25rem',
        medium: '1.5rem',
        large: '2.5rem',
    },
    transition: {
        duration: '0.2s',
    },
    zIndex,
};

export type Theme = typeof theme;
export type ThemeProps = { theme: Theme };

export type TRuleWithTheme<Props = {}> = TRule<ThemeProps & Props>;

export type RulesExtend<TExtandalbeRules, TProps = {}> = Partial<
    Record<keyof TExtandalbeRules, TRuleWithTheme<TProps>>
>;

export default theme;
