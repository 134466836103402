import * as sagaEffects from 'redux-saga/effects';

import { saga as petrus } from '../../config/petrus';

import loginForm from './loginForm';
import signUpForm from './signUpForm';
import oauthLogin from './oauthLogin';
import oauthAddAccount from './oauthAddAccount';
import passwordRecoveryForm from './passwordRecoveryForm';
import resetPasswordForm from './resetPasswordForm';
import fetchUser from './fetchUser';

const { all } = sagaEffects;

export default function* authSaga() {
    yield all([
        loginForm(),
        signUpForm(),
        passwordRecoveryForm(),
        resetPasswordForm(),
        petrus(),
        oauthLogin(),
        oauthAddAccount(),
        fetchUser(),
    ]);
}
