import { call, put, select } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiGet, ApiResponse } from 'config/antonio';
import { operations, components } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';
import { createUIErrorMessage } from 'modules/errors';
import { authUserSelector } from 'modules/auth';
import { normalizeAccountGroups } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import { types, fetchAccountGroups as apiActions } from '../actions';

type Operation = operations['accountGroupList'];
type Response = ApiResponse<components['responses']['AccountGroup']['content']['application/json'][]>;
type RequestParams = Operation['parameters']['query'];

function* fetchAccountGroupsHandler(action) {
    const user = yield select(authUserSelector);

    try {
        const { data }: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.accountGroups,
            {
                params: {
                    ...action.payload,
                    userId: user.id,
                },
            },
        );

        const { result: ids, entities } = normalizeAccountGroups(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                ids,
                byId: entities[EntityKey.ACCOUNT_GROUPS],
            }),
            entitiesActions.setEntities(EntityKey.ACCOUNTS, entities[EntityKey.ACCOUNTS]),

            apiActions.fetchAccountGroupsSuccess(),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.fetchAccountGroupsFailure(uiError));

        log.error(error);
    }
}

function* fetchAccoungGroupCancelHandler() {
    yield put(entitiesActions.resetEntitiesGroup(EntityKey.ACCOUNT_GROUPS));
}

export default function* fetchAccountGroups() {
    yield takeLatestRequest(
        () => 'fetchAccountGroups',
        {
            pattern: types.fetchAccountGroups.FETCH_ACCOUNT_GROUPS_REQUEST,
            handler: fetchAccountGroupsHandler,
        },
        {
            pattern: types.fetchAccountGroups.FETCH_ACCOUNT_GROUPS_CANCEL,
            handler: fetchAccoungGroupCancelHandler,
        },
    );
}
