import type { TRule } from 'fela';

export const cardHeading: TRule = () => ({
    marginTop: '0',
    marginBottom: '2rem',

    desktop: {
        marginTop: '0.875rem',
    },
});

export const formItem: TRule = () => ({
    marginBottom: 0,
});
