import type { FC } from 'react';
import { List } from 'antd';
import type { ListItemProps } from 'antd/es/list';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import DisabledContent from '../../DisabledContent';
import * as felaRules from './PanelItem.styles';

export interface PanelItemProps extends ListItemProps {
    disabled?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const PanelItem: FC<PanelItemProps> = ({ disabled = false, children, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <DisabledContent disabled={disabled}>
            <List.Item {...props} className={styles.item}>
                {children}
            </List.Item>
        </DisabledContent>
    );
};

export default PanelItem;
