import { TRuleWithTheme } from 'styles/theme';
import type { DisabledContentProps } from './DisabledContent';

export const content: TRuleWithTheme<Pick<DisabledContentProps, 'disabled'>> = ({ disabled }) => ({
    position: 'relative',
    opacity: disabled ? 0.5 : 1,

    extend: {
        condition: disabled,
        style: {
            ':after': {
                content: '" "',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    },
});
