import { basicApiReducer } from '@ackee/redux-utils';

import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.updateCompetition.UPDATE_COMPETITION_REQUEST,
        SUCCESS: types.updateCompetition.UPDATE_COMPETITION_SUCCESS,
        FAILURE: types.updateCompetition.UPDATE_COMPETITION_FAILURE,
        CANCEL: types.updateCompetition.UPDATE_COMPETITION_CANCEL,
        RESET: types.updateCompetition.UPDATE_COMPETITION_RESET,
    },
});
