import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Intro } from 'modules/ui';
import { Form, TextField, useFormSubmit, validatorsWithMessage, composeValidators, translate } from 'modules/form';
import { ErrorAlert } from 'modules/errors';

import { PasswordRecoveryFormField, forms } from '../../config';
import { passwordRecoveryForm } from '../../services/actions';

import * as felaRules from './PasswordRecoveryForm.styles';

export interface PasswordRecoveryFormProps {
    onSubmitSucceeded: () => void;
}

const { required, email } = validatorsWithMessage;

const validators = {
    [PasswordRecoveryFormField.Email]: composeValidators(required, email),
};

const PasswordRecoveryForm: FC<PasswordRecoveryFormProps> = ({ onSubmitSucceeded }) => {
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit(passwordRecoveryForm);

    return (
        <>
            <Intro
                title={<FormattedMessage id="auth.passwordRecovery.form.title" />}
                text={<FormattedMessage id="auth.passwordRecovery.form.text" />}
            />

            <Form name={forms.passwordRecovery} onSubmit={onSubmit} onSubmitSucceeded={onSubmitSucceeded}>
                {({ submitting, submitError }) => (
                    <>
                        <ErrorAlert error={submitError} extend={{ alert: felaRules.alert }} />

                        <TextField
                            name={PasswordRecoveryFormField.Email}
                            type="email"
                            label={<FormattedMessage id="auth.email" />}
                            disabled={submitting}
                            validate={translate(formatMessage, validators[PasswordRecoveryFormField.Email])}
                        />

                        <Button
                            type="primary"
                            size="large"
                            block
                            htmlType="submit"
                            disabled={submitting}
                            loading={submitting}
                        >
                            <FormattedMessage id="auth.passwordRecovery.form.button" />
                        </Button>
                    </>
                )}
            </Form>
        </>
    );
};

export default PasswordRecoveryForm;
