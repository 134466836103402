import { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Link, generatePath } from 'react-router-dom';
import { Heading, Paragraph, Card, Icon } from 'modules/ui';
import * as felaRules from './ArticlesCard.styles';
import config from 'config';

import { useParseHtml } from '../../hooks';

export interface ArticlesCardProps {
    title: string;
    coverImage: string;
    excerpt: string;
    id: string;
}

const ArticlesCard: FC<ArticlesCardProps> = ({ title, coverImage, excerpt, id }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const parsedExcerpt = useParseHtml(excerpt.substring(0, 100) + '...' || '');

    return (
        <Link to={generatePath(config.routes.tipsPosts, { postId: id })}>
            <Card clickable={true} extend={{ card: felaRules.card }}>
                <div className={styles.box}>
                    <img className={styles.image} alt={title} src={coverImage} />
                    <section className={styles.section}>
                        <div className={styles.text}>
                            <Heading level={3}>{title}</Heading>
                            <div>{parsedExcerpt}</div>
                        </div>
                        <Paragraph faded>
                            <Icon size="xsmall" type={Icon.Types.FORWARD} />
                        </Paragraph>
                    </section>
                </div>
            </Card>
           
        </Link>

    );
};

export default ArticlesCard;
