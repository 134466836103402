import React from 'react';
import type { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import Logo from '../Logo';

import * as felaRules from './BlankPage.styles';

export interface BlankPageProps {}

const BlankPage: FC<BlankPageProps> = ({ children }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />

            {children}
        </div>
    );
};

export default BlankPage;
