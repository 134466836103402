import React, { FC } from 'react';

import Avatar from '../Avatar';
import Badge from '../Badge';
import Icon from '../Icon';

import type { AvatarOwnProps } from '../Avatar/Avatar';
import type { Types } from '../Icon/Icon';

export interface BadgeAvatarProps extends AvatarOwnProps {
    badgeIconType: Types;
}

const BadgeAvatar: FC<BadgeAvatarProps> = ({ badgeIconType, ...props }) => (
    <Badge count={<Icon type={badgeIconType} />} offset={[-35, 10]}>
        <Avatar size={40} {...props} />
    </Badge>
);

export default BadgeAvatar;
