import React, { FunctionComponent } from 'react';
import { ErrorBoundary } from '@sentry/react';
import * as serviceWorker from 'serviceWorker';

import * as config from '../config';

import { Fela } from '../modules/fela';
import { Redux } from '../modules/redux';
import { Router } from '../modules/router';

import Enhancers from './Enhancers';

type CoreProps = { children: React.ReactNode };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
config.plugins.serviceWorker ? serviceWorker.register() : serviceWorker.unregister();

const Core: FunctionComponent<CoreProps> = ({ children }) => (
    <ErrorBoundary fallback={null}>
        <Redux>
            <Fela>
                <Router>
                    <Enhancers>{children}</Enhancers>
                </Router>
            </Fela>
        </Redux>
    </ErrorBoundary>
);

export default Core;
