import { FC, lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { Page } from 'modules/ui';

const InspirationTipsPage: FC = () => {
    const ArticlesList = lazy(() => import('../../../articles/components/ArticlesList'));

    return (
        <Page containerSize="large" heading={<FormattedMessage id="tipsInspiration.title" />}>
            <Suspense fallback={null}>
                <ArticlesList />
            </Suspense>
        </Page>
    );
};

export default InspirationTipsPage;
