import { TRuleWithTheme } from 'styles/theme';

export const navItem: TRuleWithTheme<{ compact: boolean; hideTitle: boolean }> = ({ theme, compact, hideTitle }) => ({
    display: 'inline-flex',
    alignItems: 'center',

    padding: compact ? '0.75rem 1.5rem' : '1.125rem 1.5rem',

    color: 'inherit',
    borderRadius: theme.borderRadius.navItem,
    textDecoration: 'none',

    ':hover': {
        color: 'inherit',

        '& .nav-title': {
            display: 'inline',
        },
    },

    '& .anticon': {
        color: 'inherit',
        height: 24,
        marginRight: '0.75rem',
    },

    '& .anticon svg': {
        height: 24,
    },

    ...(hideTitle && {
        '& .nav-title': {
            display: 'none',
        },
    }),

    '&.active': {
        backgroundColor: theme.colors.primarySelected,
    },

    '&.active .nav-title': {
        display: 'inline',
    },
});
