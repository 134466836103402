import type { FC } from 'react';
import { ProgressProps as AntProgressProps, Progress as AntProgress } from 'antd';

import type { RulesExtend } from 'styles/theme';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Progress.styles';

interface ProgressProps extends AntProgressProps {
    extend?: RulesExtend<typeof felaRules>;
    showPercentage?: boolean;
}

const Progress: FC<ProgressProps> = ({ extend, showPercentage = true, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, showPercentage });
    return <AntProgress className={styles.progress} {...rest} />;
};

export default Progress;
