import { createSelector } from 'reselect';

import { selectSocialPost } from 'modules/post/services/selectors';
import { selectProp } from 'services/selectors';
import type { Account } from 'modules/entities';

export const selectSummaryPost = createSelector(
    [selectProp<Account['type']>('type'), selectSocialPost],
    (accountType, post) => {
        if (!post || !accountType) {
            return null;
        }

        switch (accountType) {
            case 'facebook':
                return {
                    content: post.message,
                    comment: post.comment,
                    media: [{ url: post.full_picture }],
                };

            case 'instagram':
                return {
                    content: post.caption,
                    comment: post.comments_count,
                    media: [{ url: post.media_url }],
                };
        }
    },
);
