import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFelaEnhanced } from 'hooks';
import { UploadableAvatar, Button, Heading, Icon, DisabledContent } from 'modules/ui';
import { selectAccountsById } from 'modules/entities/modules/accounts';
import { NormalizedAccountGroup } from 'modules/entities/types';
import { useConnectSocialProfiles } from 'modules/social-accounts';
import { actions as accountGroupsActions } from 'modules/entities/modules/account-groups';

import defaultGroupPicture from 'assets/images/default_user_group.png';

import * as felaRules from './AccountGroup.styles';
import AccountGroupList from '../AccountGroupList';
import ChangeAccountGroupForm from '../ChangeAccountGroupForm';
import { useEditMode } from '../EditModeProvider';
import useAccountGroupActions from './useAccountGroupActions';
import AccountGroupPictureManagementModal from '../AccountGroupPictureManagementModal';
import DeleteButton from './DeleteButton';

export interface AccountGroupProps {
    group: Partial<NormalizedAccountGroup>;
    deleteAllowed?: boolean;
    draggingAccountId?: string;
}

const AccountGroup: FC<AccountGroupProps> = ({ group, draggingAccountId, deleteAllowed = true }) => {
    const { editMode, setEditMode, closeEditMode } = useEditMode();
    const [pictureModalVisible, setPictureModalVisible] = useState(false);
    const [, setConnectModalVisible] = useConnectSocialProfiles();
    const dispatch = useDispatch();

    const isEditMode = editMode === group.id;
    const { onDelete } = useAccountGroupActions(group.id);
    const accountsMap = useSelector(selectAccountsById);

    const { styles } = useFelaEnhanced(felaRules);

    const handleAddAccount = () => {
        setConnectModalVisible(true);
        dispatch(accountGroupsActions.setSelectedGroupId(group.id));
    };

    return (
        <article className={styles.container}>
            <header className={styles.header}>
                <div className={styles.info}>
                    <UploadableAvatar
                        size={48}
                        src={group.profilePicture ?? defaultGroupPicture}
                        alt={group.name}
                        onClick={() => setPictureModalVisible(true)}
                        extend={{ avatar: felaRules.avatar }}
                    />

                    {isEditMode ? (
                        <ChangeAccountGroupForm id={group.id} name={group.name} closeEditMode={closeEditMode} />
                    ) : (
                        <Heading level={4}>{group.name}</Heading>
                    )}
                </div>

                {!isEditMode && (
                    <div className={styles.actions}>
                        <Button
                            type="text"
                            icon={<Icon type={Icon.Types.EDIT} />}
                            onClick={() => setEditMode(group.id)}
                        />
                        {deleteAllowed && <DeleteButton onDelete={onDelete} name={group.name} />}
                    </div>
                )}
            </header>

            <DisabledContent disabled={isEditMode} extend={{ content: felaRules.content }}>
                <AccountGroupList
                    groupId={group.id}
                    items={group.accounts}
                    accounts={group.accounts.map(id => accountsMap[id]).filter(Boolean)}
                    draggingAccountId={draggingAccountId}
                />

                <Button onClick={handleAddAccount}>
                    <FormattedMessage id="profile.groups.addAccount" />
                </Button>
            </DisabledContent>

            <AccountGroupPictureManagementModal
                id={group.id}
                visible={pictureModalVisible}
                hide={() => setPictureModalVisible(false)}
            />
        </article>
    );
};

export default AccountGroup;
