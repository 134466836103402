import { all, call, put, takeLatest } from 'redux-saga/effects';

import config from 'config';
import * as log from 'config/loglevel';
import { ApiGet, ApiResponse, authApi } from 'config/antonio';

import takeLatestRequest from 'services/sagas/takeLatestRequest';
import { operations } from 'api/apiSchema';

import { createUIErrorMessage } from 'modules/errors';
import { normalizeVideoSettings } from 'modules/entities/services/normalizr';
import * as entitiesActions from 'modules/entities/services/actions';
import { EntityKey } from 'modules/entities/constants';

import { fetchVideoSettings as apiActions } from '../actions';

type Operation = operations['videoSettingsDetail'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestParams = Operation['parameters']['path'];
type RequestAction = ReturnType<typeof apiActions.request>;
type ResetAction = ReturnType<typeof apiActions.reset>;

function* fetchVideoSettingsHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.videoSettingsDetail,
            { uriParams: { id: action.meta.id } },
        );

        const { entities } = normalizeVideoSettings(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.VIDEO_SETTINGS, {
                ids: [action.meta.id],
                byId: entities[EntityKey.VIDEO_SETTINGS],
            }),
        ]);
        yield put(apiActions.success(action.meta.id));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(action.meta.id, uiError));

        log.error(error);
    }
}

function* resetVideoSettings(action: ResetAction) {
    yield put(entitiesActions.removeEntity(EntityKey.VIDEO_SETTINGS, action.meta.id));
}

export default function* fetchVideoSettings() {
    yield all([
        takeLatestRequest(
            (action: RequestAction | ResetAction) => action.meta.id,
            { pattern: apiActions.request.toString(), handler: fetchVideoSettingsHandler },
            { pattern: apiActions.cancel.toString(), handler: resetVideoSettings },
        ),
        takeLatest(apiActions.reset.toString(), resetVideoSettings),
    ]);
}
