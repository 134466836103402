import { TRuleWithTheme } from 'styles/theme';

export const planPostModal: TRuleWithTheme = ({ theme }) => ({
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100vw',
    zIndex: 1000,
    backgroundColor: theme.colors.white,
    padding: '.5rem .75rem',

    '& .ant-card': {
        height: 'auto',

        '& .ant-card-body div:first-of-type': {
            width: '100%',
        },
    },
});

export const cardSpacing: TRuleWithTheme = () => ({
    '& > div': {
        marginBottom: '0.75rem',
    },
});

export const planPostModalHeader: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .ant-btn': {
        color: theme.colors.textDark,

        '& .anticon': {
            height: 8,
            width: 8,
        },
    },

    '& h1.ant-typography': {
        marginTop: '1.375rem',
        marginBottom: '1.375rem',
        marginLeft: '.5rem',
    },
});
