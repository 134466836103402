import type { ButtonProps } from 'antd';
import type { TRuleWithTheme } from 'styles/theme';

export const back: TRuleWithTheme<ButtonProps> = ({ theme }) => ({
    position: 'absolute',
    left: '2rem',
    top: '6.5rem',

    zIndex: theme.zIndex.modalMask + 10,
});
