import { basicApiReducer } from '@ackee/redux-utils';
import * as types from '../../actions/types';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_REQUEST,
        SUCCESS: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_SUCCESS,
        FAILURE: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_FAILURE,
        CANCEL: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_CANCEL,
        RESET: types.updateVideoSettings.UPDATE_VIDEO_SETTINGS_RESET,
    },
});
