import React from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import type { FC } from 'react';

import { Button } from 'modules/ui';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './AccountFieldFormButtons.styles';

export interface AccountFieldFormButtonsProps {
    onCancel: () => void;
    onSave: () => void;
    dirty: boolean;
    submitting: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const AccountFieldFormButtons: FC<AccountFieldFormButtonsProps> = ({ onCancel, onSave, dirty, submitting, extend }) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            <Button type="text" htmlType="reset" onClick={onCancel} disabled={submitting}>
                <FormattedMessage id="general.cancel" />
            </Button>

            {dirty && (
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={onSave}
                    extend={{ button: rules.secondButton }}
                    disabled={submitting}
                    loading={submitting}
                >
                    <FormattedMessage id="general.save" />
                </Button>
            )}
        </div>
    );
};

export default AccountFieldFormButtons;
