import type { FC } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import config from 'config';
import { useFelaEnhanced } from 'hooks';
import { previousLocationSelector } from 'services/selectors';

import Icon from '../Icon';
import IconTextButton from '../IconTextButton';

import * as felaRules from './BackLink.styles';

export interface BackLinkProps {
    to?: LinkProps['to'];
    fallbackRoute?: string;
}

const BackLink: FC<BackLinkProps> = ({ fallbackRoute = config.routes.posts, to }) => {
    const { styles } = useFelaEnhanced(felaRules);

    const activeLocation = useLocation();
    const previousLocation = useSelector(previousLocationSelector) as Location;
    const previousRoute =
        activeLocation.pathname !== previousLocation.pathname ? previousLocation.pathname : fallbackRoute;

    return (
        <Link to={to ?? previousRoute ?? fallbackRoute} className={styles.backLink}>
            <IconTextButton icon={Icon.Types.BACK} size="large" iconSize="large" color="default" />
        </Link>
    );
};

export default BackLink;
