import type { FC } from 'react';
import { Row } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './FormSummaryRow.styles';

const FormSummaryRow: FC = ({ children }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return <Row className={styles.row}>{children}</Row>;
};

export default FormSummaryRow;
