import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { selectCreatePostApi, selectUpdatePostApi, actions as postsActions } from 'modules/entities/modules/posts';

import { saveDraft } from '../services/actions';
import type { PlanPostFormValues } from '../types';

export const usePostSaveApi = () => {
    const createApi = useSelector(selectCreatePostApi);
    const updateApi = useSelector(selectUpdatePostApi);

    const inProgress = createApi.inProgress || updateApi.inProgress;
    const error = createApi.error || updateApi.error;
    const lastSuccessAt = createApi.lastSuccessAt || updateApi.lastSuccessAt;

    return { inProgress, error, lastSuccessAt };
};

export const usePostSave = () => {
    const dispatch = useDispatch();
    const save = (values: PlanPostFormValues) => dispatch(saveDraft(values));
    const api = usePostSaveApi();

    useEffect(() => {
        return () => {
            batch(() => {
                dispatch(postsActions.createPost.reset());
                dispatch(postsActions.updatePost.reset(null));
            });
        };
    }, [dispatch]);

    return { ...api, save };
};
