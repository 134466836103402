import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-block',
    padding: `0.25rem ${theme.spacing.xsmall}`,

    color: theme.colors.inputLabel,
    fontSize: '0.8125rem',
});

export const counter: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-block',
    color: theme.colors.textDark,
    marginLeft: '0.5rem',
});
