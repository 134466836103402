import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey, DEFAULT_GROUP } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import type { Competition } from 'modules/entities/types';
import { denormalizeCompetitions, denormalizeCompetition } from 'modules/entities/services/normalizr';
import { selectProp } from 'services/selectors';

export const selectCreateCompetitionApi = state => apiSelector(state, EntityKey.COMPETITIONS, ApiReducerKey.CREATE);
export const selectCompetitionsApi = state => apiSelector(state, EntityKey.COMPETITIONS, ApiReducerKey.LIST);
export const selectCompetitionApi = state => apiSelector(state, EntityKey.COMPETITIONS, ApiReducerKey.DETAIL);
export const selectUpdateCompetitionApi = state => apiSelector(state, EntityKey.COMPETITIONS, ApiReducerKey.UPDATE);

export const selectCompetitionsById = createSelector(
    entitiesSelectors.selectCompetitionsEntities,
    competitions => competitions.byId,
);

export const selectCompetitions = createSelector(
    [entitiesSelectors.selectCompetitionsEntities, entitiesSelectors.selectPostsEntities],
    (competitions, posts) => {
        const denormalizedCompetitions: Array<Competition> = denormalizeCompetitions(competitions[DEFAULT_GROUP], {
            [EntityKey.POSTS]: posts.byId,
            [EntityKey.COMPETITIONS]: competitions.byId,
        });

        return denormalizedCompetitions;
    },
);

export const selectDenormalizedCompetition = createSelector(
    [
        entitiesSelectors.selectCompetitionsEntities,
        entitiesSelectors.selectPostsEntities,
        entitiesSelectors.selectVideoSettingsEntities,
        selectProp<Competition['id']>('id'),
    ],
    (competitions, posts, videoSettings, id) =>
        denormalizeCompetition(id, {
            [EntityKey.COMPETITIONS]: competitions.byId,
            [EntityKey.POSTS]: posts.byId,
            [EntityKey.VIDEO_SETTINGS]: videoSettings.byId,
        }),
);
export const selectCompetionAccountId = createSelector(
    selectDenormalizedCompetition,
    competition => competition?.posts?.[0]?.accountId,
);
