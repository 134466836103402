import { call, put, select } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import type { ApiDelete, ApiResponse } from 'config/antonio';
import * as log from 'config/loglevel';

import type { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { createUIErrorMessage } from 'modules/errors';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';
import { selectAccountGroup } from 'modules/entities/modules/account-groups/services/selectors';

import { deleteAccount as apiActions } from '../actions';
import { selectAccount } from '../selectors';

type Operation = operations['accountDelete'];
type Response = ApiResponse<Operation['responses']['204']>;
type RequestBody = Operation['parameters']['path'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* deleteAccountHandler(action: RequestAction) {
    const account = yield select(state => selectAccount(state, { id: action.meta.id }));
    const accountGroup = yield select(state => selectAccountGroup(state, { id: account.accountGroupId }));

    try {
        yield call<ApiDelete<Response, RequestBody>>(authApi.delete, config.api.endpoints.accountDetail, {
            uriParams: {
                id: action.meta.id,
            },
        });

        yield put([
            entitiesActions.removeEntity(EntityKey.ACCOUNTS, action.meta.id),
            entitiesActions.setEntitiesGroup(EntityKey.ACCOUNT_GROUPS, {
                ids: [accountGroup.id],
                byId: {
                    [accountGroup.id]: {
                        ...accountGroup,
                        accounts: accountGroup.accounts.filter(accountId => accountId !== account.id),
                    },
                },
            }),
            apiActions.success(action.meta.id),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure(action.meta.id, uiError));

        log.error(error);
    }
}

export default function* deleteAccount() {
    yield takeLatestRequest(
        (action: RequestAction) => action.meta.id,
        {
            pattern: apiActions.request.toString(),
            handler: deleteAccountHandler,
        },
        {
            pattern: apiActions.cancel.toString(),
            handler: noop,
        },
    );
}
