import type { TRuleWithTheme } from 'styles/theme';

export const list: TRuleWithTheme<{ loading: boolean }> = ({ theme, loading }) => ({
    borderRadius: theme.borderRadius.panel,
    boxShadow: theme.shadow.panel,
    backgroundColor: theme.colors.white,

    '& .ant-list-item': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderBottom: `1px solid ${theme.colors.separator}`,
    },

    '& .ant-list-item-action-split': {
        display: 'none',
    },

    '& .ant-list-item-action': {
        marginLeft: 0,
        marginTop: theme.spacing.xsmall,
    },

    '&.ant-list-split .ant-list-item:last-child': {
        borderBottom: `1px solid ${theme.colors.separator}`,
    },

    tablet: {
        '& .ant-list-item': {
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.colors.separator}`,
        },

        '& .ant-list-item-action': {
            marginLeft: '0.75rem',
            marginTop: 0,
        },
    },

    extend: {
        condition: loading,
        style: {
            padding: `${theme.spacing.small} ${theme.spacing.large}`,
        },
    },
});

export const skeleton: TRuleWithTheme = ({ theme }) => ({
    '& .ant-skeleton-paragraph': {
        marginBottom: 0,
    },

    '& .ant-skeleton-content .ant-skeleton-paragraph > li': {
        height: 65,
        borderRadius: theme.borderRadius.medium,

        ':not(:last-child)': {
            marginBottom: theme.spacing.small,
        },
    },
});
