import type { FC } from 'react';
import { StepsProps as AntStepsProps, Steps as AntSteps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import Step from './Step';
import * as felaRules from './Steps.styles';

interface StepsProps extends AntStepsProps {
    extend?: RulesExtend<typeof felaRules>;
}

const Steps: FC<StepsProps> & { Step: typeof Step } = ({ children, extend, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <AntSteps className={styles.steps} {...rest}>
            {children}
        </AntSteps>
    );
};

Steps.Step = Step;

export default Steps;
