import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useField } from 'react-final-form';

import { useFelaEnhanced } from 'hooks';
import { Counter, HashtagsCounter, EmojiPicker } from 'modules/ui';

import * as felaRules from './PostFieldFooter.styles';
import { POST_MAX_CHARACTERS, POST_MAX_HASHTAGS } from '../../config';

export interface PostFieldFooterProps {
    fieldName: string;
    cursorPosition: number;
}

const PostFieldFooter: FC<PostFieldFooterProps> = ({ fieldName, cursorPosition }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const { input } = useField<string>(fieldName, { subscription: { value: true } });

    const onEmojiChange = (emoji: string) => {
        const value = input.value.slice(0, cursorPosition) + emoji + input.value.slice(cursorPosition);
        input.onChange(value);
    };
    return (
        <div className={styles.container}>
            <div className={styles.fluidSegment}>
                <EmojiPicker onSelect={emoji => onEmojiChange(emoji)} />
            </div>
            <div className={styles.segment}>
                <Counter
                    count={input.value?.length ?? 0}
                    max={POST_MAX_CHARACTERS}
                    title={<FormattedMessage id="general.characters" />}
                    extend={{ container: felaRules.counter }}
                />
            </div>
            <div className={styles.segment}>
                <HashtagsCounter
                    value={input.value}
                    max={POST_MAX_HASHTAGS}
                    extend={{ container: felaRules.counter }}
                />
            </div>
        </div>
    );
};

export default PostFieldFooter;
