import { uniq, difference, isEmpty } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { containerReducer } from '@ackee/redux-utils';

import type { Entities, EntityState } from '../../types';
import * as actions from '../actions';

export const childInitialState: EntityState = {
    byId: {},
    ids: [],
};

const setEntitiesByIdReducer = (state, action: actions.EntitiesActions) => {
    const { byId } = action.payload;

    if (isEmpty(byId)) {
        return state;
    }

    return {
        ...state,
        ...byId,
    };
};

const byIdReducer = createReducer({} as EntityState['byId'], builder =>
    builder
        .addCase(actions.setEntities, setEntitiesByIdReducer)
        .addCase(actions.setEntitiesGroup, setEntitiesByIdReducer)
        .addCase(actions.removeEntity, (state, action) => {
            const { id } = action.payload;
            const nextById = { ...state };

            delete nextById[id];

            return nextById;
        }),
);

const groupReducer = createReducer([] as Array<Entities['id']>, builder =>
    builder
        .addCase(actions.setEntitiesGroup, (state, action) => {
            const { ids } = action.payload;
            const { strategy } = action.meta;

            switch (strategy) {
                case 'replace': {
                    return ids;
                }
                case 'append': {
                    if (isEmpty(ids)) {
                        return state;
                    }

                    return uniq([...state, ...ids]);
                }
                default: {
                    return state;
                }
            }
        })
        .addCase(actions.unsetEntitiesGroup, (state, action) => difference(state, action.payload.ids))
        .addCase(actions.resetEntitiesGroup, () => []),
);

const resetChildReducer = (state, action: actions.EntitiesActions) => {
    const { group } = action.meta;

    if (!state[group]) {
        return state;
    }

    return {
        ...state,
        [group]: groupReducer(state[group], action),
    };
};

const childReducer = createReducer(childInitialState, builder =>
    builder
        .addCase(actions.setEntities, (state, action) => {
            if (isEmpty(action.payload.byId)) {
                return state;
            }

            return {
                ...state,
                byId: byIdReducer(state.byId, action),
            };
        })
        .addCase(actions.setEntitiesGroup, (state, action) => {
            const { group } = action.meta;
            const { byId, ids } = action.payload;

            if (isEmpty(byId) && isEmpty(ids)) {
                return state;
            }

            return {
                ...state,
                byId: byIdReducer(state.byId, action),
                [group]: groupReducer(state[group], action),
            };
        })
        .addCase(actions.unsetEntitiesGroup, resetChildReducer)
        .addCase(actions.resetEntitiesGroup, resetChildReducer)
        .addCase(actions.removeEntity, (state, action) => {
            const { group } = action.meta;

            return {
                ...state,
                byId: byIdReducer(state.byId, action),
                [group]: state[group].filter(id => id !== action.payload.id),
            };
        })
        .addCase(actions.resetEntities, () => childInitialState),
);

export default containerReducer({
    initialState: {},
    actionTypes: Object.values(actions).map(action => action.toString()),
    childReducer,
    selectors: {
        itemId: action => action.meta.entityKey,
    },
    options: {
        placeholder: false,
    },
});
