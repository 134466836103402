import { FC, useState } from 'react';
import { Radio } from 'antd';
import type { ToolbarProps, View } from 'react-big-calendar';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import Button from '../../Button';
import Icon from '../../Icon';
import Paragraph from '../../Paragraph';
import * as felaRules from './CalendarToolbar.styles';

const CalendarToolbar: FC<ToolbarProps> = ({ onNavigate, onView, date, label }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const [viewMode, setViewMode] = useState<View>('month');

    const onChange = e => {
        setViewMode(e.target.value);
        onView(e.target.value);
    };
    const goBack = () => {
        onNavigate('PREV');
    };

    const goYearBack = () => {
        const newDate = new Date(date.setFullYear(date.getFullYear() - 1));
        onNavigate('DATE', newDate);
    };

    const goYearForward = () => {
        const newDate = new Date(date.setFullYear(date.getFullYear() + 1));
        onNavigate('DATE', newDate);
    };

    const goForward = () => {
        onNavigate('NEXT');
    };

    return (
        <div className={styles.toolbar}>
            <Radio.Group onChange={onChange} value={viewMode}>
                <Radio value="month" className={styles.viewButton}>
                    <FormattedMessage id="calendar.viewMode.month" />
                </Radio>
                <Radio value="week" className={styles.viewButton}>
                    <FormattedMessage id="calendar.viewMode.week" />
                </Radio>
            </Radio.Group>
            <div className={styles.navigation}>
                {viewMode !== 'week' && (
                    <Button
                        onClick={goYearBack}
                        extend={{ button: rules.button }}
                        type="text"
                        icon={<Icon type={Icon.Types.FAST_PREVIOUS} size="small" />}
                    />
                )}
                <Button
                    onClick={goBack}
                    extend={{ button: rules.button }}
                    type="text"
                    icon={<Icon type={Icon.Types.PREVIOUS} size="small" />}
                />

                <Paragraph weight="bold" extend={{ paragraph: rules.label }}>
                    {label}
                </Paragraph>
                <Button
                    onClick={goForward}
                    extend={{ button: rules.button }}
                    type="text"
                    icon={<Icon type={Icon.Types.FORWARD} size="small" />}
                />

                {viewMode !== 'week' && (
                    <Button
                        onClick={goYearForward}
                        extend={{ button: rules.button }}
                        type="text"
                        icon={<Icon type={Icon.Types.FAST_FORWARD} size="small" />}
                    />
                )}
            </div>
        </div>
    );
};

export default CalendarToolbar;
