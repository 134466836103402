import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { Media, Schema } from 'modules/entities';
import { Card, Paragraph } from 'modules/ui';

import * as felaRules from './Post.styles';
import PostMediaPreview from '../PostMediaPreview';

export interface PostProps extends Pick<Schema['Post'], 'content' | 'comment'> {
    media: Partial<Media>[];
    loading?: boolean;
}

const Post: FC<PostProps> = ({ content, comment, media = [], loading }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Card extend={{ card: felaRules.card }} loading={loading}>
            <article className={styles.post}>
                <div className={styles.content}>
                    <Paragraph faded size="small" extend={{ paragraph: felaRules.caption }}>
                        <FormattedMessage id="post.caption" />
                    </Paragraph>
                    <Paragraph>{content}</Paragraph>
                    {comment && (
                        <>
                            <hr className={styles.separator} />
                            <Paragraph>{comment}</Paragraph>
                        </>
                    )}
                </div>

                <PostMediaPreview media={media} />
            </article>
        </Card>
    );
};

export default Post;
