import { select, take, put } from 'redux-saga/effects';
import { omit } from 'lodash';

import type { operations } from 'api/apiSchema';
import { actions as competitionActions, selectRandomDrawLatestDraft } from 'modules/entities/modules/competitions';
import { convertToTags } from 'modules/form';

import type { RandomDrawFormValues } from '../../types';
import { RandomDrawField } from '../../config';

type Operation = operations['competitionUpdate'] | operations['competitionCreate'];
type Payload = Operation['requestBody']['content']['application/json'];

export default function* saveRandomDraw(values: RandomDrawFormValues & { publishedAt?: string }) {
    const payload: Payload = {
        ...omit(values, [
            RandomDrawField.HEADLINE,
            RandomDrawField.SUBHEADLINE,
            RandomDrawField.COLOR,
            RandomDrawField.POST,
        ]),
        type: 'randomDraw',
        hashtagsIncluded: convertToTags(values.hashtagsIncluded),
        profilesBlocked: convertToTags(values.profilesBlocked),
        postIds: [values.postId],
    };
    const filtredPayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v !== null));

    const draft = yield select(selectRandomDrawLatestDraft);
    let result;

    if (draft) {
        yield put(competitionActions.updateCompetition.request(draft.id, filtredPayload));

        result = yield take([
            competitionActions.updateCompetition.success.toString(),
            competitionActions.updateCompetition.failure.toString(),
        ]);
    } else {
        yield put(competitionActions.createCompetition.request(filtredPayload));

        result = yield take([
            competitionActions.createCompetition.success.toString(),
            competitionActions.createCompetition.failure.toString(),
        ]);
    }

    if (result.error) {
        throw result.error;
    }
    return result;
}
