import { TRuleWithTheme } from 'styles/theme';

export const step: TRuleWithTheme<{ status: 'wait' | 'process' | 'finish' | 'error' }> = ({ theme, status }) => ({
    '&.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon': {
        backgroundColor: theme.colors.primarySelected,
        borderColor: theme.colors.primarySelected,
    },
    '&.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon': {
        color: theme.colors.textDark,
    },

    '& .ant-steps-item-icon': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    '& .ant-steps-item-title': {
        fontWeight: 600,
        paddingRight: '0.5rem',
    },

    '& .ant-steps-item-content > .ant-steps-item-title::after': {
        backgroundColor: theme.colors.cardBorderDark,
    },

    '& .ant-steps-item-title::after': {
        height: 2,
        borderRadius: 1,
    },

    '&.ant-steps-item-wait .ant-steps-item-icon': {
        backgroundColor: status === 'finish' ? theme.colors.primarySelected : theme.colors.cardBorderDark,
        borderColor: status === 'finish' ? theme.colors.primarySelected : theme.colors.cardBorderDark,
    },

    '&.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon': {
        color: status === 'finish' ? theme.colors.textDark : theme.colors.textGray,
    },

    '&.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title': {
        color: status === 'finish' ? theme.colors.textDark : theme.colors.textGray,

        ':after': {
            backgroundColor: status === 'finish' ? theme.colors.primarySelected : theme.colors.cardBorderDark,
        },
    },
});
