import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DndContext, closestCenter, defaultDropAnimation, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { AlertTip, Panel } from 'modules/ui';
import { useFetchAccountGroups } from 'modules/entities/modules/account-groups';
import { ConnectSocialProfilesModal, useConnectSocialProfiles } from 'modules/social-accounts';
import { useFetchAccounts } from 'modules/entities/modules/accounts';
import { useErrorToast } from 'modules/errors';

import { useAccountsDnd } from '../../hooks';

import { useEditMode } from '../EditModeProvider';
import AccountGroup from '../AccountGroup';
import AddGroupButton from '../AddGroupButton';
import * as felaRules from './SocialProfiles.styles';
import { createPortal } from 'react-dom';
import AccountGroupListItem from '../AccountGroupList/AccountGroupListItem';
import { useOAuthReset } from 'modules/auth/hooks';
export interface SocialProfilesProps {}

const SocialProfiles: FC<SocialProfilesProps> = () => {
    const intl = useIntl();
    const { editMode } = useEditMode();
    const [connectModalVisible, setConnectModalVisible] = useConnectSocialProfiles();

    const { inProgress, error } = useFetchAccountGroups();
    const accountsApi = useFetchAccounts();
    // const accountGroups = useAccountGroups();
    useErrorToast(error || accountsApi.error, 'error.api.accountGroups');
    const handleReset = useOAuthReset();
    const handleClose = () => {
        handleReset();
        setConnectModalVisible(false);
    };
    const { sensors, eventHandlers, accountGroupsMap, draggingAccount } = useAccountsDnd();
    const draggingAccountId = draggingAccount?.id;

    return (
        <>
            <AlertTip tipKey="accountGroups" extend={{ alert: felaRules.alert }}>
                <FormattedMessage id="profile.groups.tip" />
            </AlertTip>

            <Panel loading={{ show: inProgress, items: 3 }}>
                <DndContext sensors={sensors} collisionDetection={closestCenter} {...eventHandlers}>
                    {error ? (
                        <Panel.Item>
                            <AccountGroup
                                group={{
                                    name: intl.formatMessage({ id: 'profile.groups.default' }),
                                    accounts: [],
                                }}
                                deleteAllowed={false}
                            />
                        </Panel.Item>
                    ) : (
                        Object.values(accountGroupsMap).map((group, index) => (
                            <SortableContext
                                key={group.id}
                                items={group.accounts}
                                strategy={verticalListSortingStrategy}
                            >
                                <Panel.Item disabled={editMode && editMode !== group.id}>
                                    <AccountGroup
                                        group={group}
                                        deleteAllowed={index > 0}
                                        draggingAccountId={draggingAccountId}
                                    />
                                </Panel.Item>
                            </SortableContext>
                        ))
                    )}
                    {createPortal(
                        <DragOverlay adjustScale={false} dropAnimation={defaultDropAnimation}>
                            {draggingAccount ? <AccountGroupListItem account={draggingAccount} isDragOverlay /> : null}
                        </DragOverlay>,
                        document.body,
                    )}
                </DndContext>

                <Panel.Item disabled={editMode}>
                    <AddGroupButton />
                </Panel.Item>
            </Panel>

            <ConnectSocialProfilesModal visible={connectModalVisible} onCancel={() => handleClose()} />
        </>
    );
};

export default SocialProfiles;
