import { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme }) => ({
    boxShadow: theme.shadow.panel,
    padding: '.5rem 1rem ',
    desktop: {
        padding: '1.5rem 7rem ',
    },
});
export const image: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    margin: '2rem 0',
    maxHeight: '40vh',
    objectFit: 'cover',
    borderRadius: theme.borderRadius.large,
});
export const excerpt: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textGray,
});
export const content: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textDark,
    '& iframe': {
        width: '100%',
    },
    '& h3': {
        fontSize: '1.7rem',
        fontWeight: 600,
        margin: 0,
    },
    '& h2': {
        fontSize: '2rem',
        fontWeight: 800,
        textAlign: 'center',
        margin: 0,
    },
});

export const skeleton: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',

    '& .ant-skeleton-title': {
        height: '2rem',
    },
    '& .ant-skeleton-header': {
        padding: 0,
        width: '100%',
    },
    '& .ant-skeleton-header > .ant-skeleton-avatar': {
        borderRadius: theme.borderRadius.large,
        width: '100%',
        margin: '2rem 0',
        minHeight: '30vh',
    },
});
