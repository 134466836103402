import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

import type { PostMediaThumbnailProps } from './PostMediaThumbnail';

export const imageButton: TRuleWithTheme<Pick<PostMediaThumbnailProps, 'active'>> = ({ theme, active }) => ({
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    overflow: 'hidden',
    width: '100%',
    height: '100%',

    borderWidth: active ? 4 : 2,
    borderStyle: 'solid',
    borderColor: active ? theme.colors.primarySelected : theme.colors.inputBorder,
    borderRadius: theme.borderRadius.small,
    transitionProperty: 'border-color',
    transitionDuration: theme.transition.duration,

    ':hover': {
        borderColor: theme.colors.primarySelected,
    },
});

export const thumbnail: TRule = () => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});
