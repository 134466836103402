import { basicApiReducer } from '@ackee/redux-utils';
import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.fetchJob.FETCH_JOB_REQUEST,
        SUCCESS: types.fetchJob.FETCH_JOB_SUCCESS,
        FAILURE: types.fetchJob.FETCH_JOB_FAILURE,
        CANCEL: types.fetchJob.FETCH_JOB_CANCEL,
    },
});
