import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Heading, Paragraph, ButtonOwnProps, useConfirmModal } from 'modules/ui';

const RevokeAccountButton: FC<ButtonOwnProps> = props => {
    const confirm = useConfirmModal();

    const handleClick = () =>
        confirm({
            title: (
                <Heading level={2}>
                    <FormattedMessage id="socialAccounts.revoke.title" />
                </Heading>
            ),
            content: (
                <Paragraph faded>
                    <FormattedMessage id="socialAccounts.revoke.text" />
                </Paragraph>
            ),
            okType: 'danger',
            okText: <FormattedMessage id="general.revoke" />,
            cancelText: <FormattedMessage id="general.cancel" />,
            onOk: props.onClick,
        });

    return <Button {...props} onClick={handleClick} />;
};

export default RevokeAccountButton;
