import { HTTPStatusCode } from 'types';

export type UIMessageID = string;

export interface UIErrorMessages {
    code?: Record<string | number, UIMessageID>;
    status?: Partial<Record<HTTPStatusCode, UIMessageID>>;
    fallbackId: UIMessageID;
}

export const errorMessages: UIErrorMessages = {
    code: {
        PasswordMismatch: 'error.login.credentials',
        DUPLICATE_ACCOUNT: 'error.api.duplicateAccount',
    },
    status: {
        [HTTPStatusCode.BadRequest]: 'error.api.400',
        [HTTPStatusCode.Unauthorized]: 'error.api.401',
        [HTTPStatusCode.AccessDenied]: 'error.api.403',
        [HTTPStatusCode.InternalServerError]: 'error.api.500',
        [HTTPStatusCode.ServiceUnavailable]: 'error.api.503',
    },
    fallbackId: 'error.api.general',
};
