import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, Icon } from 'modules/ui';
import type { AlertOwnProps } from 'modules/ui/components/Alert';

export interface ErrorAlertProps {
    error?: string;
    extend?: AlertOwnProps['extend'];
}

const ErrorAlert: FC<ErrorAlertProps> = ({ error = null, extend, children }) =>
    error && typeof error === 'string' ? (
        <Alert
            type="error"
            showIcon
            icon={<Icon type={Icon.Types.ERROR} />}
            message={<FormattedMessage id={error} />}
            extend={extend}
        />
    ) : (
        <>{children}</>
    );

export default ErrorAlert;
