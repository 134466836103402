import { combineReducers } from 'redux';

import { EntityKey } from '../../constants';
import { apiReducer as accounts } from '../../modules/accounts';
import { apiReducer as articles } from '../../modules/articles';
import { apiReducer as accountGroups } from '../../modules/account-groups';
import { apiReducer as competitions } from '../../modules/competitions';
import { apiReducer as media } from '../../modules/media';
import { apiReducer as posts } from '../../modules/posts';
import { apiReducer as facebookPosts } from '../../modules/facebook-posts';
import { apiReducer as instagramPosts } from '../../modules/instagram-posts';
import { apiReducer as jobs } from '../../modules/jobs';
import { apiReducer as videoSettings } from '../../modules/video-settings';

export default combineReducers({
    [EntityKey.ACCOUNTS]: accounts,
    [EntityKey.ACCOUNT_GROUPS]: accountGroups,
    [EntityKey.COMPETITIONS]: competitions,
    [EntityKey.MEDIA]: media,
    [EntityKey.ARTICLES]: articles,
    [EntityKey.POSTS]: posts,
    [EntityKey.INSTAGRAM_POSTS]: instagramPosts,
    [EntityKey.FACEBOOK_POSTS]: facebookPosts,
    [EntityKey.JOBS]: jobs,
    [EntityKey.VIDEO_SETTINGS]: videoSettings,
});
