import { takeLeading } from 'redux-saga/effects';
import { AUTH_SESSION_END } from '@ackee/petrus';

import { localStorageKeys } from 'constants/index';

export default function* resetConnectAccountOnboarding() {
    yield takeLeading(AUTH_SESSION_END, () => {
        localStorage.removeItem(localStorageKeys.CONNECT_ACCOUNT_ONBOARDING);
    });
}
