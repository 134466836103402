import React, { FunctionComponent } from 'react';
import { Form, FormProps } from 'antd';

export interface OwnFormProps extends FormProps {
    submitSucceeded: boolean;
    onSubmit: (values: any) => void;
    onSubmitSucceeded?: Function;
    reset: Function;
}

const AntdForm: FunctionComponent<OwnFormProps> = ({ submitSucceeded, reset, onSubmit, onSubmitSucceeded, children, ...props }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (submitSucceeded) {
            if (onSubmitSucceeded) {
                onSubmitSucceeded();
            }
            
            reset();
            form.resetFields();
        }
    }, [submitSucceeded, reset, onSubmitSucceeded, form]);

    return (
        <Form {...props} form={form} onFinish={onSubmit}>
            {children}
        </Form>
    );
};

export default AntdForm;
