import { noop } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import * as log from 'config/loglevel';

import takeLatestRequest from 'services/sagas/takeLatestRequest';

import { ApiGet, ApiResponse, authApi } from 'config/antonio';
import { createUIErrorMessage } from 'modules/errors';
import * as entitiesActions from 'modules/entities/services/actions';
import { normalizeJob } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import config from 'config/config';
import { fetchJob as apiActions } from '../actions';
import { operations } from 'api/apiSchema';
import { selectJobState } from '../selectors';
import { JobStates } from '../types';

type Operation = operations['jobDetail'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestParams = Operation['parameters']['path'];

function* fetchJobHandler(action) {
    try {
        const { id } = action.meta;

        const { data }: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.jobDetail,
            { uriParams: { id } },
        );

        const { entities } = normalizeJob(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.JOBS, {
                ids: [id],
                byId: entities[EntityKey.JOBS],
                strategy: 'replace',
            }),
        ]);
        const jobState = yield select(state => selectJobState(state, { id: id }));

        if (jobState === JobStates.ERROR_VIDEO_GENERATE) {
            log.error(JobStates.ERROR_VIDEO_GENERATE, data);
        }
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);

        yield put(apiActions.failure(action.meta.id, uiError));

        log.error(error);
    }
}

export default function* fetchJob() {
    yield takeLatestRequest(
        action => action.meta.id,
        {
            pattern: apiActions.request.toString(),
            handler: fetchJobHandler,
        },
        { pattern: apiActions.failure.toString(), handler: noop },
    );
}
