import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectDeleteAccountApi } from 'modules/entities/modules/accounts';
import type { Schema } from 'modules/entities';

type AccountId = Schema['Account']['id'];

const useDeleteAccountInList = () => {
    const [deletedAccountId, setDeletedAccountId] = useState<Schema['Account']['id']>(null);

    const { inProgress } = useSelector(selectDeleteAccountApi);
    const dispatch = useDispatch();

    const isInProgress = (id?: AccountId) => (!id ? inProgress : deletedAccountId === id && inProgress);

    const deleteAccount = (id: AccountId) => {
        setDeletedAccountId(id);
        dispatch(actions.deleteAccount.request(id));
    };

    return [isInProgress, deleteAccount];
};

export default useDeleteAccountInList;
