import type { TRule } from 'fela';

export const formContainer = () => ({
    flex: 1,
});

export const form: TRule = () => ({
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    
    tablet: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    '& label': {
        fontSize: '0.8125rem',
    },

    '& .ant-form-item': {
        flex: '1 1 auto',
    },

    '& .ant-form-item-with-help': {
        marginBottom: 0,
    },
});
