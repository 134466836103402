import type { TRule } from 'fela';

export const passwordRecovery: TRule = () => ({
    textAlign: 'right',
    marginTop: '-1.25rem',
    marginBottom: '2rem',
});

export const alert: TRule = () => ({
    textAlign: 'left',
    marginBottom: '2rem',
});
