import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import { Panel, Button } from 'modules/ui';
import { useFormSubmit, TextField, Label, composeValidators, validatorsWithMessage, translate } from 'modules/form';
import { PASSWORD_LENGTH } from 'modules/auth';

import { forms, ChangePasswordFormField } from '../../config';
import { changeUserAccount } from '../../services/actions';
import { useEditMode } from '../EditModeProvider';

import AccountFieldForm from '../AccountFieldForm';

import * as felaRules from './ChangePasswordForm.styles';

const validators = {
    [ChangePasswordFormField.Password]: composeValidators(
        validatorsWithMessage.required,
        validatorsWithMessage.password(PASSWORD_LENGTH),
    ),
};

export interface EditablePanelItemProps {}

const passwordLabel = <FormattedMessage id="general.password" />;

const ChangePasswordForm: FC<EditablePanelItemProps> = () => {
    const { editMode, setEditMode, closeEditMode } = useEditMode();
    const { rules } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    const onSubmit = useFormSubmit(changeUserAccount);

    const isPasswordEditMode = editMode === forms.changePassword;

    const editAction = (
        <Button onClick={() => setEditMode(forms.changePassword)}>
            <FormattedMessage id="general.change" />
        </Button>
    );

    return (
        <Panel.Item actions={isPasswordEditMode ? null : [editAction]} disabled={editMode && !isPasswordEditMode}>
            <AccountFieldForm
                name={forms.changePassword}
                initialValues={{ [ChangePasswordFormField.Password]: '' }}
                onSubmit={onSubmit}
                onSubmitSucceeded={closeEditMode}
            >
                {({ dirty, submitting }) =>
                    isPasswordEditMode ? (
                        <>
                            <TextField
                                name={ChangePasswordFormField.Password}
                                type="password"
                                label={passwordLabel}
                                validate={translate(
                                    ({ id }) => formatMessage({ id }, { length: PASSWORD_LENGTH }),
                                    validators[ChangePasswordFormField.Password],
                                )}
                                extend={{ input: rules.textField }}
                            />

                            <AccountFieldForm.Buttons
                                onCancel={closeEditMode}
                                onSave={() => null}
                                dirty={dirty}
                                submitting={submitting}
                            />
                        </>
                    ) : (
                        <Label label={passwordLabel}>••••••••••••••</Label>
                    )
                }
            </AccountFieldForm>
        </Panel.Item>
    );
};

export default ChangePasswordForm;
