import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import type { RulesExtend } from 'styles/theme';

import { useFelaEnhanced } from 'hooks';
import config from 'config';
import { Icon } from 'modules/ui';

import * as felaRules from './Navigation.styles';
import NavItem from '../NavItem';
import NavTab from '../NavTab';

const navItems = [
    { key: 'posts', icon: Icon.Types.CALENDAR },
    { key: 'analytics', icon: Icon.Types.ANALYTICS, disabled: true },
    { key: 'tips', icon: Icon.Types.TIPS },
];

export interface NavigationProps {
    headerScrolled: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const Navigation: FC<NavigationProps> = ({ headerScrolled, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <nav className={styles.nav}>
            <NavTab shadow={!headerScrolled} extend={{ tab: felaRules.tab }}>
                {navItems.map(({ key, icon, disabled }) => (
                    <NavItem
                        key={key}
                        to={config.routes[key]}
                        disabled={disabled}
                        icon={icon}
                        extend={{ navItem: felaRules.navItem }}
                    >
                        <FormattedMessage id={`nav.${key}`} />
                    </NavItem>
                ))}
            </NavTab>
        </nav>
    );
};

export default Navigation;
