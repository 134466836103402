import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import illustration from 'assets/images/email_sent_illustration.svg';
import { Intro, Button } from 'modules/ui';

export interface PasswordRecoverySuccesProps {
    onClose: () => void;
}

const PasswordRecoverySucces: FC<PasswordRecoverySuccesProps> = ({ onClose }) => {
    return (
        <>
            <Intro
                title={<FormattedMessage id="auth.passwordRecovery.success.title" />}
                text={<FormattedMessage id="auth.passwordRecovery.success.text" />}
                illustration={illustration}
            />

            <Button type="primary" size="large" block onClick={onClose}>
                <FormattedMessage id="general.close" />
            </Button>
        </>
    );
};

export default PasswordRecoverySucces;
