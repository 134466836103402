import type { FC } from 'react';

import RandomDrawSelectAccount from '../RandomDrawSelectAccount';
import RandomDrawPostList from '../RandomDrawPostList';

const RandomDrawPosts: FC = () => (
    <>
        <RandomDrawSelectAccount />
        <RandomDrawPostList />
    </>
);

export default RandomDrawPosts;
