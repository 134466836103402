import React, { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { Form } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { ColorPicker } from 'modules/ui';
import type { ColorPickerProps } from 'modules/ui';
import type { RulesExtend } from 'styles/theme';

import type { FormFieldProps } from '../types';

import * as felaRules from './ColorPickerField.styles';

interface ColorPickerFieldProps extends FormFieldProps<string> {
    colors: ColorPickerProps['colors'];
    colorsRowCount?: ColorPickerProps['colorsRowCount'];
    extend?: RulesExtend<typeof felaRules>;
}

const ColorPickerField: FunctionComponent<ColorPickerFieldProps> = ({
    colors,
    colorsRowCount,
    label,
    required = false,
    extend,
    ...props
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <Field<string> validateFields={[]} {...props}>
            {({ input, meta }) => (
                <Form.Item
                    name={input.name}
                    label={label}
                    required={required}
                    help={meta.touched && meta.error}
                    validateStatus={meta.touched && meta.error && 'error'}
                    className={styles.formItem}
                >
                    <ColorPicker {...input} colors={colors} colorsRowCount={colorsRowCount} />
                </Form.Item>
            )}
        </Field>
    );
};

export default ColorPickerField;
