import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { BadgeAvatar, Icon, Paragraph } from 'modules/ui';
import { RadioButtonGroupField } from 'modules/form';
import type { RadioButtonGroupFieldProps } from 'modules/form/components/RadioButtonGroupField/RadioButtonGroupField';
import type { Account } from 'modules/entities';
import { selectAccountGroupByAccount } from 'modules/entities/modules/account-groups';

export interface SelectAccountFieldProps extends Omit<RadioButtonGroupFieldProps, 'value'> {
    name: string;
    extend?: RadioButtonGroupFieldProps['extend'];
}
const SelectAccountField: FC<SelectAccountFieldProps> = ({ extend, ...props }) => {
    const { values } = useFormState<{ accountId: Account['id'] }>({ subscription: { values: true } });
    const accounts = useSelector(state => selectAccountGroupByAccount(state, { id: values.accountId }));

    return (
        <RadioButtonGroupField
            {...props}
            value={values.accountId}
            fluid
            options={accounts.map(account => ({
                value: account.id,
                label: (
                    <>
                        <BadgeAvatar
                            src={account.profilePicture}
                            badgeIconType={
                                account.type === 'facebook' ? Icon.Types.FACEBOOK_ROUND : Icon.Types.INSTAGRAM_ROUND
                            }
                        />
                        <Paragraph>{account.name}</Paragraph>
                    </>
                ),
            }))}
        />
    );
};

export default SelectAccountField;
