import { takeLatest, call } from '@redux-saga/core/effects';

import * as actions from '../actions';

import saveRandomDraw from './saveRandomDraw';
import saveVideoSettings from './saveVideoSettings';

function* handleSaveRandomDrawDraft(action: ReturnType<typeof actions.saveDraft>) {
    try {
        const result = yield call(saveRandomDraw, action.payload);
        yield call(saveVideoSettings, result.meta.id, action.payload);
    } catch (error) {}
}

export default function* saveRandomDrawDraft() {
    yield takeLatest(actions.saveDraft.toString(), handleSaveRandomDrawDraft);
}
