import React, { FC } from 'react';
import { Badge as AntdBadge } from 'antd';
import type { BadgeProps } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Badge.styles';

const Badge: FC<BadgeProps> = props => {
    const { styles } = useFelaEnhanced(felaRules);

    return <AntdBadge {...props} className={styles.badge} />;
};

export default Badge;
