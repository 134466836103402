import type { TRuleWithTheme } from 'styles/theme';

export const meta: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',

    '& .ant-list-item-meta-avatar': {
        marginRight: theme.spacing.small,
    },

    '& .ant-list-item-meta-title': {
        marginBottom: 0,
    },

    desktop: {
        '& .ant-list-item-meta-avatar': {
            marginRight: '2rem',
        },
    },
});
