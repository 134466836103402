import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from '../../../../constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.ACCOUNTS,
});

export const createAccount = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_ACCOUNT_' }),
});

export const fetchAccounts = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ACCOUNTS_' }),
});

export const deleteAccount = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'DELETE_ACCOUNT_' }),
});

export const moveAccount = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'MOVE_ACCOUNT_' }),
});
