import { combineRules } from 'fela';
import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const column = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    desktop: {
        flex: 1,
        padding: '2.5rem 2rem 0',
    },
});

export const columnForm: TRule = combineRules(column, () => ({
    marginTop: '5rem',

    desktop: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        padding: `3rem 2rem`,
        marginTop: 0,
    },
}));

export const formContent: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 420,

    textAlign: 'center',

    '& .ant-typography': {
        marginBottom: '2.5rem',
    },
});

export const columnFeatures = combineRules(column, ({ theme }) => ({
    display: 'none',

    desktop: {
        display: 'flex',
        backgroundColor: theme.colors.buttonDefaultBackground,
        borderTopRightRadius: theme.borderRadius.card,
        borderBottomRightRadius: theme.borderRadius.card,

        maxWidth: 560,
    },
}));

export const heading: TRuleWithTheme = ({ theme }) => ({
    marginTop: '7rem',
    paddingRight: '4.125rem',
    padding: '0 4.125rem 0 1.5rem',

    '&.ant-typography': {
        fontSize: '1.25rem',
        lineHeight: 1.625,
        color: theme.colors.primary,
    },
});

export const perex: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.primary,
    marginTop: '1rem',
    padding: '0 4.125rem 0 1.5rem',
});

export const separatorText: TRule = () => ({
    marginTop: '0.5rem',
    marginBottom: '1.5rem',
});

export const illustration: TRule = () => ({
    width: '100%',
    maxWidth: 520,
    marginTop: 'auto',
});

export const header: TRule = () => ({
    marginBottom: '2.25rem',

    '& .ant-typography': {
        marginBottom: '0.25rem',
    },
});

export const footer: TRule = () => ({
    marginTop: '3rem',
});
