import type { FC } from 'react';
import { StepProps as AntStepProps, Steps as AntSteps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import * as felaRules from './Step.styles';

interface StepProps extends AntStepProps {}

const Step: FC<StepProps> = ({ status, children, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { status });
    return (
        <AntSteps.Step status={status === 'finish' ? 'wait' : status} className={styles.step} {...rest}>
            {children}
        </AntSteps.Step>
    );
};

export default Step;
