import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ hide: boolean }> = ({ hide }) => ({
    display: hide ? 'none' : 'block',
});

export const list: TRule = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(116px, 1fr))',
    gap: '0.375rem',
    padding: 0,
    listStyle: 'none',

    tablet: {
        gridTemplateColumns: 'repeat(5, minmax(116px, 1fr))',
        gridTemplateRows: 'repeat(3, 116px)',
    },
});

export const button: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '0.25rem',

    background: 'none',
    borderColor: 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.borderRadius.navItem,

    cursor: 'pointer',
    transitionProperty: 'border-color',
    transitionDuration: theme.transition.duration,

    ':disabled': {
        cursor: 'not-allowed',
    },

    ':hover': {
        borderColor: theme.colors.primarySelected,
    },
    ':focus': {
        borderColor: theme.colors.primarySelected,
    },
    ':active': {
        borderColor: theme.colors.primarySelected,
    },
});

export const image: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.borderRadius.small,
});
