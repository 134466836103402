import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import { components } from 'api/apiSchema';
import { selectProp } from 'services/selectors';

import { ApiReducerKey, EntityKey } from 'modules/entities/constants';
import { denormalizeJob } from 'modules/entities/services/normalizr';
import { Job } from 'modules/entities/types';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { GenerationProgress, JobStates } from './types';

type JobState = components['schemas']['JobState'];

export const selectJobsApi = state => apiSelector(state, EntityKey.JOBS, ApiReducerKey.DETAIL);
export const selectCreateJobApi = state => apiSelector(state, EntityKey.JOBS, ApiReducerKey.CREATE);

export const selectJobDetail = createSelector(
    [entitiesSelectors.selectJobEntities, selectProp<Job['id']>('id')],
    (job, id) =>
        denormalizeJob(id, {
            [EntityKey.JOBS]: job.byId,
        }),
);

export const selectJobState = createSelector(selectJobDetail, job => job?.states[job?.states.length - 1]?.state);

export const selectJobVideoURL = createSelector(selectJobDetail, job => job?.videoUrl);

export const selectJobCompetitionId = createSelector(selectJobDetail, job => job?.competitionId);

export const selectJobPercentage = createSelector(selectJobState, (jobState: JobState['state']) => {
    switch (jobState) {
        case JobStates.NEW || JobStates.ERROR_VIDEO_GENERATE:
            return 0;
        case JobStates.DRAWING_WINNERS:
            return 12;
        case JobStates.SAVING_WINNERS_METADATA:
            return 18;
        case JobStates.CALLING_VIDEO_CREATOR_SERVICE:
            return 24;
        case JobStates.SAVING_COMPETITION_RESULTS:
            return 30;
        case JobStates.CLEANING_ELASTIC_DATA:
            return 36;
        case JobStates.CREATING_VIDEO_INPUT_FILE:
            return 48;
        case JobStates.GENERATING_VIDEO:
            return 54;
        case JobStates.UPLOADING_VIDEO:
            return 90;
        case JobStates.CLEANING_TMP_FILES:
            return 95;
        case JobStates.FINISHED:
            return 100;
        default:
            return 0;
    }
});

export const selectJobDescription = createSelector(selectJobState, (jobState: JobState['state']) => {
    switch (jobState) {
        case JobStates.NEW:
        case JobStates.DRAWING_WINNERS:
        case JobStates.SAVING_WINNERS_METADATA:
        default:
            return 'drawingWinners';
        case JobStates.CALLING_VIDEO_CREATOR_SERVICE:
        case JobStates.CREATING_VIDEO_INPUT_FILE:
        case JobStates.GENERATING_VIDEO:
            return 'generatingVideo';
        case JobStates.SAVING_COMPETITION_RESULTS:
        case JobStates.CLEANING_ELASTIC_DATA:
        case JobStates.UPLOADING_VIDEO:
        case JobStates.CLEANING_TMP_FILES:
            return 'uploadingVideo';
        case JobStates.ERROR_VIDEO_GENERATE:
            return 'generatingVideoError';
        case JobStates.FINISHED:
            return 'done';
    }
});

export const selectJobProgressState = createSelector(selectJobState, (jobState: JobStates) => {
    let progress = GenerationProgress.COMMENTS;
    const commentStates = [JobStates.NEW, JobStates.DRAWING_WINNERS, JobStates.SAVING_WINNERS_METADATA];
    if (jobState && !commentStates.includes(jobState)) {
        progress = GenerationProgress.VIDEO;
    }
    return progress;
});
