import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.MEDIA,
});

export const createMedia = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_MEDIA_' }),
});
