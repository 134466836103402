import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const header: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',

    '& .ant-typography': {
        marginBottom: 0,
    },
});

export const button: TRuleWithTheme = ({ theme }) => ({
    marginRight: '1.5rem',
    color: theme.colors.textDark,
});
