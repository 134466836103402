import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

import type { PanelFieldProps } from './PanelField';

export const panelItem: TRuleWithTheme = ({ theme }) => ({
    padding: `${theme.spacing.large} 1.5rem`,

    '& .ant-form-item': {
        marginBottom: 0,
    },

    tablet: {
        padding: `2.875rem ${theme.spacing.large}`,

        '&.ant-list-item': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
});

export const header: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
});

export const paragraph: TRuleWithTheme<Pick<PanelFieldProps, 'title'>> = ({ title }) => ({
    marginTop: title ? '0.5rem' : 0,
});

export const content: TRule = () => ({
    width: '100%',
    marginTop: '1.875rem',
});

export const description: TRuleWithTheme = ({ theme }) => ({
    marginRight: theme.spacing.small,
});
