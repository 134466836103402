import { denormalize } from 'normalizr';
import type { Schema } from 'normalizr';

import * as schemas from './schemas';

import type { EntityKey } from '../../constants';
import type { Entities } from '../../types';

const denormalizeFactory = <Entity extends Entities = Entities>(schema: Schema) => (
    input: Array<Entity['id']> | Entity['id'],
    entities: Partial<Record<EntityKey, any>>,
) => denormalize(input, schema, entities);

export const denormalizeAccount = denormalizeFactory(schemas.accountSchema);
export const denormalizeAccounts = denormalizeFactory(schemas.accountListSchema);

export const denormalizeAccountGroup = denormalizeFactory(schemas.accountGroupSchema);
export const denormalizeAccountGroups = denormalizeFactory(schemas.accountGroupListSchema);

export const denormalizeCompetition = denormalizeFactory(schemas.competitionSchema);
export const denormalizeCompetitions = denormalizeFactory(schemas.competitionListSchema);

export const denormalizePost = denormalizeFactory(schemas.postSchema);
export const denormalizePosts = denormalizeFactory(schemas.postListSchema);

export const denormalizeArticle = denormalizeFactory(schemas.articleSchema);
export const denormalizeArticles = denormalizeFactory(schemas.articleListSchema);

export const denormalizeInstagramPost = denormalizeFactory(schemas.instagramPostSchema);
export const denormalizeInstagramPosts = denormalizeFactory(schemas.instagramPostListSchema);

export const denormalizeFacebookPost = denormalizeFactory(schemas.facebookPostSchema);
export const denormalizeFacebookPosts = denormalizeFactory(schemas.facebookPostListSchema);

export const denormalizeMedia = denormalizeFactory(schemas.mediaSchema);
export const denormalizeMediaList = denormalizeFactory(schemas.mediaListSchema);

export const denormalizeJob = denormalizeFactory(schemas.jobSchema);
