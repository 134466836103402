import { takeLatest, put, call, take } from '@redux-saga/core/effects';
import type { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '../actions';
import type { RandomDrawFormValues } from '../../types';
import { push } from 'connected-react-router';

import saveRandomDraw from './saveRandomDraw';
import saveVideoSettings from './saveVideoSettings';
import { apiActions as jobApiActions } from 'modules/entities/modules/jobs';

import { generatePath } from 'react-router-dom';
import config from 'config/config';

function* handleSubmitRandomDrawForm(action: PayloadAction<RandomDrawFormValues, string>) {
    const publishedAt = new Date().toISOString();
    try {
        const result = yield call(saveRandomDraw, { ...action.payload, publishedAt });
        const competitionId = result.meta.id;
        yield call(saveVideoSettings, competitionId, action.payload);

        yield put(jobApiActions.createJob.request({ competitionId }));

        const createJobResult = yield take([
            jobApiActions.createJob.success.toString(),
            jobApiActions.createJob.failure.toString(),
        ]);
        const jobId = createJobResult.payload;
        if (!createJobResult.error) {
            yield put(actions.randomDrawForm.submitSuccess());
            yield put(push(generatePath(config.routes.randomDrawProgress, { jobId })));
        } else {
            yield put(actions.randomDrawForm.submitFailure(createJobResult.error));
        }
    } catch (error) {
        yield put(actions.randomDrawForm.submitFailure(error));
    }
}

export default function* submitRandomDrawForm() {
    yield takeLatest(actions.randomDrawForm.submit.toString(), handleSubmitRandomDrawForm);
}
