import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-final-form';
import { ComingSoonBadge } from 'modules/ui';

import { Panel, Icon } from 'modules/ui';
import {
    PanelField,
    CounterField,
    SwitchField,
    DatePickerField,
    TagsField,
    validatorsWithMessage,
    translate,
} from 'modules/form';

import * as felaRules from './RandomDraw.styles';
import { RandomDrawField } from '../../config';
import { MAX_ALLOWED_HASHTAGS } from '../../constants';

const { maxTagCount } = validatorsWithMessage;

const validators = {
    [RandomDrawField.HASHTAGS]: maxTagCount(MAX_ALLOWED_HASHTAGS),
};

const RandomDrawRules: FC = () => {
    const { formatMessage } = useIntl();
    const form = useForm();

    return (
        <Panel>
            <PanelField
                title={<FormattedMessage id="randomDraw.rules.winners.title" />}
                control={<CounterField name={RandomDrawField.WINNERS} minCount={1} maxCount={10} />}
            />

            <ComingSoonBadge>
                <PanelField
                    title={<FormattedMessage id="randomDraw.rules.substitutes.title" />}
                    control={<CounterField name={RandomDrawField.SUBSTITUTES} />}
                />
            </ComingSoonBadge>

            <PanelField
                title={<FormattedMessage id="randomDraw.rules.postCommented.title" />}
                description={<FormattedMessage id="randomDraw.rules.postCommented.description" />}
                control={<SwitchField name={RandomDrawField.POST_COMMENTED} />}
            />

            <PanelField
                title={<FormattedMessage id="randomDraw.rules.duplicatedUsersExcluded.title" />}
                description={<FormattedMessage id="randomDraw.rules.duplicatedUsersExcluded.description" />}
                control={<SwitchField name={RandomDrawField.DUPLICATED_USERS_EXCLUDED} />}
            />

            <PanelField
                title={<FormattedMessage id="randomDraw.rules.profilesMentioned.title" />}
                description={<FormattedMessage id="randomDraw.rules.profilesMentioned.description" />}
                control={<CounterField name={RandomDrawField.PROFILES_MENTIONED} minCount={0} maxCount={20} />}
            />

            <PanelField
                optional
                title={<FormattedMessage id="randomDraw.rules.endAt.title" />}
                description={<FormattedMessage id="randomDraw.rules.endAt.description" />}
                onDisable={() => form.mutators.clear(RandomDrawField.END_AT)}
            >
                {({ disabled }) => (
                    <DatePickerField
                        name={RandomDrawField.END_AT}
                        label={<FormattedMessage id="randomDraw.rules.endAt.label" />}
                        datePickerProps={{ showTime: true, disabled }}
                        extend={{ formItem: felaRules.fluidFormItem }}
                    />
                )}
            </PanelField>

            <PanelField
                optional
                defaultOn={false}
                title={<FormattedMessage id="randomDraw.rules.hashtags.title" />}
                description={<FormattedMessage id="randomDraw.rules.hashtags.description" />}
                onDisable={() => form.mutators.clear(RandomDrawField.HASHTAGS)}
            >
                {({ disabled }) => (
                    <TagsField
                        name={RandomDrawField.HASHTAGS}
                        label={<FormattedMessage id="randomDraw.rules.hashtags.label" />}
                        help={<FormattedMessage id="randomDraw.rules.hashtags.help" />}
                        prefix={<Icon type={Icon.Types.PLUS} />}
                        prefixType="delimited"
                        disabled={disabled}
                        validate={translate(
                            ({ id }) => formatMessage({ id }, { count: MAX_ALLOWED_HASHTAGS }),
                            validators[RandomDrawField.HASHTAGS],
                        )}
                        extend={{ formItem: felaRules.fluidFormItem }}
                    />
                )}
            </PanelField>

            <ComingSoonBadge>
                <PanelField
                    optional
                    defaultOn={false}
                    title={<FormattedMessage id="randomDraw.rules.blockList.title" />}
                    onDisable={() => form.mutators.clear(RandomDrawField.BLOCK_LIST)}
                >
                    {({ disabled }) => (
                        <TagsField
                            name={RandomDrawField.BLOCK_LIST}
                            label={<FormattedMessage id="randomDraw.rules.blockList.label" />}
                            help={<FormattedMessage id="randomDraw.rules.blockList.help" />}
                            prefix={<Icon type={Icon.Types.PLUS} />}
                            prefixType="delimited"
                            disabled={disabled}
                            extend={{ formItem: felaRules.fluidFormItem }}
                        />
                    )}
                </PanelField>
            </ComingSoonBadge>

            {/* TODO: Turning on only for PRO user when implemented */}
            <ComingSoonBadge borderRadius="xlarge" borderRadiusBottom>
                <PanelField
                    title={<FormattedMessage id="randomDraw.rules.brandingHidden.title" />}
                    description={<FormattedMessage id="randomDraw.rules.brandingHidden.description" />}
                    control={<SwitchField name={RandomDrawField.BRANDING_HIDDEN} disabled />}
                />
            </ComingSoonBadge>
        </Panel>
    );
};

export default RandomDrawRules;
