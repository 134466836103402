import { call, put } from 'redux-saga/effects';
import { noop } from 'lodash';

import { ApiGet, ApiResponse, authApi } from 'config/antonio';
import config from 'config/config';
import * as log from 'config/loglevel';

import takeLatestRequest from 'services/sagas/takeLatestRequest';
import { operations } from 'api/apiSchema';

import { normalizeInstagramPost } from 'modules/entities/services/normalizr';
import * as entitiesActions from 'modules/entities/services/actions';
import { EntityKey } from 'modules/entities/constants';
import { createUIErrorMessage } from 'modules/errors';

import { fetchPost as apiActions } from '../actions';

type Operation = operations['getInstagramPostDetail'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;
type RequestParams = Operation['parameters']['path'];
type RequestAction = ReturnType<typeof apiActions.request>;
type CancelAction = ReturnType<typeof apiActions.cancel>;

function* fetchInstagramPostHandler(action: RequestAction) {
    try {
        const { data }: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.instagram.postDetail,
            {
                uriParams: {
                    id: action.meta.id.id,
                    postId: action.meta.id.postId,
                },
            },
        );

        const { result: id, entities } = normalizeInstagramPost(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.INSTAGRAM_POSTS, {
                ids: [id],
                byId: entities[EntityKey.INSTAGRAM_POSTS],
            }),
            apiActions.success({ id: action.meta.id.id, postId: action.meta.id.postId }, undefined),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(apiActions.failure({ id: action.meta.id.id, postId: action.meta.id.postId }, uiError));

        log.error(error);
    }
}

export default function* fetchInstagramPost() {
    yield takeLatestRequest(
        (action: RequestAction | CancelAction) => action.meta.id.postId,
        {
            pattern: apiActions.request.toString(),
            handler: fetchInstagramPostHandler,
        },
        { pattern: apiActions.cancel.toString(), handler: noop },
    );
}
