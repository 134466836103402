import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/reducers';

import { actions as facebookActions, selectFacebookPostsApi } from 'modules/entities/modules/facebook-posts';
import { actions as instagramActions, selectInstagramPostsApi } from 'modules/entities/modules/instagram-posts';
import { Account } from 'modules/entities';

const actions = {
    instagram: instagramActions,
    facebook: facebookActions,
};

const selectors = {
    instagram: selectInstagramPostsApi,
    facebook: selectFacebookPostsApi,
};

const useFetchPosts = (providerId: Account['providerId'], platform: Account['type']) => {
    const api: ApiReducerState = useSelector(selectors[platform]);
    const dispatch = useDispatch();

    const requestPosts = () => {
        if (!api.inProgress && api.nextPageToken) {
            dispatch(
                actions[platform].fetchPosts.request(providerId, {
                    params: { nextPageToken: api.nextPageToken },
                    strategy: 'append',
                }),
            );
        }
    };
    

    useEffect(() => {
        dispatch(actions[platform].fetchPosts.request(providerId, { strategy: 'replace' }));

        return () => {
            batch(() => {
                dispatch(actions[platform].fetchPosts.cancel(providerId));
                dispatch(actions[platform].fetchPosts.reset(providerId));
            });
        };
    }, [dispatch, providerId, platform]);

    return { ...api, requestPosts };
};

export default useFetchPosts;
