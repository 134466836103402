import type { FC } from 'react';
import { Radio, RadioProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './RadioButton.styles';
import RadioButtonCircle from '../RadioButtonCircle';

export interface RadioButtonProps extends RadioProps {
    extend?: RulesExtend<typeof felaRules>;
}

const RadioButton: FC<RadioButtonProps> = ({ extend, children, checked, disabled, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, checked, disabled });

    return (
        <Radio className={styles.radioButton} checked={checked} disabled={disabled} {...rest}>
            {children}
            <RadioButtonCircle checked={checked} />
        </Radio>
    );
};

export default RadioButton;
