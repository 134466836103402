import type { FC } from 'react';
import { Button } from 'modules/ui';
import { FormattedMessage } from 'react-intl';
import { useRandomDrawAgain } from 'modules/random-draw/hooks';
import type { Competition } from 'modules/entities';

interface RandomDrawDrawAgainButtonProps {
    competitionId: Competition['id'];
}
const RandomDrawDrawAgainButton: FC<RandomDrawDrawAgainButtonProps> = ({ competitionId }) => {
    const drawAgain = useRandomDrawAgain(competitionId);

    return (
        <Button onClick={() => drawAgain()} type="default" size="large">
            <FormattedMessage id="randomDraw.results.button.draw" />
        </Button>
    );
};

export default RandomDrawDrawAgainButton;
