import { validatorsWithMessage, validators, setValidatorMessage } from 'modules/form';

import { RandomDrawTab, RandomDrawFormValues } from '../types';
import { RandomDrawField } from '../config';
import { randomDrawTabs } from '../constants';

const { required } = validatorsWithMessage;

type Errors = Partial<Record<RandomDrawField, string | undefined>>;
type Validator = (values: RandomDrawFormValues, errors?: Errors) => Errors;

const accountRequired = setValidatorMessage(validators.required, 'randomDraw.error.accountRequired');
const postRequired = setValidatorMessage(validators.required, 'randomDraw.error.postRequired');

// TODO: tests

const randomDrawTabValidators: Record<RandomDrawTab, Validator> = {
    [RandomDrawTab.POST]: (values, errors = {}) => {
        errors.accountId = accountRequired(values.accountId);
        errors.postId = postRequired(values.postId);

        return errors;
    },
    [RandomDrawTab.RULES]: (_, errors = {}) => errors,
    [RandomDrawTab.VIDEO]: (values, errors = {}) => {
        errors.headline = required(values.headline);
        return errors;
    },
    [RandomDrawTab.SUMMARY]: (_, errors = {}) => errors,
};

const isInvalid = (errors: Errors) => Object.values(errors).filter(Boolean).length > 0;

export const getFirstInvalidTab = (values: RandomDrawFormValues): RandomDrawTab | undefined => {
    let errors: Errors = {};

    for (const tab of randomDrawTabs) {
        errors = randomDrawTabValidators[tab](values, errors);

        if (isInvalid(errors)) {
            return tab;
        }
    }
};

export const validateRandomDrawForm = (tab: RandomDrawTab, values: RandomDrawFormValues) => {
    let errors = randomDrawTabValidators[RandomDrawTab.POST](values);

    if (tab === RandomDrawTab.VIDEO) {
        errors = randomDrawTabValidators[RandomDrawTab.VIDEO](values, errors);
    }

    if (isInvalid(errors)) {
        return errors;
    }
};
