import React, { FC } from 'react';

export interface AnalyticsProps {}

const Analytics: FC<AnalyticsProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path d="M5 16a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1"></path>
            <path d="M12 13a1 1 0 00-1 1v6a1 1 0 102 0v-6a1 1 0 00-1-1"></path>
            <path d="M19 11a1 1 0 00-1 1v8a1 1 0 102 0v-8a1 1 0 00-1-1"></path>
            <path d="M19.923 3.618A1.003 1.003 0 0019 3h-4a1 1 0 100 2h1.586l-3.431 3.43-1.323-1.985c-.02-.029-.05-.044-.07-.07-.022-.026-.03-.058-.055-.082-.024-.025-.057-.034-.083-.056a.957.957 0 00-.342-.182.95.95 0 00-.375-.037.889.889 0 00-.182.036.877.877 0 00-.186.076c-.031.015-.064.018-.094.038-.028.02-.043.05-.07.07-.026.022-.057.03-.082.055l-6 6a.999.999 0 101.414 1.414l5.138-5.138 1.323 1.986c.029.043.067.08.102.117.009.01.013.024.023.035.005.005.012.006.017.011a.993.993 0 00.356.216c.108.04.218.065.333.066h.002c.113 0 .226-.027.336-.066.033-.01.063-.029.096-.045.04-.019.083-.032.122-.057.029-.02.044-.049.07-.07.026-.022.058-.03.082-.055L18 6.414V8a1 1 0 102 0V4c0-.13-.026-.26-.077-.382"></path>
        </g>
    </svg>
);

export default Analytics;
