// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@ackee/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.less"}]);
// Exports
exports.locals = {
	"primary": "#6b67df",
	"primaryOpacity": "#6b67dfcc",
	"primarySelected": "#d4f2f7",
	"primarySelectedWithOpactiy": "#d4f2f780",
	"primaryHover": "#eefafc",
	"backgroundPrimary": "#f3f6f7",
	"backgroundSecondary": "#ffffff",
	"textDark": "#1a1a1a",
	"textGray": "#95a3a9",
	"textLight": "#ccd3d6",
	"separator": "#eef1f1",
	"white": "#ffffff",
	"buttonDefault": "#534eda",
	"buttonDefaultBackground": "#efeefb",
	"buttonHover": "#9491e8",
	"buttonComingSoon": "#d4f2f7",
	"comingSoon": "#95a3a94d",
	"buttonActive": "#423dd6",
	"buttonDisabled": "#6b67df",
	"inputLabel": "#b0bbbf",
	"inputPlaceholder": "#d2d9da",
	"inputBorder": "#dde2e3",
	"inputBackground": "#ffffff",
	"inputHover": "#87dbe9",
	"inputFocus": "#c3edf4",
	"error": "#ff3434",
	"errorBackground": "#ff3333",
	"errorBackgroundLight": "#ffebeb",
	"cardBorder": "#f4f5f6",
	"cardBorderDark": "#e3e7e8",
	"cardBorderActive": "#c6dae1",
	"icon": "#95a3a9"
};
module.exports = exports;
