import type { FC } from 'react';
import { noop } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import * as felaRules from './PostMediaThumbnail.styles';

export interface PostMediaThumbnailProps {
    src: string;
    alt?: string;
    active?: boolean;

    onClick?: () => void;
}

const PostMediaThumbnail: FC<PostMediaThumbnailProps> = ({ src, alt = '', active = false, onClick = noop }) => {
    const { styles } = useFelaEnhanced(felaRules, { active });

    return (
        <button type="button" className={styles.imageButton} onClick={onClick}>
            <img src={src} alt={alt} className={styles.thumbnail} />
        </button>
    );
};

export default PostMediaThumbnail;
