import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQuery } from 'hooks';
import { Button, Intro } from 'modules/ui';
import { Form, TextField, useFormSubmit, validatorsWithMessage, composeValidators, translate } from 'modules/form';
import { ErrorAlert } from 'modules/errors';

import { ResetPasswordFormField, forms, PASSWORD_LENGTH } from '../../config';
import { resetPasswordForm } from '../../services/actions';

import * as felaRules from './ResetPasswordForm.styles';

export interface ResetPasswordFormProps {
    onSubmitSucceeded: () => void;
}

const { required, password } = validatorsWithMessage;

const validators = {
    [ResetPasswordFormField.NewPassword]: composeValidators(required, password(PASSWORD_LENGTH)),
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmitSucceeded }) => {
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit(resetPasswordForm);
    const query = useQuery();

    return (
        <>
            <Intro
                title={<FormattedMessage id="auth.resetPassword.form.title" />}
                text={<FormattedMessage id="auth.resetPassword.form.text" />}
            />

            <Form
                name={forms.resetPassword}
                onSubmit={onSubmit}
                onSubmitSucceeded={onSubmitSucceeded}
                initialValues={{
                    [ResetPasswordFormField.ResetToken]: query.get('resetToken'),
                }}
            >
                {({ submitting, submitError }) => (
                    <>
                        <ErrorAlert error={submitError} extend={{ alert: felaRules.alert }} />

                        <TextField
                            name={ResetPasswordFormField.NewPassword}
                            type="password"
                            label={<FormattedMessage id="auth.resetPassword.form.newPassword" />}
                            disabled={submitting}
                            validate={translate(
                                ({ id }) => formatMessage({ id }, { length: PASSWORD_LENGTH }),
                                validators[ResetPasswordFormField.NewPassword],
                            )}
                            help={<FormattedMessage id="auth.resetPassword.form.newPassword.hint" />}
                        />

                        <TextField name={ResetPasswordFormField.ResetToken} type="hidden" />

                        <Button
                            type="primary"
                            size="large"
                            block
                            htmlType="submit"
                            disabled={submitting}
                            loading={submitting}
                        >
                            <FormattedMessage id="auth.resetPassword.form.button" />
                        </Button>
                    </>
                )}
            </Form>
        </>
    );
};

export default ResetPasswordForm;
