import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Form, useFormSubmit } from 'modules/form';
import { usePlanPostFormInitialValues, usePostSaveApi } from 'modules/new-post/hooks';
import { useToast } from 'modules/ui';

import { forms, PlanPostField } from '../../config';
import type { PlanPostFormValues } from '../../types';
import { planPostForm } from '../../services/actions';
import { useErrorToast } from 'modules/errors';

const PlanPostForm: FC = ({ children }) => {
    const { formatMessage } = useIntl();
    const toast = useToast();

    const onSubmit = useFormSubmit<PlanPostFormValues>(planPostForm);
    const initialValues = usePlanPostFormInitialValues();
    const { lastSuccessAt, error } = usePostSaveApi();

    useErrorToast(error, 'post.save.error');
    useEffect(() => {
        // TODO: Distinguish between save draft and publish
        if (lastSuccessAt) {
            toast({ type: 'success', message: formatMessage({ id: 'post.save.success' }) });
        }
    }, [lastSuccessAt, toast, formatMessage]);

    return (
        <Form<PlanPostFormValues>
            name={forms.planPost}
            onSubmit={onSubmit}
            initialValues={initialValues}
            subscription={{}}
            mutators={{
                clear: ([field]: [PlanPostField], state, utils) => {
                    utils.changeValue(state, field, () => null);
                    utils.resetFieldState(field);
                },
            }}
        >
            {() => <>{children}</>}
        </Form>
    );
};

export default PlanPostForm;
