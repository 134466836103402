import type { TRuleWithTheme } from 'styles/theme';

export const mainContent: TRuleWithTheme = ({ theme }) => ({
    '& .ant-upload.ant-upload-drag': {
        border: `2px dashed ${theme.colors.inputBorder}`,
    },
});

export const upload: TRuleWithTheme = ({ theme }) => ({
    borderRadius: theme.borderRadius.large,

    '& p': {
        marginTop: '0.25rem',
    },
});

export const mainImage: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    border: `2px solid ${theme.colors.inputBorder}`,
    borderRadius: theme.borderRadius.large,
});

export const uploadButton: TRuleWithTheme = () => ({
    '& .ant-upload': {
        width: '100%',
        height: '100%',
    },
});

export const button: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: 0,

    '& .anticon': {
        height: theme.sizes.icon.small,
    },
});

export const footer: TRuleWithTheme = () => ({
    textAlign: 'right',
});

export const footerCount: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textDark,
});
