import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './PlanPostCardsModal.styles';
import { Button, Heading, Icon } from 'modules/ui';
import { FormattedMessage } from 'react-intl';
import PlanPostCardsGrid from '../PlanPostCardsGrid';

type PlanPostCardsModalProps = {
    opened: boolean;
    toggleOpen: () => void;
};

const PlanPostCardsModal: FC<PlanPostCardsModalProps> = ({ opened, toggleOpen }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        opened && (
            <div className={styles.planPostModal}>
                <div className={styles.planPostModalHeader}>
                    <Heading>
                        <FormattedMessage id="posts.planNew" />
                    </Heading>
                    <Button type="link" icon={<Icon type={Icon.Types.CLOSE} />} onClick={toggleOpen} />
                </div>
                <div className={styles.cardSpacing}>
                    <PlanPostCardsGrid />
                </div>
            </div>
        )
    );
};

export default PlanPostCardsModal;
