import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { NavLink } from 'react-router-dom';

import { Icon } from 'modules/ui';
import { useUser } from 'modules/auth';
import { generateProfilePath } from 'modules/profile';

import * as felaRules from './NavUser.styles';
import NavTab from '../NavTab';
import NavItem from '../NavItem';

export interface NavUserProps {
    isHeaderScrolled: boolean;
}

const NavUser: FC<NavUserProps> = ({ isHeaderScrolled }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const user = useUser();

    if (!user) {
        return null;
    }

    return (
        <>
            <NavTab clickable shadow={!isHeaderScrolled} extend={{ tab: felaRules.tab }}>
                <NavItem exact={false} to={generateProfilePath()} icon={Icon.Types.USER} compact={isHeaderScrolled}>
                    {user.name ?? user.email}
                </NavItem>
            </NavTab>

            <NavLink exact={false} to={generateProfilePath()} className={styles.mobileLink}>
                <Icon type={Icon.Types.USER} />
            </NavLink>
        </>
    );
};

export default NavUser;
