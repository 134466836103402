const environments = {
    PRODUCTION: 'production',
    STAGE: 'stage',
    DEVELOPMENT: 'development',
    TEST: 'test',
};

export const currentEnv = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;

export const isEnvDevelopment = process.env.NODE_ENV === environments.DEVELOPMENT;
export const isEnvStage = currentEnv === environments.STAGE;
export const isEnvProduction = process.env.NODE_ENV === environments.PRODUCTION;
export const isEnvTest = currentEnv === environments.TEST;

export const isServerEnv = typeof window === 'undefined';
export const isDevServerEnv = process.env.REACT_APP_IS_DEV_SERVER === 'true';

export default environments;
