import { FC, useState } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { Media } from 'modules/entities';
import placeholderImage from 'assets/images/post_fallback.png';

import PostMediaThumbnail from '../PostMediaThumbnail';

import * as rules from './PostMediaPreview.styles';
import PostMediaSkeleton from '../PostMediaSkeleton';

export interface PostMediaPreviewProps {
    media?: Partial<Media>[];
}

const PostMediaPreview: FC<PostMediaPreviewProps> = ({ media = [] }) => {
    const { styles } = useFelaEnhanced(rules);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

    return (
        <PostMediaSkeleton
            thumbnailCount={media.length}
            renderThumbnail={index => (
                <PostMediaThumbnail
                    src={media[index]?.url}
                    alt={media[index]?.name}
                    active={selectedMediaIndex === index}
                    onClick={() => setSelectedMediaIndex(index)}
                />
            )}
        >
            <img
                src={media[selectedMediaIndex]?.url ?? placeholderImage}
                alt={media[selectedMediaIndex]?.name}
                className={styles.mainImage}
            />
        </PostMediaSkeleton>
    );
};

export default PostMediaPreview;
