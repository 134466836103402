import { basicApiReducer } from '@ackee/redux-utils';
import * as types from '../../actions/types';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.createJob.CREATE_JOB_REQUEST,
        SUCCESS: types.createJob.CREATE_JOB_SUCCESS,
        FAILURE: types.createJob.CREATE_JOB_FAILURE,
        CANCEL: types.createJob.CREATE_JOB_CANCEL,
    },
});
