import { take, put, select } from 'redux-saga/effects';
import { routingSelector } from '@ackee/chris';

import type { operations } from 'api/apiSchema';
import type { Post, Media, Competition } from 'modules/entities';
import { actions as postsActions } from 'modules/entities/modules/posts';
import { selectAccount } from 'modules/entities/modules/accounts';

import type { PlanPostFormValues } from '../../types';
import { selectNewPostMediaIdsFactory } from '../selectors';
import { providerGroupMap } from '../../constants';

type Operation = operations['postCreate'] | operations['competitionCreate'];
type Payload = Operation['requestBody']['content']['application/json'];

export default function* savePost(values: PlanPostFormValues, publish: boolean) {
    const location = yield select(routingSelector);
    const postId: Post['id'] = location.state?.postId;
    const competitionId: Competition['id'] = location.state?.competitionId;

    const account = yield select(state => selectAccount(state, { id: values.accountId }));
    const group = providerGroupMap[account.type];

    const selectMediaIds = selectNewPostMediaIdsFactory(group, postId, competitionId);
    const selectedMediaIds: Media['id'][] = yield select(selectMediaIds);

    const mediaIds = selectedMediaIds;
    const postContent = values.facebook ?? values.instagram;
    const payload: Payload = {
        accountIds: [values.accountId],
        type: competitionId ? 'video' : 'feed',
        content: postContent?.caption,
        comment: postContent?.firstComment,
        publish,
        scheduledAt: publish ? new Date().toISOString() : undefined,
        mediaIds,
    };
    if (postId) {
        yield put(postsActions.updatePost.request(postId, payload));

        const updateAction = yield take([
            postsActions.updatePost.success.toString(),
            postsActions.updatePost.failure.toString(),
        ]);

        return updateAction;
    } else {
        yield put(postsActions.createPost.request(payload));

        const createAction = yield take([
            postsActions.createPost.success.toString(),
            postsActions.createPost.failure.toString(),
        ]);

        return createAction;
    }
}
