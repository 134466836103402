import React, { FunctionComponent } from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { useGoogleLogin } from 'react-google-login';
import { Button, ButtonOwnProps } from 'modules/ui';
import config from 'config';

import * as googleLoginButtonRules from './GoogleLoginButton.styles';
import { useOAuth } from '../../hooks';
import { OAuthProvider } from '../../types';

export interface GoogleLoginButtonProps extends ButtonOwnProps {}

const GoogleLoginButton: FunctionComponent<GoogleLoginButtonProps> = props => {
    const { rules } = useFelaEnhanced(googleLoginButtonRules);

    const { started, inProgress, handleResponse, handleFailure, startAuth } = useOAuth('login', OAuthProvider.Google);
    const { signIn, loaded } = useGoogleLogin({
        ...config.auth.google,
        onSuccess: handleResponse,
        onFailure: handleFailure,
    });

    const onClick = () => {
        startAuth();
        signIn();
    };

    return (
        <Button
            size="large"
            block
            disabled={inProgress || !loaded}
            loading={started && inProgress}
            onClick={onClick}
            extend={{ button: rules.button }}
            {...props}
        >
            <FormattedMessage id="auth.google.button" />
        </Button>
    );
};

export default GoogleLoginButton;
