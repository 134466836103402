import { schema } from 'normalizr';
import { omit } from 'lodash';

import type { Post } from 'modules/entities/types';

import { EntityKey } from '../../constants';

export const accountSchema = new schema.Entity(EntityKey.ACCOUNTS);
export const accountListSchema = [accountSchema];

export const accountGroupSchema = new schema.Entity(EntityKey.ACCOUNT_GROUPS, {
    accounts: accountListSchema,
});

export const accountGroupListSchema = [accountGroupSchema];

export const mediaSchema = new schema.Entity(EntityKey.MEDIA);
export const mediaListSchema = [mediaSchema];

export const postSchema = new schema.Entity(
    EntityKey.POSTS,
    {
        media: mediaListSchema,
    },
    {
        processStrategy: (entity: Post) => omit(entity, 'account'),
    },
);
export const postListSchema = [postSchema];

export const articleSchema = new schema.Entity(EntityKey.ARTICLES, undefined, {
    processStrategy: value => {
        return omit(
            {
                ...value,
                id: value.id,
                featuredImage: value.featured_image_url,
                title: value.title.rendered,
                excerpt: value.excerpt.rendered,
                content: value?.content?.rendered,
            },
            ['featured_image_url', 'featured_media'],
        );
    },
});

export const articleListSchema = [articleSchema];

export const competitionSchema = new schema.Entity(EntityKey.COMPETITIONS, {
    posts: postListSchema,
    videoSettings: [new schema.Entity(EntityKey.VIDEO_SETTINGS)],
});
export const competitionListSchema = [competitionSchema];

export const instagramPostSchema = new schema.Entity(EntityKey.INSTAGRAM_POSTS);
export const instagramPostListSchema = [instagramPostSchema];

export const facebookPostSchema = new schema.Entity(EntityKey.FACEBOOK_POSTS);
export const facebookPostListSchema = [facebookPostSchema];

export const jobSchema = new schema.Entity(EntityKey.JOBS, {
    competition: competitionSchema,
    user: accountSchema,
});
export const jobListSchema = [jobSchema];

export const videoSettingsSchema = new schema.Entity(EntityKey.VIDEO_SETTINGS, {
    competition: competitionSchema,
});
export const videoSettingsListSchema = [videoSettingsSchema];
