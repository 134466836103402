import { call, put, select } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import type { ApiGet, ApiResponse } from 'config/antonio';
import { operations } from 'api/apiSchema';
import takeLatestRequest from 'services/sagas/takeLatestRequest';
import { createUIErrorMessage } from 'modules/errors';
import { authUserSelector } from 'modules/auth';
import { normalizeCompetitions } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import * as entitiesActions from 'modules/entities/services/actions';

import * as actions from '../actions';

type RequestAction = ReturnType<typeof actions.fetchCompetitions.request>;
type Operation = operations['competitionList'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json'][]>;
type RequestParams = Operation['parameters']['query'];

function* fetchCompetitionsHandler(action: RequestAction) {
    const user = yield select(authUserSelector);

    try {
        const { data, headers }: Response = yield call<ApiGet<Response, RequestParams>>(
            authApi.get,
            config.api.endpoints.competitions,
            {
                params: {
                    ...action.payload,
                    userId: user.id,
                },
            },
        );
        const { result: ids, entities } = normalizeCompetitions(data);

        yield put([
            entitiesActions.setEntitiesGroup(EntityKey.COMPETITIONS, {
                ids,
                byId: entities[EntityKey.COMPETITIONS],
            }),
            entitiesActions.setEntities(EntityKey.POSTS, entities[EntityKey.POSTS]),

            actions.fetchCompetitions.success(undefined, {
                currentCount: ids.length,
                totalCount: headers['x-total-count'],
            }),
        ]);
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchCompetitions.failure(uiError));

        log.error(error);
    }
}

function* fetchCompetitionsCancelHandler() {
    yield put(entitiesActions.resetEntitiesGroup(EntityKey.COMPETITIONS));
}

export default function* fetchCompetitions() {
    yield takeLatestRequest(
        () => 'fetchCompetitions',
        {
            pattern: actions.fetchCompetitions.request.toString(),
            handler: fetchCompetitionsHandler,
        },
        {
            pattern: actions.fetchCompetitions.cancel.toString(),
            handler: fetchCompetitionsCancelHandler,
        },
    );
}
