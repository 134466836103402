import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const card: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    flex: 1,
    height: 108,

    '&:not(:last-child)': {
        marginRight: '0.75rem',
    },

    tablet: {
        maxWidth: 170,
    },
});

export const button: TRuleWithTheme = ({ theme }) => ({
    background: 'none',
    cursor: 'pointer',

    width: '100%',
    height: '100%',

    border: `1px solid ${theme.colors.cardBorderDark}`,
    borderRadius: theme.borderRadius.navItem,

    ':hover': {
        borderColor: theme.colors.primarySelected,
    },
    ':focus': {
        borderColor: theme.colors.primarySelected,
    },
    ':active': {
        borderColor: theme.colors.primarySelected,
    },
});

export const title: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.primary,
    marginTop: '0.25rem',
});
