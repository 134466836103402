import type { Post, Account } from 'modules/entities';

import type { RandomDrawField } from './config';
import type { RandomDrawColors } from './constants';

export enum RandomDrawTab {
    POST = 'post',
    RULES = 'rules',
    VIDEO = 'video',
    SUMMARY = 'summary',
}

export interface RandomDrawFormValues {
    [RandomDrawField.ACCOUNT]: Account['id'];
    [RandomDrawField.POST]: Post['id'];
    [RandomDrawField.WINNERS]: number;
    [RandomDrawField.SUBSTITUTES]: number;
    [RandomDrawField.POST_COMMENTED]: boolean;
    [RandomDrawField.DUPLICATED_USERS_EXCLUDED]: boolean;
    [RandomDrawField.PROFILES_MENTIONED]: number;
    [RandomDrawField.END_AT]?: string;
    [RandomDrawField.HASHTAGS]: string;
    [RandomDrawField.BLOCK_LIST]: string;
    [RandomDrawField.BRANDING_HIDDEN]: boolean;
    [RandomDrawField.HEADLINE]: string;
    [RandomDrawField.SUBHEADLINE]: string;
    [RandomDrawField.COLOR]: RandomDrawColors;
}
