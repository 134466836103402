import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_SESSION_END } from '@ackee/petrus';
import type { Schema } from 'modules/entities';

type GroupId = Schema['AccountGroup']['id'];

interface SocialAccountsState {
    id?: GroupId;
}

const initialState: SocialAccountsState = {
    id: null,
};

const socialAccountsSlice = createSlice({
    name: 'selectedGroup',
    initialState,

    reducers: {
        setSelectedGroupId: (state, action: PayloadAction<GroupId>) => {
            state.id = action.payload;
        },
    },
    extraReducers: {
        [AUTH_SESSION_END]: () => initialState,
    },
});

export const { actions, reducer } = socialAccountsSlice;
