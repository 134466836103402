import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ModalHeader.styles';
import Button from '../../Button';
import Icon from '../../Icon';

export interface ModalHeaderProps {
    showBackArrow?: boolean;
    onBack?: () => void;
}

const ModalHeader: FC<ModalHeaderProps> = ({ showBackArrow, onBack, children }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <header className={styles.header}>
            {showBackArrow && (
                <Button
                    type="text"
                    icon={<Icon type={Icon.Types.BACK} />}
                    onClick={onBack}
                    extend={{ button: felaRules.button }}
                />
            )}
            {children}
        </header>
    );
};

export default ModalHeader;
