import { useMemo } from 'react';

import parse from 'html-react-parser';

const useParseHtml = (html: string) => {
    const parsedHtml = useMemo(() => parse(html), [html]);
    return parsedHtml;
};

export default useParseHtml;
