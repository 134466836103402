import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonOwnProps } from '../Button';

import * as felaRules from './SaveDraftButton.styles';

const SaveDraftButton: FC<ButtonOwnProps> = props => (
    <Button type="text" inverse size="large" {...props} extend={{ button: felaRules.button }}>
        <FormattedMessage id="randomDraw.action.saveDraft" />
    </Button>
);

export default SaveDraftButton;
