import { useDispatch } from 'react-redux';
import { randomDrawAgain } from '../services/actions';

const useSumbitRandomDrawAgain = (competitionId: string) => {

    const dispatch = useDispatch();
    const drawAgain = () => {
        dispatch(randomDrawAgain(competitionId));
    };

    return drawAgain;
};

export default useSumbitRandomDrawAgain;
