import type { FC } from 'react';
import { useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Paragraph } from 'modules/ui';
import { GenericField } from 'modules/form';
import { SelectPostList } from 'modules/posts';
import type { SocialPost } from 'modules/entities';
import { selectAccount } from 'modules/entities/modules/accounts';
import { selectSelectedGroupId } from 'modules/entities/modules/account-groups';

import RandomDrawCard from '../RandomDrawCard';
import * as felaRules from './RandomDrawPostList.styles';
import { RandomDrawField } from '../../config';
import type { RandomDrawFormValues } from '../../types';

const RandomDrawPostList: FC = () => {
    const { values } = useFormState<RandomDrawFormValues>({ subscription: { values: true } });
    const account = useSelector(state => selectAccount(state, { id: values.accountId }));
    const groupId = useSelector(selectSelectedGroupId);

    if (!account || groupId !== account.accountGroupId) {
        return null;
    }

    return (
        <RandomDrawCard>
            <Paragraph faded weight="bold" extend={{ paragraph: felaRules.cardHeading }}>
                <FormattedMessage id="randomDraw.selectPost" />
            </Paragraph>

            <GenericField<SocialPost['id']> name={RandomDrawField.POST} fluid extend={{ formItem: felaRules.formItem }}>
                <SelectPostList account={account} fieldName={RandomDrawField.POST} />
            </GenericField>
        </RandomDrawCard>
    );
};

export default RandomDrawPostList;
