import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import * as entitiesSelectors from 'modules/entities/services/selectors';
import { InstagramPost, Schema } from 'modules/entities/types';
import { denormalizeInstagramPosts } from 'modules/entities/services/normalizr';
import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { selectProp } from 'services/selectors';

export const selectInstagramPostsApi = state => apiSelector(state, EntityKey.INSTAGRAM_POSTS, ApiReducerKey.LIST);
export const selectInstagramPostApi = state => apiSelector(state, EntityKey.INSTAGRAM_POSTS, ApiReducerKey.DETAIL);

export const selectInstagramPosts = createSelector(entitiesSelectors.selectInstagramPostsEntities, posts => {
    const denormalizedPosts: Array<Schema['InstagramAccountPost']> = denormalizeInstagramPosts(posts[DEFAULT_GROUP], {
        [EntityKey.INSTAGRAM_POSTS]: posts.byId,
    });

    return denormalizedPosts;
});

export const selectInstagramPost = createSelector(
    [entitiesSelectors.selectInstagramPostsEntities, selectProp<InstagramPost['id']>('id')],
    (posts, id) =>
        denormalizeInstagramPosts(id, {
            [EntityKey.INSTAGRAM_POSTS]: posts.byId,
        }),
);
