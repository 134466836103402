import { TRuleWithTheme } from 'styles/theme';

import type { UploadableAvatarProps } from './UploadableAvatar';

export const container: TRuleWithTheme<Pick<UploadableAvatarProps, 'size'>> = ({ theme, size }) => ({
    position: 'relative',

    '& .ant-btn-icon-only': {
        width: size,
        height: size,
    },
});

export const button: TRuleWithTheme = ({ theme }) => {
    const hoverStyle = {
        boxShadow: 'none',
        color: theme.colors.white,
        backgroundColor: theme.colors.primaryOpacity,
    };

    return {
        position: 'absolute',
        top: 0,
        left: 0,

        color: theme.colors.white,
        backgroundColor: theme.colors.primaryOpacity,
        opacity: 0,

        ':hover': {
            ...hoverStyle,
            opacity: 1,
        },

        ':focus': hoverStyle,
        ':active': hoverStyle,

        '& .anticon': {
            width: 24,
            height: 24,
        },
    };
};
