import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const intro = () => ({
    fontSize: '0.875rem',
});

export const info: TRule = () => ({
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textGray,
    marginRight: '0.5rem',
    paddingTop: 3,
});

export const alert: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.spacing.xsmall,
});
