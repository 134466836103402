const config = {
    api: {
        url: 'https://api-rafflu-development.ack.ee/api/v1',
    },
    auth: {
        facebook: {
            appId: '1122470791530986',
        },
        google: {
            clientId: '689143727034-cvkali4p0db46rnrn9ngaos7fcrq3kik.apps.googleusercontent.com',
        },
    },
    pixabay: {
        apiKey: '21600815-e01ea5244382b4c525dea553b',
    },
} as const;

export type EnvConfig = typeof config;

export default config;
