import type { TRuleWithTheme } from 'styles/theme';

export const item: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
    padding: `${theme.spacing.small} 1.875rem`,

    '& .ant-list-item-action': {
        display: 'flex',
        alignItems: 'center',
    },

    '& .ant-list-item-action > li + li': {
        marginLeft: '1.5rem',
    },

    '& .ant-list-item-meta-content': {
        width: 'auto',
    },
});

export const skeleton: TRuleWithTheme = ({ theme }) => ({
    '& .ant-skeleton-title': {
        width: '100%',
        height: 32,
        margin: '0.75rem 0 2.25rem',
        borderRadius: theme.borderRadius.large,
    },
});
