import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useFelaEnhanced } from 'hooks';

import { BadgeAvatar, Button, Icon, Paragraph, List } from 'modules/ui';
import type { Schema } from 'modules/entities';
import { useDeleteAccountInList, RevokeAccountButton } from 'modules/social-accounts';

import * as felaRules from './AccountGroupListItem.styles';

type UseSortableResult = ReturnType<typeof useSortable>;

interface SortableProps {
    attributes?: UseSortableResult['attributes'];
    listeners?: UseSortableResult['listeners'];
    setNodeRef?: UseSortableResult['setNodeRef'];
    transform?: UseSortableResult['transform'];
    transition?: UseSortableResult['transition'];
}

export interface AccountGroupListItemProps extends SortableProps {
    account: Schema['Account'];
    isDragging?: boolean;
    isDragOverlay?: boolean;
}

const AccountGroupListItem: FC<AccountGroupListItemProps> = ({
    account,
    isDragging = false,
    isDragOverlay = false,
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { isDragging, isDragOverlay });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const [isDeleteInProgress, deleteAccount] = useDeleteAccountInList();

    return (
        <div ref={setNodeRef} className={styles.wrapper} style={style}>
            <List.Item
                key={account.id}
                actions={[
                    <RevokeAccountButton
                        key="revoke"
                        type="text"
                        loading={isDeleteInProgress(account.id)}
                        disabled={isDeleteInProgress()}
                        extend={{ button: felaRules.button }}
                        onClick={() => deleteAccount(account.id)}
                    >
                        <FormattedMessage id="general.revoke" />
                    </RevokeAccountButton>,
                    <Button
                        {...attributes}
                        {...listeners}
                        key="drag"
                        type="text"
                        icon={<Icon type={Icon.Types.DRAG} />}
                        extend={{ button: rules.dragButton }}
                    />,
                ]}
            >
                <List.Item.Meta
                    avatar={
                        <BadgeAvatar
                            src={account.profilePicture}
                            alt={account.name}
                            badgeIconType={
                                account.type === 'facebook' ? Icon.Types.FACEBOOK_ROUND : Icon.Types.INSTAGRAM_ROUND
                            }
                        />
                    }
                    description={<Paragraph>{account.name}</Paragraph>}
                />

                <Button
                    {...attributes}
                    {...listeners}
                    key="drag"
                    type="text"
                    icon={<Icon type={Icon.Types.DRAG} />}
                    extend={{ button: rules.dragButtonMobile }}
                />
            </List.Item>
        </div>
    );
};

export default AccountGroupListItem;
