import type { TRule } from 'fela';

export const logout: TRule<{ isEditMode: boolean }> = ({ isEditMode }) => ({
    textAlign: 'center',
    marginTop: '1.5rem',

    position: 'relative',

    opacity: isEditMode ? 0.5 : 1,

    ':after': isEditMode
        ? {
              content: '" "',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
          }
        : {},
});
