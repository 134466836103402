import { create } from '@ackee/antonio';
import type { AxiosResponse } from 'axios';

import config from 'config';
import type { SagaIterator } from 'redux-saga';

// TODO: currently we need to type response explicitly via `response: Response`.
// Figure out how to type using SagaIterator<Response> to automatically infer response type from ApiPost generics
export type ApiPost<Response, RequestBody = {}, RequestParams = undefined> = (
    endpoint: string,
    requestParams: RequestBody & (RequestParams extends undefined ? {} : { uriParams: RequestParams }),
) => SagaIterator<Response>;

export type ApiGet<Response, RequestParams = {}> = (
    endpoint: string,
    requestParams: RequestParams extends {} ? {} : { uriParams: RequestParams },
) => SagaIterator<Response>;

export type ApiPut<Response, RequestBody = {}, RequestParams = undefined> = (
    endpoint: string,
    requestParams: RequestBody & (RequestParams extends undefined ? {} : { uriParams: RequestParams }),
) => SagaIterator<Response>;

export type ApiDelete<Response, RequestParams = undefined> = (
    endpoint: string,
    requestParams: RequestParams extends undefined ? {} : { uriParams: RequestParams },
) => SagaIterator<Response>;

export type ApiResponse<ResponseData> = AxiosResponse<ResponseData>;

export type ApiResponseWithHeaders<ResponseData, ResponseHeaders> = Omit<AxiosResponse<ResponseData>, 'headers'> & {
    headers: ResponseHeaders;
};

const { api, authApi, saga } = create(
    {
        baseURL: config.api.url,
    },
    {
        // manageAuthUser: false
    },
);

export { api, authApi, saga };
