import type { FieldValidatorWithMessage, FieldValidator } from './types';

export const composeValidators = (
    ...validators: Array<FieldValidatorWithMessage>
): FieldValidatorWithMessage => value =>
    validators.reduce((error: string, validator: FieldValidatorWithMessage) => error || validator(value), undefined);

export const setValidatorMessage = (
    validator: FieldValidator,
    messageId: string,
): FieldValidatorWithMessage => value => {
    const isValid = validator(value);
    return isValid ? undefined : messageId;
};

export const translate = <FieldValue = string>(
    formatMessage: ({ id: string }) => string,
    validator: FieldValidatorWithMessage<FieldValue>,
): FieldValidatorWithMessage<FieldValue> => value => {
    const messageId = validator(value);
    return messageId ? formatMessage({ id: messageId }) : undefined;
};
