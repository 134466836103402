import React, { FC } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import config from 'config';
import { BlankPage, Button, Icon, Modal } from 'modules/ui';
import { useFormSuccessState } from 'modules/form';

import ResetPasswordForm from '../ResetPasswordForm';
import ResetPasswordSuccess from '../ResetPasswordSuccess';

import * as felaRules from './ResetPasswordPage.styles';

export interface ResetPasswordPageProps {}

const ResetPasswordPage: FC<ResetPasswordPageProps> = () => {
    const { isForm, isSuccess, setSuccessState } = useFormSuccessState();
    const dispatch = useDispatch();
    const goToLogin = () => dispatch(push(config.routes.login));

    return (
        <BlankPage>
            {isForm && (
                <Button
                    shape="circle"
                    icon={<Icon type={Icon.Types.BACK} />}
                    extend={{ button: felaRules.back }}
                    onClick={goToLogin}
                />
            )}
            <Modal mask={false} closable={false} visible={true} centered footer={null}>
                {isForm && <ResetPasswordForm onSubmitSucceeded={setSuccessState} />}
                {isSuccess && <ResetPasswordSuccess onClose={goToLogin} />}
            </Modal>
        </BlankPage>
    );
};

export default ResetPasswordPage;
