import type { FC } from 'react';
import { Tabs as AntdTabs, TabsProps } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as rules from './Tabs.styles';

export interface TabsOwnProps extends TabsProps {}

const Tabs: FC<TabsOwnProps> & { TabPane: typeof AntdTabs.TabPane } = ({ children, ...props }) => {
    const { styles } = useFelaEnhanced(rules);

    return (
        <AntdTabs {...props} className={styles.tabs}>
            {children}
        </AntdTabs>
    );
};

Tabs.TabPane = AntdTabs.TabPane;

export default Tabs;
