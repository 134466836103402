import type { TRuleWithTheme } from 'styles/theme';

export const tag: TRuleWithTheme = ({ theme }) => ({
    fontSize: '0.875rem',
    padding: `0.375rem ${theme.spacing.xsmall}`,

    borderColor: theme.colors.primarySelected,

    '& .ant-tag-close-icon': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: theme.spacing.xsmall,

        color: theme.colors.textDark,
    },

    '& .anticon svg': {
        width: 8,
        height: 8,
    },
});
