import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { getTabIndex, generateRandomDrawPath } from '../../services/utils';

import type { RandomDrawTab } from '../../types';

export interface RandomDrawTabTitleProps {
    tab: RandomDrawTab;
    currentTabIndex: number;
}

const RandomDrawTabTitle: FC<RandomDrawTabTitleProps> = ({ tab, currentTabIndex }) => {
    const tabIndex = getTabIndex(tab);

    const title = (
        <>
            {tabIndex + 1}. <FormattedMessage id={`randomDraw.tab.${tab}`} />
        </>
    );

    return currentTabIndex >= tabIndex ? <Link to={generateRandomDrawPath(tab)}>{title}</Link> : title;
};

export default RandomDrawTabTitle;
