import { generatePath } from 'react-router-dom';

import config from 'config';
import { RandomDrawTab } from '../types';
import { randomDrawTabs } from '../constants';

export const generateRandomDrawPath = (tab: RandomDrawTab | null = null) =>
    generatePath(config.routes.randomDraw, { tab });

export const getTabIndex = (tab: RandomDrawTab) => randomDrawTabs.indexOf(tab);
export const indexToTab = (index: number) => randomDrawTabs[index];

export const getNextTab = (tab: RandomDrawTab) => indexToTab(getTabIndex(tab) + 1);
