import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Form as FinalForm } from 'react-final-form';

import type { FormProps, FormRenderProps } from 'react-final-form';
import type { FormProps as AntdFormProps } from 'antd';
import type { RulesExtend } from 'styles/theme';

import AntdForm from './AntdForm';
import * as felaRules from './Form.styles';

export interface OwnFormProps<FormValues = Record<string, any>> extends FormProps<FormValues> {
    name: string;
    children: FC<FormRenderProps<FormValues>>;
    antdProps?: AntdFormProps;
    onSubmitSucceeded?: Function;
    extend?: RulesExtend<typeof felaRules>;
}

const Form = <FormValues extends unknown = Record<string, any>>({
    name,
    children,
    antdProps = {
        layout: 'vertical',
    },
    onSubmitSucceeded,
    extend,
    ...props
}: OwnFormProps<FormValues>) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.form}>
            <FinalForm<FormValues> {...props}>
                {formRenderProps => (
                    <AntdForm
                        {...antdProps}
                        submitSucceeded={formRenderProps.submitSucceeded}
                        onSubmit={formRenderProps.handleSubmit}
                        reset={formRenderProps.form.reset}
                        onSubmitSucceeded={onSubmitSucceeded}
                        initialValues={props.initialValues}
                    >
                        {children(formRenderProps)}
                    </AntdForm>
                )}
            </FinalForm>
        </div>
    );
};

export default Form;
