import { call } from 'redux-saga/effects';
import { createExpirationDate } from '@ackee/petrus';

import config from 'config';
import { api } from 'config/antonio';
import { operations } from 'api/apiSchema';
import type { ApiPost, ApiResponse } from 'config/antonio';

type Operation = operations['refreshToken'];
type RequestBody = Operation['requestBody']['content']['application/json'];
type Response = ApiResponse<Operation['responses']['200']['content']['application/json']>;

export default function* refreshTokens({ refreshToken }) {
    const response: Response = yield call<ApiPost<Response, RequestBody>>(api.post, config.api.endpoints.refreshToken, {
        refreshToken: refreshToken.token,
    });
    const { credentials, user } = response.data;

    return {
        accessToken: {
            token: credentials.accessToken,
            userId: user.id,
            expiration: createExpirationDate(credentials.expiresIn * 1000),
        },
        refreshToken: {
            token: credentials.refreshToken,
            expiration: createExpirationDate(credentials.refreshExpiresIn * 1000),
        },
    };
}
