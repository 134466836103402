import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectPostDetail } from 'modules/entities/modules/posts';
import type { Post } from 'modules/entities';

import type { PlanPostFormValues, PlanPostLocationState } from '../types';
import { selectAccount } from 'modules/entities/modules/accounts';
import { OAuthProvider } from 'modules/auth';
import { PlanPostField } from '../config';
import { useIntl } from 'react-intl';
import { useFetchCompetition } from 'modules/entities/modules/competitions';
import { useCompetition } from 'modules/entities/modules/competitions';

const usePlanPostFormInitialValues = () => {
    const location = useLocation<PlanPostLocationState>();
    const { competitionId, postId, accountId } = location.state ?? {};
    const intl = useIntl();
    useFetchCompetition(competitionId);

    const competition = useCompetition(competitionId);
    const post: Post = useSelector(state => selectPostDetail(state, { id: postId }));
    const account = useSelector(state => selectAccount(state, { id: accountId ?? post?.accountId }));

    const initialValues: PlanPostFormValues = {
        accountId: post?.accountId ?? accountId,
    };

    if (competitionId && account) {
        initialValues[account.type === 'facebook' ? OAuthProvider.Facebook : OAuthProvider.Instagram] = {
            [PlanPostField.CAPTION]: intl.formatMessage(
                { id: 'randomDraw.results.winner.post' },
                {
                    winner: competition?.winningUsers?.[0]?.providerUsername,
                    numberOfComments: competition?.commentsTotal,
                },
            ),
            [PlanPostField.FIRST_COMMENT]: '',
        };
    } else if (post && account) {
        initialValues[account.type === 'facebook' ? OAuthProvider.Facebook : OAuthProvider.Instagram] = {
            [PlanPostField.CAPTION]: post.content,
            [PlanPostField.FIRST_COMMENT]: post.comment,
        };
    }

    return initialValues;
};

export default usePlanPostFormInitialValues;
