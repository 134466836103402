import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use-storage';
import { useParams } from 'react-router-dom';

import { localStorageKeys } from 'constants/index';

import { getFirstInvalidTab } from '../validators';
import { RandomDrawTab, RandomDrawFormValues } from '../types';
import { getTabIndex, indexToTab } from '../services/utils';

// TODO: Tests

const useFirstInvalidTab = () => {
    const { tab: currentTab } = useParams<{ tab?: RandomDrawTab }>();

    const [storedValues] = useLocalStorage<RandomDrawFormValues>(localStorageKeys.RANDOM_DRAW_FORM, null);

    const [tab, setTab] = useState<RandomDrawTab>(null);

    useEffect(() => {
        if (!storedValues) {
            if (currentTab !== RandomDrawTab.POST) {
                setTab(RandomDrawTab.POST);
            }
            return;
        }
        const invalidTab = getFirstInvalidTab(storedValues);

        if (!invalidTab) {
            setTab(RandomDrawTab.SUMMARY);
        } else if (invalidTab === RandomDrawTab.POST) {
            setTab(invalidTab);
        } else {
            setTab(indexToTab(getTabIndex(invalidTab)));
        }
    }, [storedValues, currentTab]);

    return tab;
};

export default useFirstInvalidTab;
