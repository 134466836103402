import { all } from 'redux-saga/effects';

import { saga as accounts } from '../modules/accounts';
import { saga as accountGroups } from '../modules/account-groups';
import { saga as competitions } from '../modules/competitions';
import { saga as media } from '../modules/media';
import { saga as facebookPosts } from '../modules/facebook-posts';
import { saga as instagramPosts } from '../modules/instagram-posts';
import { saga as posts } from '../modules/posts';
import { saga as articles } from '../modules/articles';

import { saga as jobs } from '../modules/jobs';
import { saga as videoSettings } from '../modules/video-settings';

export default function* entitiesSaga() {
    yield all([
        accounts(),
        accountGroups(),
        competitions(),
        media(),
        articles(),
        facebookPosts(),
        instagramPosts(),
        posts(),
        jobs(),
        videoSettings(),
    ]);
}
