import type { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';

import useFelaEnhanced from 'hooks/useFelaEnhanced';
import type { AccountGroup, Account } from 'modules/entities';
import { List } from 'modules/ui';

import { AccountGroupListItemDraggable } from './AccountGroupListItem';
import * as felaRules from './AccountGroupList.styles';

export interface AccountGroupListProps {
    groupId: AccountGroup['id'];
    accounts: Account[];
    draggingAccountId?: string;
    items: string[];
}

const AccountGroupList: FC<AccountGroupListProps> = ({ groupId, draggingAccountId, accounts, items }) => {
    const { over, isOver, setNodeRef } = useDroppable({ id: groupId });

    const isDraggingOverGroup = isOver || (over ? items.includes(over.id) : false);
    const { styles } = useFelaEnhanced(felaRules, { isDraggingOverGroup });

    return (
        <div ref={setNodeRef} className={styles.wrapper}>
            <List bordered>
                {accounts.map(account => (
                    <AccountGroupListItemDraggable
                        key={account.id}
                        account={account}
                        isDragging={draggingAccountId === account.id}
                    />
                ))}
            </List>
        </div>
    );
};

export default AccountGroupList;
