import { createSelector } from 'reselect';

import type { Account } from 'modules/entities';
import { selectFacebookPosts } from 'modules/entities/modules/facebook-posts';
import { selectInstagramPosts } from 'modules/entities/modules/instagram-posts';
import { selectSocialPost } from 'modules/post/services/selectors';
import { selectProp } from 'services/selectors';

export const selectPosts = createSelector(
    [selectProp<Account['type']>('type'), selectFacebookPosts, selectInstagramPosts, selectSocialPost],
    (type, facebookPosts, instagramPots, post) => {
        if (!type) {
            return [];
        }

        let posts;
        switch (type) {
            case 'facebook':
                posts = facebookPosts;
                break;
            case 'instagram':
                posts = instagramPots;
        }

        if (post && !posts.some(foundPost => foundPost.id === post.id)) {
            posts.unshift(post);
        }

        return posts;
    },
);
