import { createSelector } from 'reselect';
import { uniq } from 'lodash';

import type { Post, Competition } from 'modules/entities';
import { selectPostDetail } from 'modules/entities/modules/posts';
import { selectMediaEntities } from 'modules/entities';
import { MediaGroup } from 'modules/entities/modules/media';
import { selectDenormalizedCompetition } from 'modules/entities/modules/competitions';

export const selectNewPostMediaIdsFactory = (
    group: MediaGroup,
    postId?: Post['id'],
    competitionId?: Competition['id'],
) =>
    createSelector(
        [
            selectMediaEntities,
            state => selectPostDetail(state, { id: postId }),
            state => selectDenormalizedCompetition(state, { id: competitionId }),
        ],
        (media, post, competition) => {
            let mediaIds = [];
            if (competition) {
                mediaIds = [competition.videoId];
            }
            if (post) {
                mediaIds = post.media.map(media => media.id);
            }
            if (media[group]) {
                mediaIds = mediaIds.concat(media[group]);
            }
            return uniq(mediaIds);
        },
    );

export const selectNewPostMediaFactory = (
    group: MediaGroup,
    postId?: Post['id'],
    competitionId?: Competition['id'],
) => {
    if (competitionId) {
        return createSelector(
            state => selectDenormalizedCompetition(state, { id: competitionId }),
            competition => [
                { url: competition?.videoUrl, id: competition?.videoId, filename: competition?.videoFileName },
            ],
        );
    }
    const selectMediaIds = selectNewPostMediaIdsFactory(group, postId);

    return createSelector([selectMediaEntities, selectMediaIds], (mediaEntities, ids) =>
        ids.map(id => mediaEntities.byId[id]),
    );
};
