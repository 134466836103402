import { createAction } from '@reduxjs/toolkit';

import { createFormActions } from 'modules/form';

import { MODULE_PREFIX } from '../constants';
import type { PixabayImage, ImageSearchFormValues } from '../types';

export const searchImages = createFormActions<ImageSearchFormValues>(`${MODULE_PREFIX}/SEARCH_IMAGES`);

export const setImages = createAction(`${MODULE_PREFIX}/SET_IMAGES`, (payload: PixabayImage[]) => ({
    payload,
}));

export const resetImages = createAction(`${MODULE_PREFIX}/RESET_IMAGES`);
