import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const heading: TRule = () => ({
    '&.ant-typography': {
        marginBottom: '1.5rem',
    },
});

export const cards: TRuleWithTheme<{ picture: boolean }> = ({ picture }) => ({
    display: 'flex',
    flexWrap: 'wrap',

    marginTop: '0.75rem',
    marginBottom: picture ? '2rem' : 0,
    padding: 0,
});
