import type { FC } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import { RandomDrawTab } from 'modules/random-draw/types';

import { useFirstInvalidTab } from '../../hooks';
import { getTabIndex, generateRandomDrawPath } from '../../services/utils';

const RandomDrawTabRedirect: FC = () => {
    const { tab } = useParams<{ tab?: RandomDrawTab }>();
    const firstInvalidTab = useFirstInvalidTab();

    if (!firstInvalidTab || getTabIndex(firstInvalidTab) >= getTabIndex(tab)) {
        return null;
    }

    return <Redirect to={generateRandomDrawPath(firstInvalidTab)} />;
};

export default RandomDrawTabRedirect;
