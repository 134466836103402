import type { TRule } from 'fela';
import { Theme } from 'styles/theme';

export interface ParagraphStyleProps {
    size?: 'x-small' | 'small' | 'medium' | 'large';
    weight?: 'regular' | 'bold';
    faded?: boolean;
}

const fontSizeMap = {
    'x-small': '0.8125rem',
    small: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
};

const lineHeightMap = {
    'x-small': 1.2,
    small: 1.43,
    medium: 1.5,
    large: 1.45,
};

export const paragraph: TRule<ParagraphStyleProps & { theme: Theme }> = ({ theme, size, weight, faded }) => ({
    fontSize: fontSizeMap[size],
    lineHeight: lineHeightMap[size],
    fontWeight: weight === 'regular' ? 400 : 700,
    color: faded ? theme.colors.textGray : theme.colors.textDark,

    marginBottom: 0,

    '& a': {
        textDecoration: 'underline',
        ...(faded && { color: 'currentColor' }),

        ':hover': {
            textDecoration: 'none',
        },
    },
});
