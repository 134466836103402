import React from 'react';
import type { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';

import AccountGroupListItem from './AccountGroupListItem';
import type { AccountGroupListItemProps } from './AccountGroupListItem';

type AccountGroupListItemDraggableProps = Pick<AccountGroupListItemProps, 'account' | 'isDragging'>;

const AccountGroupListItemDraggable: FC<AccountGroupListItemDraggableProps> = ({ account, isDragging = false }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: account.id });

    return (
        <AccountGroupListItem
            account={account}
            {...{ attributes, listeners, setNodeRef, transform, transition }}
            isDragging={isDragging}
        />
    );
};

export default AccountGroupListItemDraggable;
