import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Button } from 'modules/ui';
import { ErrorAlert } from 'modules/errors';
import { Form, TextField, useFormSubmit, validatorsWithMessage, composeValidators, translate } from 'modules/form';

import PasswordRecovery from '../PasswordRecovery';
import * as felaRules from './LoginForm.styles';
import { LoginFormField, forms } from '../../config';
import { loginForm } from '../../services/actions';
import { oauthApiSelector } from '../../services/selectors';

const { required, email } = validatorsWithMessage;

const validators = {
    [LoginFormField.Email]: composeValidators(required, email),
    [LoginFormField.Password]: required,
};

const LoginForm: FC = () => {
    const { rules } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    const onSubmit = useFormSubmit(loginForm);
    const oauthApi = useSelector(oauthApiSelector);

    return (
        <Form name={forms.login} onSubmit={onSubmit} subscription={{ submitting: true, submitError: true }}>
            {({ submitting, submitError }) => (
                <>
                    <ErrorAlert error={submitError ?? oauthApi.error} extend={{ alert: rules.alert }} />

                    <TextField
                        name={LoginFormField.Email}
                        type="email"
                        label={<FormattedMessage id="auth.email" />}
                        validate={translate(formatMessage, validators[LoginFormField.Email])}
                    />
                    <TextField
                        name={LoginFormField.Password}
                        type="password"
                        label={<FormattedMessage id="auth.password" />}
                        validate={translate(formatMessage, validators[LoginFormField.Password])}
                    />

                    <PasswordRecovery extend={{ container: rules.passwordRecovery }} />

                    <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        block
                        disabled={submitting || oauthApi.inProgress}
                        loading={submitting}
                    >
                        <FormattedMessage id="auth.login.button" />
                    </Button>
                </>
            )}
        </Form>
    );
};

export default LoginForm;
