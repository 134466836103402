import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/reducers';

import type { Article } from 'modules/entities';

import { fetchArticle } from '../services/actions';
import { selectArticleApi } from '../services/selectors';

const useFetchArticle = (id: Article['id']) => {
    const api: ApiReducerState = useSelector(selectArticleApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchArticle.request(id));

        return () => {
            batch(() => {
                dispatch(fetchArticle.cancel(id));
                dispatch(fetchArticle.reset(id));
            });
        };
    }, [dispatch, id]);

    return api;
};

export default useFetchArticle;
