import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/reducers';

import type { Competition } from 'modules/entities';

import { fetchCompetition } from '../services/actions';

import { selectCompetitionApi } from '../services/selectors';

const useFetchCompetition = (id?: Competition['id']) => {
    const api: ApiReducerState = useSelector(selectCompetitionApi);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id && !api.inProgress) {
            dispatch(fetchCompetition.request(id));
        }

        return () => {
            if (id) {
                batch(() => {
                    dispatch(fetchCompetition.cancel(id));
                    dispatch(fetchCompetition.reset(id));
                });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    return api;
};

export default useFetchCompetition;
