import { createFormActions } from 'modules/form';

import * as types from './types';
import { MODULE_PREFIX } from '../../constants';

export { types };
export { actions as addAccountsRequestIds } from '../reducers/addAccountsRequestIds';
export * from './oauth';
export * from './user';

export const loginForm = createFormActions(`${MODULE_PREFIX}/LOGIN_FORM`);
export const signUpForm = createFormActions(`${MODULE_PREFIX}/SIGN_UP_FORM`);
export const passwordRecoveryForm = createFormActions(`${MODULE_PREFIX}/PASSWORD_RECOVERY_FORM`);
export const resetPasswordForm = createFormActions(`${MODULE_PREFIX}/RESET_PASSWORD_FORM`);
