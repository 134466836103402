import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.POSTS,
});

export const fetchPost = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_POST_' }),
});

export const createPost = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'CREATE_POST_' }),
});

export const updatePost = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'UPDATE_POST_' }),
});
