import { init, createReduxEnhancer, reactRouterV5Instrumentation } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from 'config/config';
import { enableSentry, currentEnv, isEnvProduction } from 'constants/index';
import { history } from 'modules/core/modules/router/config';

if (enableSentry) {
    init({
        dsn: config.sentry.dsn,
        debug: false,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: reactRouterV5Instrumentation(history),
            }),
        ],
        environment: currentEnv,
        release: `rafflu-web@${process.env.REACT_APP_VERSION}`,
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
        ],
        normalizeDepth: 5,
        tracesSampleRate: isEnvProduction ? 0.25 : 1.0,
    });
}

export const sentryReduxEnhancer = createReduxEnhancer();
