import React, { FunctionComponent } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Paragraph.styles';

export interface ParagraphProps extends felaRules.ParagraphStyleProps {
    extend?: RulesExtend<typeof felaRules>;
}

const Paragraph: FunctionComponent<ParagraphProps> = ({
    size = 'medium',
    weight = 'regular',
    faded = false,
    extend,
    children,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, size, weight, faded });

    return <p className={styles.paragraph}>{children}</p>;
};

export default Paragraph;
