import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Counter, { CounterProps } from '../Counter';

export interface HashtagsCounterProps extends Omit<CounterProps, 'count' | 'title'> {
    value: string;
}

const HashtagsCounter: FC<HashtagsCounterProps> = ({ value, max, ...props }) => {
    const hashtagsCount = value ? (value.match(/(^|\s)(#[a-z\d-]+)/g) || []).length : 0;

    return (
        <Counter
            {...props}
            count={hashtagsCount}
            max={max}
            title={<FormattedMessage id="randomDraw.rules.hashtags.title" />}
        />
    );
};

export default HashtagsCounter;
