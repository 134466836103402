import { combineReducers } from 'redux';
import { ApiReducerKey } from 'modules/entities/constants';

import detail from './detail';
import create from './create';

export default combineReducers({
    [ApiReducerKey.DETAIL]: detail,
    [ApiReducerKey.CREATE]: create,
});
