import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useToast } from 'modules/ui';

const useErrorToast = (errorId: string, customTitleId?: string) => {
    const openToast = useToast();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (errorId) {
            openToast({
                type: 'error',
                message: customTitleId && formatMessage({ id: customTitleId }),
                description: formatMessage({ id: errorId }),
            });
            
        }
    }, [openToast, errorId, customTitleId, formatMessage]);
};

export default useErrorToast;
